import React from "react";
import "./styles/footer.css";
import LocationIcon from "../../res/icons/location";

export default function Footer() {
  return (
    <div className="footer bg-navy flex desktop:!hidden">
      <p
        className="flex items-center"
        style={{ padding: "0.5rem", fontSize: "0.75rem" }}
      >
        <span>© 2023 Whole Groom</span>
      </p>

      <div id="right-row" className="flex h-full">
        {/* <a
            title="Instagram"
            className="h-full flex items-center justify-center"
            style={{ padding: "0.5em" }}
            href="https://www.instagram.com/wholegroom"
          >
            <Instagram />
          </a> */}
        <a
          className="h-full flex items-center justify-center"
          style={{ padding: "0.5em" }}
          title="Location"
          href="https://goo.gl/maps/xiVUx4LGdt71a5mB9"
        >
          <LocationIcon />
        </a>
      </div>
    </div>
  );
}
