

export default function ChatIcon(){


    return (
      <svg
        id="Chat"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 810 515.12"
        preserveAspectRatio="xMidYMid meet"
        width="100%"
      >
        <path
          id="bubble"
          d="M608.76,216.91a290.9,290.9,0,0,0-39.26,2.66C560.05,289.12,497.3,348,409.73,376.42c14.39,67,98,118.42,199,118.42a275.87,275.87,0,0,0,90.92-15l61.05,35.25V447c30.69-24.39,49.27-56.25,49.27-91.1C810,279.13,719.9,216.91,608.76,216.91Z"
          fill="#a4ce4e"
        />
        <path
          id="bubble-2"
          dataname="bubble"
          d="M608.76,216.91a290.9,290.9,0,0,0-39.26,2.66C560.05,289.12,497.3,348,409.73,376.42c14.39,67,98,118.42,199,118.42Z"
          fill="#c0d792"
        />
        <circle id="dot0" cx="541.91" cy="349.61" r="28.8" fill="#d9d9d6" />
        <circle id="dot1" cx="617.09" cy="349.61" r="28.8" fill="#f2f2f2" />
        <circle id="dot2" cx="692.27" cy="349.61" r="28.8" fill="#f2f2f2" />
        <path
          id="bubble-3"
          dataname="bubble"
          d="M280.18,0C125.44,0,0,86.62,0,193.47,0,242,25.87,286.34,68.6,320.3v94.89l85-49.07c38,13.32,81,20.83,126.58,20.83,154.73,0,280.17-86.62,280.17-193.48S434.91,0,280.18,0Z"
          fill="#da1a5d"
        />
        <path
          id="bubble-4"
          dataname="bubble"
          d="M280.18,0h0C125.44,0,0,86.62,0,193.47,0,242,25.87,286.34,68.6,320.3v94.89l85-49.07c38,13.32,81,20.83,126.58,20.83Z"
          fill="#e04f87"
        />
        <rect
          id="line0"
          x="101.88"
          y="128.96"
          width="307.85"
          height="48.48"
          rx="3.68"
          fill="#f2f2f2"
        />
        <rect
          id="line1"
          x="101.88"
          y="212.76"
          width="158.73"
          height="48.48"
          rx="3.68"
          fill="#f2f2f2"
        />
        <path
          id="line0-2"
          dataname="line0"
          d="M295.23,129H406.64a3.09,3.09,0,0,1,3.09,3.09v42.29a3.09,3.09,0,0,1-3.09,3.09H227.1Z"
          fill="#d9d9d6"
        />
        <path
          id="line1-2"
          dataname="line1"
          d="M196.82,212.76h60.69a3.1,3.1,0,0,1,3.1,3.1v42.29a3.1,3.1,0,0,1-3.1,3.09H143.83Z"
          fill="#d9d9d6"
        />
        <path
          id="dot0-2"
          dataname="dot0"
          d="M541.91,378.42a28.81,28.81,0,0,1,0-57.61Z"
          fill="#f2f2f2"
        />
        <path
          id="dot1-2"
          dataname="dot1"
          d="M617.09,320.81a28.81,28.81,0,0,1,0,57.61Z"
          fill="#d9d9d6"
        />
        <path
          id="dot2-2"
          dataname="dot2"
          d="M692.27,320.81a28.81,28.81,0,0,1,0,57.61Z"
          fill="#d9d9d6"
        />
      </svg>
    );
}