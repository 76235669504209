import React from "react";

const LocationIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      preserveAspectRatio="xMidYMid meet"
      height="80%"
      viewBox="0 0 37 37"
    >
      <defs></defs>
      <circle id="Oval" fill="#d9d9d6" cx="50%" cy="50%" r="50%"></circle>
      <g
        id="Vivid.JS"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="Vivid-Icons" transform="scale(0.6) translate(16.75,12.5)">
          <g id="Icons">
            <g id="map-marker">
              <g>
                <path
                  d="M14,0 C21.732,0 28,5.641 28,12.6 C28,23.963 14,36 14,36 C14,36 0,24.064 0,12.6 C0,5.641 6.268,0 14,0 Z"
                  id="Shape"
                  fill="none"
                  stroke="#190740"
                  strokeWidth="3.5"
                ></path>
                <circle
                  id="Oval"
                  fill="none"
                  fillRule="nonzero"
                  stroke="#190740"
                  strokeWidth="3.5"
                  cx="14"
                  cy="14"
                  r="7"
                ></circle>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default LocationIcon;
