import React from "react";

export default function Facebook({ onClick }) {
  return (
    <svg
      id="Facebook"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 60.06 60.06"
      preserveAspectRatio="xMidYMid meet"
      height="100%"
      className="cursor-pointer"
      title="Facebook"
      onClick={onClick}
    >
      <rect
        x="7.5"
        y="7.5"
        height="45"
        width="45"
        stroke="#333"
        fill="none"
        rx="10"
        strokeWidth="4"
      ></rect>
      <path
        d="M43.1,20.8H40.28c-2.83,0-3.75,1.77-3.75,3.59V29h6.38l-1,6.61H36.55v16H29.42v-16H23.56V29h5.86V24c0-5.74,3.4-8.93,8.64-8.93a33.14,33.14,0,0,1,5.11.47Z"
        transform="translate(3 3) scale(0.8)"
        fill="none"
        stroke="#333"
        strokeWidth="4"
      ></path>
    </svg>
  );
}
