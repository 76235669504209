import React, { useState, useEffect, useRef } from "react";
import AWS from "aws-sdk";
import pawBackground from "../../res/assets/pawBackground.svg";
import { useCallback } from "react";
import "./styles.css";
import Logo from "../../res/assets/Logo";

AWS.config.update({
  accessKeyId: "AKIAS4YHYXTX6OKSIJFQ",
  secretAccessKey: "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",
  region: "us-west-1",
});
const s3 = new AWS.S3();

const params = {
  Bucket: "mgmanga",
};

/**
 *
 * @TODO - Clean Up Code + Hide AWZ Key & Configuration
 */

const Office = () => {
  const [listFiles, setListFiles] = useState([]);
  const [index, setIndex] = useState({
    shown: { 0: 0, 1: 1, 2: 2, 3: 3, 4: 4, next: 5 },
    carouselIndexes: [-2, -1, 0, 1, 2],
  });

  const timeoutRef = useRef();

  const fetchUrls = useCallback(async (list) => {
    const reqs = list?.map((Key) =>
      s3.getSignedUrlPromise("getObject", { Key, Bucket: "mgmanga" })
    );

    Promise.all(reqs).then((res) => {
      const brandBreak = (l, i) => {
        if ((i + 1) % 5 === 0)
          l.push(
            <div className="flex justify-center items-center h-full w-full bg-white">
              <Logo width="95%" color="#190740" />
            </div>
          );
      };
      setListFiles(
        res?.reduce((list, url, index) => {
          switch (true) {
            case url.includes(".MP4"):
              brandBreak(list, index);
              list.push(
                <video
                  style={{
                    objectFit: "contain",
                    height: "100%",
                    width: "100%",
                  }}
                  autoPlay
                  muted
                  loop
                >
                  <source src={url} type="video/mp4" />
                </video>
              );
              break;
            case url.includes(".mov"):
              brandBreak(list, index);
              list.push(
                <video
                  style={{
                    objectFit: "contain",
                    height: "100%",
                    width: "100%",
                  }}
                  autoPlay
                  muted
                  loop
                >
                  <source src={url} />
                </video>
              );
              break;
            default:
              brandBreak(list, index);
              list.push(
                <img
                  src={url}
                  style={{
                    objectFit: "cover",
                    height: "100%",
                    width: "100%",
                  }}
                  alt={`mediaFile-${index}`}
                />
              );
          }
          return list;
        }, [])
      );
    });
  }, []);

  useEffect(() => {
    s3.listObjects(params, (err, data) => {
      if (err) {
        console.error(err, err.stack);
      } else {
        
        fetchUrls(data.Contents?.map(({ Key }) => Key));
      }
    });
  }, [fetchUrls, listFiles.length]);

  /**
   * Traverse Media Functions
   */

  const updateShown = useCallback(
    (shown, carousel) => {
      const positions = [...carousel.slice(1), carousel[0]];
      const { next: prevNext, ...rest } = shown;
      const next = prevNext + 1 > listFiles.length - 1 ? 0 : prevNext + 1;
      const newImageIndex = positions.findIndex((item) => item === -2);
      return {
        carouselIndexes: positions,
        shown: { ...rest, next, [newImageIndex]: prevNext },
      };
    },
    [listFiles.length]
  );

  function resetTimeout() {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }

  useEffect(() => {
    resetTimeout();
    if (listFiles?.[index]?.type !== "video") {
      timeoutRef.current = setTimeout(
        () =>
          setIndex(({ shown, carouselIndexes }) =>
            updateShown(shown, carouselIndexes)
          ),
        5000
      );
    }

    return () => {
      resetTimeout();
    };
  }, [index, listFiles, listFiles.length, updateShown]);

  const styling = useCallback((position) => {
    const zIndex = -Math.abs(position) + 2;
    return {
      position: "absolute",
      width: "30%",
      height: "100%",
      "--rs": `${position}`,
      "--abs": `max(calc(var(--rs) * -1), var(--rs))`,
      transition: "all 0.25s linear",
      transform:
        "rotateY(calc(-10deg * var(--rs))) translateX(calc(-300px * var(--rs)))",
      zIndex,
    };
  }, []);

  const overlay = useCallback((position) => {
    switch (-Math.abs(position) + 2) {
      case 0:
        return "secondDegree";
      case 1:
        return "firstDegree";
      default:
        return "";
    }
  }, []);

  /**
   * Traverse Media Functions
   */

  return (
    <div
      className="card"
      style={{
        width: "100vw",
        height: "100vh",
        position: "absolute",
        top: "0",
        left: "0",
        zIndex: "999",
        backgroundImage: `url(${pawBackground})`,
        backgroundColor: "white",
        backgroundPosition: "right top",
        backgroundRepeat: "repeat",
        overflowY: "overlay",
      }}
    >
      <div className="w-full h-full flex-col justify-center items-center">
        <p
          className="text-center my-6 font-Monteria text-navy"
          style={{ fontSize: "4em" }}
        >
          Making Memories
        </p>
        <div className="carousel">
          <div
            className={`rounded-xl ${overlay(
              index.carouselIndexes[0]
            )} overflow-hidden`}
            style={styling(index.carouselIndexes[0])}
          >
            {listFiles?.[index.shown[0]]}
          </div>
          <div
            className={`rounded-xl ${overlay(
              index.carouselIndexes[1]
            )} overflow-hidden`}
            style={styling(index.carouselIndexes[1])}
          >
            {listFiles?.[index.shown[1]]}
          </div>
          <div
            className={`rounded-xl ${overlay(
              index.carouselIndexes[2]
            )} overflow-hidden`}
            style={styling(index.carouselIndexes[2])}
          >
            {listFiles?.[index.shown[2]]}
          </div>
          <div
            className={`rounded-xl ${overlay(
              index.carouselIndexes[3]
            )} overflow-hidden`}
            style={styling(index.carouselIndexes[3])}
          >
            {listFiles?.[index.shown[3]]}
          </div>
          <div
            className={`rounded-xl ${overlay(
              index.carouselIndexes[4]
            )} overflow-hidden`}
            style={styling(index.carouselIndexes[4])}
          >
            {listFiles?.[index.shown[4]]}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Office;
