import React, { useCallback, useState } from "react";
import "./styles.css";
import cntl from "cntl";
import { matchPath, useLocation, useNavigate } from "react-router-dom";

const routesCN = () => cntl`
absolute
top-full
flex
flex-col
`;

const titleCN = (active) => cntl`
${active && "underline"}
font-semibold
`;

const wrapperCN = () => cntl`
flex 
relative 
justify-end
`;

const intOpen = "M8,8 Q6,8 2,8 M8,5 L2,5 M2,2 Q4,2 8,2";

const intClose = "M2,2 Q8,8 8,8 M8,2 L2,8 M8,2 Q2,8 2,8";

const MenuButton = ({ routes }) => {
  const [open, setOpen] = useState(false);
  const [closing, setClosing] = useState(false);
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const handleOpen = useCallback(() => {
    if (open) {
      setClosing(true);
      setTimeout(() => {
        setOpen(false);
        setClosing(false);
      }, 500);
    } else {
      setOpen(true);
    }
  }, [open]);

  return (
    <div
      className={wrapperCN()}
      style={{ height: "inherit", width: "inherit" }}
    >
      <svg
        id="mobile_menuIcon"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 10 10"
        height="100%"
        preserveAspectRatio="xMaxYMid meet"
        className={open ? "menuiconclose py-4 z-[2]" : "menuiconopen py-4"}
        onClick={() => handleOpen()}
      >
        <path
          d={open ? intClose : intOpen}
          stroke="#190740"
          fill="none"
          strokeLinecap="round"
        />
      </svg>
      {open && (
        <div className={routesCN()}>
          {routes.map((item, i) => {
            return (
              <div
                className="row-span-1 bg-white text-navy rounded-lg px-6 py-2 flex items-center justify-center shadow-bottom my-4 cursor-pointer"
                style={{
                  animation: `${closing ? "moveOut" : "moveIn"} ${
                    i * 0.5 + 0.5
                  }s cubic-bezier(0.165, 0.84, 0.44, 1) forwards`,
                }}
                key={item?.name}
                onClick={() => {
                  navigate(item?.path);
                  handleOpen();
                }}
              >
                <p className={titleCN(matchPath(item, pathname))}>
                  {item?.name}
                </p>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default MenuButton;
