import React from "react";
import cntl from "cntl";
import PropTypes from "prop-types";
import "./buttonStyles.css";

const buttonCN = (className, threeD, icon, disabled) => cntl`
${className && className}
${threeD && "btn-3d"}
${icon && "flex items-center"}
${disabled && "!bg-gray-200 text-gray-600"}
`;

const BaseButton = ({
  onClick,
  className,
  label,
  threeD,
  iconRight,
  iconClassName,
  style,
  disabled,
}) => {
  return (
    <button
      className={buttonCN(className, threeD, iconRight, disabled)}
      style={style}
      onClick={!disabled ? onClick : undefined}
    >
      {label}
      {iconRight && (
        <img
          src={iconRight}
          alt="button-icon-right"
          className={iconClassName ?? "w-5 h-5 ml-1"}
        />
      )}
    </button>
  );
};

BaseButton.propTypes = {
  onClick: PropTypes.func,
  className: PropTypes.string,
  label: PropTypes.string,
  style: PropTypes.shape({}),
  disabled: PropTypes.bool,
};

BaseButton.defaultProps = {
  onClick: () => {},
  className: undefined,
  label: "Button",
  style: undefined,
  disabled: false,
};

export default BaseButton;
