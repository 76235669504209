import React from "react";

const Logo = ({ color, width }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 485.85 154.28"
      height={width ? undefined : "100%"}
      width={width ?? undefined}
      preserveAspectRatio="xMinYMid meet"
      className="inline-block pr-2 py-4"
    >
      <g id="Whole">
        <path
          id="w"
          d="M190.45,37.2l-9.8,27.7q-1.69,4.8-5.5,4.8t-5.7-5L150,12a11.1,11.1,0,0,0-2-3.95,7,7,0,0,0-3.3-1.35c-1.86-.4-2.8-1.3-2.8-2.7,0-1.73,1.14-2.6,3.4-2.6h20.6q3.41,0,3.4,2.7,0,2.3-3.6,2.6c-2.06.2-3.1,1-3.1,2.4a15.67,15.67,0,0,0,1.1,4.6l13.5,37.6,9.3-25.9-4.6-13.8a10.66,10.66,0,0,0-1.6-3.45,5.84,5.84,0,0,0-2.9-1.25c-1.93-.47-2.9-1.33-2.9-2.6q0-2.89,3.5-2.9h19.7q3.3,0,3.3,2.7,0,2.3-3.3,2.7t-3.3,2.7a16.24,16.24,0,0,0,1,4.2l12,36.6L219.05,12a7.84,7.84,0,0,0,.4-2.3c0-1.47-1.11-2.35-3.35-2.65s-3.35-1.25-3.35-2.85q0-2.8,3.8-2.8h15.2c2.14,0,3.2.9,3.2,2.7,0,1.61-1.16,2.58-3.5,2.91a4.48,4.48,0,0,0-2.7,1.2,11.53,11.53,0,0,0-1.7,3.81l-16,51.37a12.79,12.79,0,0,1-2.35,5,4.54,4.54,0,0,1-3.45,1.3,4.88,4.88,0,0,1-3.4-1.1,10,10,0,0,1-2.2-4.12Z"
          transform="translate(0)"
          fill={color ?? "#fff"}
        />
        <path
          id="h"
          d="M259.75,27.2h21.6V12.33a12.25,12.25,0,0,0-.4-4,2.18,2.18,0,0,0-1.9-1.11l-1.8-.3c-1.73-.27-2.6-1.17-2.6-2.7q0-2.8,4.7-2.8h18q4.61,0,4.6,2.8c0,1.53-.84,2.43-2.54,2.7l-1.82.3a2.22,2.22,0,0,0-1.93,1.1,12,12,0,0,0-.41,4V57.2a11.56,11.56,0,0,0,.41,3.95,2.48,2.48,0,0,0,1.93,1.15l1.82.3q2.64.4,2.64,2.7c0,1.87-1.56,2.8-4.7,2.8h-17.9q-4.69,0-4.7-2.8a2.56,2.56,0,0,1,2.5-2.7l1.8-.3a2.43,2.43,0,0,0,1.9-1.15,11.65,11.65,0,0,0,.4-3.95V39.5c0-2.67-.18-4.25-.55-4.75s-1.52-.75-3.45-.75h-13.3c-2,0-3.21.25-3.65.75s-.65,1.88-.65,4.15V57.2a13.4,13.4,0,0,0,.35,4A2.37,2.37,0,0,0,262,62.3l1.7.3a2.56,2.56,0,0,1,2.5,2.7q0,2.81-4.6,2.8h-17.9c-3.06,0-4.6-.93-4.6-2.8a2.56,2.56,0,0,1,2.5-2.7l1.8-.3a2.43,2.43,0,0,0,1.9-1.15,11.65,11.65,0,0,0,.4-3.95V12.3a12.14,12.14,0,0,0-.4-4,2.18,2.18,0,0,0-1.9-1.1l-1.8-.3a2.56,2.56,0,0,1-2.5-2.7q0-2.8,4.7-2.8h17.8c3.14,0,4.7.94,4.7,2.81,0,1.47-.87,2.38-2.6,2.71l-1.7.3a2.3,2.3,0,0,0-1.85,1.16,13.54,13.54,0,0,0-.35,4Z"
          transform="translate(0)"
          fill={color ?? "#fff"}
        />
        <path
          id="o"
          d="M307.35,35q0-16,8.9-25.5t24-9.5q15,0,24,9.4t9,25.1q0,16.1-9,25.6t-24.2,9.5q-14.79,0-23.75-9.45T307.35,35Zm15,.1q0,12.8,4.75,20a15,15,0,0,0,13.25,7.25q8.2,0,13-7.45t4.85-20.05q0-12.7-4.9-20.2t-13.2-7.5q-8.29,0-13,7.45T322.35,35.1Z"
          transform="translate(0)"
          fill={color ?? "#fff"}
        />
        <path
          id="l"
          d="M429.65,57.7l-3.1,7.4a5.15,5.15,0,0,1-1.7,2.45,6.85,6.85,0,0,1-3.3.55h-38.2q-4.69,0-4.7-2.8c0-1.47.87-2.37,2.6-2.7l1.6-.3a2.45,2.45,0,0,0,1.9-1.15,11.65,11.65,0,0,0,.4-3.95V12.3a12.14,12.14,0,0,0-.4-4,2.19,2.19,0,0,0-1.9-1.1l-1.8-.3a2.56,2.56,0,0,1-2.5-2.7q0-2.8,4.6-2.8h18.6c3.07,0,4.6.94,4.6,2.8a2.48,2.48,0,0,1-2.5,2.71l-2.4.3a2.11,2.11,0,0,0-1.8,1.1,12.21,12.21,0,0,0-.4,4V54.39q0,5.2,1.3,6.06c.87.57,3.83.85,8.9.85,5.33,0,8.67-.27,10-.81s3.07-2.33,5.2-5.37c1-1.41,2-2.12,3-2.12,1.8,0,2.7.77,2.7,2.3A7.46,7.46,0,0,1,429.65,57.7Z"
          transform="translate(0)"
          fill={color ?? "#fff"}
        />
        <path
          id="e"
          d="M454.25,27h12.4q4.39,0,4.4,3.25,0,3.55-4.2,3.55h-7.6c-2.4,0-3.83.18-4.3.55s-.7,1.52-.7,3.45V53.6q0,5.81,1.3,6.75t9.4.95c5.33,0,8.82-.28,10.45-.85s3.25-2,4.85-4.35c1-1.47,2-2.2,3-2.2,1.73,0,2.6.8,2.6,2.4a7.62,7.62,0,0,1-.8,2.8l-2.9,6.7a3.16,3.16,0,0,1-2.8,2.2l-1.9.1h-39.1q-4.61,0-4.6-2.8c0-1.47.83-2.37,2.5-2.7l1.6-.3a2.45,2.45,0,0,0,1.9-1.15,11.65,11.65,0,0,0,.4-3.95V12.3a11.65,11.65,0,0,0-.4-4,2.45,2.45,0,0,0-1.9-1.15l-1.7-.3a2.56,2.56,0,0,1-2.5-2.7q0-2.8,4.6-2.8h38.5c1.8,0,3,.67,3.5,2l2.9,7.2a5.39,5.39,0,0,1,.5,1.9q0,2.1-2.7,2.1-1.6,0-3.6-3A7.48,7.48,0,0,0,473,8.4a25.45,25.45,0,0,0-5.6-.4h-7c-2.67,0-4.35.32-5.05.95s-1.05,2.37-1.05,5.18Z"
          transform="translate(0)"
          fill={color ?? "#fff"}
        />
      </g>
      <g id="Groom">
        <path
          id="m"
          d="M449,115.73l17.3-27a10.45,10.45,0,0,1,1.8-2.25,3.86,3.86,0,0,1,2.2-.45h10.9c3.07,0,4.6.94,4.6,2.8,0,1.47-.83,2.37-2.5,2.7l-1.6.3a2.48,2.48,0,0,0-1.9,1.15,11.69,11.69,0,0,0-.4,4V142a11.61,11.61,0,0,0,.4,3.95,2.45,2.45,0,0,0,1.9,1.15l1.6.3c1.73.34,2.6,1.24,2.6,2.7q0,2.81-4.7,2.8h-17.7q-4.69,0-4.7-2.8c0-1.46.87-2.36,2.6-2.7l1.6-.3a2.45,2.45,0,0,0,1.9-1.15,11.61,11.61,0,0,0,.4-3.95v-36.1l-15.2,24.3a4,4,0,0,1-3.4,2.3c-1,0-2-.76-3-2.3L427,105.33V142a11.61,11.61,0,0,0,.4,3.95,2.45,2.45,0,0,0,1.9,1.15l1.8.3c1.73.27,2.6,1.17,2.6,2.7q0,2.81-4.7,2.8h-12.1q-4.69,0-4.7-2.8c0-1.46.87-2.36,2.6-2.7l1.7-.3a2.45,2.45,0,0,0,1.9-1.15,11.61,11.61,0,0,0,.4-3.95V96.93a12.18,12.18,0,0,0-.4-4,2.21,2.21,0,0,0-1.9-1.1l-1.8-.3a2.54,2.54,0,0,1-2.5-2.7c0-1.86,1.53-2.8,4.6-2.8h9.9a4.33,4.33,0,0,1,2.2.4,10.33,10.33,0,0,1,1.9,2.3Z"
          transform="translate(0)"
          fill={color ?? "#fff"}
        />
        <path
          id="o-2"
          data-name="o"
          d="M270.4,119.68q0-16,8.9-25.5t24-9.5q15,0,24,9.4t9,25.1q0,16.11-9,25.6t-24.2,9.5q-14.79,0-23.75-9.45T270.4,119.68Zm15,.1q0,12.81,4.75,20.05a15,15,0,0,0,13.25,7.25q8.21,0,13.05-7.45t4.85-20q0-12.69-4.9-20.2t-13.2-7.5q-8.29,0-13.05,7.45T285.4,119.78Z"
          transform="translate(0)"
          fill={color ?? "#fff"}
        />
        <path
          id="o-3"
          data-name="o"
          d="M341.6,119.68q0-16,8.9-25.5t24-9.5q15,0,24,9.4t9,25.1q0,16.11-9,25.6t-24.2,9.5q-14.81,0-23.75-9.45T341.6,119.68Zm15,.1q0,12.81,4.75,20.05a15,15,0,0,0,13.25,7.25q8.2,0,13-7.45t4.85-20q0-12.69-4.9-20.2t-13.2-7.5q-8.29,0-13,7.45T356.6,119.78Z"
          transform="translate(0)"
          fill={color ?? "#fff"}
        />
        <path
          id="r"
          d="M247.45,120.13l12.9,21.1a16.42,16.42,0,0,0,2.95,4,7.8,7.8,0,0,0,3.2,1.45,16.29,16.29,0,0,1,2.65.8,2.46,2.46,0,0,1,1.2,2.3q0,3-4,3h-11.5a5.69,5.69,0,0,1-5.3-2.9l-15.1-25.5q-1.9-3.19-1.9-4.7,0-2.59,3.8-3.1,9.9-1.29,9.9-12.5,0-5.9-2.66-9.35a8.63,8.63,0,0,0-7.2-3.45q-3.72,0-5.38,1.85c-1.11,1.24-1.69,3.72-1.76,7.45v41.3a11.61,11.61,0,0,0,.4,3.95,2.45,2.45,0,0,0,1.9,1.15l1.7.3c1.73.34,2.6,1.24,2.6,2.7q0,2.81-4.7,2.8h-17.8q-4.6,0-4.6-2.8a2.56,2.56,0,0,1,2.5-2.7l1.8-.3a2.45,2.45,0,0,0,1.9-1.15,11.61,11.61,0,0,0,.4-3.95V97.63a3.3,3.3,0,0,0-.65-2.4,4,4,0,0,0-2.45-.7c-2.07-.13-3.1-1-3.1-2.7,0-1.4.88-2.51,2.65-3.35a44.35,44.35,0,0,1,8.75-2.55,71.43,71.43,0,0,1,14.8-1.5q25.69,0,25.7,18.9Q261.05,116.44,247.45,120.13Z"
          transform="translate(0)"
          fill={color ?? "#fff"}
        />
        <path
          id="g"
          d="M194.74,86.93l2.61,9.6a10,10,0,0,1,.4,2.5c0,1.74-.94,2.6-2.81,2.6-1.07,0-2.18-.66-3.32-2q-7-8.3-15.77-8.3a16.06,16.06,0,0,0-14,7.5q-5.13,7.5-5.13,20.5,0,17,8.9,23.9a17.49,17.49,0,0,0,11,3.9q6.4,0,7.9-3.4a12.08,12.08,0,0,0,.6-4.7v-6.2a14,14,0,0,0-.2-3.2,2.27,2.27,0,0,0-2.1-1.9l-2.4-.3c-1.73-.2-2.6-1.1-2.6-2.7,0-1.86,1.57-2.8,4.7-2.8h16.9c3.07,0,4.6.94,4.6,2.8,0,1.47-.83,2.37-2.5,2.7l-1.6.3a2.37,2.37,0,0,0-1.85,1.1,13.45,13.45,0,0,0-.35,4v3.8a20.48,20.48,0,0,0,.2,3.7,1.57,1.57,0,0,0,1.2,1.1l1.3.4a1.92,1.92,0,0,1,1.4,2.1q0,3.7-8.75,6.95a52.18,52.18,0,0,1-18.35,3.25q-14.79,0-23.85-9.45t-9.05-25q0-15.8,8.93-25.5t23.49-9.7a45.68,45.68,0,0,1,12.24,1.7,4.59,4.59,0,0,0,1.31.2,2.42,2.42,0,0,0,1.8-1.15,2.74,2.74,0,0,1,2.21-1.15C193.24,84.13,194.21,85.07,194.74,86.93Z"
          transform="translate(0)"
          fill={color ?? "#fff"}
        />
      </g>
      <g id="Paw">
        <path
          d="M55.48,64.33c19.1-14.38,35.38,2.21,44.7,5.24s22.44-1,30.28,9.41c8.51,11.3,3.57,21.24-7.21,28.63-7,4.8-16.69,7.6-24.82,13.73s-13.5,14.66-20,20.06c-10.08,8.33-21,10.34-29.52-1-7.84-10.42-.34-21.8-.68-31.71S36.39,78.71,55.48,64.33Z"
          transform="translate(0)"
          fill={color ?? "#fff"}
        />
        <path
          d="M35,42.22c7.76,10.29,7.55,23.53-.46,29.56A20.47,20.47,0,0,1,5.7,67.37C-2,57.08-1.89,39.74,6.12,33.71S27.21,31.92,35,42.22Z"
          transform="translate(0)"
          fill={color ?? "#fff"}
        />
        <path
          d="M40.7,37.9c7.75,10.3,20.53,13.75,28.54,7.71A20.48,20.48,0,0,0,73,16.72C65.19,6.43,48.49,1.8,40.48,7.83S32.94,27.61,40.7,37.9Z"
          transform="translate(0)"
          fill={color ?? "#fff"}
        />
        <path
          d="M86.08,48.05c3.53,9.76,12.55,15.44,20.14,12.7a16.51,16.51,0,0,0,9.71-21.37C112.4,29.62,100.63,22.1,93,24.85S82.55,38.28,86.08,48.05Z"
          transform="translate(0)"
          fill={color ?? "#fff"}
        />
        <path
          d="M31.47,89.18c8.41,6.08,11.39,16.32,6.65,22.87a16.49,16.49,0,0,1-23.21,3.42C6.5,109.39,2.52,96,7.26,89.46S23.06,83.09,31.47,89.18Z"
          transform="translate(0)"
          fill={color ?? "#fff"}
        />
      </g>
    </svg>
  );
};

export default Logo;
