import React from "react";

const WellnessCheck = ({ children }) => {
  return (
    <svg
      id="Layer_2"
      data-name="Layer 2"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 259.5 273.5"
      preserveAspectRatio="xMaxYMax meet"
    >
      <rect
        id="Container2"
        x="0.5"
        y="121"
        width="259"
        height="152.5"
        fill="#190740"
      />
      <foreignObject x="0.5" y="121" width="259" height="152.5">
        {children}
      </foreignObject>
      <polygon
        id="Container0"
        points="71.41 0 0 47.44 142.82 47.44 71.41 0"
        fill="#13C9F2"
      />
      <rect
        id="Container1"
        x="0.5"
        y="47"
        width="259"
        height="150.5"
        fill="#13C9F2"
      />
      <g id="TitleText">
        <path
          d="M49.2,59.18a3,3,0,0,1,2,.56,2,2,0,0,1,.64,1.58,2.52,2.52,0,0,1-.26,1.12,2.08,2.08,0,0,1-.89.84,3.58,3.58,0,0,1-1.68.33h-.77v2.71H47.2V59.18Zm-.08.91h-.84V62.7h.65a2.56,2.56,0,0,0,1.38-.31,1.37,1.37,0,0,0,.06-2A2,2,0,0,0,49.12,60.09Z"
          fill="#190740"
        />
        <path
          d="M58,63.62a2.91,2.91,0,0,1-.69,2.07,2.72,2.72,0,0,1-3.15.4,2.24,2.24,0,0,1-.89-1,3.21,3.21,0,0,1-.33-1.51,2.92,2.92,0,0,1,.68-2.07,2.43,2.43,0,0,1,1.86-.72,2.57,2.57,0,0,1,1.31.32,2.34,2.34,0,0,1,.89,1A3.32,3.32,0,0,1,58,63.62Zm-4,0A2.53,2.53,0,0,0,54.33,65a1.22,1.22,0,0,0,1.09.51,1.24,1.24,0,0,0,1.1-.51,2.53,2.53,0,0,0,.34-1.42,2.46,2.46,0,0,0-.35-1.42,1.25,1.25,0,0,0-1.1-.49,1.23,1.23,0,0,0-1.09.49A2.46,2.46,0,0,0,54,63.62Z"
          fill="#190740"
        />
        <path
          d="M59.83,58.88a.63.63,0,0,1,.42.14.69.69,0,0,1,0,.93.64.64,0,0,1-.42.15A.63.63,0,0,1,59.4,60a.69.69,0,0,1,0-.93A.62.62,0,0,1,59.83,58.88Zm.52,2v5.39H59.29V60.93Z"
          fill="#190740"
        />
        <path
          d="M64.74,60.83a1.72,1.72,0,0,1,1.94,2v3.51H65.63V63q0-1.26-1.11-1.26a1.24,1.24,0,0,0-1.14.49,2.59,2.59,0,0,0-.31,1.41v2.72H62V60.93h.84l.15.72h.06a1.51,1.51,0,0,1,.72-.62A2.23,2.23,0,0,1,64.74,60.83Z"
          fill="#190740"
        />
        <path
          d="M70.25,65.56a3.28,3.28,0,0,0,.43,0,1.77,1.77,0,0,0,.38-.09v.8a2.64,2.64,0,0,1-.46.13,2.86,2.86,0,0,1-.58.06,2.16,2.16,0,0,1-.81-.15,1.17,1.17,0,0,1-.58-.52,2,2,0,0,1-.22-1v-3h-.75v-.48l.79-.4.37-1.14h.65v1.21H71v.81H69.47v3a.85.85,0,0,0,.22.65A.76.76,0,0,0,70.25,65.56Z"
          fill="#190740"
        />
        <path
          d="M51.57,71.18l-1.88,7.14H48.54l-1.26-4.41c-.05-.16-.09-.34-.14-.54S47,73,47,72.8s-.07-.3-.08-.37l-.06.37q0,.26-.12.57l-.15.57-1.24,4.38H44.2l-1.87-7.14h1.11l1.05,4.22c.07.28.14.58.2.9s.11.6.14.85q0-.39.15-.87c.07-.33.14-.62.22-.88l1.19-4.22h1.08l1.23,4.24c.08.26.15.56.22.89s.12.61.15.84q0-.36.15-.84c.06-.32.13-.63.2-.91l1-4.22Z"
          fill="#190740"
        />
        <path
          d="M54.41,72.83a2.16,2.16,0,0,1,1.67.65,2.51,2.51,0,0,1,.61,1.79v.58H53.07a1.84,1.84,0,0,0,.44,1.27,1.51,1.51,0,0,0,1.16.44,4.27,4.27,0,0,0,.93-.1,5.07,5.07,0,0,0,.83-.28v.88a4.37,4.37,0,0,1-.82.27,4.61,4.61,0,0,1-1,.09,3,3,0,0,1-1.38-.31,2.2,2.2,0,0,1-.93-.93A3,3,0,0,1,52,75.67a3.41,3.41,0,0,1,.31-1.54,2.18,2.18,0,0,1,.85-1A2.38,2.38,0,0,1,54.41,72.83Zm0,.82a1.17,1.17,0,0,0-.91.37,1.68,1.68,0,0,0-.41,1h2.54a1.8,1.8,0,0,0-.3-1A1.09,1.09,0,0,0,54.41,73.65Z"
          fill="#190740"
        />
        <path d="M59.07,78.32H58v-7.6h1.06Z" fill="#190740" />
        <path d="M61.79,78.32H60.73v-7.6h1.06Z" fill="#190740" />
        <path
          d="M66.18,72.83a1.72,1.72,0,0,1,1.94,2v3.51H67.07V75q0-1.26-1.11-1.26a1.24,1.24,0,0,0-1.14.49,2.59,2.59,0,0,0-.31,1.41v2.72H63.45V72.93h.84l.15.72h.06a1.51,1.51,0,0,1,.72-.62A2.23,2.23,0,0,1,66.18,72.83Z"
          fill="#190740"
        />
        <path
          d="M71.87,72.83a2.16,2.16,0,0,1,1.67.65,2.51,2.51,0,0,1,.61,1.79v.58H70.53A1.84,1.84,0,0,0,71,77.12a1.51,1.51,0,0,0,1.16.44,4.27,4.27,0,0,0,.93-.1,5.07,5.07,0,0,0,.83-.28v.88a4.37,4.37,0,0,1-.82.27,4.61,4.61,0,0,1-1,.09,3,3,0,0,1-1.38-.31,2.2,2.2,0,0,1-.93-.93,3,3,0,0,1-.34-1.51,3.41,3.41,0,0,1,.31-1.54,2.18,2.18,0,0,1,.85-1A2.38,2.38,0,0,1,71.87,72.83Zm0,.82A1.17,1.17,0,0,0,71,74a1.68,1.68,0,0,0-.41,1h2.54a1.8,1.8,0,0,0-.3-1A1.09,1.09,0,0,0,71.87,73.65Z"
          fill="#190740"
        />
        <path
          d="M79.05,76.8a1.38,1.38,0,0,1-.58,1.2,2.7,2.7,0,0,1-1.61.42,4.51,4.51,0,0,1-1-.09,4.12,4.12,0,0,1-.75-.24v-.93a5.24,5.24,0,0,0,.83.3,3.32,3.32,0,0,0,.92.14,1.6,1.6,0,0,0,.87-.19A.62.62,0,0,0,78,76.9a.56.56,0,0,0-.1-.33,1.09,1.09,0,0,0-.37-.3,7.1,7.1,0,0,0-.81-.35,5.93,5.93,0,0,1-.87-.42,1.65,1.65,0,0,1-.55-.49,1.26,1.26,0,0,1-.19-.73,1.22,1.22,0,0,1,.57-1.08,2.63,2.63,0,0,1,1.49-.37,4,4,0,0,1,.93.1,4.14,4.14,0,0,1,.84.28l-.35.81a5.59,5.59,0,0,0-.72-.26,2.76,2.76,0,0,0-.75-.1,1.45,1.45,0,0,0-.73.15.46.46,0,0,0-.25.42.52.52,0,0,0,.12.33,1.51,1.51,0,0,0,.41.28l.78.34a5.36,5.36,0,0,1,.86.39,1.51,1.51,0,0,1,.54.49A1.37,1.37,0,0,1,79.05,76.8Z"
          fill="#190740"
        />
        <path
          d="M83.89,76.8a1.38,1.38,0,0,1-.58,1.2,2.7,2.7,0,0,1-1.61.42,4.51,4.51,0,0,1-1-.09,4.12,4.12,0,0,1-.75-.24v-.93a5.24,5.24,0,0,0,.83.3,3.32,3.32,0,0,0,.92.14,1.6,1.6,0,0,0,.87-.19.62.62,0,0,0,.26-.51.56.56,0,0,0-.1-.33,1.09,1.09,0,0,0-.37-.3,7.1,7.1,0,0,0-.81-.35,5.93,5.93,0,0,1-.87-.42,1.65,1.65,0,0,1-.55-.49,1.26,1.26,0,0,1-.19-.73,1.22,1.22,0,0,1,.57-1.08A2.63,2.63,0,0,1,82,72.83a4,4,0,0,1,.93.1,4.14,4.14,0,0,1,.84.28l-.35.81a5.59,5.59,0,0,0-.72-.26,2.76,2.76,0,0,0-.75-.1,1.45,1.45,0,0,0-.73.15.46.46,0,0,0-.25.42.52.52,0,0,0,.12.33,1.51,1.51,0,0,0,.41.28l.78.34a5.36,5.36,0,0,1,.86.39,1.51,1.51,0,0,1,.54.49A1.37,1.37,0,0,1,83.89,76.8Z"
          fill="#190740"
        />
        <path
          d="M42.25,84a2,2,0,0,0-1.67.73,3.1,3.1,0,0,0-.6,2,3.25,3.25,0,0,0,.56,2,2,2,0,0,0,1.7.73,4.33,4.33,0,0,0,.89-.09c.28-.06.57-.13.86-.22v.93a5.09,5.09,0,0,1-.85.23,6.16,6.16,0,0,1-1,.08A3.39,3.39,0,0,1,40.29,90a2.78,2.78,0,0,1-1.08-1.28,4.78,4.78,0,0,1-.36-1.93,4.29,4.29,0,0,1,.4-1.91,2.91,2.91,0,0,1,1.15-1.3,3.52,3.52,0,0,1,1.86-.46,4.71,4.71,0,0,1,1.06.11,4.53,4.53,0,0,1,.94.33l-.4.9a5.09,5.09,0,0,0-.75-.29A3,3,0,0,0,42.25,84Z"
          fill="#190740"
        />
        <path
          d="M46.47,84.75c0,.17,0,.34,0,.5s0,.3,0,.41h.07a1.56,1.56,0,0,1,.7-.62,2.12,2.12,0,0,1,.94-.2,2.05,2.05,0,0,1,1.46.47,2,2,0,0,1,.5,1.5v3.51H49V87q0-1.26-1.11-1.26a1.24,1.24,0,0,0-1.14.49,2.65,2.65,0,0,0-.31,1.42v2.71H45.41v-7.6h1.06Z"
          fill="#190740"
        />
        <path
          d="M53.83,84.83a2.16,2.16,0,0,1,1.67.65,2.51,2.51,0,0,1,.61,1.79v.58H52.49a1.84,1.84,0,0,0,.44,1.27,1.51,1.51,0,0,0,1.16.44,4.27,4.27,0,0,0,.93-.1,5.07,5.07,0,0,0,.83-.28v.88a4.37,4.37,0,0,1-.82.27,5.37,5.37,0,0,1-1,.09,3,3,0,0,1-1.38-.31,2.2,2.2,0,0,1-.93-.93,3,3,0,0,1-.34-1.51,3.41,3.41,0,0,1,.31-1.54,2.18,2.18,0,0,1,.85-1A2.38,2.38,0,0,1,53.83,84.83Zm0,.82a1.17,1.17,0,0,0-.91.37,1.68,1.68,0,0,0-.41,1h2.54a1.8,1.8,0,0,0-.3-1A1.09,1.09,0,0,0,53.83,85.65Z"
          fill="#190740"
        />
        <path
          d="M59.6,90.42a2.47,2.47,0,0,1-1.8-.67,2.82,2.82,0,0,1-.68-2.09A3.43,3.43,0,0,1,57.45,86a2.08,2.08,0,0,1,.91-.92,2.85,2.85,0,0,1,1.32-.29,3.89,3.89,0,0,1,.85.09,3.27,3.27,0,0,1,.65.22l-.32.86a3.7,3.7,0,0,0-.59-.19,2.16,2.16,0,0,0-.6-.08c-1,0-1.46.64-1.46,1.92A2.26,2.26,0,0,0,58.57,89a1.22,1.22,0,0,0,1.06.49,3.18,3.18,0,0,0,.8-.1,3.61,3.61,0,0,0,.63-.26v.92a2.51,2.51,0,0,1-.63.25A3.89,3.89,0,0,1,59.6,90.42Z"
          fill="#190740"
        />
        <path
          d="M63.38,86.52c0,.13,0,.29,0,.48s0,.35,0,.5h0l.31-.4c.13-.16.24-.3.34-.4l1.65-1.77h1.23l-2.17,2.31L67,90.32H65.78L64,87.91l-.62.52v1.89H62.33v-7.6h1.05Z"
          fill="#190740"
        />
      </g>
      <path
        d="M50.63,101.6a2.78,2.78,0,0,1,1.56.34A1.17,1.17,0,0,1,52.7,103a1.33,1.33,0,0,1-.28.84,1.28,1.28,0,0,1-.83.45v0a1.6,1.6,0,0,1,.95.44,1.26,1.26,0,0,1,.33.93,1.53,1.53,0,0,1-.26.91,1.55,1.55,0,0,1-.71.54,2.8,2.8,0,0,1-1,.18H49.05V101.6Zm.11,2.52a1.87,1.87,0,0,0,1.14-.27,1,1,0,0,0,.34-.81.88.88,0,0,0-.38-.79,2.31,2.31,0,0,0-1.21-.24H49.52v2.11Zm-1.22.4v2.39h1.3a1.83,1.83,0,0,0,1.17-.32,1.14,1.14,0,0,0,.38-.92,1,1,0,0,0-.4-.87,2.2,2.2,0,0,0-1.22-.28Z"
        fill="#190740"
        strokeWidth="0.4"
        stroke="#190740"
      />
      <path
        d="M57.57,105.18a2.81,2.81,0,0,1-.22,1.17,1.7,1.7,0,0,1-.65.77,1.9,1.9,0,0,1-1,.28,1.87,1.87,0,0,1-1-.28,1.76,1.76,0,0,1-.65-.77,2.81,2.81,0,0,1-.23-1.17,2.38,2.38,0,0,1,.51-1.62,1.8,1.8,0,0,1,1.42-.58,1.78,1.78,0,0,1,1,.28,1.66,1.66,0,0,1,.63.77A2.74,2.74,0,0,1,57.57,105.18Zm-3.34,0a2.27,2.27,0,0,0,.35,1.32,1.22,1.22,0,0,0,1.09.5,1.19,1.19,0,0,0,1.08-.5,2.31,2.31,0,0,0,.35-1.32,2.77,2.77,0,0,0-.15-.94,1.29,1.29,0,0,0-.45-.64,1.37,1.37,0,0,0-.82-.23,1.26,1.26,0,0,0-1.08.48A2.17,2.17,0,0,0,54.23,105.18Z"
        fill="#190740"
        strokeWidth="0.4"
        stroke="#190740"
      />
      <path
        d="M60.26,107.4a1.65,1.65,0,0,1-1.32-.55,3.11,3.11,0,0,1,0-3.29A1.64,1.64,0,0,1,60.3,103a1.52,1.52,0,0,1,.87.23,1.63,1.63,0,0,1,.5.58h0c0-.12,0-.26,0-.41s0-.3,0-.42v-1.72h.45v6.08h-.37l-.06-.75h0a1.6,1.6,0,0,1-.51.59A1.5,1.5,0,0,1,60.26,107.4Zm.05-.4a1.2,1.2,0,0,0,1-.44,2.27,2.27,0,0,0,.31-1.3v-.07a2.5,2.5,0,0,0-.3-1.35,1.15,1.15,0,0,0-1-.47,1.18,1.18,0,0,0-1,.49,2.27,2.27,0,0,0-.35,1.38,2.2,2.2,0,0,0,.33,1.31A1.19,1.19,0,0,0,60.31,107Z"
        fill="#190740"
        strokeWidth="0.4"
        stroke="#190740"
      />
      <path
        d="M62.83,103.06h.48l1,2.64c.09.24.16.45.23.64s.11.34.15.49h0c.08-.27.21-.64.38-1.12l1-2.65h.49l-1.92,5a2.7,2.7,0,0,1-.51.87,1.09,1.09,0,0,1-.8.3h-.28a.86.86,0,0,1-.23-.06v-.38l.21,0,.24,0a.75.75,0,0,0,.55-.21,1.8,1.8,0,0,0,.38-.64l.27-.71Z"
        fill="#190740"
        strokeWidth="0.4"
        stroke="#190740"
      />
      <path
        d="M75.67,101.6l-1.55,5.72h-.45l-1.2-4.2-.16-.54c0-.16-.07-.29-.09-.4,0,.1,0,.23-.08.38s-.07.31-.12.48l-1.19,4.28h-.46l-1.54-5.72h.48l1,3.92c.06.23.11.43.15.61s.09.37.12.55c0-.19.07-.37.11-.56s.1-.4.16-.62L72,101.6h.47l1.13,3.92c.07.23.12.44.17.63s.08.37.11.54c0-.17.06-.35.1-.54s.09-.39.16-.63l1-3.92Z"
        fill="#190740"
        strokeWidth="0.4"
        stroke="#190740"
      />
      <path
        d="M78,103a1.55,1.55,0,0,1,.91.25,1.57,1.57,0,0,1,.55.69,2.49,2.49,0,0,1,.19,1v.31h-3a1.87,1.87,0,0,0,.4,1.3,1.42,1.42,0,0,0,1.13.46,3.31,3.31,0,0,0,.68-.07,4.16,4.16,0,0,0,.65-.22v.41a2.59,2.59,0,0,1-.63.21,3.82,3.82,0,0,1-.72.07,1.85,1.85,0,0,1-1.47-.59,2.34,2.34,0,0,1-.51-1.59,2.78,2.78,0,0,1,.22-1.15,1.87,1.87,0,0,1,.63-.8A1.77,1.77,0,0,1,78,103Zm0,.38a1.22,1.22,0,0,0-1,.4,1.81,1.81,0,0,0-.42,1.11h2.56a1.9,1.9,0,0,0-.29-1.09A1,1,0,0,0,78,103.36Z"
        fill="#190740"
        strokeWidth="0.4"
        stroke="#190740"
      />
      <path
        d="M81,101.48c.21,0,.31.12.31.35s-.1.35-.31.35-.31-.12-.31-.35S80.83,101.48,81,101.48Zm.23,1.58v4.26h-.46v-4.26Z"
        fill="#190740"
        strokeWidth="0.4"
        stroke="#190740"
      />
      <path
        d="M84.23,103a1.5,1.5,0,0,1,.83.2,1.42,1.42,0,0,1,.53.53h0l.06-.65h.37v4.39a1.9,1.9,0,0,1-.43,1.31,1.81,1.81,0,0,1-1.4.48,4.42,4.42,0,0,1-.86-.08,3,3,0,0,1-.66-.21v-.44a2.84,2.84,0,0,0,.68.24,3.89,3.89,0,0,0,.85.09,1.33,1.33,0,0,0,1-.36,1.41,1.41,0,0,0,.34-1v-.59c0-.12,0-.22,0-.33h0a1.4,1.4,0,0,1-1.42.84,1.6,1.6,0,0,1-1.29-.56,2.5,2.5,0,0,1-.46-1.62,2.64,2.64,0,0,1,.46-1.64A1.6,1.6,0,0,1,84.23,103Zm0,.39a1.18,1.18,0,0,0-1,.49,2.33,2.33,0,0,0-.34,1.37,2.18,2.18,0,0,0,.34,1.32,1.17,1.17,0,0,0,1,.45,1.41,1.41,0,0,0,.83-.21,1.18,1.18,0,0,0,.43-.59,2.77,2.77,0,0,0,.12-.85v-.28a2.2,2.2,0,0,0-.3-1.25A1.14,1.14,0,0,0,84.28,103.37Z"
        fill="#190740"
        strokeWidth="0.4"
        stroke="#190740"
      />
      <path
        d="M87.92,103.18c0,.11,0,.22,0,.32a2.64,2.64,0,0,1,0,.29h0a1.46,1.46,0,0,1,.53-.58,1.73,1.73,0,0,1,.91-.23,1.53,1.53,0,0,1,1.12.38,1.56,1.56,0,0,1,.39,1.17v2.79h-.45v-2.76a1.26,1.26,0,0,0-.28-.9,1.11,1.11,0,0,0-.82-.29c-.93,0-1.4.54-1.4,1.63v2.32h-.45v-6.08h.45Z"
        fill="#190740"
        strokeWidth="0.4"
        stroke="#190740"
      />
      <path
        d="M93.43,107a1.88,1.88,0,0,0,.34,0,1.63,1.63,0,0,0,.28-.07v.37a1.6,1.6,0,0,1-.3.08,2.1,2.1,0,0,1-.37,0,1.11,1.11,0,0,1-.83-.3,1.37,1.37,0,0,1-.28-1v-2.7h-.64v-.26l.64-.15.16-1h.29v1H94v.37H92.72v2.68C92.72,106.71,93,107,93.43,107Z"
        fill="#190740"
        strokeWidth="0.4"
        stroke="#190740"
      />
      <path
        d="M53.23,120.32h-.46v-2.79H49.52v2.79h-.47V114.6h.47v2.52h3.25V114.6h.46Z"
        fill="#190740"
        strokeWidth="0.4"
        stroke="#190740"
      />
      <path
        d="M56.25,116a1.47,1.47,0,0,1,1.07.35,1.5,1.5,0,0,1,.35,1.11v2.87h-.34l-.08-.72h0a2.1,2.1,0,0,1-.57.58,1.71,1.71,0,0,1-.92.22,1.46,1.46,0,0,1-1-.31,1.14,1.14,0,0,1-.35-.89,1.08,1.08,0,0,1,.52-1,3.12,3.12,0,0,1,1.51-.37l.78,0v-.3a1.33,1.33,0,0,0-.25-.89,1,1,0,0,0-.75-.26,2.28,2.28,0,0,0-.63.09,4.92,4.92,0,0,0-.6.23l-.15-.37a5.06,5.06,0,0,1,.66-.24A3.06,3.06,0,0,1,56.25,116Zm.26,2.21a2.61,2.61,0,0,0-1.22.28.79.79,0,0,0-.39.72.76.76,0,0,0,.24.61,1.08,1.08,0,0,0,.68.2,1.37,1.37,0,0,0,1-.38,1.46,1.46,0,0,0,.38-1v-.42Z"
        fill="#190740"
        strokeWidth="0.4"
        stroke="#190740"
      />
      <path
        d="M59.27,114.48c.21,0,.31.12.31.35s-.1.35-.31.35-.31-.12-.31-.35S59.06,114.48,59.27,114.48Zm.22,1.58v4.26H59v-4.26Z"
        fill="#190740"
        strokeWidth="0.4"
        stroke="#190740"
      />
      <path
        d="M62.65,116a2.65,2.65,0,0,1,.58.06l-.08.43a1.82,1.82,0,0,0-.52-.08,1.13,1.13,0,0,0-.93.44,1.74,1.74,0,0,0-.35,1.14v2.34H60.9v-4.26h.38l0,.79h0a1.6,1.6,0,0,1,.49-.61A1.28,1.28,0,0,1,62.65,116Z"
        fill="#190740"
        strokeWidth="0.4"
        stroke="#190740"
      />
      <path
        d="M63.34,119.48c-.07.29-.16.6-.27.94a9.09,9.09,0,0,1-.34.93h-.34a18.4,18.4,0,0,0,.42-2h.49Z"
        fill="#190740"
        strokeWidth="0.4"
        stroke="#190740"
      />
      <path
        d="M69.19,114.94a2.14,2.14,0,0,0-1.21.32,2,2,0,0,0-.77.87,3,3,0,0,0-.27,1.31,3.41,3.41,0,0,0,.24,1.34,1.88,1.88,0,0,0,.72.89,2.2,2.2,0,0,0,1.21.31,4.51,4.51,0,0,0,.76-.06,6.36,6.36,0,0,0,.66-.17v.41a3.85,3.85,0,0,1-.65.17,4.82,4.82,0,0,1-.82.07,2.65,2.65,0,0,1-1.44-.37,2.33,2.33,0,0,1-.88-1,4,4,0,0,1-.29-1.56,3.44,3.44,0,0,1,.32-1.5,2.49,2.49,0,0,1,.94-1,2.79,2.79,0,0,1,1.49-.38,3.43,3.43,0,0,1,1.49.32l-.18.4A3,3,0,0,0,69.19,114.94Z"
        fill="#190740"
        strokeWidth="0.4"
        stroke="#190740"
      />
      <path
        d="M75.2,118.18a2.81,2.81,0,0,1-.22,1.17,1.64,1.64,0,0,1-.65.77,2,2,0,0,1-2.06,0,1.76,1.76,0,0,1-.65-.77,2.81,2.81,0,0,1-.23-1.17,2.34,2.34,0,0,1,.52-1.62,2,2,0,0,1,2.44-.3A1.74,1.74,0,0,1,75,117,2.91,2.91,0,0,1,75.2,118.18Zm-3.33,0a2.27,2.27,0,0,0,.34,1.32,1.44,1.44,0,0,0,2.18,0,2.31,2.31,0,0,0,.34-1.32,3,3,0,0,0-.14-.94,1.24,1.24,0,0,0-1.28-.87,1.26,1.26,0,0,0-1.08.48A2.17,2.17,0,0,0,71.87,118.18Z"
        fill="#190740"
        strokeWidth="0.4"
        stroke="#190740"
      />
      <path
        d="M77.87,116a1.48,1.48,0,0,1,1.07.35,1.54,1.54,0,0,1,.35,1.11v2.87H79l-.08-.72h0a1.94,1.94,0,0,1-.56.58,1.73,1.73,0,0,1-.93.22,1.42,1.42,0,0,1-1-.31,1.11,1.11,0,0,1-.36-.89,1.1,1.1,0,0,1,.52-1,3.16,3.16,0,0,1,1.52-.37l.77,0v-.3a1.28,1.28,0,0,0-.25-.89,1,1,0,0,0-.74-.26,2.18,2.18,0,0,0-.63.09,5.05,5.05,0,0,0-.61.23l-.14-.37a5.06,5.06,0,0,1,.66-.24A2.94,2.94,0,0,1,77.87,116Zm.25,2.21a2.56,2.56,0,0,0-1.21.28.8.8,0,0,0-.4.72.77.77,0,0,0,.25.61,1,1,0,0,0,.67.2,1.4,1.4,0,0,0,1-.38,1.46,1.46,0,0,0,.37-1v-.42Z"
        fill="#190740"
        strokeWidth="0.4"
        stroke="#190740"
      />
      <path
        d="M81.85,120a1.88,1.88,0,0,0,.34,0,1.91,1.91,0,0,0,.28-.07v.37a1.6,1.6,0,0,1-.3.08,2.22,2.22,0,0,1-.38,0,1.09,1.09,0,0,1-.82-.3,1.37,1.37,0,0,1-.29-1v-2.7h-.63v-.26l.63-.15.16-1h.3v1h1.3v.37h-1.3v2.68C81.14,119.71,81.38,120,81.85,120Z"
        fill="#190740"
        strokeWidth="0.4"
        stroke="#190740"
      />
      <path
        d="M83.94,119.48c-.07.29-.16.6-.27.94a9.09,9.09,0,0,1-.34.93H83a18.4,18.4,0,0,0,.42-2h.49Z"
        fill="#190740"
        strokeWidth="0.4"
        stroke="#190740"
      />
      <path
        d="M90.52,118.83a1.41,1.41,0,0,1-.25.86,1.54,1.54,0,0,1-.71.53,2.67,2.67,0,0,1-1,.18,4.81,4.81,0,0,1-.91-.08,3.18,3.18,0,0,1-.65-.18v-.46a4.21,4.21,0,0,0,.7.21,4.1,4.1,0,0,0,.88.09,1.85,1.85,0,0,0,1.07-.28,1,1,0,0,0,.41-.84.91.91,0,0,0-.16-.56,1.28,1.28,0,0,0-.48-.38,5.77,5.77,0,0,0-.82-.33,5,5,0,0,1-.83-.36,1.6,1.6,0,0,1-.53-.5,1.45,1.45,0,0,1-.18-.76,1.3,1.3,0,0,1,.24-.79,1.45,1.45,0,0,1,.64-.49,2.54,2.54,0,0,1,.94-.17,4.09,4.09,0,0,1,.8.08,4.21,4.21,0,0,1,.71.23l-.15.4a3.59,3.59,0,0,0-1.37-.29,1.68,1.68,0,0,0-1,.26.89.89,0,0,0-.37.76,1,1,0,0,0,.16.59,1.19,1.19,0,0,0,.47.37,4.86,4.86,0,0,0,.74.31,5.17,5.17,0,0,1,.87.37,1.39,1.39,0,0,1,.56.48A1.23,1.23,0,0,1,90.52,118.83Z"
        fill="#190740"
        strokeWidth="0.4"
        stroke="#190740"
      />
      <path
        d="M92.07,117.32v.54c0,.18,0,.36,0,.54h0l.23-.28.25-.3,1.62-1.76h.55L93,117.88l1.83,2.44h-.55l-1.59-2.14-.65.66v1.48h-.44v-6.08h.44Z"
        fill="#190740"
        strokeWidth="0.4"
        stroke="#190740"
      />
      <path
        d="M95.81,114.48c.21,0,.31.12.31.35s-.1.35-.31.35-.31-.12-.31-.35S95.6,114.48,95.81,114.48Zm.22,1.58v4.26h-.45v-4.26Z"
        fill="#190740"
        strokeWidth="0.4"
        stroke="#190740"
      />
      <path
        d="M99.35,116a1.54,1.54,0,0,1,1.11.38,1.6,1.6,0,0,1,.38,1.17v2.79h-.45v-2.76a1.26,1.26,0,0,0-.28-.9,1.07,1.07,0,0,0-.81-.29c-.94,0-1.4.54-1.4,1.63v2.32h-.46v-4.26h.37l.06.73h0a1.46,1.46,0,0,1,.53-.58A1.73,1.73,0,0,1,99.35,116Z"
        fill="#190740"
        strokeWidth="0.4"
        stroke="#190740"
      />
      <path
        d="M106,114.52a1.39,1.39,0,0,1,1,.31,1.08,1.08,0,0,1,.37.88,1.18,1.18,0,0,1-.35.87,3.86,3.86,0,0,1-.91.65l1.75,1.73a2.94,2.94,0,0,0,.38-.62,4.09,4.09,0,0,0,.26-.77h.46a4.1,4.1,0,0,1-.81,1.69l1.06,1.06h-.62l-.74-.76a3.08,3.08,0,0,1-.84.6,2.51,2.51,0,0,1-1.15.24,2,2,0,0,1-1.29-.4,1.39,1.39,0,0,1-.49-1.15,1.47,1.47,0,0,1,.16-.72,1.65,1.65,0,0,1,.46-.53,6.66,6.66,0,0,1,.73-.45,6,6,0,0,1-.56-.67,1.32,1.32,0,0,1-.21-.75,1.1,1.1,0,0,1,.38-.89A1.48,1.48,0,0,1,106,114.52Zm-.34,2.92c-.24.13-.45.26-.63.38a1.43,1.43,0,0,0-.39.43,1.2,1.2,0,0,0-.13.59,1,1,0,0,0,.36.84,1.4,1.4,0,0,0,.94.3,2.19,2.19,0,0,0,1-.2,2.57,2.57,0,0,0,.71-.51Zm.33-2.54a1,1,0,0,0-.68.22.79.79,0,0,0-.26.61,1.07,1.07,0,0,0,.19.6,4.3,4.3,0,0,0,.53.62,3.07,3.07,0,0,0,.81-.55.94.94,0,0,0,.28-.69.76.76,0,0,0-.24-.59A.88.88,0,0,0,106,114.9Z"
        fill="#190740"
        strokeWidth="0.4"
        stroke="#190740"
      />
      <path
        d="M113.79,114.6a2.82,2.82,0,0,1,1.56.34,1.18,1.18,0,0,1,.5,1.06,1.25,1.25,0,0,1-1.11,1.29v0a1.6,1.6,0,0,1,1,.44,1.26,1.26,0,0,1,.33.93,1.53,1.53,0,0,1-.26.91,1.5,1.5,0,0,1-.71.54,2.8,2.8,0,0,1-1,.18H112.2V114.6Zm.1,2.52a1.87,1.87,0,0,0,1.14-.27,1,1,0,0,0,.34-.81.86.86,0,0,0-.38-.79,2.3,2.3,0,0,0-1.2-.24h-1.12v2.11Zm-1.22.4v2.39H114a1.83,1.83,0,0,0,1.17-.32,1.14,1.14,0,0,0,.38-.92,1,1,0,0,0-.4-.87,2.17,2.17,0,0,0-1.22-.28Z"
        fill="#190740"
        strokeWidth="0.4"
        stroke="#190740"
      />
      <path
        d="M120.72,118.18a2.81,2.81,0,0,1-.22,1.17,1.64,1.64,0,0,1-.65.77,2,2,0,0,1-2.06,0,1.76,1.76,0,0,1-.65-.77,2.81,2.81,0,0,1-.23-1.17,2.34,2.34,0,0,1,.52-1.62,2,2,0,0,1,2.44-.3,1.74,1.74,0,0,1,.64.77A2.91,2.91,0,0,1,120.72,118.18Zm-3.33,0a2.27,2.27,0,0,0,.34,1.32,1.44,1.44,0,0,0,2.18,0,2.31,2.31,0,0,0,.34-1.32,3,3,0,0,0-.14-.94,1.24,1.24,0,0,0-1.28-.87,1.26,1.26,0,0,0-1.08.48A2.17,2.17,0,0,0,117.39,118.18Z"
        fill="#190740"
        strokeWidth="0.4"
        stroke="#190740"
      />
      <path
        d="M123.41,120.4a1.65,1.65,0,0,1-1.32-.55,3.11,3.11,0,0,1,0-3.29,1.64,1.64,0,0,1,1.34-.58,1.52,1.52,0,0,1,.87.23,1.54,1.54,0,0,1,.5.58h0c0-.12,0-.26,0-.41s0-.3,0-.42v-1.72h.45v6.08h-.36l-.07-.75h0a1.45,1.45,0,0,1-1.41.83Zm.06-.4a1.18,1.18,0,0,0,1-.44,2.27,2.27,0,0,0,.32-1.3v-.07a2.49,2.49,0,0,0-.31-1.35,1.15,1.15,0,0,0-1-.47,1.18,1.18,0,0,0-1,.49,2.27,2.27,0,0,0-.35,1.38,2.2,2.2,0,0,0,.33,1.31A1.21,1.21,0,0,0,123.47,120Z"
        fill="#190740"
        strokeWidth="0.4"
        stroke="#190740"
      />
      <path
        d="M126,116.06h.48l1,2.64c.09.24.16.45.23.64s.11.34.15.49h0c.08-.27.21-.64.38-1.12l1-2.65h.49l-1.92,5a2.38,2.38,0,0,1-.51.87,1.09,1.09,0,0,1-.8.3h-.28a.86.86,0,0,1-.23-.06v-.38l.21,0,.24,0a.74.74,0,0,0,.55-.21,1.8,1.8,0,0,0,.38-.64l.27-.71Z"
        fill="#190740"
        strokeWidth="0.4"
        stroke="#190740"
      />
      <path
        d="M135,114.94a2.11,2.11,0,0,0-1.2.32,2.06,2.06,0,0,0-.78.87,3.19,3.19,0,0,0-.26,1.31,3.41,3.41,0,0,0,.24,1.34,1.88,1.88,0,0,0,.72.89,2.14,2.14,0,0,0,1.2.31,4.54,4.54,0,0,0,.77-.06,6.36,6.36,0,0,0,.66-.17v.41a4.11,4.11,0,0,1-.65.17,4.82,4.82,0,0,1-.82.07,2.71,2.71,0,0,1-1.45-.37,2.31,2.31,0,0,1-.87-1,4,4,0,0,1-.29-1.56,3.44,3.44,0,0,1,.32-1.5,2.49,2.49,0,0,1,.94-1,2.78,2.78,0,0,1,1.48-.38,3.44,3.44,0,0,1,1.5.32l-.19.4A3,3,0,0,0,135,114.94Z"
        fill="#190740"
        strokeWidth="0.4"
        stroke="#190740"
      />
      <path
        d="M141.05,118.18a2.81,2.81,0,0,1-.22,1.17,1.7,1.7,0,0,1-.65.77,1.9,1.9,0,0,1-1,.28,1.85,1.85,0,0,1-1-.28,1.76,1.76,0,0,1-.65-.77,2.81,2.81,0,0,1-.23-1.17,2.38,2.38,0,0,1,.51-1.62,2,2,0,0,1,2.45-.3,1.66,1.66,0,0,1,.63.77A2.74,2.74,0,0,1,141.05,118.18Zm-3.34,0a2.27,2.27,0,0,0,.35,1.32,1.43,1.43,0,0,0,2.17,0,2.31,2.31,0,0,0,.35-1.32,2.77,2.77,0,0,0-.15-.94,1.29,1.29,0,0,0-.45-.64,1.37,1.37,0,0,0-.82-.23,1.26,1.26,0,0,0-1.08.48A2.1,2.1,0,0,0,137.71,118.18Z"
        fill="#190740"
        strokeWidth="0.4"
        stroke="#190740"
      />
      <path
        d="M144.11,116a1.55,1.55,0,0,1,1.12.38,1.6,1.6,0,0,1,.38,1.17v2.79h-.45v-2.76a1.26,1.26,0,0,0-.28-.9,1.08,1.08,0,0,0-.81-.29c-.94,0-1.4.54-1.4,1.63v2.32h-.46v-4.26h.37l.06.73h0a1.49,1.49,0,0,1,.54-.58A1.72,1.72,0,0,1,144.11,116Z"
        fill="#190740"
        strokeWidth="0.4"
        stroke="#190740"
      />
      <path
        d="M148.51,120.4a1.64,1.64,0,0,1-1.32-.55,2.46,2.46,0,0,1-.46-1.62,2.54,2.54,0,0,1,.48-1.67,1.62,1.62,0,0,1,1.34-.58,1.51,1.51,0,0,1,.86.23,1.54,1.54,0,0,1,.5.58h0c0-.12,0-.26,0-.41s0-.3,0-.42v-1.72h.46v6.08H150l-.06-.75h0a1.57,1.57,0,0,1-.5.59A1.52,1.52,0,0,1,148.51,120.4Zm.05-.4a1.21,1.21,0,0,0,1.05-.44,2.27,2.27,0,0,0,.31-1.3v-.07a2.41,2.41,0,0,0-.31-1.35,1.12,1.12,0,0,0-1-.47,1.2,1.2,0,0,0-1,.49,2.34,2.34,0,0,0-.35,1.38,2.13,2.13,0,0,0,.34,1.31A1.18,1.18,0,0,0,148.56,120Z"
        fill="#190740"
        strokeWidth="0.4"
        stroke="#190740"
      />
      <path
        d="M152,114.48c.21,0,.31.12.31.35s-.1.35-.31.35-.31-.12-.31-.35S151.79,114.48,152,114.48Zm.23,1.58v4.26h-.46v-4.26Z"
        fill="#190740"
        strokeWidth="0.4"
        stroke="#190740"
      />
      <path
        d="M154.83,120a1.88,1.88,0,0,0,.34,0,1.63,1.63,0,0,0,.28-.07v.37a1.6,1.6,0,0,1-.3.08,2.1,2.1,0,0,1-.37,0,1.11,1.11,0,0,1-.83-.3,1.37,1.37,0,0,1-.28-1v-2.7H153v-.26l.64-.15.16-1h.29v1h1.31v.37h-1.31v2.68C154.12,119.71,154.36,120,154.83,120Z"
        fill="#190740"
        strokeWidth="0.4"
        stroke="#190740"
      />
      <path
        d="M156.59,114.48c.2,0,.31.12.31.35s-.11.35-.31.35-.32-.12-.32-.35S156.38,114.48,156.59,114.48Zm.22,1.58v4.26h-.46v-4.26Z"
        fill="#190740"
        strokeWidth="0.4"
        stroke="#190740"
      />
      <path
        d="M161.78,118.18a3,3,0,0,1-.22,1.17,1.76,1.76,0,0,1-.65.77,1.92,1.92,0,0,1-1,.28,1.85,1.85,0,0,1-1-.28,1.76,1.76,0,0,1-.65-.77,2.81,2.81,0,0,1-.23-1.17,2.38,2.38,0,0,1,.51-1.62,1.78,1.78,0,0,1,1.42-.58,1.8,1.8,0,0,1,1,.28,1.66,1.66,0,0,1,.63.77A2.74,2.74,0,0,1,161.78,118.18Zm-3.34,0a2.27,2.27,0,0,0,.35,1.32,1.43,1.43,0,0,0,2.17,0,2.31,2.31,0,0,0,.35-1.32,2.77,2.77,0,0,0-.15-.94,1.36,1.36,0,0,0-.45-.64,1.37,1.37,0,0,0-.82-.23,1.26,1.26,0,0,0-1.08.48A2.1,2.1,0,0,0,158.44,118.18Z"
        fill="#190740"
        strokeWidth="0.4"
        stroke="#190740"
      />
      <path
        d="M164.84,116a1.51,1.51,0,0,1,1.11.38,1.56,1.56,0,0,1,.39,1.17v2.79h-.45v-2.76a1.26,1.26,0,0,0-.28-.9,1.11,1.11,0,0,0-.82-.29c-.93,0-1.4.54-1.4,1.63v2.32h-.45v-4.26h.37l.06.73h0a1.42,1.42,0,0,1,.54-.58A1.67,1.67,0,0,1,164.84,116Z"
        fill="#190740"
        strokeWidth="0.4"
        stroke="#190740"
      />
      <path
        d="M51.53,133.32l-2.05-5.23h0c0,.15,0,.32,0,.51s0,.39,0,.6v4.12h-.44V127.6h.7l2,5h0l2-5h.69v5.72H54v-4.17c0-.18,0-.36,0-.54s0-.36,0-.51h0l-2.06,5.22Z"
        fill="#190740"
        strokeWidth="0.4"
        stroke="#190740"
      />
      <path
        d="M59.49,131.18a2.81,2.81,0,0,1-.22,1.17,1.7,1.7,0,0,1-.65.77,1.9,1.9,0,0,1-1,.28,1.87,1.87,0,0,1-1-.28,1.76,1.76,0,0,1-.65-.77,2.81,2.81,0,0,1-.23-1.17,2.38,2.38,0,0,1,.51-1.62,1.8,1.8,0,0,1,1.42-.58,1.78,1.78,0,0,1,1,.28,1.66,1.66,0,0,1,.63.77A2.74,2.74,0,0,1,59.49,131.18Zm-3.33,0a2.27,2.27,0,0,0,.34,1.32,1.44,1.44,0,0,0,2.18,0,2.39,2.39,0,0,0,.34-1.32,3,3,0,0,0-.14-.94,1.24,1.24,0,0,0-1.28-.87,1.26,1.26,0,0,0-1.08.48A2.17,2.17,0,0,0,56.16,131.18Z"
        fill="#190740"
        strokeWidth="0.4"
        stroke="#190740"
      />
      <path
        d="M64,129.06v4.26h-.37l-.07-.73h0a1.5,1.5,0,0,1-.54.58,1.71,1.71,0,0,1-.91.23q-1.5,0-1.5-1.56v-2.78h.46v2.74a1.26,1.26,0,0,0,.28.91,1,1,0,0,0,.82.29c.93,0,1.4-.54,1.4-1.62v-2.32Z"
        fill="#190740"
        strokeWidth="0.4"
        stroke="#190740"
      />
      <path
        d="M66.62,133a1.88,1.88,0,0,0,.34,0l.27-.07v.37a1.48,1.48,0,0,1-.29.08,2.22,2.22,0,0,1-.38,0,1.09,1.09,0,0,1-.82-.3,1.37,1.37,0,0,1-.29-1v-2.7h-.63v-.26l.63-.15.16-1h.3v1h1.3v.37h-1.3v2.68C65.91,132.71,66.14,133,66.62,133Z"
        fill="#190740"
        strokeWidth="0.4"
        stroke="#190740"
      />
      <path
        d="M68.59,129.18v.32l0,.29h0a1.49,1.49,0,0,1,.54-.58A1.67,1.67,0,0,1,70,129a1.52,1.52,0,0,1,1.12.38,1.56,1.56,0,0,1,.39,1.17v2.79h-.45v-2.76a1.26,1.26,0,0,0-.28-.9,1.11,1.11,0,0,0-.82-.29c-.93,0-1.4.54-1.4,1.63v2.32h-.45v-6.08h.45Z"
        fill="#190740"
        strokeWidth="0.4"
        stroke="#190740"
      />
      <path
        d="M72.83,129.38c0-.26.12-.39.35-.39s.36.13.36.39-.12.4-.36.4S72.83,129.65,72.83,129.38Zm0,3.62c0-.26.12-.39.35-.39s.36.13.36.39-.12.4-.36.4S72.83,133.27,72.83,133Z"
        fill="#190740"
        strokeWidth="0.4"
        stroke="#190740"
      />
      <path
        d="M78.59,133.32h-.47V128H76.28v-.42h4.15V128H78.59Z"
        fill="#190740"
        strokeWidth="0.4"
        stroke="#190740"
      />
      <path
        d="M82.24,129a1.53,1.53,0,0,1,.91.25,1.57,1.57,0,0,1,.55.69,2.49,2.49,0,0,1,.19,1v.31h-3a1.87,1.87,0,0,0,.4,1.3,1.42,1.42,0,0,0,1.13.46,3.49,3.49,0,0,0,.69-.07,4.44,4.44,0,0,0,.64-.22v.41a2.59,2.59,0,0,1-.63.21,3.74,3.74,0,0,1-.72.07,1.85,1.85,0,0,1-1.47-.59,2.34,2.34,0,0,1-.51-1.59,2.78,2.78,0,0,1,.22-1.15,1.94,1.94,0,0,1,.63-.8A1.77,1.77,0,0,1,82.24,129Zm0,.38a1.2,1.2,0,0,0-.94.4,1.7,1.7,0,0,0-.42,1.11h2.55a1.9,1.9,0,0,0-.29-1.09A1,1,0,0,0,82.23,129.36Z"
        fill="#190740"
        strokeWidth="0.4"
        stroke="#190740"
      />
      <path
        d="M86.66,129a1.57,1.57,0,0,1,.91.25,1.64,1.64,0,0,1,.55.69,2.67,2.67,0,0,1,.19,1v.31h-3a1.92,1.92,0,0,0,.39,1.3,1.44,1.44,0,0,0,1.13.46,3.34,3.34,0,0,0,.69-.07,4.16,4.16,0,0,0,.65-.22v.41a2.59,2.59,0,0,1-.63.21,3.82,3.82,0,0,1-.72.07,1.85,1.85,0,0,1-1.47-.59,2.29,2.29,0,0,1-.52-1.59,3,3,0,0,1,.22-1.15,1.89,1.89,0,0,1,.64-.8A1.74,1.74,0,0,1,86.66,129Zm0,.38a1.21,1.21,0,0,0-1,.4,1.81,1.81,0,0,0-.42,1.11h2.55a1.82,1.82,0,0,0-.29-1.09A1,1,0,0,0,86.65,129.36Z"
        fill="#190740"
        strokeWidth="0.4"
        stroke="#190740"
      />
      <path
        d="M90.66,133a1.72,1.72,0,0,0,.33,0l.28-.07v.37a1.48,1.48,0,0,1-.29.08,2.22,2.22,0,0,1-.38,0,1.09,1.09,0,0,1-.82-.3,1.37,1.37,0,0,1-.29-1v-2.7h-.63v-.26l.63-.15.16-1H90v1h1.3v.37H90v2.68C90,132.71,90.18,133,90.66,133Z"
        fill="#190740"
        strokeWidth="0.4"
        stroke="#190740"
      />
      <path
        d="M92.63,129.18v.32l0,.29h0a1.49,1.49,0,0,1,.54-.58,1.67,1.67,0,0,1,.9-.23,1.52,1.52,0,0,1,1.12.38,1.56,1.56,0,0,1,.39,1.17v2.79h-.45v-2.76a1.26,1.26,0,0,0-.28-.9,1.11,1.11,0,0,0-.82-.29c-.93,0-1.4.54-1.4,1.63v2.32h-.45v-6.08h.45Z"
        fill="#190740"
        strokeWidth="0.4"
        stroke="#190740"
      />
      <path
        d="M97.51,132.48c-.07.29-.15.6-.26.94s-.22.64-.34.93h-.35a18.61,18.61,0,0,0,.43-2h.48Z"
        fill="#190740"
        strokeWidth="0.4"
        stroke="#190740"
      />
      <path
        d="M103.23,130.4h2.08v2.66a5.19,5.19,0,0,1-1.9.34,2.65,2.65,0,0,1-2.07-.78,3,3,0,0,1-.71-2.15,3.3,3.3,0,0,1,.34-1.54,2.59,2.59,0,0,1,1-1,3.12,3.12,0,0,1,1.57-.37,4,4,0,0,1,1.68.34l-.18.41a4,4,0,0,0-.74-.25,3.46,3.46,0,0,0-.78-.09,2.53,2.53,0,0,0-1.3.32,2.1,2.1,0,0,0-.83.89,2.92,2.92,0,0,0-.29,1.32,2.66,2.66,0,0,0,.61,1.88,2.35,2.35,0,0,0,1.79.64,4.18,4.18,0,0,0,.74-.06,2.42,2.42,0,0,0,.59-.15v-1.94h-1.61Z"
        fill="#190740"
        strokeWidth="0.4"
        stroke="#190740"
      />
      <path
        d="M110,129.06v4.26h-.37l-.07-.73h0a1.36,1.36,0,0,1-.54.58,1.67,1.67,0,0,1-.9.23q-1.5,0-1.5-1.56v-2.78H107v2.74a1.3,1.3,0,0,0,.27.91,1.07,1.07,0,0,0,.82.29c.94,0,1.4-.54,1.4-1.62v-2.32Z"
        fill="#190740"
        strokeWidth="0.4"
        stroke="#190740"
      />
      <path
        d="M115.84,129a1.34,1.34,0,0,1,1,.36,1.56,1.56,0,0,1,.36,1.14v2.84h-.45v-2.83a1.19,1.19,0,0,0-.26-.84.9.9,0,0,0-.7-.28,1.21,1.21,0,0,0-.94.36,1.56,1.56,0,0,0-.33,1.1v2.49h-.44v-2.83a1.19,1.19,0,0,0-.26-.84.91.91,0,0,0-.7-.28,1.18,1.18,0,0,0-.94.38,1.75,1.75,0,0,0-.32,1.15v2.42h-.46v-4.26h.37l.07.67h0a1.39,1.39,0,0,1,1.31-.75,1.46,1.46,0,0,1,.78.2,1.06,1.06,0,0,1,.46.59h0a1.4,1.4,0,0,1,.55-.58A1.71,1.71,0,0,1,115.84,129Z"
        fill="#190740"
        strokeWidth="0.4"
        stroke="#190740"
      />
      <path
        d="M121.23,132.19a1,1,0,0,1-.44.89,2,2,0,0,1-1.21.32,3.7,3.7,0,0,1-.79-.08,2.3,2.3,0,0,1-.58-.2v-.44a2.84,2.84,0,0,0,.64.24,3.06,3.06,0,0,0,.74.09,1.53,1.53,0,0,0,.9-.21.69.69,0,0,0,.29-.58.59.59,0,0,0-.3-.54,4.06,4.06,0,0,0-.87-.39,7.42,7.42,0,0,1-.7-.27,1.36,1.36,0,0,1-.47-.36.94.94,0,0,1-.17-.59.92.92,0,0,1,.42-.8,1.94,1.94,0,0,1,1.11-.29,3.26,3.26,0,0,1,.72.07,3.63,3.63,0,0,1,.61.19l-.17.38a3.36,3.36,0,0,0-.56-.18,2.64,2.64,0,0,0-.62-.08,1.47,1.47,0,0,0-.79.18.59.59,0,0,0-.28.51.6.6,0,0,0,.14.41,1.19,1.19,0,0,0,.39.25l.63.23a6.37,6.37,0,0,1,.68.28,1.35,1.35,0,0,1,.49.37A.88.88,0,0,1,121.23,132.19Z"
        fill="#190740"
        strokeWidth="0.4"
        stroke="#190740"
      />
      <path
        d="M122.87,132.48c-.07.29-.16.6-.27.94a9.09,9.09,0,0,1-.34.93h-.35a16.39,16.39,0,0,0,.43-2h.49Z"
        fill="#190740"
        strokeWidth="0.4"
        stroke="#190740"
      />
      <path
        d="M127.91,127.52a1.4,1.4,0,0,1,1,.31,1.1,1.1,0,0,1,.36.88,1.15,1.15,0,0,1-.35.87,3.6,3.6,0,0,1-.9.65l1.74,1.73a3,3,0,0,0,.39-.62,4.09,4.09,0,0,0,.25-.77h.47a4.23,4.23,0,0,1-.81,1.69l1,1.06h-.61l-.75-.76a3.08,3.08,0,0,1-.84.6,2.48,2.48,0,0,1-1.15.24,2,2,0,0,1-1.29-.4,1.42,1.42,0,0,1-.48-1.15,1.47,1.47,0,0,1,.16-.72,1.53,1.53,0,0,1,.46-.53,5.83,5.83,0,0,1,.73-.45,5.09,5.09,0,0,1-.56-.67,1.25,1.25,0,0,1-.22-.75,1.13,1.13,0,0,1,.38-.89A1.49,1.49,0,0,1,127.91,127.52Zm-.33,2.92a5.48,5.48,0,0,0-.63.38,1.43,1.43,0,0,0-.39.43,1.2,1.2,0,0,0-.13.59,1,1,0,0,0,.35.84,1.42,1.42,0,0,0,1,.3,2.19,2.19,0,0,0,1-.2,2.72,2.72,0,0,0,.71-.51Zm.33-2.54a1,1,0,0,0-.69.22.78.78,0,0,0-.25.61,1.06,1.06,0,0,0,.18.6,5.06,5.06,0,0,0,.53.62,3.14,3.14,0,0,0,.82-.55,1,1,0,0,0,.28-.69.76.76,0,0,0-.24-.59A.88.88,0,0,0,127.91,127.9Z"
        fill="#190740"
        strokeWidth="0.4"
        stroke="#190740"
      />
      <path
        d="M135.68,127.6a2.78,2.78,0,0,1,1.56.34,1.17,1.17,0,0,1,.51,1.06,1.28,1.28,0,0,1-.29.84,1.26,1.26,0,0,1-.83.45v0a1.6,1.6,0,0,1,1,.44,1.3,1.3,0,0,1,.32.93,1.53,1.53,0,0,1-.25.91,1.55,1.55,0,0,1-.71.54,2.8,2.8,0,0,1-1,.18H134.1V127.6Zm.11,2.52a1.86,1.86,0,0,0,1.13-.27,1,1,0,0,0,.35-.81.88.88,0,0,0-.38-.79,2.31,2.31,0,0,0-1.21-.24h-1.12v2.11Zm-1.23.4v2.39h1.31a1.78,1.78,0,0,0,1.16-.32,1.12,1.12,0,0,0,.39-.92,1,1,0,0,0-.4-.87,2.24,2.24,0,0,0-1.23-.28Z"
        fill="#190740"
        strokeWidth="0.4"
        stroke="#190740"
      />
      <path
        d="M140.81,129a2.65,2.65,0,0,1,.58.06l-.08.43a1.82,1.82,0,0,0-.52-.08,1.13,1.13,0,0,0-.93.44,1.74,1.74,0,0,0-.35,1.14v2.34h-.45v-4.26h.38l.05.79h0a1.6,1.6,0,0,1,.49-.61A1.28,1.28,0,0,1,140.81,129Z"
        fill="#190740"
        strokeWidth="0.4"
        stroke="#190740"
      />
      <path
        d="M143.67,129a1.52,1.52,0,0,1,.9.25,1.59,1.59,0,0,1,.56.69,2.67,2.67,0,0,1,.18,1v.31h-3a1.92,1.92,0,0,0,.4,1.3,1.42,1.42,0,0,0,1.13.46,3.49,3.49,0,0,0,.69-.07,4.54,4.54,0,0,0,.65-.22v.41a2.91,2.91,0,0,1-.63.21,3.82,3.82,0,0,1-.72.07,1.87,1.87,0,0,1-1.48-.59,2.34,2.34,0,0,1-.51-1.59,3,3,0,0,1,.22-1.15,1.89,1.89,0,0,1,.64-.8A1.74,1.74,0,0,1,143.67,129Zm0,.38a1.21,1.21,0,0,0-.95.4,1.81,1.81,0,0,0-.42,1.11h2.55a1.9,1.9,0,0,0-.29-1.09A1,1,0,0,0,143.66,129.36Z"
        fill="#190740"
        strokeWidth="0.4"
        stroke="#190740"
      />
      <path
        d="M148,129a1.47,1.47,0,0,1,1.07.35,1.5,1.5,0,0,1,.35,1.11v2.87h-.34l-.08-.72h0a2.1,2.1,0,0,1-.57.58,1.71,1.71,0,0,1-.92.22,1.45,1.45,0,0,1-1-.31,1.1,1.1,0,0,1-.35-.89,1.08,1.08,0,0,1,.52-1,3.09,3.09,0,0,1,1.51-.37l.78,0v-.3a1.28,1.28,0,0,0-.26-.89,1,1,0,0,0-.74-.26,2.18,2.18,0,0,0-.63.09,5.77,5.77,0,0,0-.61.23l-.14-.37a5.06,5.06,0,0,1,.66-.24A3,3,0,0,1,148,129Zm.26,2.21a2.61,2.61,0,0,0-1.22.28.79.79,0,0,0-.39.72.76.76,0,0,0,.24.61,1.06,1.06,0,0,0,.68.2,1.39,1.39,0,0,0,1-.38,1.51,1.51,0,0,0,.38-1v-.42Z"
        fill="#190740"
        strokeWidth="0.4"
        stroke="#190740"
      />
      <path
        d="M152,133a1.88,1.88,0,0,0,.34,0,1.63,1.63,0,0,0,.28-.07v.37a1.6,1.6,0,0,1-.3.08,2.1,2.1,0,0,1-.37,0,1.11,1.11,0,0,1-.83-.3,1.37,1.37,0,0,1-.28-1v-2.7h-.64v-.26l.64-.15.16-1h.29v1h1.31v.37h-1.31v2.68C151.24,132.71,151.48,133,152,133Z"
        fill="#190740"
        strokeWidth="0.4"
        stroke="#190740"
      />
      <path
        d="M153.93,129.18c0,.11,0,.22,0,.32a2.64,2.64,0,0,1,0,.29h0a1.46,1.46,0,0,1,.53-.58,1.71,1.71,0,0,1,.91-.23,1.35,1.35,0,0,1,1.5,1.55v2.79h-.44v-2.76a1.31,1.31,0,0,0-.28-.9,1.11,1.11,0,0,0-.82-.29c-.93,0-1.4.54-1.4,1.63v2.32h-.46v-6.08h.46Z"
        fill="#190740"
        strokeWidth="0.4"
        stroke="#190740"
      />
      <path
        d="M53.28,146.32h-.49l-3.31-5h0l0,.66c0,.24,0,.48,0,.73v3.61h-.44V140.6h.5l3.3,5h0c0-.17,0-.38,0-.63s0-.5,0-.72V140.6h.44Z"
        fill="#190740"
        strokeWidth="0.4"
        stroke="#190740"
      />
      <path
        d="M58.35,144.18a3,3,0,0,1-.22,1.17,1.76,1.76,0,0,1-.65.77,1.92,1.92,0,0,1-1,.28,1.85,1.85,0,0,1-1-.28,1.76,1.76,0,0,1-.65-.77,2.81,2.81,0,0,1-.23-1.17,2.38,2.38,0,0,1,.51-1.62,1.78,1.78,0,0,1,1.42-.58,1.83,1.83,0,0,1,1,.28,1.72,1.72,0,0,1,.63.77A2.91,2.91,0,0,1,58.35,144.18Zm-3.34,0a2.27,2.27,0,0,0,.35,1.32,1.21,1.21,0,0,0,1.08.5,1.23,1.23,0,0,0,1.09-.5,2.31,2.31,0,0,0,.35-1.32,2.77,2.77,0,0,0-.15-.94,1.38,1.38,0,0,0-.46-.64,1.34,1.34,0,0,0-.81-.23,1.26,1.26,0,0,0-1.08.48A2.1,2.1,0,0,0,55,144.18Z"
        fill="#190740"
        strokeWidth="0.4"
        stroke="#190740"
      />
      <path
        d="M62.19,145.19a1,1,0,0,1-.44.89,2,2,0,0,1-1.21.32,3.7,3.7,0,0,1-.79-.08,2.45,2.45,0,0,1-.58-.2v-.44a2.84,2.84,0,0,0,.64.24,3.06,3.06,0,0,0,.74.09,1.51,1.51,0,0,0,.9-.21.69.69,0,0,0,.29-.58.59.59,0,0,0-.3-.54,3.84,3.84,0,0,0-.87-.39,7.42,7.42,0,0,1-.7-.27,1.36,1.36,0,0,1-.47-.36.94.94,0,0,1-.17-.59.92.92,0,0,1,.42-.8,1.94,1.94,0,0,1,1.11-.29,3.26,3.26,0,0,1,.72.07,3.63,3.63,0,0,1,.61.19l-.17.38a3.36,3.36,0,0,0-.56-.18,2.64,2.64,0,0,0-.62-.08,1.47,1.47,0,0,0-.79.18.59.59,0,0,0-.28.51.6.6,0,0,0,.14.41,1.19,1.19,0,0,0,.39.25l.63.23a6.37,6.37,0,0,1,.68.28,1.35,1.35,0,0,1,.49.37A.88.88,0,0,1,62.19,145.19Z"
        fill="#190740"
        strokeWidth="0.4"
        stroke="#190740"
      />
      <path
        d="M64.87,142a1.53,1.53,0,0,1,.91.25,1.5,1.5,0,0,1,.55.69,2.49,2.49,0,0,1,.19,1v.31h-3a1.87,1.87,0,0,0,.4,1.3A1.42,1.42,0,0,0,65,146a3.49,3.49,0,0,0,.69-.07,4.54,4.54,0,0,0,.65-.22v.41a2.81,2.81,0,0,1-.64.21,3.65,3.65,0,0,1-.72.07,1.85,1.85,0,0,1-1.47-.59,2.34,2.34,0,0,1-.51-1.59,2.78,2.78,0,0,1,.22-1.15,1.89,1.89,0,0,1,.64-.8A1.71,1.71,0,0,1,64.87,142Zm0,.38a1.21,1.21,0,0,0-1,.4,1.81,1.81,0,0,0-.42,1.11h2.55a1.9,1.9,0,0,0-.29-1.09A1,1,0,0,0,64.87,142.36Z"
        fill="#190740"
        strokeWidth="0.4"
        stroke="#190740"
      />
      <path
        d="M71.48,140.52a1.4,1.4,0,0,1,1,.31,1.1,1.1,0,0,1,.36.88,1.15,1.15,0,0,1-.35.87,3.79,3.79,0,0,1-.9.65L73.29,145a2.58,2.58,0,0,0,.38-.62,3.67,3.67,0,0,0,.26-.77h.47a4.23,4.23,0,0,1-.81,1.69l1,1.06H74l-.75-.76a3.08,3.08,0,0,1-.84.6,2.48,2.48,0,0,1-1.15.24A2,2,0,0,1,70,146a1.42,1.42,0,0,1-.49-1.15,1.59,1.59,0,0,1,.16-.72,1.68,1.68,0,0,1,.47-.53,5.18,5.18,0,0,1,.73-.45,5.09,5.09,0,0,1-.56-.67,1.25,1.25,0,0,1-.22-.75,1.13,1.13,0,0,1,.38-.89A1.48,1.48,0,0,1,71.48,140.52Zm-.33,2.92a5.48,5.48,0,0,0-.63.38,1.31,1.31,0,0,0-.39.43,1.2,1.2,0,0,0-.14.59,1,1,0,0,0,.36.84,1.42,1.42,0,0,0,1,.3,2.22,2.22,0,0,0,1-.2,2.72,2.72,0,0,0,.71-.51Zm.32-2.54a1,1,0,0,0-.68.22.78.78,0,0,0-.25.61,1.06,1.06,0,0,0,.18.6,5.06,5.06,0,0,0,.53.62,3.14,3.14,0,0,0,.82-.55,1,1,0,0,0,.28-.69.76.76,0,0,0-.24-.59A.89.89,0,0,0,71.47,140.9Z"
        fill="#190740"
        strokeWidth="0.4"
        stroke="#190740"
      />
      <path
        d="M80.8,146.32H77.67V140.6H80.8V141H78.13v2.09h2.52v.41H78.13v2.38H80.8Z"
        fill="#190740"
        strokeWidth="0.4"
        stroke="#190740"
      />
      <path
        d="M81.3,142.06h.48l1,2.64c.09.24.16.45.23.63s.11.35.15.5h0c.08-.27.21-.64.38-1.11l1-2.66h.49l-1.92,5a2.38,2.38,0,0,1-.51.87,1.09,1.09,0,0,1-.8.3h-.28a.86.86,0,0,1-.23-.06v-.38l.21.05.24,0a.74.74,0,0,0,.55-.21,1.8,1.8,0,0,0,.38-.64l.27-.71Z"
        fill="#190740"
        strokeWidth="0.4"
        stroke="#190740"
      />
      <path
        d="M87.36,142a1.53,1.53,0,0,1,.91.25,1.57,1.57,0,0,1,.55.69,2.49,2.49,0,0,1,.19,1v.31H86a1.87,1.87,0,0,0,.4,1.3,1.42,1.42,0,0,0,1.13.46,3.49,3.49,0,0,0,.69-.07,4.44,4.44,0,0,0,.64-.22v.41a2.59,2.59,0,0,1-.63.21,3.82,3.82,0,0,1-.72.07,1.85,1.85,0,0,1-1.47-.59,2.34,2.34,0,0,1-.51-1.59,2.78,2.78,0,0,1,.22-1.15,1.94,1.94,0,0,1,.63-.8A1.77,1.77,0,0,1,87.36,142Zm0,.38a1.2,1.2,0,0,0-.94.4,1.7,1.7,0,0,0-.42,1.11h2.55a1.9,1.9,0,0,0-.29-1.09A1,1,0,0,0,87.35,142.36Z"
        fill="#190740"
        strokeWidth="0.4"
        stroke="#190740"
      />
      <path
        d="M92.84,145.19a1,1,0,0,1-.43.89,2,2,0,0,1-1.22.32,3.77,3.77,0,0,1-.79-.08,2.22,2.22,0,0,1-.57-.2v-.44a2.67,2.67,0,0,0,.64.24,3,3,0,0,0,.73.09,1.55,1.55,0,0,0,.91-.21.68.68,0,0,0,.28-.58.58.58,0,0,0-.29-.54,4.06,4.06,0,0,0-.87-.39,7.42,7.42,0,0,1-.7-.27,1.36,1.36,0,0,1-.47-.36.94.94,0,0,1-.17-.59.92.92,0,0,1,.42-.8,1.9,1.9,0,0,1,1.11-.29,3.23,3.23,0,0,1,.71.07,3.47,3.47,0,0,1,.62.19l-.17.38a3.69,3.69,0,0,0-.56-.18,2.72,2.72,0,0,0-.63-.08,1.45,1.45,0,0,0-.78.18.57.57,0,0,0-.28.51.6.6,0,0,0,.14.41,1.19,1.19,0,0,0,.39.25l.62.23a5.8,5.8,0,0,1,.69.28,1.44,1.44,0,0,1,.49.37A.93.93,0,0,1,92.84,145.19Z"
        fill="#190740"
        strokeWidth="0.4"
        stroke="#190740"
      />
      <path
        d="M52.19,159.32H49.05V153.6h3.14V154H49.52v2.09H52v.41H49.52v2.38h2.67Z"
        fill="#190740"
        strokeWidth="0.4"
        stroke="#190740"
      />
      <path
        d="M54.88,155a1.42,1.42,0,0,1,1.07.35,1.48,1.48,0,0,1,.36,1.11v2.87H56l-.08-.72h0a2.1,2.1,0,0,1-.57.58,1.69,1.69,0,0,1-.92.22,1.46,1.46,0,0,1-1-.31,1.14,1.14,0,0,1-.35-.89,1.08,1.08,0,0,1,.52-1,3.12,3.12,0,0,1,1.51-.37l.78,0v-.3a1.28,1.28,0,0,0-.25-.88,1,1,0,0,0-.74-.27,2.3,2.3,0,0,0-.64.09,4.92,4.92,0,0,0-.6.23l-.15-.37a6.42,6.42,0,0,1,.66-.24A3.06,3.06,0,0,1,54.88,155Zm.26,2.21a2.53,2.53,0,0,0-1.21.28.78.78,0,0,0-.4.72.76.76,0,0,0,.24.61,1.08,1.08,0,0,0,.68.2,1.37,1.37,0,0,0,1-.38,1.46,1.46,0,0,0,.38-1v-.42Z"
        fill="#190740"
        strokeWidth="0.4"
        stroke="#190740"
      />
      <path
        d="M59.42,155a2.65,2.65,0,0,1,.58.06l-.08.43a1.82,1.82,0,0,0-.52-.08,1.15,1.15,0,0,0-.93.43,1.79,1.79,0,0,0-.35,1.15v2.34h-.45v-4.26h.38l.05.79h0a1.6,1.6,0,0,1,.49-.61A1.28,1.28,0,0,1,59.42,155Z"
        fill="#190740"
        strokeWidth="0.4"
        stroke="#190740"
      />
      <path
        d="M63.5,158.19a1.06,1.06,0,0,1-.43.89,2.06,2.06,0,0,1-1.22.32,3.7,3.7,0,0,1-.79-.08,2.45,2.45,0,0,1-.58-.2v-.44a2.84,2.84,0,0,0,.64.24,3.06,3.06,0,0,0,.74.09,1.57,1.57,0,0,0,.91-.21.68.68,0,0,0,.28-.58.59.59,0,0,0-.3-.54,3.64,3.64,0,0,0-.87-.39c-.26-.09-.5-.18-.7-.27a1.46,1.46,0,0,1-.47-.36.94.94,0,0,1-.16-.59.93.93,0,0,1,.41-.8,1.94,1.94,0,0,1,1.11-.29,3.26,3.26,0,0,1,.72.07,3.36,3.36,0,0,1,.61.19l-.17.38a3.36,3.36,0,0,0-.56-.18,2.57,2.57,0,0,0-.62-.08,1.41,1.41,0,0,0-.78.18.57.57,0,0,0-.28.51.59.59,0,0,0,.13.41,1.19,1.19,0,0,0,.39.25l.63.23c.25.09.48.18.69.28a1.4,1.4,0,0,1,.48.37A.88.88,0,0,1,63.5,158.19Z"
        fill="#190740"
        strokeWidth="0.4"
        stroke="#190740"
      />
      <path
        d="M52.63,172.32l-.76-2H49.5l-.77,2h-.48l2.25-5.74h.42l2.19,5.74ZM51,167.87l-.13-.35c0-.16-.09-.31-.13-.43,0,.15-.09.29-.14.43s-.08.25-.12.36l-.79,2h2.07Z"
        fill="#190740"
        strokeWidth="0.4"
        stroke="#190740"
      />
      <path
        d="M55.72,168a1.47,1.47,0,0,1,1.11.38,1.56,1.56,0,0,1,.39,1.17v2.79h-.45v-2.76a1.26,1.26,0,0,0-.28-.9,1.11,1.11,0,0,0-.82-.29c-.93,0-1.4.54-1.4,1.63v2.32h-.45v-4.26h.37l.06.74h0a1.38,1.38,0,0,1,.54-.59A1.69,1.69,0,0,1,55.72,168Z"
        fill="#190740"
        strokeWidth="0.4"
        stroke="#190740"
      />
      <path
        d="M61.94,168.06v4.26h-.37l-.07-.73h0a1.36,1.36,0,0,1-.54.58,1.67,1.67,0,0,1-.9.23q-1.5,0-1.5-1.56v-2.78H59v2.74a1.3,1.3,0,0,0,.27.91,1.07,1.07,0,0,0,.82.29c.94,0,1.4-.54,1.4-1.62v-2.32Z"
        fill="#190740"
        strokeWidth="0.4"
        stroke="#190740"
      />
      <path
        d="M66,171.19a1,1,0,0,1-.43.89,2,2,0,0,1-1.21.32,3.8,3.8,0,0,1-.8-.08,2.36,2.36,0,0,1-.57-.2v-.44a2.84,2.84,0,0,0,.64.24,3,3,0,0,0,.74.09,1.53,1.53,0,0,0,.9-.21.69.69,0,0,0,.29-.58.59.59,0,0,0-.3-.54,4.06,4.06,0,0,0-.87-.39,7.42,7.42,0,0,1-.7-.27,1.36,1.36,0,0,1-.47-.36.94.94,0,0,1-.17-.59.92.92,0,0,1,.42-.8,1.94,1.94,0,0,1,1.11-.29,3.26,3.26,0,0,1,.72.07,3.63,3.63,0,0,1,.61.19l-.17.38a3.36,3.36,0,0,0-.56-.18,2.7,2.7,0,0,0-.62-.08,1.47,1.47,0,0,0-.79.18.59.59,0,0,0-.28.51.6.6,0,0,0,.14.41,1.19,1.19,0,0,0,.39.25l.62.23a5.8,5.8,0,0,1,.69.28,1.35,1.35,0,0,1,.49.37A.93.93,0,0,1,66,171.19Z"
        fill="#190740"
        strokeWidth="0.4"
        stroke="#190740"
      />
      <path
        d="M49.52,185.32h-.47V179.6h3.14V180H49.52v2.35H52v.42H49.52Z"
        fill="#190740"
        strokeWidth="0.4"
        stroke="#190740"
      />
      <path
        d="M54.62,181a1.57,1.57,0,0,1,.91.25,1.64,1.64,0,0,1,.55.69,2.67,2.67,0,0,1,.19,1v.31h-3a1.92,1.92,0,0,0,.39,1.3,1.44,1.44,0,0,0,1.13.46,3.34,3.34,0,0,0,.69-.07,4.16,4.16,0,0,0,.65-.22v.41a2.59,2.59,0,0,1-.63.21,3.82,3.82,0,0,1-.72.07,1.85,1.85,0,0,1-1.47-.59,2.34,2.34,0,0,1-.52-1.59,3,3,0,0,1,.22-1.15,1.89,1.89,0,0,1,.64-.8A1.74,1.74,0,0,1,54.62,181Zm0,.38a1.22,1.22,0,0,0-1,.4,1.81,1.81,0,0,0-.42,1.11h2.55a1.82,1.82,0,0,0-.29-1.09A1,1,0,0,0,54.61,181.36Z"
        fill="#190740"
        strokeWidth="0.4"
        stroke="#190740"
      />
      <path
        d="M59,181a1.53,1.53,0,0,1,.91.25,1.5,1.5,0,0,1,.55.69,2.49,2.49,0,0,1,.19,1v.31h-3a1.87,1.87,0,0,0,.4,1.3,1.42,1.42,0,0,0,1.13.46,3.49,3.49,0,0,0,.69-.07,4.54,4.54,0,0,0,.65-.22v.41a2.91,2.91,0,0,1-.63.21,3.85,3.85,0,0,1-.73.07,1.85,1.85,0,0,1-1.47-.59,2.34,2.34,0,0,1-.51-1.59,2.78,2.78,0,0,1,.22-1.15,1.89,1.89,0,0,1,.64-.8A1.71,1.71,0,0,1,59,181Zm0,.38a1.21,1.21,0,0,0-1,.4,1.81,1.81,0,0,0-.42,1.11h2.55a1.9,1.9,0,0,0-.29-1.09A1,1,0,0,0,59,181.36Z"
        fill="#190740"
        strokeWidth="0.4"
        stroke="#190740"
      />
      <path
        d="M63,185a1.88,1.88,0,0,0,.34,0l.28-.07v.37l-.3.08a2.1,2.1,0,0,1-.37,0,1.11,1.11,0,0,1-.83-.3,1.37,1.37,0,0,1-.28-1v-2.7h-.64v-.26l.64-.15.16-1h.29v1h1.31v.37H62.32v2.68C62.32,184.71,62.56,185,63,185Z"
        fill="#190740"
        strokeWidth="0.4"
        stroke="#190740"
      />
      <path
        d="M64.48,181.38c0-.26.12-.39.35-.39s.36.13.36.39-.12.4-.36.4S64.48,181.65,64.48,181.38Zm0,3.62c0-.26.12-.39.35-.39s.36.13.36.39-.12.4-.36.4S64.48,185.27,64.48,185Z"
        fill="#190740"
        strokeWidth="0.4"
        stroke="#190740"
      />
      <path
        d="M72.92,185.32h-.5l-3.31-5h0q0,.31,0,.66c0,.24,0,.48,0,.73v3.61h-.44V179.6h.5l3.3,5h0q0-.26,0-.63c0-.26,0-.5,0-.72V179.6h.45Z"
        fill="#190740"
        strokeWidth="0.4"
        stroke="#190740"
      />
      <path
        d="M75.92,181a1.42,1.42,0,0,1,1.07.35,1.48,1.48,0,0,1,.36,1.11v2.87H77l-.08-.72h0a2.1,2.1,0,0,1-.57.58,1.69,1.69,0,0,1-.92.22,1.46,1.46,0,0,1-1-.31,1.14,1.14,0,0,1-.35-.89,1.08,1.08,0,0,1,.52-1,3.12,3.12,0,0,1,1.51-.37l.78,0v-.3a1.28,1.28,0,0,0-.25-.88,1,1,0,0,0-.74-.27,2.3,2.3,0,0,0-.64.09,4.92,4.92,0,0,0-.6.23l-.15-.37a5.66,5.66,0,0,1,.66-.24A3.06,3.06,0,0,1,75.92,181Zm.26,2.21a2.53,2.53,0,0,0-1.21.28.78.78,0,0,0-.4.72.76.76,0,0,0,.24.61,1.08,1.08,0,0,0,.68.2,1.37,1.37,0,0,0,1-.38,1.46,1.46,0,0,0,.38-1v-.42Z"
        fill="#190740"
        strokeWidth="0.4"
        stroke="#190740"
      />
      <path
        d="M78.94,179.48c.21,0,.31.12.31.35s-.1.35-.31.35-.31-.12-.31-.35S78.73,179.48,78.94,179.48Zm.22,1.58v4.26h-.45v-4.26Z"
        fill="#190740"
        strokeWidth="0.4"
        stroke="#190740"
      />
      <path
        d="M81,185.32h-.46v-6.08H81Z"
        fill="#190740"
        strokeWidth="0.4"
        stroke="#190740"
      />
      <path
        d="M85.11,184.19a1,1,0,0,1-.43.89,2,2,0,0,1-1.21.32,3.8,3.8,0,0,1-.8-.08,2.36,2.36,0,0,1-.57-.2v-.44a2.84,2.84,0,0,0,.64.24,3,3,0,0,0,.73.09,1.55,1.55,0,0,0,.91-.21.69.69,0,0,0,.29-.58.59.59,0,0,0-.3-.54,4.06,4.06,0,0,0-.87-.39,7.42,7.42,0,0,1-.7-.27,1.36,1.36,0,0,1-.47-.36.94.94,0,0,1-.17-.59.92.92,0,0,1,.42-.8,1.94,1.94,0,0,1,1.11-.29,3.26,3.26,0,0,1,.72.07,3.63,3.63,0,0,1,.61.19l-.17.38a3.36,3.36,0,0,0-.56-.18,2.7,2.7,0,0,0-.62-.08,1.47,1.47,0,0,0-.79.18.59.59,0,0,0-.28.51.6.6,0,0,0,.14.41,1.19,1.19,0,0,0,.39.25l.62.23a5.8,5.8,0,0,1,.69.28,1.35,1.35,0,0,1,.49.37A.93.93,0,0,1,85.11,184.19Z"
        fill="#190740"
        strokeWidth="0.4"
        stroke="#190740"
      />
      <path
        d="M86.75,184.48a9.08,9.08,0,0,1-.26.94c-.11.34-.22.64-.34.93H85.8a18.61,18.61,0,0,0,.43-2h.48Z"
        fill="#190740"
        strokeWidth="0.4"
        stroke="#190740"
      />
      <path
        d="M91.72,185.32h-.46V180H89.42v-.42h4.14V180H91.72Z"
        fill="#190740"
        strokeWidth="0.4"
        stroke="#190740"
      />
      <path
        d="M97.32,183.18a2.81,2.81,0,0,1-.22,1.17,1.64,1.64,0,0,1-.65.77,2,2,0,0,1-2.06,0,1.76,1.76,0,0,1-.65-.77,2.81,2.81,0,0,1-.23-1.17,2.34,2.34,0,0,1,.52-1.62,2,2,0,0,1,2.44-.3,1.74,1.74,0,0,1,.64.77A2.91,2.91,0,0,1,97.32,183.18Zm-3.33,0a2.27,2.27,0,0,0,.34,1.32,1.44,1.44,0,0,0,2.18,0,2.31,2.31,0,0,0,.34-1.32,3,3,0,0,0-.14-.94,1.24,1.24,0,0,0-1.28-.87,1.26,1.26,0,0,0-1.08.48A2.17,2.17,0,0,0,94,183.18Z"
        fill="#190740"
        strokeWidth="0.4"
        stroke="#190740"
      />
      <path
        d="M100.1,181a1.57,1.57,0,0,1,.91.25,1.64,1.64,0,0,1,.55.69,2.67,2.67,0,0,1,.19,1v.31h-3a1.92,1.92,0,0,0,.39,1.3,1.44,1.44,0,0,0,1.13.46,3.34,3.34,0,0,0,.69-.07,4.16,4.16,0,0,0,.65-.22v.41a2.59,2.59,0,0,1-.63.21,3.82,3.82,0,0,1-.72.07,1.85,1.85,0,0,1-1.47-.59,2.29,2.29,0,0,1-.52-1.59,3,3,0,0,1,.22-1.15,1.89,1.89,0,0,1,.64-.8A1.74,1.74,0,0,1,100.1,181Zm0,.38a1.21,1.21,0,0,0-1,.4,1.81,1.81,0,0,0-.42,1.11h2.55a1.82,1.82,0,0,0-.29-1.09A1,1,0,0,0,100.09,181.36Z"
        fill="#190740"
        strokeWidth="0.4"
        stroke="#190740"
      />
      <path
        d="M105.58,184.19a1.06,1.06,0,0,1-.43.89,2.06,2.06,0,0,1-1.22.32,3.7,3.7,0,0,1-.79-.08,2.45,2.45,0,0,1-.58-.2v-.44a2.84,2.84,0,0,0,.64.24,3.06,3.06,0,0,0,.74.09,1.57,1.57,0,0,0,.91-.21.68.68,0,0,0,.28-.58.59.59,0,0,0-.3-.54,3.64,3.64,0,0,0-.87-.39c-.26-.09-.5-.18-.7-.27a1.46,1.46,0,0,1-.47-.36.94.94,0,0,1-.16-.59.91.91,0,0,1,.41-.8,1.94,1.94,0,0,1,1.11-.29,3.26,3.26,0,0,1,.72.07,3.63,3.63,0,0,1,.61.19l-.17.38a3.36,3.36,0,0,0-.56-.18,2.57,2.57,0,0,0-.62-.08,1.41,1.41,0,0,0-.78.18.57.57,0,0,0-.28.51.59.59,0,0,0,.13.41,1.19,1.19,0,0,0,.39.25l.63.23c.25.09.48.18.69.28a1.4,1.4,0,0,1,.48.37A.88.88,0,0,1,105.58,184.19Z"
        fill="#190740"
        strokeWidth="0.4"
        stroke="#190740"
      />
      <path
        d="M107.22,184.48c-.07.29-.16.6-.27.94a9.09,9.09,0,0,1-.34.93h-.34a18.4,18.4,0,0,0,.42-2h.49Z"
        fill="#190740"
        strokeWidth="0.4"
        stroke="#190740"
      />
      <path
        d="M112.27,179.52a1.39,1.39,0,0,1,1,.31,1.11,1.11,0,0,1,.37.88,1.15,1.15,0,0,1-.36.87,3.6,3.6,0,0,1-.9.65l1.74,1.73a3,3,0,0,0,.39-.62,4.34,4.34,0,0,0,.25-.77h.47a4.23,4.23,0,0,1-.81,1.69l1.06,1.06h-.62l-.74-.76a3.08,3.08,0,0,1-.84.6,2.52,2.52,0,0,1-1.16.24,2,2,0,0,1-1.28-.4,1.39,1.39,0,0,1-.49-1.15,1.47,1.47,0,0,1,.16-.72,1.53,1.53,0,0,1,.46-.53,5.83,5.83,0,0,1,.73-.45,5.09,5.09,0,0,1-.56-.67,1.33,1.33,0,0,1-.22-.75,1.13,1.13,0,0,1,.38-.89A1.5,1.5,0,0,1,112.27,179.52Zm-.34,2.92a6.52,6.52,0,0,0-.63.38,1.43,1.43,0,0,0-.39.43,1.2,1.2,0,0,0-.13.59,1.06,1.06,0,0,0,.35.84,1.44,1.44,0,0,0,1,.3,2.19,2.19,0,0,0,1-.2,2.57,2.57,0,0,0,.71-.51Zm.33-2.54a1,1,0,0,0-.69.22.81.81,0,0,0-.25.61,1,1,0,0,0,.19.6,4.15,4.15,0,0,0,.52.62,3.14,3.14,0,0,0,.82-.55.94.94,0,0,0,.28-.69.76.76,0,0,0-.24-.59A.88.88,0,0,0,112.26,179.9Z"
        fill="#190740"
        strokeWidth="0.4"
        stroke="#190740"
      />
      <path
        d="M119.87,179.6c1.37,0,2.06.54,2.06,1.62a1.64,1.64,0,0,1-.53,1.29,2.46,2.46,0,0,1-1.65.47h-.84v2.34h-.46V179.6Zm0,.41h-.92v2.56h.8a2.33,2.33,0,0,0,1.28-.29,1.11,1.11,0,0,0,.46-1,1.07,1.07,0,0,0-.4-.93A2,2,0,0,0,119.83,180Z"
        fill="#190740"
        strokeWidth="0.4"
        stroke="#190740"
      />
      <path
        d="M124.57,181a1.42,1.42,0,0,1,1.07.35,1.48,1.48,0,0,1,.35,1.11v2.87h-.34l-.08-.72h0a2.25,2.25,0,0,1-.57.58,1.71,1.71,0,0,1-.92.22,1.45,1.45,0,0,1-1-.31,1.1,1.1,0,0,1-.35-.89,1.08,1.08,0,0,1,.52-1,3.09,3.09,0,0,1,1.51-.37l.78,0v-.3a1.23,1.23,0,0,0-.26-.88,1,1,0,0,0-.74-.27,2.18,2.18,0,0,0-.63.09,5.77,5.77,0,0,0-.61.23l-.14-.37a5.06,5.06,0,0,1,.66-.24A3,3,0,0,1,124.57,181Zm.26,2.21a2.61,2.61,0,0,0-1.22.28.79.79,0,0,0-.39.72.76.76,0,0,0,.24.61,1.06,1.06,0,0,0,.68.2,1.39,1.39,0,0,0,1-.38,1.46,1.46,0,0,0,.38-1v-.42Z"
        fill="#190740"
        strokeWidth="0.4"
        stroke="#190740"
      />
      <path
        d="M129.9,182.55q-.09-.27-.15-.51c-.05-.16-.08-.3-.12-.44h0c0,.14-.07.28-.11.45s-.1.33-.15.51l-.9,2.76H128l-1.2-4.26h.48l.71,2.62c.07.24.12.45.16.63s.08.34.11.48h0c0-.13.06-.28.11-.46s.1-.37.17-.58l.88-2.69h.47l.84,2.69c.06.19.12.38.17.56a4.33,4.33,0,0,1,.11.47h0a2.45,2.45,0,0,1,.09-.46c0-.19.09-.4.16-.64l.69-2.62h.47l-1.16,4.26h-.5Z"
        fill="#190740"
        strokeWidth="0.4"
        stroke="#190740"
      />
      <path
        d="M135.92,184.19a1,1,0,0,1-.43.89,2,2,0,0,1-1.22.32,3.7,3.7,0,0,1-.79-.08,2.22,2.22,0,0,1-.57-.2v-.44a2.67,2.67,0,0,0,.64.24,3,3,0,0,0,.73.09,1.55,1.55,0,0,0,.91-.21.68.68,0,0,0,.28-.58.58.58,0,0,0-.29-.54,4.06,4.06,0,0,0-.87-.39,7.42,7.42,0,0,1-.7-.27,1.36,1.36,0,0,1-.47-.36.94.94,0,0,1-.17-.59.92.92,0,0,1,.42-.8,1.9,1.9,0,0,1,1.11-.29,3.23,3.23,0,0,1,.71.07,3.47,3.47,0,0,1,.62.19l-.17.38a3.69,3.69,0,0,0-.56-.18,2.72,2.72,0,0,0-.63-.08,1.45,1.45,0,0,0-.78.18.57.57,0,0,0-.28.51.6.6,0,0,0,.14.41,1.19,1.19,0,0,0,.39.25l.62.23a5.8,5.8,0,0,1,.69.28,1.54,1.54,0,0,1,.49.37A.93.93,0,0,1,135.92,184.19Z"
        fill="#190740"
        strokeWidth="0.4"
        stroke="#190740"
      />
      <text
        transform="translate(14.28 91.5)"
        fontSize="48"
        fill="#190740"
        strokeWidth="0.4"
        stroke="#190740"
        fontFamily="NotoSans-Bold, Noto Sans"
        fontWeight="700"
      >
        7
      </text>
      <g id="Bullets">
        <circle
          cx="40.5"
          cy="105"
          r="1.5"
          fill="#190740"
          strokeWidth="0.4"
          stroke="#190740"
        />
        <circle
          cx="40.5"
          cy="118"
          r="1.5"
          fill="#190740"
          strokeWidth="0.4"
          stroke="#190740"
        />
        <circle
          cx="40.5"
          cy="131"
          r="1.5"
          fill="#190740"
          strokeWidth="0.4"
          stroke="#190740"
        />
        <circle
          cx="40.5"
          cy="144"
          r="1.5"
          fill="#190740"
          strokeWidth="0.4"
          stroke="#190740"
        />
        <circle
          cx="40.5"
          cy="157"
          r="1.5"
          fill="#190740"
          strokeWidth="0.4"
          stroke="#190740"
        />
        <circle
          cx="40.5"
          cy="170"
          r="1.5"
          fill="#190740"
          strokeWidth="0.4"
          stroke="#190740"
        />
        <circle
          cx="40.5"
          cy="183"
          r="1.5"
          fill="#190740"
          strokeWidth="0.4"
          stroke="#190740"
        />
      </g>
      <g id="Paws" opacity="0.17">
        <g id="Paw1">
          <path
            d="M202.05,82.81c6.87-5.18,12.73.79,16.09,1.88s8.07-.36,10.9,3.39c3.06,4.07,1.28,7.65-2.6,10.31-2.52,1.72-6,2.73-8.93,4.94s-4.86,5.27-7.22,7.22c-3.63,3-7.56,3.72-10.62-.35-2.83-3.75-.13-7.84-.25-11.41S195.17,88,202.05,82.81Z"
            fill="#190740"
            strokeWidth="0.4"
            stroke="#190740"
          />
          <path
            d="M194.66,74.85c2.79,3.7,2.72,8.47-.17,10.64a7.36,7.36,0,0,1-10.36-1.59c-2.79-3.7-2.74-9.94.15-12.12S191.87,71.14,194.66,74.85Z"
            fill="#190740"
            strokeWidth="0.4"
            stroke="#190740"
          />
          <path
            d="M196.72,73.29c2.79,3.71,7.39,4.95,10.28,2.78a7.37,7.37,0,0,0,1.33-10.4c-2.79-3.71-8.8-5.37-11.69-3.2S193.93,69.59,196.72,73.29Z"
            fill="#190740"
            strokeWidth="0.4"
            stroke="#190740"
          />
          <path
            d="M213.06,76.94c1.27,3.52,4.52,5.56,7.25,4.58a5.93,5.93,0,0,0,3.49-7.69c-1.27-3.52-5.5-6.23-8.24-5.24S211.79,73.43,213.06,76.94Z"
            fill="#190740"
            strokeWidth="0.4"
            stroke="#190740"
          />
          <path
            d="M193.4,91.75c3,2.19,4.1,5.88,2.4,8.23a5.94,5.94,0,0,1-8.36,1.24c-3-2.19-4.46-7-2.76-9.37S190.38,89.56,193.4,91.75Z"
            fill="#190740"
            strokeWidth="0.4"
            stroke="#190740"
          />
        </g>
        <g id="Paw2">
          <path
            d="M25.31,157.61c3.84,1.84,2.55,5.78,2.84,7.5s2,3.47,1,5.56C28.07,173,26.09,173,24,171.89c-1.33-.72-2.57-2-4.21-2.8s-3.43-.93-4.82-1.52c-2.15-.91-3.36-2.47-2.27-4.75,1-2.09,3.41-1.84,4.95-2.71S21.46,155.77,25.31,157.61Z"
            fill="#190740"
            strokeWidth="0.4"
            stroke="#190740"
          />
          <path
            d="M27.11,152.55c-1,2.07-3.1,3.13-4.71,2.36A3.66,3.66,0,0,1,20.73,150c1-2.07,3.75-3.47,5.36-2.7S28.1,150.47,27.11,152.55Z"
            fill="#190740"
            strokeWidth="0.4"
            stroke="#190740"
          />
          <path
            d="M28.27,153.1c-1,2.07-.49,4.38,1.13,5.15a3.65,3.65,0,0,0,4.87-1.79c1-2.08.35-5.1-1.26-5.87S29.26,151,28.27,153.1Z"
            fill="#190740"
            strokeWidth="0.4"
            stroke="#190740"
          />
          <path
            d="M30.4,161.11c-1.26,1.36-1.41,3.25-.36,4.23a2.94,2.94,0,0,0,4.18-.22c1.25-1.36,1.48-3.84.42-4.82S31.65,159.75,30.4,161.11Z"
            fill="#190740"
            strokeWidth="0.4"
            stroke="#190740"
          />
          <path
            d="M19.4,155.86c-.27,1.83-1.64,3.14-3.07,2.93a2.94,2.94,0,0,1-2.45-3.39c.27-1.83,2.06-3.56,3.48-3.35S19.67,154,19.4,155.86Z"
            fill="#190740"
            strokeWidth="0.4"
            stroke="#190740"
          />
        </g>
        <g id="Paw3">
          <path
            d="M98.87,67c-2.05,1.15-3.42-.68-4.28-1.1s-2.2-.2-2.83-1.32-.06-2.12,1.09-2.69c.74-.38,1.72-.52,2.6-1s1.51-1.25,2.22-1.69c1.09-.68,2.18-.73,2.86.49s-.26,2.13-.36,3.1S100.92,65.89,98.87,67Z"
            fill="#190740"
            strokeWidth="0.4"
            stroke="#190740"
          />
          <path
            d="M100.57,69.46a2.09,2.09,0,0,1,.44-2.87,2,2,0,0,1,2.75.81c.62,1.11.37,2.79-.49,3.27S101.19,70.57,100.57,69.46Z"
            fill="#190740"
            strokeWidth="0.4"
            stroke="#190740"
          />
          <path
            d="M100,69.8a2.1,2.1,0,0,0-2.68-1.13,2,2,0,0,0-.74,2.77c.61,1.1,2.18,1.78,3,1.3S100.58,70.91,100,69.8Z"
            fill="#190740"
            strokeWidth="0.4"
            stroke="#190740"
          />
          <path
            d="M95.67,68.21a1.67,1.67,0,0,0-1.79-1.5,1.62,1.62,0,0,0-1.23,2c.22,1,1.26,1.88,2,1.72A1.74,1.74,0,0,0,95.67,68.21Z"
            fill="#190740"
            strokeWidth="0.4"
            stroke="#190740"
          />
          <path
            d="M101.54,64.93a1.67,1.67,0,0,1-.34-2.31,1.61,1.61,0,0,1,2.3,0,2.12,2.12,0,0,1,.4,2.64A1.73,1.73,0,0,1,101.54,64.93Z"
            fill="#190740"
            strokeWidth="0.4"
            stroke="#190740"
          />
          <path
            d="M98.87,67c-2.05,1.15-3.42-.68-4.28-1.1s-2.2-.2-2.83-1.32-.06-2.12,1.09-2.69c.74-.38,1.72-.52,2.6-1s1.51-1.25,2.22-1.69c1.09-.68,2.18-.73,2.86.49s-.26,2.13-.36,3.1S100.92,65.89,98.87,67Z"
            fill="#190740"
            strokeWidth="0.4"
            stroke="#190740"
          />
          <path
            d="M100.57,69.46a2.09,2.09,0,0,1,.44-2.87,2,2,0,0,1,2.75.81c.62,1.11.37,2.79-.49,3.27S101.19,70.57,100.57,69.46Z"
            fill="#190740"
            strokeWidth="0.4"
            stroke="#190740"
          />
          <path
            d="M100,69.8a2.1,2.1,0,0,0-2.68-1.13,2,2,0,0,0-.74,2.77c.61,1.1,2.18,1.78,3,1.3S100.58,70.91,100,69.8Z"
            fill="#190740"
            strokeWidth="0.4"
            stroke="#190740"
          />
          <path
            d="M95.67,68.21a1.67,1.67,0,0,0-1.79-1.5,1.62,1.62,0,0,0-1.23,2c.22,1,1.26,1.88,2,1.72A1.74,1.74,0,0,0,95.67,68.21Z"
            fill="#190740"
            strokeWidth="0.4"
            stroke="#190740"
          />
          <path
            d="M101.54,64.93a1.67,1.67,0,0,1-.34-2.31,1.61,1.61,0,0,1,2.3,0,2.12,2.12,0,0,1,.4,2.64A1.73,1.73,0,0,1,101.54,64.93Z"
            fill="#190740"
            strokeWidth="0.4"
            stroke="#190740"
          />
        </g>
        <g id="Paw4">
          <path
            d="M133.4,166.87c-.82-1.69.71-2.69,1.09-3.36s.26-1.74,1.19-2.19,1.69.05,2.09,1c.26.61.33,1.4.68,2.12s.92,1.27,1.24,1.85c.48.91.47,1.78-.53,2.26s-1.69-.31-2.45-.43S134.21,168.56,133.4,166.87Z"
            fill="#190740"
            strokeWidth="0.4"
            stroke="#190740"
          />
          <path
            d="M131.38,168.11a1.67,1.67,0,0,1,2.27.49,1.61,1.61,0,0,1-.78,2.15,2.1,2.1,0,0,1-2.59-.54A1.74,1.74,0,0,1,131.38,168.11Z"
            fill="#190740"
            strokeWidth="0.4"
            stroke="#190740"
          />
          <path
            d="M131.13,167.61a1.67,1.67,0,0,0,1-2.08,1.61,1.61,0,0,0-2.17-.73,2.08,2.08,0,0,0-1.19,2.36A1.73,1.73,0,0,0,131.13,167.61Z"
            fill="#190740"
            strokeWidth="0.4"
            stroke="#190740"
          />
          <path
            d="M132.61,164.27c.81-.13,1.39-.73,1.29-1.36a1.3,1.3,0,0,0-1.5-1.08c-.8.12-1.56.92-1.47,1.54A1.4,1.4,0,0,0,132.61,164.27Z"
            fill="#190740"
            strokeWidth="0.4"
            stroke="#190740"
          />
          <path
            d="M134.94,169.1a1.36,1.36,0,0,1,1.87-.16,1.29,1.29,0,0,1-.1,1.84,1.67,1.67,0,0,1-2.12.19A1.39,1.39,0,0,1,134.94,169.1Z"
            fill="#190740"
            strokeWidth="0.4"
            stroke="#190740"
          />
        </g>
      </g>
    </svg>
  );
};

export default WellnessCheck;
