import { useState, useEffect } from "react";
import BaseButton from "../Buttons/BaseButton";
import Input from "../Input";
import "./styles.css";

export default function ServiceContactForm() {
  const [sent, setSent] = useState(false);
  const [form, setForm] = useState({
    set: (key, val) => setForm((prev) => ({ ...prev, [key]: val })),
  });

  const handleSubmit = () => {
    setForm({
      set: (key, val) => setForm((prev) => ({ ...prev, [key]: val })),
    });
  };

  return (
    <>
      {sent === false ? (
        <form
          id="contactform"
          onSubmit={() => handleSubmit()}
          // action={`${process.env.API_ENDPOINT}/api/createinquiry`}
          // method="POST"
          className="grid desktop:w-1/2 mobile:w-full gap-10 self-center"
          style={{
            gridTemplateColumns: "repeat(2, minmax(0, 1fr))",
          }}
        >
          <p className="text-3xl mb-10 text-navy font-semibold col-span-2">
            Send us a message{"🚀"}
          </p>
          <Input
            label="Name"
            type="name"
            id="fullname"
            placeholder="Name"
            className="col-span-1 relative mb-4"
            value={form?.name}
            onChange={(val) => form.set("name", val)}
            required
          />

          <Input
            label="Pet Name"
            type="name"
            id="pet"
            placeholder="Pet Name"
            className="col-span-1 relative mb-4"
            value={form?.pet}
            onChange={(val) => form.set("pet", val)}
            required
          />

          <Input
            label="Phone Number"
            type="tel"
            id="phone"
            placeholder="Phone"
            className="col-span-1 relative mb-4"
            value={form?.phone}
            onChange={(val) => form.set("phone", val)}
            required
          />

          <Input
            label="Email Address"
            type="email"
            id="mail"
            placeholder="Email"
            className="col-span-1 relative mb-4"
            value={form?.email}
            onChange={(val) => form.set("email", val)}
          />

          <Input
            cols="40"
            rows="4"
            name="message"
            id="mess"
            form="contactform"
            label="Message to us"
            placeholder="How may we help you?"
            className="col-span-2 relative"
            value={form?.message}
            onChange={(val) => form.set("message", val)}
            required
          />

          <input
            type="submit"
            value="Send Message"
            className="bg-purple rounded-full px-6 py-4 text-white truncate font-semibold shadow-bottom text-md text-center cursor-pointer col-span-1"
          />
          <BaseButton
            label={
              <p className="truncate">
                Call Us <br /> (623)-248-0498
              </p>
            }
            className="bg-purple text-white rounded-full px-6 py-2 whitespace-nowrap font-semibold shadow-bottom text-md col-span-1"
            onClick={() => window.open("tel:6232480498")}
          />
        </form>
      ) : (
        <>{}</>
      )}
    </>
  );
}
