import React from "react";
import { v4 as uuidv4 } from "uuid";
import PropTypes from "prop-types";

const Image = ({ className, style, shadow, id, image, aspectRatio }) => {
  return (
    <svg
      viewBox="0 0 20 20"
      className={className}
      style={style}
      preserveAspectRatio={aspectRatio}
    >
      <g id={`image-${id}`}>
        <defs>
          {shadow && (
            <filter
              id={`imageShadow-${id}`}
              x="-10"
              y="-10"
              width="100"
              height="100"
            >
              <feDropShadow
                dx="-0.1"
                dy="0.7"
                stdDeviation="0.7"
                floodColor="black"
                floodOpacity="0.3"
              />
            </filter>
          )}
        </defs>
        <image
          x="0"
          y="0"
          height="20"
          width="20"
          href={image}
          filter={`url(#imageShadow-${id})`}
        />
      </g>
    </svg>
  );
};

export default Image;

Image.propTypes = {
  className: PropTypes.string,
  style: PropTypes.shape({}),
  id: PropTypes.string,
  shadow: PropTypes.bool,
};

Image.defaultProps = {
  className: undefined,
  style: undefined,
  id: uuidv4(),
  shadow: false,
};
