import React, { useContext, useReducer, createContext } from "react";

const Context = createContext();

const initialState = {
  bookingOpen: false,
  innerWidth: 0,
};

const appReducer = (state, action) => {
  switch (action.type) {
    case "open_booking":
      return { ...state, bookingOpen: true };
    case "close_booking":
      return { ...state, bookingOpen: false };
    case "set_width":
      return { ...state, innerWidth: action.width };
    default:
      return state;
  }
};

export const AppStateProvider = ({ children }) => {
  const value = useReducer(appReducer, initialState);
  return <Context.Provider value={value}>{children}</Context.Provider>;
};

export const useAppState = () => {
  return useContext(Context);
};
