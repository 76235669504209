import React from "react";
import HeaderOne from "../../Components/Header1/Header";
import WellnessCheck from "../../Components/WellnessCheck";
import petCarrier from "../../res/assets/petCarrier.webp";
import doodle from "../../res/assets/doodle.png";
import pawBackground from "../../res/assets/pawBackground.svg";
import Image from "../../Components/Image/Image.jsx";
import lacarte from "../../res/assets/ALaCarte.svg";
import PricesSection from "../../Components/PricesSection/PricesSection";
import Specials from "../../Components/Specials/Specials";
import BaseButton from "../../Components/Buttons/BaseButton";

/**
 *
 * @TODO - Add Specialty Packages "Gold Groom" as it's own element
 */
const Services = () => {
  return (
    <div className="flex flex-col w-full">
      <main className="flex items-start justify-between relative desktop:min-h-[600px] mobile:min-h-[700px]">
        <div className="flex flex-col px-6 pb-6 desktop:ml-6 topSlideIn">
          <HeaderOne className="text-white relative mt-10 mb-6 self-start">
            Where <br /> grooming <br /> meets wellness
            <img
              src={petCarrier}
              className="absolute"
              style={{ height: "70%", top: "10%", right: "0%" }}
              alt="3d-pet-carrier"
            />
          </HeaderOne>
          <p
            className="text-white desktop:w-1/4 mobile:w-3/4 mobile:z-[40]"
            style={{ textShadow: "1px 1px rgba(0, 0, 0, 0.5)" }}
          >
            Whole Groom believes Pets are family. We are a pet spa designed to
            enhance the grooming experience. Whole Groom seeks to increase span
            and quality of life combining Wellness and Grooming for our extended
            family. Healthy high quality spa offerings in addition to Veterinary
            designed well visits create a comprehensive next level experience in
            a safe, fun space.
          </p>
        </div>
      </main>
      <div
        className="w-full h-full flex flex-col justify-between"
        style={{
          backgroundImage: `url(${pawBackground}), linear-gradient(
      rgba(255, 255, 255, 1), 
      rgba(255, 255, 255, 1)
    )`,
        }}
      >
        <div className="desktop:w-3/4 mobile:w-full mobile:px-2 self-center flex relative">
          <Image
            image={doodle}
            className="absolute desktop:bottom-[80%] mobile:bottom-[99%] desktop:h-[100%] mobile:h-[40%]"
            style={{
              zIndex: 20,
              right: "-5%",
            }}
            aspectRatio="xMidYMax meet"
          />
          <PricesSection />
        </div>
        <div className="desktop:w-3/4 mobile:w-full mobile:px-2 self-center flex flex-col relative">
          <Specials />
        </div>

        <div className="desktop:w-full mobile:w-full self-center flex relative">
          <img src={lacarte} className="w-full" alt="alacarte" />
        </div>
      </div>
    </div>
  );
};

export default Services;
