import React, { useCallback, useState } from "react";
import BaseButton from "../Buttons/BaseButton";
import cntl from "cntl";
import { v4 as uuid } from "uuid";
import PropTypes from "prop-types";

const inputCN = (button, className) => cntl`
${button && "rounded-l-full rounded-r-full"}
flex 
bg-white 
inputCN 
text-gray-700
overflow-visible
relative
${className}
`;

const Input = ({
  onChange,
  type,
  handleSubmit,
  placeholder,
  buttonBackground,
  label,
  className,
  cols,
  rows,
  form,
  required,
  value: val,
}) => {
  const labelId = uuid();
  const [value, setValue] = useState(val);
  const handleChange = useCallback(
    (val) => {
      setValue(val);
      onChange(val);
    },
    [onChange]
  );

  const onSubmit = useCallback(
    (val) => {
      handleSubmit(val);
      setValue();
    },
    [handleSubmit]
  );

  return (
    <div className={inputCN(handleSubmit, className)}>
      {label && (
        <label
          className="text-navy text-md whitespace-nowrap"
          style={{
            position: "absolute",
            bottom: "100%",
            left: "0%",
            paddingLeft: "0.5rem",
          }}
          htmlFor={labelId}
        >
          {label}
        </label>
      )}
      {!cols && !rows ? (
        <>
          <input
            className="inputCN full z-10 bg-transparent pl-4 pr-1"
            type={type ?? "text"}
            id={labelId}
            placeholder={placeholder ?? "enter text..."}
            value={value ?? ""}
            onChange={(e) => handleChange(e.target.value)}
            required={required}
          />
          {handleSubmit && (
            <BaseButton
              label="Submit"
              onClick={(e) => onSubmit(e.target.value)}
              className="rounded-full text-xs px-2 text-white"
              style={{ background: buttonBackground }}
            />
          )}
        </>
      ) : (
        <textarea
          className="inputCN w-full p-2"
          cols={cols}
          rows={rows}
          id="mess"
          form={form}
          placeholder={placeholder}
          required={required}
        />
      )}
    </div>
  );
};

Input.propTypes = {
  onChange: PropTypes.func,
  type: PropTypes.string,
  handleSubmit: PropTypes.func,
  placeholder: PropTypes.string,
  buttonBackground: PropTypes.string,
  label: PropTypes.string,
  className: PropTypes.string,
  required: PropTypes.bool,
  form: PropTypes.string,
};

Input.defaultProps = {
  onChange: undefined,
  type: undefined,
  handleSubmit: undefined,
  placeholder: undefined,
  buttonBackground: undefined,
  label: undefined,
  className: undefined,
  required: false,
  form: undefined,
};

export default Input;
