import React from "react";

import SlideNavigation from "../SlideNavigation";
import HeaderOne from "../Header1/Header";
import BaseButton from "../Buttons/BaseButton";
import arrowRight from "../../res/assets/rightCircledArrow.svg";
import dogVideo from "../../res/assets/HappyDog.mp4";
import pupPic from "../../res/assets/PupPicture1.png";
import dogeIcon from "../../res/assets/DogIcon.svg";
import shampooIcon from "../../res/assets/petShampoo.png";
import cntl from "cntl";
import Image from "../Image/Image";
import VideoContainer from "../VideoConainter/VideoContainer.jsx";
import { useNavigate } from "react-router-dom";
import LearnMore from "../Buttons/LearnMoreButton";
import Facebook from "../../res/icons/social/fb";
import InstagramIcon from "../../res/icons/social/instagram";
import VirtualTour from "../VirtualTour";

const videoCN = (playing) => cntl`
bg-yellow 
object-cover 
mr-6 
rounded-lg 
${playing && "border-4 border-yellow"}
`;

const sections = [
  {
    title: "Take the tour!",
    text: (
      <span style={{ width: "15ch" }}>
        "Learn more about what it's like inside of Whole Groom's first Flagship
        store!"
      </span>
    ),
    element: <VirtualTour />,
    buttonLabel: "Book Now",
  },
  {
    title: "Premier Dog Grooming Services",
    text: `We get your pet camera
            ready playing industry studied and approved music proven to make
            your loved one happy and less anxious while being served by the most
            highly trained pet friendly staff in the valley.`,
    buttonLabel: "Book Now",
  },
  {
    title: "One-on-One",
    text: `Your pet will be on the same hands from start to finish, giving your pet time to connect with the groomer, this also helps the groomer to work in making the grooming experience unique and extraordinary.`,
    buttonLabel: "Book Now",
  },
  {
    title: "Ergonomic Equipment",
    text: `We have stainless steel tubs, electric tables, gentle variable-speed hair dryers for those shy pets, and AC to keep your pet at a comfortable temperature.`,
    buttonLabel: "Book Now",
  },
];

const MainSection = ({ handleOpenBooking }) => {
  const navigate = useNavigate();

  return (
    <div
      className="mb-12 px-4 desktop:mt-3rem"
      style={{
        width: "100%",
        display: "flex",
        position: "relative",
      }}
    >
      <div className="flex flex-col justify-start">
        <div className="flex flex-col desktop:w-1/2 mobile:w-full relative">
          <HeaderOne className="text-navy mobile:text-white desktop:pl-6 mobile:layered-text leading-none whitespace-nowrap mb-4 relative w-fit topSlideIn flex flex-col gap-3">
            Where a <br /> happy dog <br />
            is a healthy dog
            <Image
              image={shampooIcon}
              shadow
              className="absolute mobile:hidden"
              style={{
                width: "15rem",
                height: "15rem",
                transform: "rotate(-30deg)",
                left: "55%",
                top: "-35%",
                overflow: "visible",
              }}
            />
          </HeaderOne>
          <p
            className="desktop:text-gray-900 text-navy font-medium mb-6 mobile:!text-sm p-6 mobile:hidden leftSlideIn bg-white rounded-2xl"
            style={{
              lineHeight: "2rem",
              textShadow: "1px 1px rgba(0, 0, 0, 0.1)",
            }}
          >
            Whole Groom spa is designed to offer an environmentally safe,
            disease-free, calming atmosphere. Achieved by utilizing soft pastel
            paint colors, dog friendly calming Spa aroma&apos;s and above
            industry standard inward/outward airflow. We get your pet camera
            ready playing industry studied and approved music proven to make
            your loved one happy and less anxious while being served by the most
            highly trained pet friendly staff in the valley.
          </p>
        </div>
        <BaseButton
          className="self-start bg-purple rounded-full desktop:ml-6 px-4 py-3 text-white font-semibold shadow-bottom text-xs"
          label={
            <span className="flex gap-2">
              Our Services
              <span
                className="rounded-full inline-block"
                style={{
                  background: "#f2622e",
                  height: "1rem",
                  width: "1rem",
                  fontSize: "0.55rem",
                }}
              >
                ➜
              </span>
            </span>
          }
          onClick={() => navigate("/services")}
        />
        <div
          className="flex flex-col relative rounded-2xl mt-10 frosted-glass-yellow desktop:hidden leftSlideIn"
          style={{}}
        >
          <SlideNavigation tabs={sections} openBooking={handleOpenBooking} />
        </div>
        <div className="w-fit relative flex mt-10 desktop:ml-12 mobile:ml-4">
          <VideoContainer
            video={dogVideo}
            placeholder={pupPic}
            style={{ height: "7.5rem", width: "7.5rem" }}
          />
          <p
            className="font-bold text-sm text-navy mobile:frosted-glass p-4 !rounded-lg"
            style={{ fontFamily: "Noto Sans", lineHeight: "1.25rem" }}
          >
            <span>
              <img src={dogeIcon} alt="dog-icon" className="w-10 h-10 mb-1" />
            </span>
            Keeping tails wagging
            <br />
            <span
              className="font-normal text-xs inline-block mt-1"
              style={{ lineHeight: "1.25rem" }}
            >
              Benefits of pet grooming
              <br />
              <LearnMore
                url="https://thedogstop.com/blog/the-benefits-of-dog-grooming/"
                className="!text-gray-700"
              />
            </span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default MainSection;
