import React, { useCallback, useEffect, useState } from "react";
import AWS from "aws-sdk";

AWS.config.update({
  accessKeyId: "AKIAS4YHYXTX6OKSIJFQ",
  secretAccessKey: "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",
  region: "us-west-1",
});
const s3 = new AWS.S3();

const VirtualTour = () => {
  const [url, setUrl] = useState(null);

  const fetchUrl = useCallback(async () => {
    const res = await s3.getSignedUrl("getObject", {
      Key: "tour.mp4",
      Bucket: "mgmanga",
    });
    console.log("track res", res);
    setUrl(res);
  }, []);

  useEffect(() => {
    fetchUrl();
  }, [fetchUrl]);

  return (
    <div
      className="flex rounded-lg overflow-hidden absolute shadow-lg border-8 border-2-white"
      style={{ width: "75%", height: "75%", left: "150px", top: "60px" }}
    >
      <video className="object-cover w-full h-full" autoPlay controls>
        <source src={url} />
      </video>
    </div>
  );
};

export default VirtualTour;
