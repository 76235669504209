import React from "react";
import BaseButton from "./BaseButton";
import PlusIcon from "../../res/assets/PlusIcon.svg";
import cntl from "cntl";

const containerCN = (className) => cntl`
  text-gray-400 
  text-xs
  ${className}
`;

const LearnMore = ({ url, className }) => {
  return (
    <BaseButton
      label="Learn more"
      className={containerCN(className)}
      iconClassName="w-3 h-3 ml-4"
      iconRight={PlusIcon}
      onClick={() => window.open(url)}
    />
  );
};

export default LearnMore;
