

export default function ContactBackground(){

    return (
      <svg
        id="GearBackground"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 233.05 155.39"
        width="100%"
        preserveAspectRatio="xMidYMid meet"
      >
        <defs>
          <filter id="rectShadow" width="233" height="155">
            <feDropShadow
              dx="0"
              dy="0"
              stdDeviation="5"
              floodColor="#000"
              floodOpacity="0.3"
            />
          </filter>

          <filter id="circShadow" width="233" height="155">
            <feDropShadow
              dx="0"
              dy="0"
              stdDeviation="1"
              floodColor="#000"
              floodOpacity="0.3"
            />
          </filter>
          <linearGradient id="blueGradient" gradientTransform="rotate(90)">
            <stop offset="5%" stopColor="#007abc" />
            <stop offset="65%" stopColor="#0090da" />
          </linearGradient>
          <linearGradient id="greenGradient" gradientTransform="rotate(90)">
            <stop offset="5%" stopColor="#a4ce4e" />
            <stop offset="100%" stopColor="#bbd97f" />
          </linearGradient>
          <linearGradient id="redGradient" gradientTransform="rotate(90)">
            <stop offset="50%" stopColor="#e14884" />
            <stop offset="100%" stopColor="#db0a5b" />
          </linearGradient>
          <linearGradient id="yellowGradient" gradientTransform="rotate(90)">
            <stop offset="0%" stopColor="#fdd34e" />
            <stop offset="100%" stopColor="#ffc600" />
          </linearGradient>
          <linearGradient id="purpleGradient" gradientTransform="rotate(90)">
            <stop offset="0%" stopColor="#875eb5" />
            <stop offset="100%" stopColor="#5f259f" />
          </linearGradient>
        </defs>
        <g id="Rects">
          <path
            d="M157.79,133h0a11.67,11.67,0,0,1-4.2-20.56l79.46-60.92v29.4l-65.26,50A11.67,11.67,0,0,1,157.79,133Z"
            fill="url(#redGradient)"
          />
          <path
            d="M60.19,150.72h0a19.19,19.19,0,0,1-6.9-33.81L205.76,0h27.29V27.44l-86.4,66.24-19.34,14.84L76.64,147.37A19.19,19.19,0,0,1,60.19,150.72Z"
            fill="#fff"
            opacity="0.25"
          />
          <path
            d="M93.66,49.58h0a10.41,10.41,0,0,1-3.74-18.34L130.66,0h34.21L102.58,47.76A10.37,10.37,0,0,1,93.66,49.58Z"
            fill="#fff"
            opacity="0.25"
          />
          <path
            d="M118.21,136.94h0a15.11,15.11,0,0,1-5.44-26.63L233.05,18.08v38.1L131.17,134.3A15.12,15.12,0,0,1,118.21,136.94Z"
            fill="url(#greenGradient)"
            filter="url(#rectShadow)"
          />
          <path
            d="M57.73,110h0a15.57,15.57,0,0,1-5.6-27.43l12.58-9.64,69.35-53.17L159.76,0h51.15L71.07,107.22A15.55,15.55,0,0,1,57.73,110Z"
            fill="url(#blueGradient)"
            filter="url(#rectShadow)"
          />
          <g opacity="0.25">
            <path
              d="M1.64,91.58a1.16,1.16,0,0,1-.91-.44,1.14,1.14,0,0,1,.21-1.59l88.8-68.09a1.13,1.13,0,1,1,1.38,1.8L2.33,91.35A1.12,1.12,0,0,1,1.64,91.58Z"
              fill="none"
              stroke="#fff"
              strokeMiterlimit="10"
            />
          </g>
          <line
            x1="194.94"
            y1="124.52"
            x2="155.99"
            y2="154.39"
            fill="none"
            stroke="#fff"
            strokeLinecap="round"
            strokeMiterlimit="10"
            strokeWidth="2"
            opacity="0.25"
          />
          <line
            x1="140.67"
            y1="14.7"
            x2="126.49"
            y2="25.58"
            fill="none"
            stroke="#fff"
            strokeLinecap="round"
            strokeMiterlimit="10"
            strokeWidth="2"
            opacity="0.25"
          />
          <line
            x1="229"
            y1="116.65"
            x2="214.81"
            y2="127.53"
            fill="none"
            stroke="#fff"
            strokeLinecap="round"
            strokeMiterlimit="10"
            strokeWidth="2"
            opacity="0.25"
          />
          <line
            x1="78.89"
            y1="121.87"
            x2="39.95"
            y2="151.73"
            fill="none"
            stroke="#fff"
            strokeLinecap="round"
            strokeMiterlimit="10"
            strokeWidth="2"
            opacity="0.25"
          />
          <line
            x1="156.85"
            y1="95.49"
            x2="142.66"
            y2="106.37"
            fill="none"
            stroke="#fff"
            strokeLinecap="round"
            strokeMiterlimit="10"
            strokeWidth="2"
            opacity="0.25"
          />
          <path
            d="M184.07,91.07a1,1,0,0,1-.8-.39,1,1,0,0,1,.19-1.41L222.4,59.41a1,1,0,0,1,1.41.19,1,1,0,0,1-.19,1.4L184.68,90.86A1,1,0,0,1,184.07,91.07Z"
            fill="none"
            stroke="#fff"
            strokeMiterlimit="10"
            opacity="0.25"
          />
          <line
            x1="53.78"
            y1="9.26"
            x2="48.37"
            y2="13.41"
            fill="none"
            stroke="#fff"
            strokeLinecap="round"
            strokeMiterlimit="10"
            strokeWidth="2"
            opacity="0.25"
          />
        </g>
        <g id="Layer_3" data-name="Layer 3">
          <circle
            cx="146.24"
            cy="26.64"
            r="10.65"
            fill="url(#yellowGradient)"
            filter="url(#circShadow)"
          />
          <circle cx="189.69" cy="58.82" r="5.78" fill="#218ece" />
          <circle
            cx="193.79"
            cy="29.88"
            r="17.26"
            fill="url(#purpleGradient)"
            filter="url(#circShadow)"
          />
          <circle
            cx="156.84"
            cy="72.28"
            r="15.88"
            fill="#00aca0"
            filter="url(#circShadow)"
          />
          <circle
            cx="199.47"
            cy="105.35"
            r="13.26"
            fill="#fff"
            opacity="0.25"
          />
          <circle
            cx="123.21"
            cy="52.98"
            r="5.84"
            fill="#a4ce4d"
            filter="url(#circShadow)"
          />
          <circle
            cx="141.03"
            cy="116.83"
            r="5.97"
            fill="#ffc70a"
            filter="url(#circShadow)"
          />
          <circle
            cx="103.06"
            cy="104.38"
            r="10.24"
            fill="#218ece"
            filter="url(#circShadow)"
          />
          <circle
            cx="89.86"
            cy="60.92"
            r="5.46"
            fill="#603393"
            filter="url(#circShadow)"
          />
          <circle
            cx="75.48"
            cy="88.42"
            r="4.45"
            fill="#00aca0"
            filter="url(#circShadow)"
          />
          <path
            d="M113.27,142.69a5.14,5.14,0,1,1,5.13-5.14A5.14,5.14,0,0,1,113.27,142.69Zm0-9.27a4.14,4.14,0,1,0,4.13,4.13A4.14,4.14,0,0,0,113.27,133.42Z"
            fill="#da1a5d"
          />
          <circle cx="82.26" cy="131.28" r="4.2" fill="#a4ce4d" />
          <path
            d="M102,48.63A12.78,12.78,0,1,1,114.8,35.85,12.79,12.79,0,0,1,102,48.63Zm0-22.56a9.78,9.78,0,1,0,9.78,9.78A9.79,9.79,0,0,0,102,26.07Z"
            fill="#fff"
            opacity="0.25"
          />
          <circle cx="158.46" cy="49.31" r="2.17" fill="#fff" opacity="0.75" />
          <circle cx="81.46" cy="52.77" r="2.17" fill="#fff" opacity="0.75" />
          <circle cx="163.48" cy="106.36" r="2.17" fill="#fff" opacity="0.75" />
          <circle cx="134.11" cy="132.75" r="2.17" fill="#fff" opacity="0.75" />
          <circle cx="111.4" cy="18.89" r="2.17" fill="#fff" opacity="0.75" />
          <circle cx="61.59" cy="103.03" r="2.17" fill="#fff" opacity="0.75" />
          <circle cx="46.03" cy="26.07" r="5.39" fill="#fff" opacity="0.25" />
        </g>
        <g id="Phone">
          <path
            id="Bottom"
            d="M155.55,141.15l-.65-4.25a1,1,0,0,0-.94-.85l-.43,0a2.62,2.62,0,0,1-2.5-2.42v0a.68.68,0,0,0-.56-.61l-.21,0a9.21,9.21,0,0,0-3.2,0l-.21,0a.68.68,0,0,0-.56.61v0a2.62,2.62,0,0,1-2.5,2.42l-.43,0a1,1,0,0,0-.94.85l-.65,4.25a1.13,1.13,0,0,0,1.12,1.31h11.54A1.13,1.13,0,0,0,155.55,141.15Zm-6.89-1.69a1.83,1.83,0,1,1,1.83-1.83A1.83,1.83,0,0,1,148.66,139.46Z"
            fill="#218ece"
          />
          <path
            id="Top"
            d="M156.61,131h0a8.87,8.87,0,0,0-3.78-1.93,16.67,16.67,0,0,0-8.34,0,8.87,8.87,0,0,0-3.78,1.93h0a2.22,2.22,0,0,0-.64,1.65l0,.79a1.6,1.6,0,0,0,2.1,1.44l1.36-.46a1.5,1.5,0,0,0,1-1.4v-.49a1.23,1.23,0,0,1,.82-1.13h0a20.07,20.07,0,0,1,6.44,0h0a1.23,1.23,0,0,1,.82,1.13V133a1.5,1.5,0,0,0,1,1.4l1.36.46a1.6,1.6,0,0,0,2.1-1.44l0-.79A2.22,2.22,0,0,0,156.61,131Z"
            fill="#218ece"
          />
          <path
            id="Bottom-2"
            data-name="Bottom"
            d="M148.66,135.8a1.83,1.83,0,1,1,0,3.66v3h5.77a1.13,1.13,0,0,0,1.12-1.31l-.65-4.25a1,1,0,0,0-.94-.85l-.43,0a2.62,2.62,0,0,1-2.5-2.42v0a.68.68,0,0,0-.56-.61l-.21,0a9.52,9.52,0,0,0-1.6-.14Z"
            fill="#037abc"
          />
          <path
            id="Top-2"
            data-name="Top"
            d="M156.61,131h0a8.87,8.87,0,0,0-3.78-1.93,21.47,21.47,0,0,0-4.17-.53v2.6a20.1,20.1,0,0,1,3.22.26h0a1.23,1.23,0,0,1,.82,1.13V133a1.5,1.5,0,0,0,1,1.4l1.36.46a1.6,1.6,0,0,0,2.1-1.44l0-.79A2.22,2.22,0,0,0,156.61,131Z"
            fill="#037abc"
          />
        </g>
        <g id="MailIcon">
          <path
            d="M166.42,75.48a1.37,1.37,0,0,1-.62-.31.78.78,0,0,1,.15-1.09l2.43-1.84c.34-.26,1.09.15,1.09.15l-.15,3.09Z"
            fill="#fdd34e"
          />
          <path
            d="M197.08,75.48h-2.91s-.4-2.75-.14-3.09a.77.77,0,0,1,1.08-.15l2.43,1.84a.77.77,0,0,1,.15,1.09A1.35,1.35,0,0,1,197.08,75.48Z"
            fill="#fdd34e"
          />
          <path
            d="M195.19,76.6h-1.55v7.45H169.85V76.6H168.3V62.94a.78.78,0,0,1,.77-.78H188.5a.78.78,0,0,1,.78.78v5.48h5.14a.76.76,0,0,1,.77.77Z"
            fill="#f2f2f3"
          />
          <path
            d="M195,68.66h0l-5.91-6.25h0a.81.81,0,0,0-.57-.25h-.77v7a.77.77,0,0,0,.77.78h6.69v-.78A.77.77,0,0,0,195,68.66Z"
            fill="#d6d6d6"
          />
          <path
            d="M197.08,94.16H166.42a.77.77,0,0,1-.78-.77V74.7a.78.78,0,0,1,.43-.69.75.75,0,0,1,.8.07l12.83,9.36.37-.25a3,3,0,0,1,3.36,0l.37.25,12.82-9.36a.77.77,0,0,1,1.23.62V93.39A.77.77,0,0,1,197.08,94.16Z"
            fill="#ffc70a"
          />
          <path
            d="M197.08,94.16H166.42a.77.77,0,0,1-.44-1.41l14.09-9.56a3,3,0,0,1,3.36,0l14.08,9.56a.77.77,0,0,1-.43,1.41Z"
            fill="#fdd34e"
          />
          <path
            d="M181.74,79.84a6.89,6.89,0,0,1-2.52-.46,5.79,5.79,0,0,1-3.3-3.21,6.44,6.44,0,0,1-.46-2.42A7.28,7.28,0,0,1,176,71a5.65,5.65,0,0,1,3.33-3.28,6.76,6.76,0,0,1,2.43-.43,7.27,7.27,0,0,1,2.79.5,5.54,5.54,0,0,1,2,1.34A5.27,5.27,0,0,1,187.67,71,6.16,6.16,0,0,1,188,73.3a4.37,4.37,0,0,1-.68,2.49,2.3,2.3,0,0,1-2,.87,2.68,2.68,0,0,1-1.31-.33,1.66,1.66,0,0,1-.82-1l.48,0a1.9,1.9,0,0,1-.94.9,3.11,3.11,0,0,1-2.7-.1,2.73,2.73,0,0,1-1-1,3.14,3.14,0,0,1-.36-1.53,3,3,0,0,1,.38-1.56,2.73,2.73,0,0,1,1-1,3,3,0,0,1,1.47-.36,3.16,3.16,0,0,1,1.14.22,2.17,2.17,0,0,1,.92.64l-.35.43V70.94h1.13l0,3a1.56,1.56,0,0,0,.25,1,.89.89,0,0,0,.75.33.91.91,0,0,0,.67-.25,1.46,1.46,0,0,0,.34-.68,5.42,5.42,0,0,0,.11-1,4.81,4.81,0,0,0-.39-2.18A3.82,3.82,0,0,0,185,69.72a4.46,4.46,0,0,0-1.53-.79,5.73,5.73,0,0,0-1.67-.25,5.4,5.4,0,0,0-2.08.37,4.22,4.22,0,0,0-1.54,1,4.39,4.39,0,0,0-.94,1.59,5.89,5.89,0,0,0-.3,2,5.69,5.69,0,0,0,.4,2,4.34,4.34,0,0,0,1,1.5,4.42,4.42,0,0,0,1.53.94,5.37,5.37,0,0,0,1.92.33A5.82,5.82,0,0,0,183,78.3a5,5,0,0,0,1.07-.37l.48,1.31a6.38,6.38,0,0,1-1.36.45A6.88,6.88,0,0,1,181.74,79.84Zm-.07-4.7a1.31,1.31,0,0,0,1.4-1.51,1.6,1.6,0,0,0-.36-1.13,1.3,1.3,0,0,0-1-.38,1.52,1.52,0,0,0-1.14.4,1.57,1.57,0,0,0-.37,1.11,1.5,1.5,0,0,0,.39,1.11A1.41,1.41,0,0,0,181.67,75.14Z"
            fill="#333"
          />
        </g>
        <g id="Location">
          <path
            d="M84.86,65.2a8.1,8.1,0,0,0-8.1,8.09c0,4.35,7.39,11.76,7.7,12.07l.4.4.39-.4c.32-.31,7.7-7.72,7.7-12.07A8.1,8.1,0,0,0,84.86,65.2Zm0,11.89a3.8,3.8,0,1,1,3.79-3.8A3.81,3.81,0,0,1,84.86,77.09Z"
            fill="#da1a5e"
          />
          <path
            d="M81.06,73.29a3.8,3.8,0,0,1,3.8-3.79V65.2a8.1,8.1,0,0,0-8.1,8.09c0,4.35,7.39,11.76,7.7,12.07l.4.4V77.09A3.81,3.81,0,0,1,81.06,73.29Z"
            fill="#e04f87"
          />
        </g>
        <g id="Calender">
          <path
            d="M73.07,147H56.55a.65.65,0,0,1-.65-.65v-15.1a.65.65,0,0,1,.65-.65H73.07a.66.66,0,0,1,.65.65v15.1A.66.66,0,0,1,73.07,147Z"
            fill="#f2f2f3"
          />
          <path
            d="M73.07,147H64.81v-16.4h8.26a.66.66,0,0,1,.65.65v15.1A.66.66,0,0,1,73.07,147Z"
            fill="#d6d6d6"
          />
          <path
            d="M63.63,138.68v-.05a1.57,1.57,0,0,0,1.13-1.56,1.71,1.71,0,0,0-.25-.91,1.83,1.83,0,0,0-.7-.68,2.2,2.2,0,0,0-1.09-.25,2.45,2.45,0,0,0-1.09.23,2,2,0,0,0-.8.63,1.58,1.58,0,0,0-.33.95h.81a1,1,0,0,1,.21-.58,1.22,1.22,0,0,1,.51-.38,1.9,1.9,0,0,1,.67-.13,1.34,1.34,0,0,1,.66.15,1,1,0,0,1,.44.4,1.2,1.2,0,0,1,.15.61,1.1,1.1,0,0,1-.66,1,1.81,1.81,0,0,1-.72.14H62V139h.55a2.17,2.17,0,0,1,.89.17,1.3,1.3,0,0,1,.55.45,1.27,1.27,0,0,1,.18.67A1.07,1.07,0,0,1,64,141a1.29,1.29,0,0,1-.54.43,1.94,1.94,0,0,1-.78.15,1.9,1.9,0,0,1-.73-.13,1.24,1.24,0,0,1-.52-.37,1,1,0,0,1-.23-.57h-.85a1.73,1.73,0,0,0,.33,1,2,2,0,0,0,.83.63,2.76,2.76,0,0,0,1.18.23,2.67,2.67,0,0,0,1.19-.25,2.12,2.12,0,0,0,.83-.7,1.76,1.76,0,0,0,.3-1,1.71,1.71,0,0,0-.36-1.1A1.65,1.65,0,0,0,63.63,138.68Z"
            fill="#333"
          />
          <path
            d="M62.69,142.54a3,3,0,0,1-1.28-.25,2.21,2.21,0,0,1-.92-.71,1.89,1.89,0,0,1-.38-1.09.23.23,0,0,1,.06-.18.27.27,0,0,1,.18-.08h.85a.25.25,0,0,1,.25.23.72.72,0,0,0,.17.43,1,1,0,0,0,.43.3,1.79,1.79,0,0,0,1.31,0,1.09,1.09,0,0,0,.44-.34.84.84,0,0,0,.15-.5.94.94,0,0,0-.15-.53,1,1,0,0,0-.45-.37,2,2,0,0,0-.78-.14H62a.25.25,0,0,1-.25-.25v-.74a.25.25,0,0,1,.25-.24h.55a1.44,1.44,0,0,0,.61-.13.85.85,0,0,0,.39-.33.88.88,0,0,0,.14-.49.9.9,0,0,0-.12-.48.73.73,0,0,0-.34-.31,1.4,1.4,0,0,0-1.12,0,1.08,1.08,0,0,0-.42.3.74.74,0,0,0-.16.44.24.24,0,0,1-.24.24H60.5a.25.25,0,0,1-.18-.08.29.29,0,0,1-.07-.18,2,2,0,0,1,.38-1.09,2.4,2.4,0,0,1,.89-.71,2.82,2.82,0,0,1,2.41,0,2.12,2.12,0,0,1,.8.77,2,2,0,0,1,.28,1,1.85,1.85,0,0,1-.36,1.14,1.73,1.73,0,0,1-.41.4,1.92,1.92,0,0,1,.6.46,2,2,0,0,1,.42,1.26,2,2,0,0,1-.35,1.15,2.28,2.28,0,0,1-.92.78A2.89,2.89,0,0,1,62.69,142.54Zm-2.06-1.81a1.61,1.61,0,0,0,.25.55,1.81,1.81,0,0,0,.73.56,2.84,2.84,0,0,0,2.16,0,1.88,1.88,0,0,0,.74-.62,1.54,1.54,0,0,0,.26-.87,1.46,1.46,0,0,0-.31-.94,1.4,1.4,0,0,0-.87-.47.25.25,0,0,1-.21-.24v-.05a.25.25,0,0,1,.18-.24,1.34,1.34,0,0,0,.7-.48,1.37,1.37,0,0,0,.25-.84,1.56,1.56,0,0,0-.82-1.37,2.35,2.35,0,0,0-2,0,1.79,1.79,0,0,0-.71.56,1.42,1.42,0,0,0-.24.55h.31a1.13,1.13,0,0,1,.24-.49,1.65,1.65,0,0,1,.61-.45,2,2,0,0,1,1.54,0,1.32,1.32,0,0,1,.53.49,1.38,1.38,0,0,1,.19.74,1.34,1.34,0,0,1-.22.77,1.49,1.49,0,0,1-.58.5,2,2,0,0,1-.83.17h-.3v.24h.3a2.5,2.5,0,0,1,1,.19,1.47,1.47,0,0,1,.65.55,1.44,1.44,0,0,1,.23.8,1.29,1.29,0,0,1-.24.78,1.49,1.49,0,0,1-.64.51,2.27,2.27,0,0,1-1.7,0,1.56,1.56,0,0,1-.62-.44,1.23,1.23,0,0,1-.25-.48Z"
            fill="#333"
          />
          <polygon
            points="67.97 135.32 66.25 136.46 66.25 137.31 67.93 136.19 67.97 136.19 67.97 142.2 68.8 142.2 68.8 135.32 67.97 135.32"
            fill="#333"
          />
          <path
            d="M68.8,142.45H68a.25.25,0,0,1-.25-.25v-5.57l-1.33.89a.27.27,0,0,1-.26,0,.26.26,0,0,1-.13-.22v-.85a.23.23,0,0,1,.11-.2l1.72-1.14a.24.24,0,0,1,.14-.05h.83a.25.25,0,0,1,.25.25v6.88A.25.25,0,0,1,68.8,142.45Zm-.59-.5h.34v-6.38H68l-1.54,1v.25l1.29-.86a.28.28,0,0,1,.42.21Z"
            fill="#333"
          />
          <rect
            x="59.42"
            y="128.15"
            width="1.84"
            height="5.52"
            rx="0.92"
            fill="#665f5d"
          />
          <rect
            x="68.35"
            y="128.15"
            width="1.84"
            height="5.52"
            rx="0.92"
            fill="#665f5d"
          />
        </g>
        <g id="Gear2" opacity="0.25">
          <path
            d="M141.49,113.25h2a8.54,8.54,0,0,0,1.12,2.7l-1.43,1.44a.51.51,0,0,0,0,.74l1.72,1.72a.53.53,0,0,0,.74,0l1.43-1.43a8.07,8.07,0,0,0,2.32,1c.13,0,.26,0,.39.08v2a.52.52,0,0,0,.52.53h2.44a.52.52,0,0,0,.52-.53v-2a8.2,8.2,0,0,0,2.7-1.11l1.44,1.43a.53.53,0,0,0,.74,0l1.72-1.72a.53.53,0,0,0,0-.74L158.45,116a7.65,7.65,0,0,0,.75-1.45,8,8,0,0,0,.28-.87c0-.12.06-.25.09-.38h2a.53.53,0,0,0,.52-.52v-2.44a.53.53,0,0,0-.52-.53h-2a8.17,8.17,0,0,0-1.12-2.69l1.44-1.44a.53.53,0,0,0,0-.74l-1.72-1.73a.53.53,0,0,0-.74,0L156,104.6a8.12,8.12,0,0,0-2.32-1l-.38-.08v-2a.52.52,0,0,0-.52-.52h-2.44a.52.52,0,0,0-.52.52v2a8.18,8.18,0,0,0-2.71,1.12l-1.43-1.44a.53.53,0,0,0-.74,0l-1.72,1.73a.51.51,0,0,0,0,.74l1.43,1.43a8.38,8.38,0,0,0-1,2.32c0,.13-.05.26-.08.38h-2a.52.52,0,0,0-.52.53v2.44A.52.52,0,0,0,141.49,113.25Zm7.07-1.74a3,3,0,1,1,3,3A3,3,0,0,1,148.56,111.51Z"
            fill="#fff"
          />
          <path
            d="M186.34,8.09h9.44A38,38,0,0,0,201,20.62L194.3,27.3a2.43,2.43,0,0,0,0,3.44l8,8a2.44,2.44,0,0,0,3.45,0l6.65-6.65a38.22,38.22,0,0,0,10.77,4.77c.59.16,1.19.22,1.78.36v9.49a2.43,2.43,0,0,0,2.44,2.43h5.66V13.89A13.91,13.91,0,0,1,219.15,0H183.9V5.66A2.43,2.43,0,0,0,186.34,8.09Z"
            fill="#fff"
          />
          <path
            d="M113.29,82h3.48a13.86,13.86,0,0,0,1.91,4.61L116.22,89a.9.9,0,0,0,0,1.27l3,2.94a.89.89,0,0,0,1.26,0l2.45-2.45a14,14,0,0,0,4,1.76c.22.06.44.08.66.13v3.49a.9.9,0,0,0,.9.9h4.16a.89.89,0,0,0,.89-.9V92.71a13.91,13.91,0,0,0,4.62-1.91l2.45,2.45a.9.9,0,0,0,1.27,0l2.94-2.94a.9.9,0,0,0,0-1.27l-2.45-2.45a13.47,13.47,0,0,0,1.27-2.48,14.25,14.25,0,0,0,.49-1.48c.06-.22.09-.44.14-.66h3.48a.89.89,0,0,0,.9-.89V76.92a.9.9,0,0,0-.9-.9h-3.47a14.18,14.18,0,0,0-1.92-4.61L144.73,69a.89.89,0,0,0,0-1.26l-2.94-3a.9.9,0,0,0-1.27,0l-2.45,2.45a14,14,0,0,0-4-1.75c-.22-.06-.44-.09-.66-.13v-3.5a.89.89,0,0,0-.89-.89H128.4a.89.89,0,0,0-.9.89v3.48a14.19,14.19,0,0,0-4.62,1.9l-2.45-2.45a.89.89,0,0,0-1.26,0l-3,3a.89.89,0,0,0,0,1.26l2.46,2.46a13.69,13.69,0,0,0-1.76,4c-.06.22-.1.44-.14.66h-3.49a.89.89,0,0,0-.89.9v4.16A.89.89,0,0,0,113.29,82Zm12.08-3a5.11,5.11,0,1,1,5.11,5.11A5.11,5.11,0,0,1,125.37,79Z"
            fill="#fff"
          />
        </g>
      </svg>
    );
}