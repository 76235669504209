import React from "react";
import LearnMore from "../Buttons/LearnMoreButton";
import Input from "../Input";
import StayConnected from "../StayConnected";
import puppy0 from "../../res/assets/pupp0.png";
import "./SubSectionStyles.css";

const SubSection = ({}) => {
  return (
    <div className="w-full flex justify-center z-1 mobile:my-10 desktop:bottomSlideIn">
      <div
        className="h-full text-white desktop:bg-navy grid desktop:grid-cols-4 mobile:grid-cols-2 mobile:gap-4"
        style={{
          justifyItems: "center",
          width: "90%",
          borderTopLeftRadius: "3rem",
          borderTopRightRadius: "3rem",
        }}
      >
        <div className="w-full h-full flex flex-col mobile:py-6 desktop:pt-10 desktop:pb-6 mobile:px-4 desktop:px-10 justify-between items-start mobile:bg-navy mobile:rounded-lg mobile:shadow-bottom mobile:leftSlideIn">
          <p
            className="font-semibold tracking-wide text-xl text-gray-200 self-start mb-3"
            style={{ fontFamily: "Recoleta" }}
          >
            Pet Effect {"🐶"}
          </p>
          <p className="text-xs text-gray-300 mb-3">
            The health benefits of the human-animal bond.
          </p>
          <LearnMore url="https://habri.org/the-pet-effect/#infographics" />
        </div>
        <div
          className="h-full w-full relative mobile:hidden"
          style={{
            boxShadow:
              "rgba(0, 0, 0, 0.23) 3px -5px 20px, rgba(0, 0, 0, 0.25) 3px -3px 6px",
          }}
        >
          <div
            style={{
              height: "130%",
            }}
            className="absolute bg-purple bottom-0 w-full rounded-t-xl overflow-hidden"
          >
            <div className="h-full w-full relative p-6">
              <svg
                viewBox="0 0 10 10"
                preserveAspectRatio="xMaxYMax meet"
                width="100%"
                height="100%"
                xmlns="http://www.w3.org/2000/svg"
                className="absolute top-0 left-0"
              >
                <circle cx="8" cy="2" r="3" fill="#5A2AAF" />
              </svg>
              <svg
                viewBox="0 0 10 10"
                preserveAspectRatio="xMinYMid meet"
                width="100%"
                xmlns="http://www.w3.org/2000/svg"
                className="h-3/5 mb-2"
              >
                <filter
                  id={`pup0-shadow`}
                  x="-10"
                  y="-10"
                  width="100"
                  height="100"
                >
                  <feDropShadow
                    dx="-0.1"
                    dy="0.3"
                    stdDeviation="0.3"
                    floodColor="black"
                    floodOpacity="0.1"
                  />
                </filter>
                <image
                  href={puppy0}
                  height="10"
                  width="10"
                  x="-2"
                  y="0"
                  filter="url(#pup0-shadow)"
                />
              </svg>
              <p className="font-semibold tracking-wide text-md text-gray-200 self-start mb-1">
                Your Extended Family
              </p>
              <p className="text-xs text-gray-300 mb-3 leading-5">
                Beauty is our profession, where wellness meets grooming.
              </p>
            </div>
          </div>
        </div>

        <div className="flex flex-col justify-start items-start col-span-1 mobile:py-6 desktop:pt-10 desktop:pb-6 mobile:px-4 desktop:px-10 w-full h-full relative right-border mobile:bg-navy mobile:rounded-lg mobile:shadow-bottom mobile:rightSlideIn">
          <p
            className="font-semibold tracking-wide text-xl text-gray-200 self-start mb-3"
            style={{ fontFamily: "Recoleta" }}
          >
            Newsletter {"📧"}
          </p>
          {/* <p className="text-xs text-gray-300 mb-3">Stay connected.</p> */}
          <StayConnected />
        </div>

        <div className="flex flex-col justify-between items-start col-span-1 mobile:py-6 desktop:pt-10 desktop:pb-6 mobile:px-4 desktop:px-10 w-full h-full mobile:bg-navy mobile:rounded-lg mobile:shadow-bottom mobile:leftSlideIn">
          <p
            className="font-semibold tracking-wide text-xl text-gray-200 self-start"
            style={{ fontFamily: "Recoleta" }}
          >
            Location {"🗺"}
          </p>
          <a
            className="text-brandGreen text-xs"
            href="https://goo.gl/maps/xiVUx4LGdt71a5mB9"
          >
            15643 N Reems Rd Suite 101, <br />
            Surprise, AZ 85374
          </a>
          <p className="" style={{ fontSize: "0.5rem" }}>
            Copyright © 2023 Whole Groom
          </p>
        </div>
      </div>
    </div>
  );
};

export default SubSection;
