import React from "react";
import ReactModal from "react-modal";
import "./ModalStyles.css";

const Modal = ({ isOpen, onClose, children }) => {
  return (
    <ReactModal isOpen={isOpen} onRequestClose={onClose}>
      {children}
    </ReactModal>
  );
};

export default Modal;
