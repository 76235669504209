import React, { useCallback, useEffect, useState } from "react";

const HeaderOne = ({ children, className, weight }) => {
  const [innerWidth, setInnerWidth] = useState(window.innerWidth);

  const checkSize = useCallback(() => {
    setInnerWidth(window.innerWidth);
  }, []);

  useEffect(() => {
    window.addEventListener("resize", checkSize);

    return () => window.removeEventListener("resize", checkSize);
  }, [checkSize, innerWidth]);
  return (
    <p
      className={`${className}`}
      style={{
        fontSize: innerWidth > 1024 ? "4rem" : "3rem",
        fontWeight: `${weight ?? "800"}`,
        fontFamily: "Recoleta",
        lineHeight: innerWidth > 1024 ? "4.5rem" : "3.5rem",
      }}
    >
      {children}
    </p>
  );
};

export default HeaderOne;
