import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";

const NavLink = ({ title, onClick, active, pathName }) => {
  const [hover = false, setHover] = useState();

  const color = useMemo(() => {
    switch (pathName) {
      case "/services":
        return "#fff";
      default:
        return "#190740";
    }
  }, [pathName]);

  const handleHover = (action) => {
    setHover(action);
  };
  return (
    <div
      className="flex self-center relative col-span-1 mobile:hidden"
      style={{ minWidth: 0, color: color }}
      role="button"
      tabIndex={0}
      onKeyUp={() => onClick()}
      onClick={() => onClick()}
      onMouseOver={() => handleHover(true)}
      onMouseLeave={() => handleHover(false)}
    >
      <p className="relative inline">
        {title}
        {(hover || active) && (
          <svg
            viewBox="0 0 100 100"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="xMaxYMid meet"
            className="absolute w-full left-0"
            style={{ top: "100%" }}
          >
            <path
              d="M5,0 5,5 C 30,15 70,15 95,5 V0 C70,7.5 30,7.5 5,0z"
              fill={color}
              stroke={color}
              strokeWidth="1"
              strokeLinecap="round"
            />
          </svg>
        )}
      </p>
    </div>
  );
};

NavLink.propTypes = {
  title: PropTypes.string,
  onClick: PropTypes.func,
  active: PropTypes.shape({}),
};

NavLink.defaultProps = {
  title: undefined,
  onClick: () => {},
  active: undefined,
};

export default NavLink;
