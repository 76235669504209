import React, { useEffect, useMemo, useRef } from "react";
import { useState } from "react";
import ReactModal from "react-modal";
import { matchPath, useLocation, useNavigate } from "react-router-dom";
import Logo from "../../res/assets/Logo";
import PhoneIcon from "../../res/assets/PhoneIcon";
import BaseButton from "../Buttons/BaseButton";
import Modal from "../Modal";
import NavLink from "./NavLink";
import "../../index.css";
import MenuButton from "./MenuButton";
import cntl from "cntl";
import PropTypes from "prop-types";

const navCN = (routes) => cntl`
grid
desktop:grid-cols-7
mobile: grid-cols-5
w-full
text-black 
z-10
gap-4 
mt-4
mobile:px-4
desktop:px-5
`;

const NavBar = ({ routes, live, innerWidth, handleOpenBooking }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const navRoutes = useMemo(() => {
    return routes().filter(
      (item) => item.name !== "Office" && item.name !== "Upload"
    );
  }, [routes]);

  return (
    <div
      className={navCN(routes().length)}
      style={{
        height: "100px",
      }}
    >
      <div
        className="col-span-2 flex justify-start items-center"
        style={{ height: "inherit" }}
      >
        <Logo color={pathname === "/services" ? "white" : "#190740"} />
      </div>
      {navRoutes?.map((route) => {
        return (
          <NavLink
            title={route?.name}
            key={route?.name}
            onClick={() => navigate(route?.path)}
            pathName={pathname}
            active={matchPath(route, pathname)}
          />
        );
      })}

      <div className="col-span-1 flex mobile:hidden justify-start items-center self-center col-end-8">
        {live ? (
          <BaseButton
            className="self-start whitespace-nowrap bg-navy text-white px-4 py-2 rounded-full font-semibold"
            label="Book Now"
            onClick={() => handleOpenBooking()}
          />
        ) : (
          <p className="font-semibold text-lg text-white border border-navy py-2 px-4 cursor-pointer bg-navy rounded-lg">
            Coming Soon...
          </p>
        )}
      </div>
      <div
        className="col-span-1 col-start-5 desktop:hidden w-full flex items-center justify-end p-3"
        style={{ height: "inherit" }}
      >
        <MenuButton routes={routes()} />
      </div>
    </div>
  );
};

NavBar.propTypes = {
  routes: PropTypes.func,
  live: PropTypes.bool,
};

NavBar.defaultProps = {
  routes: () => [],
  live: true,
};

export default NavBar;
