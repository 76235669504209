import React from "react";
import Input from "../Input";

const StayConnected = () => {
  return (
    <div
      className="flex flex-col text-xs px-1 w-full"
      style={{ fontFamily: "Recoleta" }}
    >
      <span>Stay connected.</span>
      <Input
        buttonClassName="rounded-lg text-xs px-2 text-white"
        buttonBackground="linear-gradient(180deg, rgba(12,231,149,1) 0%, rgba(48,131,117,1) 100%)"
        handleSubmit={() => {}}
      />
    </div>
  );
};

export default StayConnected;
