import React, { useState, useRef } from "react";
import cntl from "cntl";
import play from "../../res/assets/PlayButton.svg";
import pause from "../../res/assets/Pause.svg";

const videoCN = (playing) => cntl`
bg-yellow 
object-cover 
mr-6 
rounded-lg 
${playing && "border-4 border-yellow"}
`;
const VideoContainer = ({ video, placeholder }) => {
  const [playing = false, setPlaying] = useState(false);
  const videoRef = useRef();

  const resetVideo = () => {
    videoRef.current.pause();
    videoRef.current.load();
    setPlaying(false);
  };

  const pressPlay = () => {
    if (playing) {
      resetVideo();
    } else {
      videoRef.current.play();
      setPlaying(true);
    }
  };

  return (
    <div className="relative">
      <video
        className={videoCN(playing)}
        style={{ height: "7.5rem", width: "7.5rem" }}
        poster={placeholder}
        ref={videoRef}
        onEnded={() => resetVideo()}
      >
        <source src={video} type="video/mp4" />
      </video>
      <div
        id="play-button"
        onClick={() => pressPlay()}
        role="button"
        tabIndex={0}
        onKeyUp={() => {}}
        className="absolute top-0 bg-orange rounded-full flex justify-center items-center text-white font-bold text-center"
        style={{
          left: "-1.25rem",
          top: "20%",
          height: "2.5rem",
          width: "2.5rem",
        }}
      >
        {!playing ? (
          <img src={play} className="w-full h-full p-3" />
        ) : (
          <img src={pause} className="w-full h-full p-3" />
        )}
      </div>
    </div>
  );
};

export default VideoContainer;
