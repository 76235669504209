

export default function EmailIcon(){

    return (
      <svg
        id="Layer_1"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 66.73 66.73"
        preserveAspectRatio="xMidYMid meet"
        width="100%"
      >
        <circle
          id="Container"
          cx="33.36"
          cy="33.36"
          r="33.36"
          fill="rgba(255, 255, 255, .25)"
        />
        <path
          d="M49.51,48.4H17.22c-2.85,0-5.17-2.69-5.17-6V22.7c0-3,2.11-3.29,2.76-3.3,1,0,1.69.61,2.73,1.64l.36.36L33.36,34.9,49,21.12c1.17-1.13,1.8-1.73,2.9-1.72.64,0,2.74.32,2.74,3.28V42.41C54.68,45.71,52.36,48.4,49.51,48.4ZM15.05,22.8V42.41c0,1.62,1,3,2.17,3H49.51c1.17,0,2.17-1.37,2.17-3V22.75l-.55.53-.28.26L33.37,38.88,15.42,23.17C15.3,23,15.17,22.92,15.05,22.8Z"
          fill="#333333"
        />
        <rect x="14.83" y="19.4" width="37.06" height="3" fill="#333333" />
        <rect
          x="12.07"
          y="37.64"
          width="18.88"
          height="3"
          transform="translate(-21.38 26.67) rotate(-45)"
          fill="#333333"
        />
        <rect
          x="43.72"
          y="29.7"
          width="3"
          height="18.88"
          transform="translate(-14.43 43.44) rotate(-45)"
          fill="#333333"
        />
      </svg>
    );
}