import React, { useCallback, useRef, useState } from "react";
import { uploadFile } from "react-s3";
import HeaderOne from "../../Components/Header1/Header";
import RoundedButton from "../../Components/Buttons/RoundedButton";
window.Buffer = window.Buffer || require("buffer").Buffer;

const UploadMedia = () => {
  const [uploads, setUploads] = useState([]);
  const inputRef = useRef();
  const config = {
    bucketName: "mgmanga",
    accessKeyId: "AKIAS4YHYXTX6OKSIJFQ",
    secretAccessKey: "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",
    region: "us-west-1",
  };
  function upload(e) {
    const promises = uploads.map((item) => uploadFile(item, config));
    Promise.all(promises).then(() => {
      setUploads([]);
      alert("Successfully Uploaded Media");
    });
  }

  const onChange = useCallback(({ target: { files } }) => {
    setUploads((prev) => [...prev, ...files]);
  }, []);

  const onRemove = useCallback((name) => {
    setUploads((prev) =>
      prev.filter((item) => {
        return item.name !== name;
      })
    );
  }, []);
  const handleClick = useCallback(() => {
    inputRef.current.click();
  }, []);
  console.log("track uploads", inputRef.current);
  return (
    <div className="flex w-full h-full justify-center items-center">
      <div controlId="formFileMultiple" className="flex flex-col gap-6">
        <div className="flex flex-col border-2 min-h-10 min-w-20 gap-4 p-4">
          <HeaderOne className="self-center">Uploads</HeaderOne>
          {!uploads.length ? (
            <p className="self-center">No Files Selected</p>
          ) : (
            <>
              {uploads.map(({ name }) => {
                return (
                  <div className="flex gap-3">
                    {name}
                    <RoundedButton
                      label="X"
                      color="red"
                      className="text-white !px-2 !py-1"
                      onClick={() => onRemove(name)}
                    />
                  </div>
                );
              })}
            </>
          )}
        </div>
        <div className="flex gap-4">
          <RoundedButton label="Select Media" onClick={handleClick} />
          <input
            type="file"
            name="images"
            ref={inputRef}
            multiple
            accept="image/png, image/jpeg, video/mp4, video/mov"
            onChange={onChange}
            label="Select Media"
            className="cursor-pointer hidden"
          />
          <RoundedButton
            color="green"
            label="Upload"
            className="cursor-pointer text-white"
            disabled={uploads.length === 0}
            onClick={upload}
          />
        </div>
      </div>
    </div>
  );
};

export default UploadMedia;
