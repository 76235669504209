import React, { useCallback, useEffect, useState } from "react";
import NavBar from "./Components/NavBar/NavBar";
import wgRoutes from "./routes/routeConfig";
import { Route, Routes, useLocation, useParams } from "react-router-dom";
import homeBackground from "./res/assets/homeBackground.svg";
import mobileBkg from "./res/assets/mobileBackground.svg";
import pawBackground from "./res/assets/pawBackground.svg";
import servicesBackground from "./res/assets/servicesBackground.svg";
import Footer from "./Components/Footer/Footer.jsx";
import Modal from "./Components/Modal";
import closeButton from "./res/assets/closeButton.svg";
import pawBackgroundBlack from "./res/assets/pawBackgroundBlack.svg";
import { useAppState } from "./state/appState";

const BaseContainer = () => {
  const { pathname } = useLocation();
  const [innerWidth, setInnerWidth] = useState(window.innerWidth);
  const [{ bookingOpen }, dispatch] = useAppState();

  const background = useCallback(() => {
    switch (pathname) {
      case "/":
        return innerWidth > 1024
          ? {
              backgroundImage: `url('${homeBackground}'), url(${pawBackground})`,
              backgroundPosition: "right top",
              backgroundRepeat: "no-repeat",
              overflowY: "overlay",
            }
          : {
              backgroundImage: `url('${mobileBkg}')`,
              backgroundSize: "auto",
              backgroundPosition: "right top",

              overflowY: "overlay",
            };
      case "/services":
        return {
          backgroundImage: `url(${pawBackgroundBlack}), linear-gradient(
      #13C9F2, 
      #13C9F2
    )`,
          backgroundPosition: "top",
          backgroundRepeat: "no-repeat",
          overflowY: "overlay",
        };
      default:
        return null;
    }
  }, [innerWidth, pathname]);

  const handleCloseBooking = () => {
    dispatch({ type: "close_booking" });
  };

  const handleOpenBooking = () => {
    dispatch({ type: "open_booking" });
  };

  const checkSize = useCallback(() => {
    setInnerWidth(window.innerWidth);
    dispatch({ type: "set_width", width: window.innerWidth });
  }, [dispatch]);

  useEffect(() => {
    window.addEventListener("resize", checkSize);

    return () => window.removeEventListener("resize", checkSize);
  }, [checkSize, innerWidth]);
  return (
    <>
      <div
        id="base_container"
        className="flex flex-col h-full w-full overflow-x-hidden bg-no-repeat"
        style={{
          ...background(),
          backgroundRepeat: "no-repeat",
        }}
      >
        <NavBar
          routes={wgRoutes}
          innerWidth={innerWidth}
          handleOpenBooking={handleOpenBooking}
        />

        <Routes>
          {wgRoutes({ handleOpenBooking }).map((page) => {
            return (
              <Route
                exact
                path={page.path}
                element={page.component}
                key={page?.name}
                innerWidth={innerWidth}
              />
            );
          })}
        </Routes>
        <Footer />
      </div>
      <Modal isOpen={bookingOpen} onClose={handleCloseBooking}>
        <div
          style={{ height: "100%" }}
          className="flex flex-col rounded-lg overflow-hidden relative"
        >
          <p
            className="self-end h-[5%] cursor-pointer font-semibold"
            onClick={() => handleCloseBooking()}
            alt="close-button"
            style={{ zIndex: "99" }}
          >
            Close
          </p>
          <iframe
            src="https://booking.moego.pet/go/?name=WholeGroom&book=0"
            width="100%"
            height="100%"
            frameBorder="0"
            title="Online booking"
            scrolling="no"
            id="online-booking"
            className="transparent"
          />
        </div>
      </Modal>
    </>
  );
};

export default BaseContainer;
