import React from "react";
import MainSection from "../MainSection/MainSection";
import SubSection from "../SubSection";
import pawIcon from "../../res/assets/pawIcon.svg";
import InstagramIcon from "../../res/icons/social/instagram";
import Facebook from "../../res/icons/social/fb";

const Home = ({ handleOpenBooking }) => {
  return (
    <div className="flex flex-col flex-1 items-center justify-start relative mt-6">
      <div
        className="flex flex-col w-full h-full justify-between relative"
        style={{ zIndex: 1 }}
      >
        <MainSection handleOpenBooking={handleOpenBooking} />

        <SubSection />

        <img
          src={pawIcon}
          className="w-10 h-10 absolute"
          alt="paw-icon0"
          style={{
            top: "60%",
            left: "-2.5%",
            opacity: "20%",
            transform: "rotate(45deg)",
          }}
        />
        <img
          src={pawIcon}
          className="w-8 h-8 absolute"
          alt="paw-icon1"
          style={{
            top: "70%",
            right: "50%",
            opacity: "20%",
            transform: "rotate(-45deg)",
          }}
        />
      </div>
      <div className="flex gap-2 w-full h-10 mb-2 desktop:hidden">
        <InstagramIcon
          onClick={() => window.open("https://www.instagram.com/wholegroom")}
        />
        <Facebook
          onClick={() => window.open("https://www.facebook.com/wholegroom")}
        />
      </div>
    </div>
  );
};

export default Home;
