import BaseButton from "./BaseButton";
import PropTypes from "prop-types";
import cntl from "cntl";

const buttonCN = (className) => cntl`
rounded-full
${className}
px-7
py-2
font-bold
text-xs
`;

const RoundedButton = ({ onClick, color, className, disabled, label }) => {
  return (
    <BaseButton
      onClick={onClick}
      style={{ background: color }}
      className={buttonCN(className)}
      disabled={disabled}
      label={label}
    />
  );
};

RoundedButton.propTypes = {
  onClick: PropTypes.func,
  color: PropTypes.string,
  disabled: PropTypes.bool,
  label: PropTypes.string,
};

RoundedButton.defaultProps = {
  onClick: () => alert("Button Clicked"),
  color: "linear-gradient(180deg, #0FD9A8 0%, #7BFF81 175%)",
  disabled: false,
  label: "[Button Label]",
};

export default RoundedButton;
