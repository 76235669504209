import React from "react";
import right from "../../res/assets/arrowRight.svg";

const RightNavigation = ({ onClick }) => {
  return (
    <div
      className="ml-2 w-6 h-6 cursor-pointer nav-btn-3d relative col-span-1"
      onClick={() => onClick()}
    >
      <div className="absolute top-0 left-0 flex w-full h-full justify-center items-center p-1">
        <img alt="right-arrow" src={right} className="w-full h-full" />
      </div>
    </div>
  );
};

export default RightNavigation;
