import React from "react";
import Office from "../Pages/Office/Office.jsx";
import Services from "../Pages/Services/Services.jsx";
import Contact from "../Pages/Contact/Contact.jsx";
import Home from "../Components/Home/Home";
import UploadMedia from "../Pages/Upload/UploadMedia.jsx";

const wgRoutes = (props) => [
  {
    name: "Home",
    path: "/",
    component: <Home {...props} />,
  },
  {
    name: "Services",
    path: "/services",
    component: <Services />,
  },
  {
    name: "Contact",
    path: "/contact",
    component: <Contact />,
  },
  {
    name: "Office",
    path: "/office",
    exact: true,
    component: <Office />,
  },
  {
    name: "Upload",
    path: "/office/upload",
    component: <UploadMedia />,
  },
];

export default wgRoutes;
