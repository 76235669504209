import React, { useState } from "react";
import RightNavigation from "./RightNavigation";
import LeftNavigation from "./LeftNavigation";
import BaseButton from "../Buttons/BaseButton";
import "../../index.css";

const SlideNavigation = ({ tabs, openBooking }) => {
  const [tab = 0, setTab] = useState();

  const onLeftClick = () => {
    if (tab > 0) {
      setTab(tab - 1);
    } else {
      setTab(tabs?.length - 1);
    }
  };

  const onRightClick = () => {
    if (tab < tabs?.length - 1) {
      setTab(tab + 1);
    } else {
      setTab(0);
    }
  };
  return (
    <div
      key={tabs[tab]?.title}
      id="content-container"
      className="flex flex-col h-full items-start text-box-fade-in col-span-1 p-4"
    >
      {/* <div className="w-1/2 self-end mb-2">
        <TabIndicator current={tab} tabs={tabs?.length} />
      </div> */}

      <p
        className="text-white font-bold mb-4"
        style={{
          textShadow: "1px 1px 2px rgba(0, 0, 0, 0.5",
          fontSize: "1.5rem",
        }}
      >
        {tabs[tab]?.title}
      </p>
      <p
        className="flex text-box text-sm text-navy"
        style={{
          minHeight: "0",
          overflowY: "scroll",
          scrollbarWidth: "none",
          textShadow: "1px 1px rgba(0, 0, 0, 0.25",
        }}
      >
        {tabs[tab]?.text}
      </p>
      <BaseButton
        label={tabs[tab]?.buttonLabel}
        onClick={tabs[tab]?.onClick ?? openBooking}
        style={{
          background: "linear-gradient(180deg, #FFA149 0%, #FF6258 100%)",
        }}
        className="rounded-full px-4 py-2 text-[#fff] font-semibold my-6"
      />
      <div className="flex my-1 gap-5 mx-4">
        <BaseButton
          label={
            <span
              className="rounded-full flex items-center justify-center text-white bg-purple"
              style={{
                height: "1.5rem",
                width: "1.5rem",
                transform: "scaleX(-1)",
              }}
            >
              ➜
            </span>
          }
          onClick={onLeftClick}
        />
        <BaseButton
          label={
            <span
              className="rounded-full flex items-center justify-center text-white bg-purple"
              style={{
                height: "1.5rem",
                width: "1.5rem",
              }}
            >
              ➜
            </span>
          }
          onClick={onRightClick}
        />
      </div>
      {tabs[tab]?.element}
    </div>
  );
};

SlideNavigation.defaultProps = {
  onChange: () => {},
  tabs: [],
};

export default SlideNavigation;
