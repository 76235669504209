import ChatIcon from "../../Components/Chaticon";
import ServiceContactForm from "../../Components/servicecontactform";
import ContactBackground from "./contactBackground";
import "./styles/contact.css";
import petOwner from "../../res/assets/petOwner.jpeg";
import EmailIcon from "../../Components/Emailicon";
import InstagramIcon from "../../res/icons/social/instagram";
import Facebook from "../../res/icons/social/fb";

export default function Contact() {
  // const dispatch = useDispatch();
  // const globe = useSelector((state) => state);

  // useEffect(() => {
  //   {
  //     !globe && getUser(dispatch);
  //   }
  //   console.log(globe);
  //   //{globe && pageView(dispatch)}
  // }, [globe]);
  return (
    <>
      <div className="w-full h-full flex mobile:flex-col desktop:items-center desktop:justify-center overflow-x-hidden">
        <div
          style={{ width: "50%", paddingLeft: "5%" }}
          className="desktop:h-full flex justify-center items-start pt-10 desktop:half-border relative pr-20 mobile:hidden"
        >
          <div className="flex justify-end relative" style={{ height: "70%" }}>
            <p
              className="font-semibold absolute text-navy"
              style={{
                fontSize: "3rem",
                lineHeight: "3rem",
                letterSpacing: "-0.1rem",
                fontFamily: "Recoleta",
                left: "-30%",
                top: "25%",
                zIndex: "10",
              }}
            >
              Let&apos;s chat.
              <br /> Tell me about your
              <br /> visit.
            </p>
            <img
              src={petOwner}
              className="rounded-full"
              style={{ opacity: "70%" }}
              alt="pet-owner"
            />
          </div>
        </div>
        <div
          id="contact-form-container"
          className="rounded-lg desktop:px-10 mobile:px-5 flex flex-col mobile:!w-full"
          style={{ width: "50%" }}
        >
          <ServiceContactForm />
        </div>
      </div>
      <div className="w-full h-10 flex mx-2 my-2">
        <InstagramIcon
          onClick={() => window.open("https://www.instagram.com/wholegroom")}
        />
        <Facebook
          onClick={() => window.open("https://www.facebook.com/wholegroom")}
        />
      </div>
    </>
  );
}
