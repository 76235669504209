import React from "react";
import { useAppState } from "../../state/appState";
import BaseButton from "../Buttons/BaseButton";

const PricesSection = () => {
  const [, dispatch] = useAppState();

  const handleOpenBooking = () => {
    dispatch({ type: "open_booking" });
  };
  return (
    <>
      <svg
        id="Layer_1"
        dataname="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        className="w-full mobile:hidden"
        style={{ transform: "translate(0, -5%)" }}
        viewBox="0 0 540 284.31"
      >
        <defs>
          <filter
            id="filter-1"
            x="135"
            y="69.31"
            width="135"
            height="171"
            filterUnits="userSpaceOnUse"
          >
            <feOffset input="SourceAlpha" />
            <feGaussianBlur stdDeviation="1" result="blur" />
            <feFlood flood-color="#605f60" />
            <feComposite operator="in" in2="blur" />
            <feComposite in="SourceGraphic" />
          </filter>
          <linearGradient
            id="linear-gradient-1"
            x1="-79.16"
            y1="118.59"
            x2="49.53"
            y2="118.59"
            gradientTransform="matrix(-1, 0, 0, 1, 187.74, 0)"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stop-color="#74bbe7" />
            <stop offset="1" stop-color="#74bbe7" />
          </linearGradient>
          <linearGradient
            id="linear-gradient-1-2"
            x1="138.21"
            y1="106.73"
            x2="266.9"
            y2="106.73"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stop-color="#868ec6" />
            <stop offset="1" stop-color="#74bbe7" />
          </linearGradient>
          <filter id="filter-1-2">
            <feOffset input="SourceAlpha" />
            <feGaussianBlur stdDeviation="1" result="blur-2" />
            <feFlood flood-color="#7a797a" />
            <feComposite operator="in" in2="blur-2" />
            <feComposite in="SourceGraphic" />
          </filter>
          <filter
            id="filter-1-3"
            x="269"
            y="69.31"
            width="136"
            height="192"
            filterUnits="userSpaceOnUse"
          >
            <feOffset input="SourceAlpha" />
            <feGaussianBlur stdDeviation="1" result="blur-3" />
            <feFlood flood-color="#605f60" />
            <feComposite operator="in" in2="blur-3" />
            <feComposite in="SourceGraphic" />
          </filter>
          <linearGradient
            id="linear-gradient-1-3"
            x1="-213.78"
            y1="119.32"
            x2="-85.08"
            y2="119.32"
            gradientTransform="matrix(-1, 0, 0, 1, 187.74, 0)"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stop-color="#565ba7" />
            <stop offset="1" stop-color="#8b5fa7" />
          </linearGradient>
          <linearGradient
            id="linear-gradient-1-4"
            x1="272.82"
            y1="107.46"
            x2="401.52"
            y2="107.46"
            gradientTransform="matrix(1, 0, 0, 1, 0, 0)"
            href="#linear-gradient-1-3"
          />
          <filter id="filter-1-4">
            <feOffset input="SourceAlpha" />
            <feGaussianBlur stdDeviation="1" result="blur-4" />
            <feFlood flood-color="#7a797a" />
            <feComposite operator="in" in2="blur-4" />
            <feComposite in="SourceGraphic" />
          </filter>
          <filter
            id="filter-1-5"
            x="404"
            y="69.31"
            width="136"
            height="215"
            filterUnits="userSpaceOnUse"
          >
            <feOffset input="SourceAlpha" />
            <feGaussianBlur stdDeviation="1" result="blur-5" />
            <feFlood flood-color="#605f60" />
            <feComposite operator="in" in2="blur-5" />
            <feComposite in="SourceGraphic" />
          </filter>
          <linearGradient
            id="linear-gradient-1-5"
            x1="-348.39"
            y1="119.32"
            x2="-219.69"
            y2="119.32"
            gradientTransform="matrix(-1, 0, 0, 1, 187.74, 0)"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stop-color="#f6902f" />
            <stop offset="1" stop-color="#ef5456" />
          </linearGradient>
          <linearGradient
            id="linear-gradient-1-6"
            x1="407.43"
            y1="107.46"
            x2="536.13"
            y2="107.46"
            gradientTransform="matrix(1, 0, 0, 1, 0, 0)"
            href="#linear-gradient-1-5"
          />
          <filter id="filter-1-6">
            <feOffset input="SourceAlpha" />
            <feGaussianBlur stdDeviation="1" result="blur-6" />
            <feFlood flood-color="#7a797a" />
            <feComposite operator="in" in2="blur-6" />
            <feComposite in="SourceGraphic" />
          </filter>
          <filter
            id="filter-1-7"
            x="0"
            y="68.31"
            width="136"
            height="156"
            filterUnits="userSpaceOnUse"
          >
            <feOffset input="SourceAlpha" />
            <feGaussianBlur stdDeviation="1" result="blur-7" />
            <feFlood flood-color="#605f60" />
            <feComposite operator="in" in2="blur-7" />
            <feComposite in="SourceGraphic" />
          </filter>
          <linearGradient
            id="linear-gradient-1-7"
            x1="55.45"
            y1="118.01"
            x2="184.14"
            y2="118.01"
            gradientTransform="matrix(-1, 0, 0, 1, 187.74, 0)"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stop-color="#acd049" />
            <stop offset="1" stop-color="#00b392" />
          </linearGradient>
          <linearGradient
            id="linear-gradient-1-8"
            x1="3.6"
            y1="106.15"
            x2="132.29"
            y2="106.15"
            gradientTransform="matrix(1, 0, 0, 1, 0, 0)"
            href="#linear-gradient-1-7"
          />
          <filter id="filter-1-8">
            <feOffset input="SourceAlpha" />
            <feGaussianBlur stdDeviation="1" result="blur-8" />
            <feFlood flood-color="#7a797a" />
            <feComposite operator="in" in2="blur-8" />
            <feComposite in="SourceGraphic" />
          </filter>
          <filter id="filter-1-9">
            <feOffset dx="2" dy="2" input="SourceAlpha" />
            <feGaussianBlur result="blur-9" />
            <feFlood flood-color="#74bbe7" />
            <feComposite operator="in" in2="blur-9" />
            <feComposite in="SourceGraphic" />
          </filter>
        </defs>
        <rect
          x="138.21"
          y="72.5"
          width="128.7"
          height="163.97"
          rx="16.02"
          fill="#fff"
          filter="url(#filter-1)"
        />
        <path
          d="M266.9,114.89V97.29a16,16,0,0,0-16-16H154.23a16,16,0,0,0-16,16v58.62s15.33-23.52,33.39-29.9c11.23-4,23.4-1.62,52.33,5.85S266.9,114.89,266.9,114.89Z"
          fill="url(#linear-gradient-1)"
        />
        <path
          d="M138.21,141V88.52a16,16,0,0,1,16-16h96.66a16,16,0,0,1,16,16v24.23s-12.29,11.71-30.64,11.71-51-12.88-69.33-4.85A80.58,80.58,0,0,0,138.21,141Z"
          fill="url(#linear-gradient-1-2)"
        />
        <g filter="url(#filter-1-2)">
          <path
            d="M164.41,94.12h2.36c.55,0,.83.2.83.62s-.26.67-.79.67h-1.45a1.84,1.84,0,0,0-.82.1c-.08.07-.13.29-.13.66v3c0,.74.08,1.16.25,1.28a4.58,4.58,0,0,0,1.78.18,7.46,7.46,0,0,0,2-.16,2,2,0,0,0,.92-.82q.28-.42.57-.42c.33,0,.5.15.5.45a1.54,1.54,0,0,1-.16.54l-.55,1.27a.59.59,0,0,1-.53.42l-.36,0h-7.43q-.87,0-.87-.54c0-.27.15-.44.47-.51l.31-.06a.46.46,0,0,0,.36-.21,2.33,2.33,0,0,0,.07-.75V91.32a2.33,2.33,0,0,0-.07-.75.46.46,0,0,0-.36-.21L161,90.3a.48.48,0,0,1-.47-.51q0-.54.87-.54h7.32a.63.63,0,0,1,.66.38L169.9,91a.89.89,0,0,1,.1.36c0,.27-.17.4-.52.4-.2,0-.43-.19-.68-.57a1.46,1.46,0,0,0-.84-.61,5,5,0,0,0-1.06-.07h-1.33a1.61,1.61,0,0,0-1,.18,1.51,1.51,0,0,0-.2,1Z"
            fill="#fff"
          />
          <path
            d="M176.29,94.12l.34,1.48a2.39,2.39,0,0,1,.06.28c0,.25-.15.37-.44.37s-.31-.12-.48-.35a2.07,2.07,0,0,0-1.71-1c-.6,0-.91.26-.91.78a1.1,1.1,0,0,0,.57.85l1,.51a6.4,6.4,0,0,1,1.79,1.28,2.39,2.39,0,0,1-.31,3.19,3,3,0,0,1-2,.66,5.82,5.82,0,0,1-1.44-.21l-.27,0a.41.41,0,0,0-.25.07l-.24.14a.51.51,0,0,1-.21,0c-.26,0-.42-.17-.5-.51l-.36-1.54a1,1,0,0,1-.06-.32.42.42,0,0,1,.48-.48q.24,0,.63.54a2.44,2.44,0,0,0,2,1.19,1.18,1.18,0,0,0,.77-.24.8.8,0,0,0,.3-.63.94.94,0,0,0-.28-.66,5.61,5.61,0,0,0-1.11-.73,7,7,0,0,1-1.9-1.34,2.05,2.05,0,0,1-.51-1.41,2.15,2.15,0,0,1,.7-1.66,2.59,2.59,0,0,1,1.82-.64,4.35,4.35,0,0,1,.75.09,3.79,3.79,0,0,0,.73.1.44.44,0,0,0,.25-.08l.21-.13a.62.62,0,0,1,.23,0C176.08,93.68,176.22,93.83,176.29,94.12Z"
            fill="#fff"
          />
          <path
            d="M183.26,94.12l.34,1.48a2.39,2.39,0,0,1,.06.28c0,.25-.15.37-.44.37s-.31-.12-.47-.35a2.1,2.1,0,0,0-1.71-1c-.61,0-.92.26-.92.78a1.1,1.1,0,0,0,.57.85l1,.51a6.25,6.25,0,0,1,1.79,1.28,2.15,2.15,0,0,1,.47,1.44,2.21,2.21,0,0,1-.77,1.75,3.05,3.05,0,0,1-2,.66,5.82,5.82,0,0,1-1.44-.21l-.27,0a.36.36,0,0,0-.24.07l-.25.14a.51.51,0,0,1-.21,0c-.25,0-.42-.17-.49-.51l-.37-1.54a1.32,1.32,0,0,1-.05-.32.42.42,0,0,1,.47-.48q.26,0,.63.54a2.44,2.44,0,0,0,2,1.19,1.16,1.16,0,0,0,.77-.24.8.8,0,0,0,.3-.63.9.9,0,0,0-.28-.66,5.61,5.61,0,0,0-1.11-.73,7.2,7.2,0,0,1-1.9-1.34,2.05,2.05,0,0,1-.51-1.41,2.15,2.15,0,0,1,.7-1.66,2.6,2.6,0,0,1,1.83-.64,4.58,4.58,0,0,1,.75.09,3.61,3.61,0,0,0,.73.1.41.41,0,0,0,.24-.08l.21-.13a.66.66,0,0,1,.23,0C183.06,93.68,183.2,93.83,183.26,94.12Z"
            fill="#fff"
          />
          <path
            d="M191.52,97.23l-2.6,1.5a1.23,1.23,0,0,1-.55.21.58.58,0,0,1-.42-.18.61.61,0,0,1-.17-.42.48.48,0,0,1,.25-.44l1.19-.78c.49-.32.73-.64.73-1a1.2,1.2,0,0,0-.31-.84,1,1,0,0,0-.74-.34,1.38,1.38,0,0,0-1.2.82,4.21,4.21,0,0,0-.47,2.15,3.6,3.6,0,0,0,.54,2.11,1.73,1.73,0,0,0,1.48.76,4.06,4.06,0,0,0,1.94-.65,1.22,1.22,0,0,1,.53-.21c.32,0,.49.18.49.55s-.35.75-1,1.09a5,5,0,0,1-2.24.51A3.91,3.91,0,0,1,184.8,98a4.14,4.14,0,0,1,1.18-3,4,4,0,0,1,3-1.19,3.64,3.64,0,0,1,2.28.73,2.17,2.17,0,0,1,1,1.76A1.15,1.15,0,0,1,191.52,97.23Z"
            fill="#fff"
          />
          <path
            d="M196.52,94.73a3.3,3.3,0,0,1,2.43-1,2.64,2.64,0,0,1,2,.74,3,3,0,0,1,.55,2v3.48a1.76,1.76,0,0,0,.09.69.8.8,0,0,0,.62.34c.32.05.48.21.48.49s-.17.48-.51.48h-3.53c-.39,0-.59-.15-.59-.44a.49.49,0,0,1,.17-.42,1.53,1.53,0,0,1,.46-.15c.31-.08.47-.34.47-.8V96.63c0-1-.34-1.45-1-1.45a1.34,1.34,0,0,0-1.2.67,3.64,3.64,0,0,0-.36,1.9V100a2.81,2.81,0,0,0,.05.64.65.65,0,0,0,.51.33c.36.07.54.24.54.49a.41.41,0,0,1-.3.44,3,3,0,0,1-.59,0h-3.18c-.39,0-.59-.16-.59-.48s.22-.48.65-.54a.57.57,0,0,0,.53-.4,3.14,3.14,0,0,0,0-.7V96.66a2.19,2.19,0,0,0-.06-.65.6.6,0,0,0-.55-.29c-.4,0-.61-.19-.61-.51s.17-.42.52-.56l2.28-.89a.94.94,0,0,1,.36-.08c.21,0,.32.17.32.49Z"
            fill="#fff"
          />
          <path
            d="M207,94h1.14q.57,0,.57.51c0,.35-.3.53-.89.53a1.22,1.22,0,0,0-.69.12,1,1,0,0,0-.13.64v3.9a2.83,2.83,0,0,0,.08.85.51.51,0,0,0,.51.31,1,1,0,0,0,.4-.08,1.94,1.94,0,0,1,.44-.11c.34,0,.51.19.51.58s-.22.49-.66.7a3.31,3.31,0,0,1-1.47.31,2,2,0,0,1-1.9-.91,3.52,3.52,0,0,1-.25-1.54v-4.2c0-.26-.05-.43-.16-.49a1.13,1.13,0,0,0-.58-.1c-.43,0-.64-.18-.64-.51s.26-.51.78-.51h.6V91.84a.79.79,0,0,1,.5-.74l1.23-.61a.64.64,0,0,1,.31-.12c.2,0,.3.19.3.56Z"
            fill="#fff"
          />
          <path
            d="M213.09,94.33v5a6.62,6.62,0,0,0,.07,1.31.48.48,0,0,0,.4.3l.23,0c.34,0,.51.21.51.49s-.26.5-.79.5h-3.14c-.53,0-.8-.17-.8-.5s.18-.42.54-.49l.19,0a.5.5,0,0,0,.41-.32,6.19,6.19,0,0,0,.08-1.29V96.72a2.6,2.6,0,0,0,0-.63.58.58,0,0,0-.51-.34c-.46-.07-.69-.25-.69-.51s.21-.43.61-.59l2-.8a1.45,1.45,0,0,1,.47-.13C213,93.72,213.09,93.92,213.09,94.33ZM210.41,91a1.35,1.35,0,0,1,.43-1,1.45,1.45,0,0,1,2.48,1,1.43,1.43,0,0,1-.42,1,1.45,1.45,0,0,1-2.05,0A1.42,1.42,0,0,1,210.41,91Z"
            fill="#fff"
          />
          <path
            d="M219.93,101.24a2.57,2.57,0,0,1-2.11.91,2.85,2.85,0,0,1-1.94-.66,2.18,2.18,0,0,1-.76-1.71,2.59,2.59,0,0,1,1.31-2.24,3.79,3.79,0,0,1,2.09-.69c.42,0,.63.19.63.55a.64.64,0,0,1-.42.59l-.34.17a1.51,1.51,0,0,0-1,1.41,1.29,1.29,0,0,0,.29.87.91.91,0,0,0,.74.35,1,1,0,0,0,1-.73,5.82,5.82,0,0,0,.15-1.59v-1.1a4.42,4.42,0,0,0-.22-1.88,1.18,1.18,0,0,0-1-.4,3.23,3.23,0,0,0-1.16.26,5.44,5.44,0,0,0-.68.29,1.13,1.13,0,0,1-.38.11c-.38,0-.57-.2-.57-.61s.2-.52.61-.74a5.76,5.76,0,0,1,2.79-.68,3,3,0,0,1,2.66,1.08,3.07,3.07,0,0,1,.32,1.6v3.49a1.81,1.81,0,0,0,.1.82c.06.09.27.17.64.23s.46.21.46.49-.26.5-.78.5h-2.13a.29.29,0,0,1-.32-.33Z"
            fill="#fff"
          />
          <path
            d="M227.47,89.61v9.68a6.3,6.3,0,0,0,.08,1.31.45.45,0,0,0,.4.3l.21,0c.35.06.53.23.53.49s-.27.5-.8.5h-3.17c-.54,0-.8-.17-.8-.5s.18-.43.53-.49l.21,0a.47.47,0,0,0,.4-.3,6.62,6.62,0,0,0,.07-1.31V91.76a.57.57,0,0,0-.59-.63c-.4,0-.6-.2-.6-.49a.62.62,0,0,1,.45-.57l2.26-1A1.35,1.35,0,0,1,227,89C227.33,89,227.47,89.18,227.47,89.61Z"
            fill="#fff"
          />
          <path
            d="M241.8,94.8h-2.66V97a.71.71,0,0,0,.1.33.59.59,0,0,1,.09.33.74.74,0,0,1-.3.48,1,1,0,0,1-.56.19.86.86,0,0,1-.24,0,1.41,1.41,0,0,1-.46-.35l-.55-.58a6.25,6.25,0,0,1-.82-.78.48.48,0,0,1,0-.3.83.83,0,0,0,.08-.27V94.88l-1.26.09a.46.46,0,0,0-.22.08.35.35,0,0,1-.35,0,5.26,5.26,0,0,1-1.48-1.83.72.72,0,0,1-.1-.61.66.66,0,0,1,.52-.38l.78-.07q1.5-.12,2-.12V88.57c0-.1.11-.22.32-.37a1.51,1.51,0,0,1,.63-.26c.16,0,.48.24.95.8a4.38,4.38,0,0,1,.87,1.37,1.78,1.78,0,0,1,.08.47,3.73,3.73,0,0,1-.07.55,5.55,5.55,0,0,0-.06.82h1.33a4.69,4.69,0,0,0,.85-.11,2.59,2.59,0,0,1,.55-.06c.19,0,.38.36.57.92a4.8,4.8,0,0,1,.29,1.47.57.57,0,0,1-.27.56A1.91,1.91,0,0,1,241.8,94.8Z"
            fill="#fff"
          />
        </g>
        <rect
          x="272.82"
          y="73.23"
          width="128.7"
          height="184.27"
          rx="16.02"
          fill="#fff"
          filter="url(#filter-1-3)"
        />
        <path
          d="M401.52,115.62V98a16,16,0,0,0-16-16H288.84a16,16,0,0,0-16,16v58.62s15.34-23.53,33.4-29.9c11.22-4,23.4-1.62,52.32,5.85S401.52,115.62,401.52,115.62Z"
          fill="url(#linear-gradient-1-3)"
        />
        <path
          d="M272.82,141.69V89.25a16,16,0,0,1,16-16H385.5a16,16,0,0,1,16,16v24.23s-12.3,11.7-30.64,11.7-51-12.87-69.34-4.84A80.58,80.58,0,0,0,272.82,141.69Z"
          fill="url(#linear-gradient-1-4)"
        />
        <g filter="url(#filter-1-4)">
          <path
            d="M290.81,96.78l-1.87,5.27c-.21.61-.56.91-1,.91s-.84-.32-1.08-.95l-3.71-10a1.9,1.9,0,0,0-.38-.75,1.25,1.25,0,0,0-.63-.26c-.35-.08-.53-.25-.53-.51s.22-.5.65-.5h3.91c.43,0,.65.17.65.52s-.23.45-.69.49-.58.19-.58.46a3.15,3.15,0,0,0,.2.87l2.57,7.14,1.77-4.92-.88-2.62a2.06,2.06,0,0,0-.3-.66,1.14,1.14,0,0,0-.55-.23c-.37-.09-.55-.26-.55-.5s.22-.55.66-.55h3.74c.42,0,.63.17.63.52s-.21.46-.63.51-.62.22-.62.51a2.92,2.92,0,0,0,.19.8l2.28,7L296.24,92a1.58,1.58,0,0,0,.08-.44c0-.28-.22-.45-.64-.5s-.64-.24-.64-.55.24-.53.73-.53h2.88c.41,0,.61.17.61.52s-.22.48-.66.55a.84.84,0,0,0-.52.23,2.05,2.05,0,0,0-.32.72l-3,9.76a2.38,2.38,0,0,1-.45.95.82.82,0,0,1-.65.25.92.92,0,0,1-.65-.21,2,2,0,0,1-.42-.78Z"
            fill="#fff"
          />
          <path
            d="M303.46,95.25a3.36,3.36,0,0,1,2.34-.84,2.54,2.54,0,0,1,2.18.88,2.68,2.68,0,0,1,.4,1.63V100a6.35,6.35,0,0,0,.07,1.3.48.48,0,0,0,.41.32l.19,0c.35.07.53.24.53.49s-.27.5-.8.5h-3.13q-.69,0-.69-.48a.48.48,0,0,1,.42-.52l.28,0c.26,0,.38-.38.38-1V97.55a2.83,2.83,0,0,0-.17-1.24.9.9,0,0,0-.85-.42,1.44,1.44,0,0,0-1.33.84,5.12,5.12,0,0,0-.23,2v1.93a2.52,2.52,0,0,0,.08.8c0,.11.19.19.41.24a2,2,0,0,1,.44.14.47.47,0,0,1,.15.4c0,.3-.24.46-.74.46h-3.09c-.54,0-.8-.17-.8-.5s.17-.43.53-.49l.21,0a.47.47,0,0,0,.4-.3,6.75,6.75,0,0,0,.07-1.32V92.93a2,2,0,0,0-.1-.84q-.11-.16-.51-.21c-.39,0-.58-.21-.58-.51s.15-.41.47-.55l2.28-1a.9.9,0,0,1,.34-.09c.29,0,.44.26.44.78Z"
            fill="#fff"
          />
          <path
            d="M310.36,98.7a4.11,4.11,0,0,1,1.17-3.08,4.83,4.83,0,0,1,6.27,0A4.07,4.07,0,0,1,319,98.68a4.12,4.12,0,0,1-1.19,3.07,4.36,4.36,0,0,1-3.18,1.17,4,4,0,0,1-4.25-4.22Zm2.49,0a4.19,4.19,0,0,0,.47,2.19,1.49,1.49,0,0,0,1.33.78c1.2,0,1.81-1,1.81-3s-.61-3-1.83-3S312.85,96.7,312.85,98.7Z"
            fill="#fff"
          />
          <path
            d="M323.41,90.34V100a6.43,6.43,0,0,0,.08,1.32.45.45,0,0,0,.39.3l.21,0c.36.06.54.23.54.49s-.27.5-.8.5h-3.17c-.54,0-.8-.17-.8-.5s.17-.43.53-.49l.21,0a.47.47,0,0,0,.4-.3,6.75,6.75,0,0,0,.07-1.32V92.49a.58.58,0,0,0-.6-.63c-.39,0-.59-.2-.59-.49a.62.62,0,0,1,.45-.57l2.26-1a1.35,1.35,0,0,1,.38-.11C323.26,89.7,323.41,89.91,323.41,90.34Z"
            fill="#fff"
          />
          <path
            d="M332.17,98l-2.6,1.5a1.26,1.26,0,0,1-.56.21.54.54,0,0,1-.41-.18.57.57,0,0,1-.17-.43.47.47,0,0,1,.24-.43l1.2-.78c.48-.32.72-.64.72-1a1.24,1.24,0,0,0-.3-.84,1,1,0,0,0-.74-.34,1.37,1.37,0,0,0-1.21.82,4.3,4.3,0,0,0-.46,2.14,3.58,3.58,0,0,0,.53,2.11,1.72,1.72,0,0,0,1.49.76,4,4,0,0,0,1.93-.64,1.27,1.27,0,0,1,.53-.21c.33,0,.49.18.49.55s-.35.75-1,1.09a5,5,0,0,1-2.24.51,3.91,3.91,0,0,1-4.13-4.14,4.13,4.13,0,0,1,1.18-3,4,4,0,0,1,3-1.19,3.6,3.6,0,0,1,2.28.73,2.14,2.14,0,0,1,1,1.75A1.16,1.16,0,0,1,332.17,98Z"
            fill="#fff"
          />
          <path
            d="M347.61,90.15l.5,1.83a2,2,0,0,1,.08.47c0,.33-.18.5-.54.5a.91.91,0,0,1-.63-.38A3.89,3.89,0,0,0,344,91a3,3,0,0,0-2.65,1.42,6.82,6.82,0,0,0-1,3.9c0,2.15.56,3.67,1.69,4.54a3.34,3.34,0,0,0,2.09.74c.81,0,1.31-.21,1.5-.64a2.5,2.5,0,0,0,.11-.9V98.87a2.3,2.3,0,0,0,0-.6.42.42,0,0,0-.4-.36l-.45-.06c-.33,0-.5-.21-.5-.51s.3-.54.9-.54h3.21q.87,0,.87.54a.5.5,0,0,1-.47.51l-.31.06a.43.43,0,0,0-.35.2,2.86,2.86,0,0,0-.06.76v.73a4.86,4.86,0,0,0,0,.7.33.33,0,0,0,.23.21l.25.07a.37.37,0,0,1,.26.4c0,.47-.55.91-1.66,1.32a9.74,9.74,0,0,1-3.48.62,6,6,0,0,1-4.54-1.79,6.6,6.6,0,0,1-1.72-4.74,6.88,6.88,0,0,1,1.7-4.85,5.77,5.77,0,0,1,4.46-1.84,8.9,8.9,0,0,1,2.33.32.77.77,0,0,0,.25,0,.45.45,0,0,0,.34-.22.5.5,0,0,1,.42-.22C347.33,89.62,347.51,89.8,347.61,90.15Z"
            fill="#fff"
          />
          <path
            d="M353.66,96a3.28,3.28,0,0,1,.87-1.21,1.87,1.87,0,0,1,1.17-.35,1.9,1.9,0,0,1,1,.24c.28.16.42.34.42.56a2.73,2.73,0,0,1-.53,1.08c-.17.24-.32.36-.46.36a.81.81,0,0,1-.42-.19,1.19,1.19,0,0,0-.68-.26c-.53,0-.91.34-1.12,1a6.88,6.88,0,0,0-.21,2V100a6.72,6.72,0,0,0,.08,1.36c.06.17.22.27.49.28l.28,0c.4,0,.59.19.59.49s-.26.5-.8.5H351c-.53,0-.8-.17-.8-.5s.18-.43.53-.5l.23,0a.45.45,0,0,0,.4-.3,6.75,6.75,0,0,0,.07-1.32V97.39a1.87,1.87,0,0,0-.07-.67c-.08-.18-.26-.27-.55-.29s-.57-.19-.57-.49.16-.43.47-.56l2.34-.89a.67.67,0,0,1,.27-.08c.22,0,.34.14.34.41Z"
            fill="#fff"
          />
          <path
            d="M357.4,98.7a4.11,4.11,0,0,1,1.17-3.08,4.83,4.83,0,0,1,6.27,0,4.52,4.52,0,0,1,0,6.12,4.35,4.35,0,0,1-3.17,1.17,4.17,4.17,0,0,1-3.09-1.16A4.11,4.11,0,0,1,357.4,98.7Zm2.49,0a4.29,4.29,0,0,0,.47,2.19,1.49,1.49,0,0,0,1.33.78c1.21,0,1.81-1,1.81-3s-.61-3-1.83-3S359.89,96.7,359.89,98.7Z"
            fill="#fff"
          />
          <path
            d="M366.92,98.7a4.11,4.11,0,0,1,1.17-3.08,4.83,4.83,0,0,1,6.27,0,4.54,4.54,0,0,1,0,6.12,4.36,4.36,0,0,1-3.18,1.17,4.16,4.16,0,0,1-3.08-1.16A4.11,4.11,0,0,1,366.92,98.7Zm2.49,0a4.29,4.29,0,0,0,.47,2.19,1.49,1.49,0,0,0,1.33.78c1.21,0,1.81-1,1.81-3s-.61-3-1.83-3S369.41,96.7,369.41,98.7Z"
            fill="#fff"
          />
          <path
            d="M379.88,95.43a3.28,3.28,0,0,1,2.4-1,2.5,2.5,0,0,1,2.22,1.12,3.3,3.3,0,0,1,2.5-1.12,2.46,2.46,0,0,1,2.05.84,2.58,2.58,0,0,1,.44,1.63v3.65a2.16,2.16,0,0,0,.1.88c.07.11.28.19.63.26s.47.21.47.49-.25.5-.74.5l-3.21,0c-.4,0-.59-.19-.59-.5s.09-.38.26-.43l.34-.1a.46.46,0,0,0,.34-.25,3.06,3.06,0,0,0,.06-.77v-3a3.46,3.46,0,0,0-.11-1.12.82.82,0,0,0-.85-.51,1.29,1.29,0,0,0-1.2.82,4.23,4.23,0,0,0-.25,1.79v2.09a2.52,2.52,0,0,0,.08.8.54.54,0,0,0,.42.24c.35.06.53.23.53.51a.44.44,0,0,1-.5.5h-3a1.48,1.48,0,0,1-.75-.12.39.39,0,0,1-.16-.36.51.51,0,0,1,.49-.53.59.59,0,0,0,.45-.27,2.52,2.52,0,0,0,.08-.85V97.41c0-1-.31-1.5-.95-1.5a1.34,1.34,0,0,0-1.38,1.18,8.43,8.43,0,0,0-.12,1.78V101a.6.6,0,0,0,.51.65c.33.07.5.25.5.51s-.27.52-.82.52h-2.85q-.87,0-.87-.48c0-.29.17-.46.51-.52s.55-.14.62-.27a2.9,2.9,0,0,0,.1-1V97.15c0-.43-.21-.66-.56-.68s-.65-.2-.65-.52.15-.42.45-.53l2.38-.93a.52.52,0,0,1,.28-.08q.33,0,.33.48Z"
            fill="#fff"
          />
        </g>
        <rect
          x="407.43"
          y="73.23"
          width="128.7"
          height="207.54"
          rx="16.02"
          fill="#fff"
          filter="url(#filter-1-5)"
        />
        <path
          d="M536.13,115.62V98a16,16,0,0,0-16-16H423.45a16,16,0,0,0-16,16v58.62s15.34-23.53,33.4-29.9c11.22-4,23.4-1.62,52.32,5.85S536.13,115.62,536.13,115.62Z"
          fill="url(#linear-gradient-1-5)"
        />
        <path
          d="M407.43,141.69V89.25a16,16,0,0,1,16-16h96.66a16,16,0,0,1,16,16v24.23s-12.3,11.7-30.64,11.7-51-12.87-69.34-4.84A80.58,80.58,0,0,0,407.43,141.69Z"
          fill="url(#linear-gradient-1-6)"
        />
        <g filter="url(#filter-1-6)">
          <path
            d="M441.58,90.25l.46,2a1.57,1.57,0,0,1,0,.27q0,.45-.57.45a.62.62,0,0,1-.49-.34,3.18,3.18,0,0,0-2.7-1.69,1.55,1.55,0,0,0-1.06.34,1.16,1.16,0,0,0-.4.93,1.86,1.86,0,0,0,.7,1.37c.09.09.53.41,1.33,1a18.24,18.24,0,0,1,2.6,2.09,3.59,3.59,0,0,1,1,2.6,3.42,3.42,0,0,1-1.13,2.68,4.35,4.35,0,0,1-3,1,6.34,6.34,0,0,1-2-.29,2.53,2.53,0,0,0-.42-.11.61.61,0,0,0-.41.2.68.68,0,0,1-.46.2c-.27,0-.46-.23-.57-.68l-.48-1.89A2.09,2.09,0,0,1,434,100c0-.39.21-.59.62-.59a.54.54,0,0,1,.46.25l.27.38a3.28,3.28,0,0,0,2.81,1.5,2.25,2.25,0,0,0,1.49-.46,1.56,1.56,0,0,0,.56-1.25,2,2,0,0,0-.54-1.33,11.63,11.63,0,0,0-2.06-1.7c-.93-.65-1.53-1.09-1.81-1.33a3.59,3.59,0,0,1-1.46-2.73,2.82,2.82,0,0,1,1-2.24,3.82,3.82,0,0,1,2.57-.83,8.19,8.19,0,0,1,2.06.3l.24,0a.74.74,0,0,0,.44-.2.74.74,0,0,1,.44-.2C441.31,89.64,441.49,89.84,441.58,90.25Z"
            fill="#fff"
          />
          <path
            d="M447,95.06v5a6.43,6.43,0,0,0,.08,1.32.45.45,0,0,0,.4.3l.22,0c.35,0,.52.21.52.49s-.27.5-.8.5h-3.14c-.53,0-.79-.17-.79-.5s.17-.42.53-.49l.19,0a.5.5,0,0,0,.42-.32,6.56,6.56,0,0,0,.07-1.3V97.45a2.6,2.6,0,0,0,0-.63.57.57,0,0,0-.51-.34c-.45-.08-.68-.25-.68-.51s.2-.43.61-.59l2-.8a1.59,1.59,0,0,1,.47-.13C446.89,94.45,447,94.65,447,95.06Zm-2.68-3.33a1.47,1.47,0,0,1,2.49-1,1.41,1.41,0,0,1,.42,1,1.43,1.43,0,0,1-.42,1,1.45,1.45,0,0,1-2,0A1.44,1.44,0,0,1,444.35,91.73Z"
            fill="#fff"
          />
          <path
            d="M455.33,95.3a2.06,2.06,0,0,1,1.09,1.88,2.34,2.34,0,0,1-2.3,2.3c-.65,0-1-.19-1-.59,0-.14.1-.26.28-.38a1.53,1.53,0,0,0,.86-1.38,1.37,1.37,0,0,0-.43-1,1.57,1.57,0,0,0-1.11-.4,1.51,1.51,0,0,0-1.13.43,1.48,1.48,0,0,0-.43,1.11,1.83,1.83,0,0,0,.47,1.25,11.59,11.59,0,0,0,2,1.51,12.65,12.65,0,0,1,2,1.48,2.9,2.9,0,0,1,.89,2.15,2.79,2.79,0,0,1-1.05,2.25,4.24,4.24,0,0,1-2.74.86,4.5,4.5,0,0,1-2.64-.7,2.18,2.18,0,0,1-1-1.84,1.76,1.76,0,0,1,1.82-1.81c.66,0,1,.2,1,.59q0,.27-.33.39c-.45.17-.68.44-.68.8a1.11,1.11,0,0,0,.49.92,2.13,2.13,0,0,0,1.28.36,1.92,1.92,0,0,0,1.25-.41,1.31,1.31,0,0,0,.49-1.06,1.78,1.78,0,0,0-.7-1.33,14.8,14.8,0,0,0-1.54-1.12A13.27,13.27,0,0,1,450,99.85a3.3,3.3,0,0,1-1-2.26,2.83,2.83,0,0,1,1-2.26,4,4,0,0,1,2.65-.86,5.62,5.62,0,0,1,1.26.15.67.67,0,0,0,.22,0c.21,0,.33-.16.38-.46.09-.49.33-.74.73-.74a.71.71,0,0,1,.81.8A1,1,0,0,1,455.33,95.3Z"
            fill="#fff"
          />
          <path
            d="M460.86,95.45a3.34,3.34,0,0,1,2.44-1,2.63,2.63,0,0,1,2,.74,3,3,0,0,1,.55,2v3.48a1.47,1.47,0,0,0,.1.68.74.74,0,0,0,.61.35c.33,0,.49.21.49.49s-.17.48-.52.48H463c-.39,0-.59-.15-.59-.44a.49.49,0,0,1,.17-.42,1.53,1.53,0,0,1,.46-.15c.32-.08.47-.34.47-.8v-3.5q0-1.44-1-1.44a1.35,1.35,0,0,0-1.2.67,3.64,3.64,0,0,0-.36,1.9v2.24a2.24,2.24,0,0,0,.06.64.62.62,0,0,0,.5.33c.36.07.54.24.54.49a.42.42,0,0,1-.3.44,3,3,0,0,1-.59,0H458c-.4,0-.59-.16-.59-.48s.21-.49.64-.54a.57.57,0,0,0,.53-.4,3.2,3.2,0,0,0,0-.7V97.39a2.79,2.79,0,0,0-.05-.65.6.6,0,0,0-.56-.29c-.4,0-.6-.2-.6-.51s.17-.42.51-.56l2.28-.89a.84.84,0,0,1,.36-.08c.22,0,.32.16.32.49Z"
            fill="#fff"
          />
          <path
            d="M472.61,102a2.57,2.57,0,0,1-2.11.91,2.83,2.83,0,0,1-1.94-.66,2.18,2.18,0,0,1-.76-1.71,2.58,2.58,0,0,1,1.31-2.24,3.77,3.77,0,0,1,2.09-.69c.42,0,.63.19.63.55a.64.64,0,0,1-.42.59l-.34.17a1.51,1.51,0,0,0-1,1.41,1.3,1.3,0,0,0,.28.87.92.92,0,0,0,.74.35,1,1,0,0,0,1-.73,5.87,5.87,0,0,0,.15-1.59V98.1a4.45,4.45,0,0,0-.23-1.89,1.18,1.18,0,0,0-1-.39,3,3,0,0,0-1.16.26,6,6,0,0,0-.69.29,1.07,1.07,0,0,1-.38.11c-.38,0-.57-.2-.57-.61s.21-.52.61-.74a5.83,5.83,0,0,1,2.8-.68,3,3,0,0,1,2.66,1.08,3.07,3.07,0,0,1,.32,1.6v3.49a2,2,0,0,0,.09.82c.07.09.28.16.65.23a.46.46,0,0,1,.45.49c0,.33-.25.5-.77.5h-2.13c-.22,0-.32-.11-.32-.33Z"
            fill="#fff"
          />
          <path
            d="M480.09,94.68h1.14q.57,0,.57.51c0,.35-.3.53-.89.53a1.35,1.35,0,0,0-.69.11c-.09.08-.13.3-.13.65v3.9a2.88,2.88,0,0,0,.08.85.51.51,0,0,0,.51.3,1,1,0,0,0,.4-.07,2.09,2.09,0,0,1,.44-.12c.34,0,.51.2.51.59s-.22.49-.66.7a3.31,3.31,0,0,1-1.47.31A2,2,0,0,1,478,102a3.55,3.55,0,0,1-.25-1.54v-4.2c0-.27-.05-.43-.16-.49a1.15,1.15,0,0,0-.58-.1c-.43,0-.64-.18-.64-.51s.26-.51.78-.51h.6V92.57a.79.79,0,0,1,.5-.74l1.23-.61a.64.64,0,0,1,.31-.12c.2,0,.3.19.3.55Z"
            fill="#fff"
          />
          <path
            d="M488.75,101.82a3.37,3.37,0,0,1-2.45,1.08c-1.71,0-2.56-.88-2.56-2.64V97.09a.53.53,0,0,0-.5-.57c-.4,0-.6-.23-.6-.53s.21-.49.62-.63l2-.68a1,1,0,0,1,.38-.08c.26,0,.4.23.4.7v4.49a2.44,2.44,0,0,0,.23,1.24.92.92,0,0,0,.83.35,1.4,1.4,0,0,0,1.09-.51,2.58,2.58,0,0,0,.45-1.71V97.35a2.44,2.44,0,0,0-.06-.68.53.53,0,0,0-.51-.34q-.63-.06-.63-.48c0-.26.22-.47.65-.6l2.22-.75.29,0c.25,0,.38.22.38.66V100a8,8,0,0,0,.06,1.31.5.5,0,0,0,.41.31l.19,0c.36.07.53.24.53.49s-.26.5-.79.5h-2.34a.29.29,0,0,1-.33-.33Z"
            fill="#fff"
          />
          <path
            d="M496.49,96a3.28,3.28,0,0,1,.87-1.21,1.87,1.87,0,0,1,1.17-.35,1.9,1.9,0,0,1,1,.24c.28.16.42.34.42.56a2.73,2.73,0,0,1-.53,1.08c-.17.24-.32.36-.46.36a.81.81,0,0,1-.42-.19,1.19,1.19,0,0,0-.68-.26c-.53,0-.91.34-1.12,1a6.88,6.88,0,0,0-.21,2V100a6.72,6.72,0,0,0,.08,1.36c.06.17.22.27.49.28l.28,0c.4,0,.59.19.59.49s-.26.5-.8.5h-3.3c-.53,0-.8-.17-.8-.5s.18-.43.53-.5l.23,0a.45.45,0,0,0,.4-.3,6.75,6.75,0,0,0,.07-1.32V97.39a1.87,1.87,0,0,0-.07-.67c-.08-.18-.26-.27-.55-.29s-.57-.19-.57-.49.16-.43.47-.56l2.34-.89a.72.72,0,0,1,.27-.08c.22,0,.34.14.34.41Z"
            fill="#fff"
          />
          <path
            d="M506.94,98l-2.61,1.5a1.23,1.23,0,0,1-.55.21.54.54,0,0,1-.41-.18.58.58,0,0,1-.18-.43.48.48,0,0,1,.25-.43l1.2-.78c.48-.32.72-.64.72-1a1.24,1.24,0,0,0-.3-.84,1,1,0,0,0-.74-.34,1.37,1.37,0,0,0-1.21.82,4.3,4.3,0,0,0-.47,2.14,3.58,3.58,0,0,0,.54,2.11,1.72,1.72,0,0,0,1.49.76,4,4,0,0,0,1.93-.64,1.27,1.27,0,0,1,.53-.21c.33,0,.49.18.49.55s-.35.75-1,1.09a5,5,0,0,1-2.24.51,3.91,3.91,0,0,1-4.13-4.14,4.13,4.13,0,0,1,1.18-3,4,4,0,0,1,3-1.19,3.6,3.6,0,0,1,2.28.73,2.17,2.17,0,0,1,.95,1.75A1.16,1.16,0,0,1,506.94,98Z"
            fill="#fff"
          />
        </g>
        <rect
          x="3.6"
          y="71.92"
          width="128.7"
          height="148.47"
          rx="16.02"
          fill="#fff"
          filter="url(#filter-1-7)"
        />
        <path
          d="M132.29,114.3V96.71a16,16,0,0,0-16-16H19.61a16,16,0,0,0-16,16v58.62S18.93,131.8,37,125.43c11.22-4,23.4-1.63,52.33,5.85S132.29,114.3,132.29,114.3Z"
          fill="url(#linear-gradient-1-7)"
        />
        <path
          d="M3.6,140.38V87.93a16,16,0,0,1,16-16h96.66a16,16,0,0,1,16,16v24.23s-12.3,11.71-30.64,11.71S50.62,111,32.31,119A80.44,80.44,0,0,0,3.6,140.38Z"
          fill="url(#linear-gradient-1-8)"
        />
        <g filter="url(#filter-1-8)">
          <path
            d="M36.83,93.53h2.36c.55,0,.83.21.83.62s-.27.67-.8.67H37.78a1.75,1.75,0,0,0-.82.11c-.09.07-.13.29-.13.65v3c0,.73.08,1.16.25,1.28a4.58,4.58,0,0,0,1.78.18,7.93,7.93,0,0,0,2-.16,2.1,2.1,0,0,0,.92-.83q.28-.42.57-.42c.33,0,.49.15.49.46a1.44,1.44,0,0,1-.15.53l-.55,1.27a.59.59,0,0,1-.53.42l-.36,0H33.81c-.58,0-.88-.18-.88-.53s.16-.45.48-.51l.3-.06a.46.46,0,0,0,.36-.22,2.23,2.23,0,0,0,.08-.75V90.74a2.23,2.23,0,0,0-.08-.75.46.46,0,0,0-.36-.22l-.32-.06a.48.48,0,0,1-.47-.51c0-.36.29-.53.87-.53H41.1a.63.63,0,0,1,.67.38l.55,1.37a1.1,1.1,0,0,1,.1.36c0,.26-.18.4-.52.4s-.43-.19-.68-.57a1.45,1.45,0,0,0-.84-.61,4.79,4.79,0,0,0-1.06-.08H38a1.61,1.61,0,0,0-1,.18c-.13.12-.2.45-.2,1Z"
            fill="#fff"
          />
          <path
            d="M48.7,93.53,49.05,95c0,.17.05.26.05.29,0,.24-.14.36-.43.36s-.31-.11-.48-.34a2.08,2.08,0,0,0-1.71-1c-.61,0-.91.26-.91.78a1.08,1.08,0,0,0,.57.85l1,.52a6.18,6.18,0,0,1,1.78,1.27,2.37,2.37,0,0,1-.3,3.19,3,3,0,0,1-2,.67,6,6,0,0,1-1.45-.21l-.26,0a.39.39,0,0,0-.25.07l-.25.14a.35.35,0,0,1-.21,0c-.25,0-.41-.17-.49-.51l-.36-1.55a1,1,0,0,1-.06-.32.42.42,0,0,1,.48-.47c.16,0,.37.17.62.53a2.45,2.45,0,0,0,2,1.2,1.19,1.19,0,0,0,.77-.25.8.8,0,0,0,.29-.63.89.89,0,0,0-.27-.65A5.35,5.35,0,0,0,46,98.21a7.5,7.5,0,0,1-1.9-1.34,2,2,0,0,1-.52-1.42,2.14,2.14,0,0,1,.71-1.65,2.57,2.57,0,0,1,1.82-.65,3.68,3.68,0,0,1,.75.1,4.39,4.39,0,0,0,.73.09.45.45,0,0,0,.25-.07l.21-.14a.85.85,0,0,1,.23,0C48.5,93.09,48.64,93.24,48.7,93.53Z"
            fill="#fff"
          />
          <path
            d="M55.68,93.53,56,95a2.34,2.34,0,0,1,.06.29c0,.24-.15.36-.44.36s-.31-.11-.48-.34a2.08,2.08,0,0,0-1.71-1c-.6,0-.91.26-.91.78a1.08,1.08,0,0,0,.57.85l1,.52a6.41,6.41,0,0,1,1.79,1.27,2.21,2.21,0,0,1,.47,1.44,2.17,2.17,0,0,1-.78,1.75,3,3,0,0,1-2,.67,5.82,5.82,0,0,1-1.44-.21l-.27,0a.41.41,0,0,0-.25.07l-.24.14a.37.37,0,0,1-.21,0c-.26,0-.42-.17-.5-.51l-.36-1.55a1,1,0,0,1-.06-.32.42.42,0,0,1,.48-.47c.16,0,.37.17.63.53a2.43,2.43,0,0,0,2,1.2,1.19,1.19,0,0,0,.77-.25.78.78,0,0,0,.3-.63.9.9,0,0,0-.28-.65A4.83,4.83,0,0,0,53,98.21a7.5,7.5,0,0,1-1.9-1.34,2.08,2.08,0,0,1-.51-1.42,2.13,2.13,0,0,1,.7-1.65,2.6,2.6,0,0,1,1.82-.65,3.68,3.68,0,0,1,.75.1,4.51,4.51,0,0,0,.73.09.43.43,0,0,0,.25-.07l.21-.14a.93.93,0,0,1,.23,0C55.47,93.09,55.61,93.24,55.68,93.53Z"
            fill="#fff"
          />
          <path
            d="M63.94,96.65l-2.6,1.5a1.28,1.28,0,0,1-.55.21.54.54,0,0,1-.42-.18.63.63,0,0,1-.17-.43.48.48,0,0,1,.25-.44l1.19-.78c.48-.31.72-.64.72-1a1.21,1.21,0,0,0-.3-.83.94.94,0,0,0-.74-.34,1.38,1.38,0,0,0-1.21.81,4.34,4.34,0,0,0-.46,2.15,3.6,3.6,0,0,0,.54,2.11,1.71,1.71,0,0,0,1.48.76,4,4,0,0,0,1.93-.65,1.27,1.27,0,0,1,.53-.21c.33,0,.5.19.5.55s-.35.75-1,1.09a5,5,0,0,1-2.23.51,4.06,4.06,0,0,1-3-1.14,4,4,0,0,1-1.13-3,4.13,4.13,0,0,1,1.17-3,4,4,0,0,1,3-1.2,3.7,3.7,0,0,1,2.28.73,2.18,2.18,0,0,1,.95,1.76A1.17,1.17,0,0,1,63.94,96.65Z"
            fill="#fff"
          />
          <path
            d="M68.94,94.14a3.27,3.27,0,0,1,2.43-1,2.57,2.57,0,0,1,2,.75,3,3,0,0,1,.56,2v3.48A1.67,1.67,0,0,0,74,100a.75.75,0,0,0,.62.34c.32,0,.48.22.48.5s-.17.47-.51.47H71.09c-.4,0-.59-.15-.59-.44a.43.43,0,0,1,.17-.41,1.78,1.78,0,0,1,.45-.16c.32-.07.48-.34.48-.79V96q0-1.44-1-1.44a1.37,1.37,0,0,0-1.21.66,3.67,3.67,0,0,0-.36,1.9V99.4a2.33,2.33,0,0,0,.06.65.65.65,0,0,0,.5.32c.37.08.55.24.55.5a.41.41,0,0,1-.31.43,2.25,2.25,0,0,1-.59,0H66.05c-.39,0-.59-.16-.59-.48s.22-.48.65-.53a.59.59,0,0,0,.53-.4,4.22,4.22,0,0,0,0-.71V96.07a2.19,2.19,0,0,0-.06-.65.59.59,0,0,0-.55-.28c-.41,0-.61-.2-.61-.52s.17-.41.51-.55l2.28-.9a1,1,0,0,1,.37-.08c.21,0,.32.17.32.5Z"
            fill="#fff"
          />
          <path
            d="M79.37,93.36h1.14q.57,0,.57.51c0,.36-.3.54-.89.54a1.24,1.24,0,0,0-.69.11c-.09.08-.13.29-.13.65v3.89a3.09,3.09,0,0,0,.07.86.51.51,0,0,0,.52.3,1,1,0,0,0,.4-.08,2.81,2.81,0,0,1,.43-.11c.35,0,.52.2.52.59s-.22.48-.66.69a3.37,3.37,0,0,1-1.47.32,2,2,0,0,1-1.9-.92A3.35,3.35,0,0,1,77,99.17V95c0-.27,0-.44-.16-.5a1.31,1.31,0,0,0-.58-.09c-.43,0-.64-.19-.64-.52s.25-.51.77-.51H77V91.25a.8.8,0,0,1,.5-.74l1.23-.61a.74.74,0,0,1,.3-.11c.21,0,.31.18.31.55Z"
            fill="#fff"
          />
          <path
            d="M85.51,93.74v5a6.55,6.55,0,0,0,.07,1.31.44.44,0,0,0,.4.3l.23,0c.34.05.51.22.51.5s-.26.49-.8.49H82.79c-.53,0-.8-.16-.8-.49s.18-.42.53-.5l.19,0a.49.49,0,0,0,.42-.32,6.06,6.06,0,0,0,.08-1.29V96.13a3.09,3.09,0,0,0,0-.62.58.58,0,0,0-.51-.34c-.46-.08-.69-.25-.69-.52s.2-.42.61-.59l2-.79a1.67,1.67,0,0,1,.48-.14C85.37,93.13,85.51,93.33,85.51,93.74Zm-2.68-3.32a1.39,1.39,0,0,1,.42-1,1.47,1.47,0,0,1,2.07,0,1.43,1.43,0,0,1,.41,1,1.44,1.44,0,0,1-.41,1,1.37,1.37,0,0,1-1,.43,1.44,1.44,0,0,1-1-.44A1.39,1.39,0,0,1,82.83,90.42Z"
            fill="#fff"
          />
          <path
            d="M92.35,100.66a2.6,2.6,0,0,1-2.11.91,2.81,2.81,0,0,1-1.94-.67,2.15,2.15,0,0,1-.76-1.71A2.59,2.59,0,0,1,88.85,97a3.83,3.83,0,0,1,2.09-.68c.42,0,.63.18.63.55a.65.65,0,0,1-.42.59l-.34.17a1.49,1.49,0,0,0-1,1.4,1.33,1.33,0,0,0,.28.88.91.91,0,0,0,.74.34,1,1,0,0,0,1-.72,6,6,0,0,0,.15-1.6v-1.1a4.2,4.2,0,0,0-.23-1.88,1.16,1.16,0,0,0-1-.4,3.06,3.06,0,0,0-1.16.27,5.28,5.28,0,0,0-.69.28.94.94,0,0,1-.38.12c-.37,0-.56-.21-.56-.61s.2-.53.6-.74a5.72,5.72,0,0,1,2.8-.69,2.93,2.93,0,0,1,2.66,1.09,3,3,0,0,1,.32,1.59v3.5a2,2,0,0,0,.09.81,1.15,1.15,0,0,0,.65.23.48.48,0,0,1,.46.5c0,.33-.26.49-.78.49H92.67a.28.28,0,0,1-.32-.32Z"
            fill="#fff"
          />
          <path
            d="M99.89,89V98.7A6.55,6.55,0,0,0,100,100a.45.45,0,0,0,.4.3l.21,0c.36.07.53.23.53.5s-.26.49-.79.49H97.13c-.53,0-.79-.16-.79-.49s.17-.43.53-.5l.21,0a.44.44,0,0,0,.4-.3,6.55,6.55,0,0,0,.07-1.31V91.18a.58.58,0,0,0-.6-.63c-.4,0-.6-.2-.6-.5a.63.63,0,0,1,.46-.57l2.26-1a1.16,1.16,0,0,1,.38-.12C99.74,88.38,99.89,88.6,99.89,89Z"
            fill="#fff"
          />
        </g>
        <path
          d="M24.3,147.6h0a3.86,3.86,0,0,1,3.86,3.86h0a3.86,3.86,0,0,1-3.86,3.87h0a3.87,3.87,0,0,1-3.87-3.87h0A3.86,3.86,0,0,1,24.3,147.6Z"
          fill="#dfdfdf"
        />
        <path
          d="M26,150.28a.19.19,0,0,1,0,.27l-2.2,2.1a.19.19,0,0,1-.14,0,.2.2,0,0,1-.14-.06l-.84-1a.2.2,0,0,1,0-.27.19.19,0,0,1,.27,0l.72.82,2.06-2A.19.19,0,0,1,26,150.28Z"
          fill="#fff"
          fill-rule="evenodd"
        />
        <path
          d="M24.3,159.82h0a3.86,3.86,0,0,1,3.86,3.86h0a3.86,3.86,0,0,1-3.86,3.87h0a3.87,3.87,0,0,1-3.87-3.87h0A3.86,3.86,0,0,1,24.3,159.82Z"
          fill="#dfdfdf"
        />
        <path
          d="M26,162.5a.19.19,0,0,1,0,.27l-2.2,2.1a.16.16,0,0,1-.14,0,.2.2,0,0,1-.14-.06l-.84-1a.2.2,0,0,1,0-.27.19.19,0,0,1,.27,0l.72.82,2.06-2A.19.19,0,0,1,26,162.5Z"
          fill="#fff"
          fill-rule="evenodd"
        />
        <path
          d="M24.3,172h0a3.86,3.86,0,0,1,3.86,3.86h0a3.86,3.86,0,0,1-3.86,3.87h0a3.87,3.87,0,0,1-3.87-3.87h0A3.86,3.86,0,0,1,24.3,172Z"
          fill="#dfdfdf"
        />
        <path
          d="M26,174.72a.19.19,0,0,1,0,.27l-2.2,2.1a.16.16,0,0,1-.14,0,.2.2,0,0,1-.14-.06l-.84-1a.2.2,0,0,1,0-.27.19.19,0,0,1,.27,0l.72.82,2.06-2A.19.19,0,0,1,26,174.72Z"
          fill="#fff"
          fill-rule="evenodd"
        />
        <path
          d="M24.3,184.26h0a3.86,3.86,0,0,1,3.86,3.86h0A3.86,3.86,0,0,1,24.3,192h0a3.87,3.87,0,0,1-3.87-3.87h0A3.86,3.86,0,0,1,24.3,184.26Z"
          fill="#dfdfdf"
        />
        <path
          d="M26,186.94a.19.19,0,0,1,0,.27l-2.2,2.1a.16.16,0,0,1-.14.05.2.2,0,0,1-.14-.06l-.84-1a.2.2,0,0,1,0-.27.19.19,0,0,1,.27,0l.72.82,2.06-2A.19.19,0,0,1,26,186.94Z"
          fill="#fff"
          fill-rule="evenodd"
        />
        <text
          transform="translate(30.79 154.76)"
          font-size="7"
          fill="#231f20"
          font-family="NotoSans-Italic, Noto Sans"
          font-style="italic"
        >
          <tspan letter-spacing="-0.02em">W</tspan>
          <tspan x="5.85" y="0">
            arm Hygienic Bath
          </tspan>
        </text>
        <text
          transform="translate(30.79 166.99)"
          font-size="7"
          fill="#231f20"
          font-family="NotoSans-Italic, Noto Sans"
          font-style="italic"
        >
          Bl
          <tspan x="6.01" y="0" letter-spacing="-0.02em">
            o
          </tspan>
          <tspan x="9.81" y="0" letter-spacing="0em">
            w Dry
          </tspan>
        </text>
        <text
          transform="translate(30.79 179.21)"
          font-size="7"
          fill="#231f20"
          font-family="NotoSans-Italic, Noto Sans"
          font-style="italic"
        >
          Gentle Brush &amp; De-Shedding
        </text>
        <text
          transform="translate(30.79 191.43)"
          font-size="7"
          fill="#231f20"
          font-family="NotoSans-Italic, Noto Sans"
          font-style="italic"
        >
          Pawdicu
          <tspan x="26.09" y="0" letter-spacing="-0.02em">
            r
          </tspan>
          <tspan x="28.73" y="0" letter-spacing="0em">
            e (Buff &amp;{" "}
          </tspan>
          <tspan x="57.15" y="0" letter-spacing="-0.05em">
            T
          </tspan>
          <tspan x="60.3" y="0">
            rim)
          </tspan>
        </text>
        <path
          d="M159.18,147.6h0a3.86,3.86,0,0,1,3.86,3.86h0a3.86,3.86,0,0,1-3.86,3.87h0a3.86,3.86,0,0,1-3.86-3.87h0A3.86,3.86,0,0,1,159.18,147.6Z"
          fill="#dfdfdf"
        />
        <path
          d="M160.85,150.28a.19.19,0,0,1,0,.27l-2.21,2.1a.19.19,0,0,1-.27,0l-.85-1a.19.19,0,1,1,.29-.25l.72.82,2.06-2A.19.19,0,0,1,160.85,150.28Z"
          fill="#fff"
          fill-rule="evenodd"
        />
        <path
          d="M159.18,159.82h0a3.86,3.86,0,0,1,3.86,3.86h0a3.86,3.86,0,0,1-3.86,3.87h0a3.86,3.86,0,0,1-3.86-3.87h0A3.86,3.86,0,0,1,159.18,159.82Z"
          fill="#dfdfdf"
        />
        <path
          d="M160.85,162.5a.19.19,0,0,1,0,.27l-2.21,2.1a.14.14,0,0,1-.14,0,.16.16,0,0,1-.13-.06l-.85-1a.19.19,0,1,1,.29-.25l.72.82,2.06-2A.19.19,0,0,1,160.85,162.5Z"
          fill="#fff"
          fill-rule="evenodd"
        />
        <path
          d="M159.18,172h0A3.86,3.86,0,0,1,163,175.9h0a3.86,3.86,0,0,1-3.86,3.87h0a3.86,3.86,0,0,1-3.86-3.87h0A3.86,3.86,0,0,1,159.18,172Z"
          fill="#dfdfdf"
        />
        <path
          d="M160.85,174.72a.19.19,0,0,1,0,.27l-2.21,2.1a.14.14,0,0,1-.14,0,.16.16,0,0,1-.13-.06l-.85-1a.19.19,0,1,1,.29-.25l.72.82,2.06-2A.19.19,0,0,1,160.85,174.72Z"
          fill="#fff"
          fill-rule="evenodd"
        />
        <path
          d="M159.18,184.26h0a3.86,3.86,0,0,1,3.86,3.86h0a3.86,3.86,0,0,1-3.86,3.87h0a3.86,3.86,0,0,1-3.86-3.87h0A3.86,3.86,0,0,1,159.18,184.26Z"
          fill="#dfdfdf"
        />
        <path
          d="M160.85,186.94a.19.19,0,0,1,0,.27l-2.21,2.1a.14.14,0,0,1-.14.05.16.16,0,0,1-.13-.06l-.85-1a.19.19,0,1,1,.29-.25l.72.82,2.06-2A.19.19,0,0,1,160.85,186.94Z"
          fill="#fff"
          fill-rule="evenodd"
        />
        <path
          d="M159.18,196.48h0a3.86,3.86,0,0,1,3.86,3.87h0a3.86,3.86,0,0,1-3.86,3.86h0a3.86,3.86,0,0,1-3.86-3.86h0A3.86,3.86,0,0,1,159.18,196.48Z"
          fill="#dfdfdf"
        />
        <path
          d="M160.85,199.16a.19.19,0,0,1,0,.27l-2.21,2.1a.14.14,0,0,1-.14.05.16.16,0,0,1-.13-.06l-.85-1a.19.19,0,1,1,.29-.25l.72.82,2.06-2A.2.2,0,0,1,160.85,199.16Z"
          fill="#fff"
          fill-rule="evenodd"
        />
        <text
          transform="translate(165.68 154.76)"
          font-size="7"
          fill="#231f20"
          font-family="NotoSans-Italic, Noto Sans"
          font-style="italic"
        >
          <tspan letter-spacing="-0.02em">W</tspan>
          <tspan x="5.85" y="0">
            arm Hygienic Bath
          </tspan>
        </text>
        <text
          transform="translate(165.68 166.99)"
          font-size="7"
          fill="#231f20"
          font-family="NotoSans-Italic, Noto Sans"
          font-style="italic"
        >
          Bl
          <tspan x="6.01" y="0" letter-spacing="-0.02em">
            o
          </tspan>
          <tspan x="9.81" y="0" letter-spacing="0em">
            w Dry
          </tspan>
        </text>
        <text
          transform="translate(165.68 179.21)"
          font-size="7"
          fill="#231f20"
          font-family="NotoSans-Italic, Noto Sans"
          font-style="italic"
        >
          Signatu
          <tspan x="23.8" y="0" letter-spacing="-0.02em">
            r
          </tspan>
          <tspan x="26.45" y="0" letter-spacing="0em">
            e B
          </tspan>
          <tspan x="35.96" y="0" letter-spacing="-0.02em">
            r
          </tspan>
          <tspan x="38.6" y="0">
            eed Hai
          </tspan>
          <tspan x="62.01" y="0" letter-spacing="-0.02em">
            r
          </tspan>
          <tspan x="64.65" y="0">
            cut
          </tspan>
        </text>
        <text
          transform="translate(165.68 191.43)"
          font-size="7"
          fill="#231f20"
          font-family="NotoSans-Italic, Noto Sans"
          font-style="italic"
        >
          Gentle Brush &amp; De-Shedding
        </text>
        <text
          transform="translate(165.68 203.65)"
          font-size="7"
          fill="#231f20"
          font-family="NotoSans-Italic, Noto Sans"
          font-style="italic"
        >
          Pawdicu
          <tspan x="26.09" y="0" letter-spacing="-0.02em">
            r
          </tspan>
          <tspan x="28.73" y="0" letter-spacing="0em">
            e (Buff &amp;{" "}
          </tspan>
          <tspan x="57.15" y="0" letter-spacing="-0.05em">
            T
          </tspan>
          <tspan x="60.3" y="0">
            rim)
          </tspan>
        </text>
        <path
          d="M291.86,147.6h0a3.86,3.86,0,0,1,3.86,3.86h0a3.86,3.86,0,0,1-3.86,3.87h0a3.87,3.87,0,0,1-3.87-3.87h0A3.86,3.86,0,0,1,291.86,147.6Z"
          fill="#dfdfdf"
        />
        <path
          d="M293.52,150.28a.19.19,0,0,1,0,.27l-2.2,2.1a.19.19,0,0,1-.14,0,.2.2,0,0,1-.14-.06l-.84-1a.19.19,0,1,1,.28-.25l.72.82,2.06-2A.19.19,0,0,1,293.52,150.28Z"
          fill="#fff"
          fill-rule="evenodd"
        />
        <path
          d="M291.86,159.82h0a3.86,3.86,0,0,1,3.86,3.86h0a3.86,3.86,0,0,1-3.86,3.87h0a3.87,3.87,0,0,1-3.87-3.87h0A3.86,3.86,0,0,1,291.86,159.82Z"
          fill="#dfdfdf"
        />
        <path
          d="M293.52,162.5a.19.19,0,0,1,0,.27l-2.2,2.1a.16.16,0,0,1-.14,0,.2.2,0,0,1-.14-.06l-.84-1a.19.19,0,1,1,.28-.25l.72.82,2.06-2A.19.19,0,0,1,293.52,162.5Z"
          fill="#fff"
          fill-rule="evenodd"
        />
        <path
          d="M291.86,172h0a3.86,3.86,0,0,1,3.86,3.86h0a3.86,3.86,0,0,1-3.86,3.87h0A3.87,3.87,0,0,1,288,175.9h0A3.86,3.86,0,0,1,291.86,172Z"
          fill="#dfdfdf"
        />
        <path
          d="M293.52,174.72a.19.19,0,0,1,0,.27l-2.2,2.1a.16.16,0,0,1-.14,0,.2.2,0,0,1-.14-.06l-.84-1a.19.19,0,1,1,.28-.25l.72.82,2.06-2A.19.19,0,0,1,293.52,174.72Z"
          fill="#fff"
          fill-rule="evenodd"
        />
        <path
          d="M291.86,184.26h0a3.86,3.86,0,0,1,3.86,3.86h0a3.86,3.86,0,0,1-3.86,3.87h0a3.87,3.87,0,0,1-3.87-3.87h0A3.86,3.86,0,0,1,291.86,184.26Z"
          fill="#dfdfdf"
        />
        <path
          d="M293.52,186.94a.19.19,0,0,1,0,.27l-2.2,2.1a.16.16,0,0,1-.14.05.2.2,0,0,1-.14-.06l-.84-1a.19.19,0,1,1,.28-.25l.72.82,2.06-2A.19.19,0,0,1,293.52,186.94Z"
          fill="#fff"
          fill-rule="evenodd"
        />
        <path
          d="M291.86,196.48h0a3.86,3.86,0,0,1,3.86,3.87h0a3.86,3.86,0,0,1-3.86,3.86h0a3.86,3.86,0,0,1-3.87-3.86h0A3.87,3.87,0,0,1,291.86,196.48Z"
          fill="#dfdfdf"
        />
        <path
          d="M293.52,199.16a.19.19,0,0,1,0,.27l-2.2,2.1a.16.16,0,0,1-.14.05.2.2,0,0,1-.14-.06l-.84-1a.19.19,0,1,1,.28-.25l.72.82,2.06-2A.2.2,0,0,1,293.52,199.16Z"
          fill="#fff"
          fill-rule="evenodd"
        />
        <path
          d="M291.86,208.17h0a3.86,3.86,0,0,1,3.86,3.86h0a3.86,3.86,0,0,1-3.86,3.87h0A3.87,3.87,0,0,1,288,212h0A3.86,3.86,0,0,1,291.86,208.17Z"
          fill="#dfdfdf"
        />
        <path
          d="M293.52,210.85a.19.19,0,0,1,0,.27l-2.2,2.1a.16.16,0,0,1-.14.05.2.2,0,0,1-.14-.06l-.84-1a.19.19,0,1,1,.28-.25l.72.82,2.06-2A.19.19,0,0,1,293.52,210.85Z"
          fill="#fff"
          fill-rule="evenodd"
        />
        <path
          d="M291.86,220.39h0a3.86,3.86,0,0,1,3.86,3.86h0a3.86,3.86,0,0,1-3.86,3.87h0a3.87,3.87,0,0,1-3.87-3.87h0A3.86,3.86,0,0,1,291.86,220.39Z"
          fill="#dfdfdf"
        />
        <path
          d="M293.52,223.07a.19.19,0,0,1,0,.27l-2.2,2.1a.16.16,0,0,1-.14.05.2.2,0,0,1-.14-.06l-.84-1a.19.19,0,1,1,.28-.25l.72.82,2.06-2A.19.19,0,0,1,293.52,223.07Z"
          fill="#fff"
          fill-rule="evenodd"
        />
        <text
          transform="translate(298.35 154.76)"
          font-size="7"
          fill="#231f20"
          font-family="NotoSans-Italic, Noto Sans"
          font-style="italic"
        >
          <tspan letter-spacing="-0.02em">W</tspan>
          <tspan x="5.85" y="0">
            arm Hygienic Bath
          </tspan>
        </text>
        <text
          transform="translate(298.35 166.99)"
          font-size="7"
          fill="#231f20"
          font-family="NotoSans-Italic, Noto Sans"
          font-style="italic"
        >
          Bl
          <tspan x="6.01" y="0" letter-spacing="-0.02em">
            o
          </tspan>
          <tspan x="9.81" y="0" letter-spacing="0em">
            w Dry
          </tspan>
        </text>
        <text
          transform="translate(298.35 179.21)"
          font-size="7"
          fill="#231f20"
          font-family="NotoSans-Italic, Noto Sans"
          font-style="italic"
        >
          Signatu
          <tspan x="23.8" y="0" letter-spacing="-0.02em">
            r
          </tspan>
          <tspan x="26.45" y="0" letter-spacing="0em">
            e B
          </tspan>
          <tspan x="35.96" y="0" letter-spacing="-0.02em">
            r
          </tspan>
          <tspan x="38.6" y="0">
            eed Hai
          </tspan>
          <tspan x="62.01" y="0" letter-spacing="-0.02em">
            r
          </tspan>
          <tspan x="64.65" y="0">
            cut
          </tspan>
        </text>
        <text
          transform="translate(298.35 191.43)"
          font-size="7"
          fill="#231f20"
          font-family="NotoSans-Italic, Noto Sans"
          font-style="italic"
        >
          Gentle Brush &amp; De-Shedding
        </text>
        <text
          transform="translate(298.35 203.65)"
          font-size="7"
          fill="#231f20"
          font-family="NotoSans-Italic, Noto Sans"
          font-style="italic"
        >
          Pawdicu
          <tspan x="26.09" y="0" letter-spacing="-0.02em">
            r
          </tspan>
          <tspan x="28.73" y="0" letter-spacing="0em">
            e (Buff &amp;{" "}
          </tspan>
          <tspan x="57.15" y="0" letter-spacing="-0.05em">
            T
          </tspan>
          <tspan x="60.3" y="0">
            rim)
          </tspan>
        </text>
        <text
          transform="translate(298.35 215.34)"
          font-size="7"
          fill="#231f20"
          font-family="NotoSans-Italic, Noto Sans"
          font-style="italic"
        >
          7 Point{" "}
          <tspan x="23.59" y="0" letter-spacing="-0.02em">
            W
          </tspan>
          <tspan x="29.44" y="0">
            ellness Check
          </tspan>
        </text>
        <text
          transform="translate(298.35 227.56)"
          font-size="7"
          fill="#231f20"
          font-family="NotoSans-Italic, Noto Sans"
          font-style="italic"
        >
          Ear Cleaning
        </text>
        <path
          d="M428.38,147.6h0a3.86,3.86,0,0,1,3.86,3.86h0a3.86,3.86,0,0,1-3.86,3.87h0a3.86,3.86,0,0,1-3.86-3.87h0A3.86,3.86,0,0,1,428.38,147.6Z"
          fill="#dfdfdf"
        />
        <path
          d="M430,150.28a.19.19,0,0,1,0,.27l-2.21,2.1a.19.19,0,0,1-.14,0,.16.16,0,0,1-.13-.06l-.85-1a.19.19,0,0,1,.29-.25l.71.82,2.06-2A.19.19,0,0,1,430,150.28Z"
          fill="#fff"
          fill-rule="evenodd"
        />
        <path
          d="M428.38,159.82h0a3.86,3.86,0,0,1,3.86,3.86h0a3.86,3.86,0,0,1-3.86,3.87h0a3.86,3.86,0,0,1-3.86-3.87h0A3.86,3.86,0,0,1,428.38,159.82Z"
          fill="#dfdfdf"
        />
        <path
          d="M430,162.5a.19.19,0,0,1,0,.27l-2.21,2.1a.16.16,0,0,1-.14,0,.16.16,0,0,1-.13-.06l-.85-1a.19.19,0,1,1,.29-.25l.71.82,2.06-2A.19.19,0,0,1,430,162.5Z"
          fill="#fff"
          fill-rule="evenodd"
        />
        <path
          d="M428.38,172h0a3.86,3.86,0,0,1,3.86,3.86h0a3.86,3.86,0,0,1-3.86,3.87h0a3.86,3.86,0,0,1-3.86-3.87h0A3.86,3.86,0,0,1,428.38,172Z"
          fill="#dfdfdf"
        />
        <path
          d="M430,174.72a.19.19,0,0,1,0,.27l-2.21,2.1a.16.16,0,0,1-.14,0,.16.16,0,0,1-.13-.06l-.85-1a.19.19,0,0,1,.29-.25l.71.82,2.06-2A.19.19,0,0,1,430,174.72Z"
          fill="#fff"
          fill-rule="evenodd"
        />
        <path
          d="M428.38,184.26h0a3.86,3.86,0,0,1,3.86,3.86h0a3.86,3.86,0,0,1-3.86,3.87h0a3.86,3.86,0,0,1-3.86-3.87h0A3.86,3.86,0,0,1,428.38,184.26Z"
          fill="#dfdfdf"
        />
        <path
          d="M430,186.94a.19.19,0,0,1,0,.27l-2.21,2.1a.16.16,0,0,1-.14.05.16.16,0,0,1-.13-.06l-.85-1a.19.19,0,0,1,.29-.25l.71.82,2.06-2A.19.19,0,0,1,430,186.94Z"
          fill="#fff"
          fill-rule="evenodd"
        />
        <path
          d="M428.38,196.48h0a3.86,3.86,0,0,1,3.86,3.87h0a3.86,3.86,0,0,1-3.86,3.86h0a3.86,3.86,0,0,1-3.86-3.86h0A3.86,3.86,0,0,1,428.38,196.48Z"
          fill="#dfdfdf"
        />
        <path
          d="M430,199.16a.19.19,0,0,1,0,.27l-2.21,2.1a.16.16,0,0,1-.14.05.16.16,0,0,1-.13-.06l-.85-1a.19.19,0,0,1,.29-.25l.71.82,2.06-2A.2.2,0,0,1,430,199.16Z"
          fill="#fff"
          fill-rule="evenodd"
        />
        <path
          d="M428.38,208.17h0a3.86,3.86,0,0,1,3.86,3.86h0a3.86,3.86,0,0,1-3.86,3.87h0a3.86,3.86,0,0,1-3.86-3.87h0A3.86,3.86,0,0,1,428.38,208.17Z"
          fill="#dfdfdf"
        />
        <path
          d="M430,210.85a.19.19,0,0,1,0,.27l-2.21,2.1a.16.16,0,0,1-.14.05.16.16,0,0,1-.13-.06l-.85-1A.19.19,0,0,1,427,212l.71.82,2.06-2A.19.19,0,0,1,430,210.85Z"
          fill="#fff"
          fill-rule="evenodd"
        />
        <path
          d="M428.38,220.39h0a3.86,3.86,0,0,1,3.86,3.86h0a3.86,3.86,0,0,1-3.86,3.87h0a3.86,3.86,0,0,1-3.86-3.87h0A3.86,3.86,0,0,1,428.38,220.39Z"
          fill="#dfdfdf"
        />
        <path
          d="M430,223.07a.19.19,0,0,1,0,.27l-2.21,2.1a.16.16,0,0,1-.14.05.16.16,0,0,1-.13-.06l-.85-1a.19.19,0,0,1,.29-.25l.71.82,2.06-2A.19.19,0,0,1,430,223.07Z"
          fill="#fff"
          fill-rule="evenodd"
        />
        <path
          d="M428.38,232.61h0a3.86,3.86,0,0,1,3.86,3.86h0a3.86,3.86,0,0,1-3.86,3.87h0a3.86,3.86,0,0,1-3.86-3.87h0A3.86,3.86,0,0,1,428.38,232.61Z"
          fill="#dfdfdf"
        />
        <path
          d="M430,235.29a.19.19,0,0,1,0,.27l-2.21,2.1a.16.16,0,0,1-.14.05.16.16,0,0,1-.13-.06l-.85-1a.19.19,0,0,1,.29-.25l.71.82,2.06-2A.19.19,0,0,1,430,235.29Z"
          fill="#fff"
          fill-rule="evenodd"
        />
        <path
          d="M428.38,244.83h0a3.86,3.86,0,0,1,3.86,3.87h0a3.86,3.86,0,0,1-3.86,3.86h0a3.86,3.86,0,0,1-3.86-3.86h0A3.86,3.86,0,0,1,428.38,244.83Z"
          fill="#dfdfdf"
        />
        <path
          d="M430,247.51a.19.19,0,0,1,0,.27l-2.21,2.1a.2.2,0,0,1-.14.06.21.21,0,0,1-.13-.07l-.85-1a.19.19,0,0,1,.29-.25l.71.82,2.06-2A.2.2,0,0,1,430,247.51Z"
          fill="#fff"
          fill-rule="evenodd"
        />
        <text
          transform="translate(434.87 154.76)"
          font-size="7"
          fill="#231f20"
          font-family="NotoSans-Italic, Noto Sans"
          font-style="italic"
        >
          <tspan letter-spacing="-0.02em">W</tspan>
          <tspan x="5.85" y="0">
            arm Hygienic Bath
          </tspan>
        </text>
        <text
          transform="translate(434.87 166.99)"
          font-size="7"
          fill="#231f20"
          font-family="NotoSans-Italic, Noto Sans"
          font-style="italic"
        >
          Bl
          <tspan x="6.01" y="0" letter-spacing="-0.02em">
            o
          </tspan>
          <tspan x="9.81" y="0" letter-spacing="0em">
            w Dry
          </tspan>
        </text>
        <text
          transform="translate(434.87 179.21)"
          font-size="7"
          fill="#231f20"
          font-family="NotoSans-Italic, Noto Sans"
          font-style="italic"
        >
          Signatu
          <tspan x="23.8" y="0" letter-spacing="-0.02em">
            r
          </tspan>
          <tspan x="26.45" y="0" letter-spacing="0em">
            e B
          </tspan>
          <tspan x="35.96" y="0" letter-spacing="-0.02em">
            r
          </tspan>
          <tspan x="38.6" y="0">
            eed Hai
          </tspan>
          <tspan x="62.01" y="0" letter-spacing="-0.02em">
            r
          </tspan>
          <tspan x="64.65" y="0">
            cut
          </tspan>
        </text>
        <text
          transform="translate(434.87 252)"
          font-size="7"
          fill="#231f20"
          font-family="NotoSans-Italic, Noto Sans"
          font-style="italic"
        >
          One À la carte Item
        </text>
        <text
          transform="translate(434.87 191.43)"
          font-size="7"
          fill="#231f20"
          font-family="NotoSans-Italic, Noto Sans"
          font-style="italic"
        >
          Gentle Brush &amp; De-Shedding
        </text>
        <text
          transform="translate(434.87 203.65)"
          font-size="7"
          fill="#231f20"
          font-family="NotoSans-Italic, Noto Sans"
          font-style="italic"
        >
          Pawdicu
          <tspan x="26.09" y="0" letter-spacing="-0.02em">
            r
          </tspan>
          <tspan x="28.73" y="0" letter-spacing="0em">
            e (Buff &amp;{" "}
          </tspan>
          <tspan x="57.15" y="0" letter-spacing="-0.05em">
            T
          </tspan>
          <tspan x="60.3" y="0">
            rim)
          </tspan>
        </text>
        <text
          transform="translate(434.87 215.34)"
          font-size="7"
          fill="#231f20"
          font-family="NotoSans-Italic, Noto Sans"
          font-style="italic"
        >
          7 Point{" "}
          <tspan x="23.59" y="0" letter-spacing="-0.02em">
            W
          </tspan>
          <tspan x="29.44" y="0">
            ellness Check
          </tspan>
        </text>
        <text
          transform="translate(434.87 227.56)"
          font-size="7"
          fill="#231f20"
          font-family="NotoSans-Italic, Noto Sans"
          font-style="italic"
        >
          Ear Cleaning
        </text>
        <text
          transform="translate(434.87 239.78)"
          font-size="7"
          fill="#231f20"
          font-family="NotoSans-Italic, Noto Sans"
          font-style="italic"
        >
          Brush{" "}
          <tspan x="19.94" y="0" letter-spacing="-0.07em">
            T
          </tspan>
          <tspan x="22.95" y="0">
            eeth
          </tspan>
        </text>
        <rect
          x="58.06"
          y="0.5"
          width="427.16"
          height="49.26"
          rx="12"
          fill="#fff"
          stroke="#1b0f44"
          stroke-miterlimit="10"
        />
        <g id="Dots">
          <circle cx="123.89" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="120.67" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="117.45" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="114.23" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="111.01" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="107.79" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="104.57" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="101.35" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="98.13" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="94.91" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="91.69" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="88.47" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="85.25" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="82.03" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="78.81" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="75.59" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="72.36" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="69.14" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="65.92" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="127.11" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="194.75" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="191.52" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="188.3" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="185.08" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="181.86" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="178.64" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="175.42" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="172.2" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="168.98" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="165.76" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="162.54" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="159.32" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="156.1" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="152.88" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="149.66" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="146.44" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="143.22" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="140" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="136.78" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="133.55" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="197.97" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="130.33" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="265.58" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="262.36" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="259.14" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="255.92" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="252.7" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="249.47" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="246.25" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="243.03" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="239.81" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="236.59" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="233.37" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="230.15" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="226.93" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="223.71" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="220.49" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="217.27" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="214.05" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="210.83" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="207.61" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="204.39" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="268.8" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="201.17" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="336.43" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="333.21" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="329.99" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="326.77" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="323.55" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="320.33" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="317.11" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="313.89" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="310.67" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="307.44" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="304.22" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="301" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="297.78" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="294.56" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="291.34" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="288.12" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="284.9" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="281.68" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="278.46" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="275.24" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="339.65" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="272.02" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="407.69" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="404.47" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="401.25" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="398.03" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="394.81" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="391.59" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="388.37" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="385.15" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="381.93" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="378.71" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="375.49" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="372.27" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="369.05" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="365.82" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="362.6" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="359.38" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="356.16" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="352.94" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="349.72" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="346.5" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="410.91" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="343.28" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="478.54" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="475.32" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="472.1" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="468.88" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="465.66" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="462.44" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="459.22" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="456" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="452.78" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="449.56" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="446.34" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="443.12" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="439.9" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="436.68" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="433.46" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="430.24" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="427.01" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="423.79" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="420.57" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="417.35" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="414.13" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="123.89" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="120.67" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="117.45" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="114.23" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="111.01" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="107.79" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="104.57" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="101.35" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="98.13" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="94.91" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="91.69" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="88.47" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="85.25" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="82.03" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="78.81" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="75.59" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="72.36" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="69.14" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="65.92" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="62.7" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="127.11" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="194.75" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="191.52" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="188.3" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="185.08" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="181.86" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="178.64" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="175.42" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="172.2" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="168.98" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="165.76" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="162.54" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="159.32" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="156.1" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="152.88" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="149.66" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="146.44" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="143.22" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="140" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="136.78" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="133.55" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="197.97" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="130.33" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="265.58" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="262.36" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="259.14" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="255.92" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="252.7" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="249.47" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="246.25" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="243.03" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="239.81" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="236.59" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="233.37" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="230.15" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="226.93" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="223.71" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="220.49" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="217.27" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="214.05" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="210.83" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="207.61" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="204.39" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="268.8" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="201.17" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="336.43" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="333.21" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="329.99" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="326.77" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="323.55" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="320.33" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="317.11" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="313.89" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="310.67" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="307.44" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="304.22" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="301" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="297.78" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="294.56" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="291.34" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="288.12" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="284.9" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="281.68" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="278.46" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="275.24" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="339.65" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="272.02" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="407.69" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="404.47" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="401.25" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="398.03" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="394.81" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="391.59" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="388.37" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="385.15" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="381.93" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="378.71" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="375.49" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="372.27" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="369.05" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="365.82" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="362.6" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="359.38" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="356.16" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="352.94" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="349.72" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="346.5" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="410.91" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="343.28" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="478.54" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="475.32" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="472.1" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="468.88" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="465.66" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="462.44" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="459.22" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="456" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="452.78" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="449.56" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="446.34" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="443.12" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="439.9" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="436.68" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="433.46" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="430.24" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="427.01" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="423.79" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="420.57" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="417.35" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="481.76" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="414.13" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="123.89" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="120.67" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="117.45" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="114.23" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="111.01" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="107.79" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="104.57" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="101.35" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="98.13" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="94.91" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="91.69" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="88.47" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="85.25" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="82.03" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="78.81" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="75.59" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="72.36" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="69.14" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="65.92" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="62.7" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="127.11" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="59.48" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="194.75" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="191.52" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="188.3" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="185.08" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="181.86" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="178.64" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="175.42" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="172.2" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="168.98" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="165.76" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="162.54" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="159.32" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="156.1" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="152.88" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="149.66" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="146.44" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="143.22" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="140" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="136.78" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="133.55" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="197.97" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="130.33" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="265.58" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="262.36" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="259.14" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="255.92" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="252.7" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="249.47" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="246.25" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="243.03" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="239.81" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="236.59" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="233.37" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="230.15" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="226.93" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="223.71" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="220.49" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="217.27" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="214.05" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="210.83" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="207.61" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="204.39" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="268.8" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="201.17" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="336.43" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="333.21" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="329.99" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="326.77" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="323.55" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="320.33" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="317.11" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="313.89" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="310.67" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="307.44" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="304.22" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="301" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="297.78" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="294.56" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="291.34" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="288.12" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="284.9" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="281.68" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="278.46" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="275.24" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="339.65" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="272.02" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="407.69" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="404.47" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="401.25" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="398.03" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="394.81" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="391.59" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="388.37" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="385.15" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="381.93" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="378.71" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="375.49" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="372.27" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="369.05" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="365.82" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="362.6" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="359.38" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="356.16" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="352.94" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="349.72" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="346.5" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="410.91" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="343.28" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="478.54" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="475.32" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="472.1" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="468.88" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="465.66" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="462.44" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="459.22" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="456" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="452.78" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="449.56" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="446.34" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="443.12" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="439.9" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="436.68" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="433.46" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="430.24" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="427.01" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="423.79" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="420.57" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="417.35" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="481.76" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="414.13" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="123.89" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="120.67" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="117.45" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="114.23" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="111.01" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="107.79" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="104.57" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="101.35" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="98.13" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="94.91" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="91.69" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="88.47" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="85.25" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="82.03" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="78.81" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="75.59" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="72.36" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="69.14" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="65.92" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="62.7" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="127.11" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="59.48" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="194.75" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="191.52" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="188.3" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="185.08" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="181.86" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="178.64" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="175.42" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="172.2" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="168.98" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="165.76" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="162.54" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="159.32" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="156.1" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="152.88" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="149.66" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="146.44" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="143.22" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="140" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="136.78" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="133.55" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="197.97" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="130.33" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="265.58" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="262.36" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="259.14" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="255.92" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="252.7" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="249.47" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="246.25" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="243.03" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="239.81" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="236.59" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="233.37" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="230.15" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="226.93" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="223.71" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="220.49" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="217.27" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="214.05" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="210.83" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="207.61" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="204.39" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="268.8" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="201.17" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="336.43" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="333.21" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="329.99" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="326.77" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="323.55" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="320.33" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="317.11" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="313.89" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="310.67" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="307.44" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="304.22" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="301" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="297.78" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="294.56" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="291.34" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="288.12" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="284.9" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="281.68" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="278.46" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="275.24" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="339.65" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="272.02" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="407.69" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="404.47" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="401.25" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="398.03" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="394.81" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="391.59" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="388.37" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="385.15" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="381.93" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="378.71" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="375.49" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="372.27" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="369.05" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="365.82" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="362.6" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="359.38" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="356.16" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="352.94" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="349.72" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="346.5" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="410.91" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="343.28" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="478.54" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="475.32" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="472.1" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="468.88" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="465.66" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="462.44" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="459.22" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="456" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="452.78" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="449.56" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="446.34" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="443.12" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="439.9" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="436.68" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="433.46" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="430.24" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="427.01" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="423.79" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="420.57" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="417.35" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="481.76" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="414.13" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="123.89" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="120.67" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="117.45" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="114.23" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="111.01" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="107.79" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="104.57" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="101.35" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="98.13" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="94.91" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="91.69" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="88.47" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="85.25" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="82.03" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="78.81" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="75.59" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="72.36" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="69.14" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="65.92" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="62.7" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="127.11" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="59.48" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="194.75" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="191.52" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="188.3" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="185.08" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="181.86" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="178.64" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="175.42" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="172.2" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="168.98" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="165.76" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="162.54" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="159.32" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="156.1" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="152.88" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="149.66" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="146.44" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="143.22" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="140" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="136.78" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="133.55" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="197.97" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="130.33" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="265.58" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="262.36" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="259.14" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="255.92" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="252.7" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="249.47" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="246.25" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="243.03" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="239.81" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="236.59" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="233.37" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="230.15" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="226.93" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="223.71" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="220.49" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="217.27" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="214.05" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="210.83" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="207.61" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="204.39" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="268.8" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="201.17" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="336.43" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="333.21" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="329.99" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="326.77" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="323.55" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="320.33" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="317.11" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="313.89" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="310.67" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="307.44" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="304.22" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="301" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="297.78" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="294.56" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="291.34" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="288.12" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="284.9" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="281.68" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="278.46" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="275.24" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="339.65" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="272.02" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="407.69" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="404.47" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="401.25" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="398.03" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="394.81" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="391.59" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="388.37" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="385.15" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="381.93" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="378.71" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="375.49" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="372.27" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="369.05" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="365.82" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="362.6" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="359.38" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="356.16" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="352.94" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="349.72" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="346.5" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="410.91" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="343.28" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="478.54" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="475.32" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="472.1" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="468.88" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="465.66" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="462.44" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="459.22" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="456" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="452.78" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="449.56" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="446.34" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="443.12" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="439.9" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="436.68" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="433.46" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="430.24" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="427.01" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="423.79" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="420.57" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="417.35" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="481.76" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="414.13" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="123.89" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="120.67" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="117.45" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="114.23" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="111.01" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="107.79" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="104.57" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="101.35" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="98.13" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="94.91" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="91.69" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="88.47" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="85.25" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="82.03" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="78.81" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="75.59" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="72.36" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="69.14" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="65.92" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="62.7" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="127.11" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="59.48" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="194.75" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="191.52" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="188.3" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="185.08" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="181.86" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="178.64" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="175.42" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="172.2" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="168.98" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="165.76" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="162.54" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="159.32" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="156.1" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="152.88" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="149.66" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="146.44" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="143.22" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="140" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="136.78" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="133.55" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="197.97" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="130.33" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="265.58" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="262.36" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="259.14" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="255.92" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="252.7" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="249.47" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="246.25" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="243.03" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="239.81" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="236.59" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="233.37" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="230.15" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="226.93" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="223.71" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="220.49" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="217.27" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="214.05" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="210.83" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="207.61" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="204.39" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="268.8" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="201.17" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="336.43" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="333.21" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="329.99" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="326.77" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="323.55" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="320.33" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="317.11" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="313.89" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="310.67" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="307.44" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="304.22" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="301" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="297.78" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="294.56" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="291.34" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="288.12" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="284.9" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="281.68" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="278.46" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="275.24" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="339.65" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="272.02" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="407.69" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="404.47" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="401.25" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="398.03" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="394.81" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="391.59" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="388.37" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="385.15" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="381.93" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="378.71" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="375.49" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="372.27" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="369.05" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="365.82" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="362.6" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="359.38" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="356.16" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="352.94" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="349.72" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="346.5" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="410.91" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="343.28" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="478.54" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="475.32" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="472.1" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="468.88" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="465.66" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="462.44" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="459.22" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="456" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="452.78" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="449.56" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="446.34" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="443.12" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="439.9" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="436.68" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="433.46" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="430.24" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="427.01" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="423.79" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="420.57" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="417.35" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="481.76" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="414.13" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="123.89" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="120.67" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="117.45" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="114.23" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="111.01" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="107.79" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="104.57" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="101.35" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="98.13" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="94.91" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="91.69" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="88.47" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="85.25" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="82.03" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="78.81" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="75.59" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="72.36" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="69.14" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="65.92" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="62.7" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="127.11" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="59.48" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="194.75" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="191.52" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="188.3" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="185.08" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="181.86" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="178.64" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="175.42" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="172.2" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="168.98" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="165.76" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="162.54" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="159.32" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="156.1" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="152.88" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="149.66" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="146.44" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="143.22" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="140" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="136.78" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="133.55" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="197.97" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="130.33" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="265.58" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="262.36" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="259.14" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="255.92" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="252.7" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="249.47" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="246.25" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="243.03" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="239.81" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="236.59" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="233.37" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="230.15" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="226.93" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="223.71" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="220.49" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="217.27" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="214.05" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="210.83" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="207.61" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="204.39" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="268.8" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="201.17" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="336.43" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="333.21" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="329.99" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="326.77" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="323.55" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="320.33" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="317.11" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="313.89" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="310.67" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="307.44" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="304.22" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="301" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="297.78" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="294.56" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="291.34" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="288.12" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="284.9" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="281.68" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="278.46" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="275.24" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="339.65" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="272.02" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="407.69" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="404.47" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="401.25" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="398.03" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="394.81" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="391.59" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="388.37" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="385.15" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="381.93" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="378.71" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="375.49" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="372.27" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="369.05" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="365.82" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="362.6" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="359.38" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="356.16" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="352.94" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="349.72" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="346.5" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="410.91" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="343.28" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="478.54" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="475.32" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="472.1" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="468.88" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="465.66" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="462.44" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="459.22" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="456" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="452.78" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="449.56" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="446.34" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="443.12" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="439.9" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="436.68" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="433.46" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="430.24" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="427.01" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="423.79" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="420.57" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="417.35" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="481.76" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="414.13" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="123.89" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="120.67" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="117.45" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="114.23" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="111.01" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="107.79" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="104.57" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="101.35" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="98.13" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="94.91" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="91.69" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="88.47" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="85.25" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="82.03" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="78.81" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="75.59" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="72.36" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="69.14" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="65.92" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="62.7" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="127.11" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="59.48" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="194.75" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="191.52" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="188.3" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="185.08" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="181.86" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="178.64" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="175.42" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="172.2" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="168.98" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="165.76" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="162.54" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="159.32" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="156.1" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="152.88" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="149.66" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="146.44" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="143.22" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="140" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="136.78" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="133.55" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="197.97" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="130.33" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="265.58" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="262.36" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="259.14" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="255.92" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="252.7" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="249.47" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="246.25" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="243.03" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="239.81" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="236.59" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="233.37" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="230.15" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="226.93" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="223.71" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="220.49" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="217.27" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="214.05" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="210.83" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="207.61" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="204.39" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="268.8" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="201.17" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="336.43" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="333.21" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="329.99" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="326.77" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="323.55" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="320.33" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="317.11" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="313.89" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="310.67" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="307.44" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="304.22" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="301" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="297.78" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="294.56" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="291.34" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="288.12" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="284.9" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="281.68" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="278.46" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="275.24" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="339.65" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="272.02" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="407.69" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="404.47" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="401.25" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="398.03" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="394.81" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="391.59" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="388.37" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="385.15" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="381.93" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="378.71" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="375.49" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="372.27" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="369.05" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="365.82" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="362.6" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="359.38" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="356.16" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="352.94" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="349.72" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="346.5" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="410.91" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="343.28" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="478.54" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="475.32" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="472.1" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="468.88" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="465.66" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="462.44" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="459.22" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="456" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="452.78" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="449.56" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="446.34" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="443.12" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="439.9" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="436.68" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="433.46" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="430.24" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="427.01" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="423.79" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="420.57" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="417.35" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="481.76" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="414.13" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="123.89" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="120.67" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="117.45" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="114.23" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="111.01" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="107.79" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="104.57" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="101.35" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="98.13" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="94.91" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="91.69" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="88.47" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="85.25" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="82.03" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="78.81" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="75.59" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="72.36" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="69.14" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="65.92" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="62.7" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="127.11" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="59.48" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="194.75" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="191.52" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="188.3" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="185.08" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="181.86" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="178.64" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="175.42" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="172.2" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="168.98" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="165.76" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="162.54" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="159.32" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="156.1" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="152.88" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="149.66" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="146.44" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="143.22" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="140" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="136.78" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="133.55" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="197.97" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="130.33" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="265.58" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="262.36" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="259.14" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="255.92" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="252.7" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="249.47" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="246.25" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="243.03" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="239.81" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="236.59" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="233.37" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="230.15" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="226.93" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="223.71" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="220.49" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="217.27" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="214.05" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="210.83" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="207.61" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="204.39" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="268.8" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="201.17" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="336.43" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="333.21" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="329.99" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="326.77" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="323.55" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="320.33" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="317.11" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="313.89" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="310.67" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="307.44" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="304.22" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="301" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="297.78" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="294.56" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="291.34" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="288.12" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="284.9" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="281.68" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="278.46" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="275.24" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="339.65" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="272.02" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="407.69" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="404.47" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="401.25" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="398.03" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="394.81" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="391.59" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="388.37" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="385.15" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="381.93" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="378.71" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="375.49" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="372.27" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="369.05" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="365.82" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="362.6" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="359.38" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="356.16" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="352.94" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="349.72" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="346.5" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="410.91" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="343.28" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="478.54" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="475.32" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="472.1" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="468.88" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="465.66" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="462.44" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="459.22" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="456" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="452.78" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="449.56" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="446.34" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="443.12" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="439.9" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="436.68" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="433.46" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="430.24" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="427.01" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="423.79" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="420.57" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="417.35" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="481.76" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="414.13" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="123.89" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="120.67" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="117.45" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="114.23" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="111.01" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="107.79" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="104.57" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="101.35" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="98.13" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="94.91" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="91.69" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="88.47" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="85.25" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="82.03" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="78.81" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="75.59" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="72.36" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="69.14" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="65.92" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="62.7" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="127.11" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="59.48" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="194.75" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="191.52" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="188.3" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="185.08" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="181.86" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="178.64" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="175.42" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="172.2" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="168.98" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="165.76" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="162.54" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="159.32" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="156.1" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="152.88" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="149.66" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="146.44" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="143.22" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="140" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="136.78" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="133.55" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="197.97" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="130.33" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="265.58" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="262.36" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="259.14" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="255.92" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="252.7" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="249.47" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="246.25" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="243.03" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="239.81" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="236.59" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="233.37" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="230.15" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="226.93" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="223.71" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="220.49" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="217.27" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="214.05" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="210.83" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="207.61" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="204.39" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="268.8" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="201.17" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="336.43" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="333.21" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="329.99" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="326.77" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="323.55" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="320.33" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="317.11" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="313.89" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="310.67" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="307.44" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="304.22" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="301" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="297.78" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="294.56" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="291.34" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="288.12" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="284.9" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="281.68" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="278.46" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="275.24" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="339.65" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="272.02" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="407.69" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="404.47" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="401.25" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="398.03" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="394.81" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="391.59" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="388.37" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="385.15" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="381.93" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="378.71" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="375.49" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="372.27" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="369.05" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="365.82" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="362.6" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="359.38" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="356.16" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="352.94" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="349.72" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="346.5" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="410.91" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="343.28" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="478.54" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="475.32" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="472.1" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="468.88" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="465.66" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="462.44" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="459.22" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="456" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="452.78" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="449.56" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="446.34" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="443.12" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="439.9" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="436.68" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="433.46" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="430.24" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="427.01" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="423.79" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="420.57" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="417.35" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="481.76" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="414.13" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="123.89" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="120.67" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="117.45" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="114.23" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="111.01" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="107.79" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="104.57" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="101.35" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="98.13" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="94.91" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="91.69" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="88.47" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="85.25" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="82.03" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="78.81" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="75.59" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="72.36" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="69.14" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="65.92" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="62.7" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="127.11" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="59.48" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="194.75" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="191.52" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="188.3" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="185.08" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="181.86" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="178.64" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="175.42" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="172.2" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="168.98" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="165.76" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="162.54" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="159.32" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="156.1" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="152.88" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="149.66" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="146.44" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="143.22" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="140" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="136.78" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="133.55" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="197.97" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="130.33" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="265.58" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="262.36" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="259.14" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="255.92" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="252.7" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="249.47" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="246.25" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="243.03" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="239.81" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="236.59" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="233.37" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="230.15" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="226.93" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="223.71" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="220.49" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="217.27" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="214.05" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="210.83" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="207.61" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="204.39" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="268.8" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="201.17" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="336.43" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="333.21" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="329.99" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="326.77" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="323.55" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="320.33" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="317.11" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="313.89" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="310.67" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="307.44" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="304.22" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="301" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="297.78" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="294.56" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="291.34" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="288.12" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="284.9" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="281.68" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="278.46" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="275.24" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="339.65" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="272.02" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="407.69" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="404.47" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="401.25" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="398.03" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="394.81" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="391.59" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="388.37" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="385.15" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="381.93" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="378.71" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="375.49" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="372.27" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="369.05" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="365.82" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="362.6" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="359.38" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="356.16" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="352.94" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="349.72" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="346.5" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="410.91" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="343.28" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="478.54" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="475.32" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="472.1" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="468.88" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="465.66" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="462.44" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="459.22" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="456" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="452.78" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="449.56" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="446.34" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="443.12" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="439.9" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="436.68" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="433.46" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="430.24" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="427.01" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="423.79" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="420.57" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="417.35" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="481.76" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="414.13" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="123.89" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="120.67" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="117.45" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="114.23" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="111.01" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="107.79" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="104.57" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="101.35" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="98.13" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="94.91" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="91.69" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="88.47" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="85.25" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="82.03" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="78.81" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="75.59" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="72.36" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="69.14" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="65.92" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="62.7" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="127.11" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="59.48" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="194.75" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="191.52" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="188.3" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="185.08" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="181.86" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="178.64" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="175.42" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="172.2" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="168.98" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="165.76" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="162.54" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="159.32" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="156.1" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="152.88" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="149.66" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="146.44" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="143.22" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="140" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="136.78" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="133.55" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="197.97" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="130.33" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="265.58" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="262.36" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="259.14" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="255.92" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="252.7" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="249.47" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="246.25" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="243.03" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="239.81" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="236.59" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="233.37" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="230.15" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="226.93" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="223.71" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="220.49" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="217.27" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="214.05" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="210.83" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="207.61" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="204.39" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="268.8" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="201.17" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="336.43" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="333.21" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="329.99" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="326.77" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="323.55" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="320.33" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="317.11" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="313.89" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="310.67" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="307.44" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="304.22" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="301" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="297.78" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="294.56" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="291.34" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="288.12" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="284.9" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="281.68" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="278.46" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="275.24" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="339.65" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="272.02" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="407.69" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="404.47" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="401.25" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="398.03" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="394.81" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="391.59" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="388.37" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="385.15" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="381.93" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="378.71" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="375.49" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="372.27" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="369.05" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="365.82" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="362.6" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="359.38" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="356.16" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="352.94" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="349.72" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="346.5" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="410.91" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="343.28" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="478.54" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="475.32" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="472.1" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="468.88" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="465.66" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="462.44" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="459.22" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="456" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="452.78" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="449.56" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="446.34" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="443.12" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="439.9" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="436.68" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="433.46" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="430.24" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="427.01" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="423.79" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="420.57" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="417.35" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="481.76" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="414.13" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="123.89" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="120.67" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="117.45" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="114.23" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="111.01" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="107.79" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="104.57" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="101.35" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="98.13" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="94.91" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="91.69" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="88.47" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="85.25" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="82.03" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="78.81" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="75.59" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="72.36" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="69.14" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="65.92" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="62.7" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="127.11" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="59.48" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="194.75" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="191.52" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="188.3" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="185.08" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="181.86" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="178.64" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="175.42" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="172.2" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="168.98" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="165.76" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="162.54" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="159.32" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="156.1" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="152.88" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="149.66" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="146.44" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="143.22" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="140" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="136.78" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="133.55" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="197.97" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="130.33" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="265.58" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="262.36" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="259.14" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="255.92" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="252.7" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="249.47" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="246.25" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="243.03" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="239.81" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="236.59" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="233.37" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="230.15" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="226.93" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="223.71" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="220.49" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="217.27" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="214.05" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="210.83" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="207.61" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="204.39" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="268.8" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="201.17" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="336.43" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="333.21" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="329.99" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="326.77" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="323.55" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="320.33" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="317.11" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="313.89" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="310.67" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="307.44" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="304.22" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="301" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="297.78" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="294.56" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="291.34" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="288.12" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="284.9" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="281.68" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="278.46" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="275.24" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="339.65" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="272.02" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="407.69" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="404.47" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="401.25" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="398.03" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="394.81" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="391.59" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="388.37" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="385.15" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="381.93" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="378.71" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="375.49" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="372.27" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="369.05" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="365.82" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="362.6" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="359.38" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="356.16" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="352.94" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="349.72" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="346.5" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="410.91" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="343.28" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="478.54" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="475.32" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="472.1" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="468.88" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="465.66" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="462.44" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="459.22" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="456" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="452.78" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="449.56" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="446.34" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="443.12" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="439.9" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="436.68" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="433.46" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="430.24" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="427.01" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="423.79" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="420.57" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="417.35" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="481.76" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="414.13" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="123.89" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="120.67" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="117.45" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="114.23" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="111.01" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="107.79" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="104.57" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="101.35" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="98.13" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="94.91" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="91.69" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="88.47" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="85.25" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="82.03" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="78.81" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="75.59" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="72.36" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="69.14" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="65.92" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="62.7" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="127.11" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="59.48" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="194.75" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="191.52" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="188.3" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="185.08" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="181.86" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="178.64" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="175.42" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="172.2" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="168.98" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="165.76" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="162.54" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="159.32" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="156.1" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="152.88" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="149.66" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="146.44" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="143.22" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="140" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="136.78" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="133.55" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="197.97" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="130.33" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="265.58" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="262.36" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="259.14" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="255.92" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="252.7" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="249.47" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="246.25" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="243.03" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="239.81" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="236.59" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="233.37" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="230.15" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="226.93" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="223.71" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="220.49" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="217.27" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="214.05" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="210.83" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="207.61" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="204.39" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="268.8" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="201.17" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="336.43" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="333.21" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="329.99" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="326.77" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="323.55" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="320.33" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="317.11" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="313.89" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="310.67" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="307.44" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="304.22" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="301" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="297.78" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="294.56" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="291.34" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="288.12" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="284.9" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="281.68" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="278.46" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="275.24" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="339.65" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="272.02" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="407.69" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="404.47" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="401.25" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="398.03" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="394.81" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="391.59" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="388.37" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="385.15" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="381.93" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="378.71" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="375.49" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="372.27" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="369.05" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="365.82" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="362.6" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="359.38" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="356.16" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="352.94" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="349.72" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="346.5" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="410.91" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="343.28" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="478.54" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="475.32" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="472.1" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="468.88" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="465.66" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="462.44" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="459.22" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="456" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="452.78" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="449.56" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="446.34" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="443.12" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="439.9" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="436.68" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="433.46" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="430.24" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="427.01" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="423.79" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="420.57" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="417.35" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="481.76" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="414.13" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="123.89" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="120.67" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="117.45" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="114.23" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="111.01" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="107.79" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="104.57" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="101.35" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="98.13" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="94.91" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="91.69" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="88.47" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="85.25" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="82.03" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="78.81" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="75.59" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="72.36" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="69.14" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="65.92" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="62.7" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="127.11" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="59.48" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="194.75" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="191.52" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="188.3" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="185.08" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="181.86" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="178.64" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="175.42" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="172.2" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="168.98" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="165.76" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="162.54" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="159.32" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="156.1" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="152.88" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="149.66" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="146.44" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="143.22" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="140" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="136.78" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="133.55" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="197.97" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="130.33" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="265.58" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="262.36" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="259.14" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="255.92" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="252.7" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="249.47" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="246.25" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="243.03" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="239.81" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="236.59" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="233.37" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="230.15" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="226.93" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="223.71" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="220.49" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="217.27" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="214.05" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="210.83" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="207.61" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="204.39" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="268.8" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="201.17" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="336.43" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="333.21" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="329.99" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="326.77" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="323.55" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="320.33" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="317.11" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="313.89" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="310.67" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="307.44" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="304.22" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="301" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="297.78" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="294.56" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="291.34" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="288.12" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="284.9" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="281.68" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="278.46" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="275.24" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="339.65" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="272.02" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="407.69" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="404.47" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="401.25" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="398.03" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="394.81" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="391.59" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="388.37" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="385.15" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="381.93" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="378.71" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="375.49" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="372.27" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="369.05" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="365.82" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="362.6" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="359.38" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="356.16" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="352.94" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="349.72" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="346.5" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="410.91" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="343.28" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="478.54" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="475.32" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="472.1" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="468.88" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="465.66" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="462.44" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="459.22" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="456" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="452.78" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="449.56" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="446.34" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="443.12" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="439.9" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="436.68" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="433.46" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="430.24" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="427.01" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="423.79" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="420.57" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="417.35" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="481.76" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="414.13" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="123.89" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="120.67" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="117.45" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="114.23" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="111.01" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="107.79" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="104.57" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="101.35" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="98.13" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="94.91" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="91.69" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="88.47" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="85.25" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="82.03" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="78.81" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="75.59" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="72.36" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="69.14" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="65.92" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="62.7" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="127.11" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="59.48" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="194.75" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="191.52" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="188.3" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="185.08" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="181.86" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="178.64" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="175.42" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="172.2" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="168.98" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="165.76" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="162.54" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="159.32" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="156.1" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="152.88" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="149.66" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="146.44" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="143.22" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="140" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="136.78" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="133.55" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="197.97" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="130.33" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="265.58" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="262.36" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="259.14" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="255.92" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="252.7" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="249.47" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="246.25" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="243.03" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="239.81" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="236.59" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="233.37" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="230.15" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="226.93" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="223.71" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="220.49" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="217.27" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="214.05" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="210.83" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="207.61" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="204.39" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="268.8" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="201.17" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="336.43" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="333.21" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="329.99" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="326.77" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="323.55" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="320.33" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="317.11" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="313.89" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="310.67" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="307.44" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="304.22" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="301" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="297.78" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="294.56" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="291.34" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="288.12" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="284.9" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="281.68" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="278.46" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="275.24" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="339.65" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="272.02" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="407.69" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="404.47" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="401.25" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="398.03" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="394.81" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="391.59" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="388.37" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="385.15" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="381.93" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="378.71" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="375.49" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="372.27" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="369.05" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="365.82" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="362.6" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="359.38" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="356.16" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="352.94" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="349.72" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="346.5" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="410.91" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="343.28" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="478.54" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="475.32" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="472.1" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="468.88" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="465.66" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="462.44" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="459.22" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="456" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="452.78" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="449.56" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="446.34" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="443.12" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="439.9" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="436.68" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="433.46" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="430.24" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="427.01" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="423.79" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="420.57" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="417.35" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="481.76" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="414.13" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="123.89" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="120.67" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="117.45" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="114.23" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="111.01" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="107.79" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="104.57" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="101.35" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="98.13" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="94.91" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="91.69" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="88.47" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="85.25" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="82.03" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="78.81" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="75.59" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="72.36" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="69.14" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="65.92" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="62.7" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="127.11" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="194.75" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="191.52" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="188.3" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="185.08" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="181.86" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="178.64" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="175.42" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="172.2" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="168.98" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="165.76" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="162.54" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="159.32" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="156.1" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="152.88" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="149.66" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="146.44" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="143.22" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="140" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="136.78" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="133.55" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="197.97" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="130.33" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="265.58" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="262.36" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="259.14" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="255.92" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="252.7" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="249.47" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="246.25" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="243.03" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="239.81" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="236.59" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="233.37" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="230.15" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="226.93" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="223.71" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="220.49" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="217.27" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="214.05" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="210.83" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="207.61" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="204.39" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="268.8" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="201.17" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="336.43" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="333.21" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="329.99" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="326.77" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="323.55" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="320.33" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="317.11" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="313.89" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="310.67" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="307.44" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="304.22" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="301" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="297.78" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="294.56" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="291.34" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="288.12" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="284.9" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="281.68" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="278.46" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="275.24" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="339.65" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="272.02" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="407.69" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="404.47" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="401.25" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="398.03" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="394.81" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="391.59" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="388.37" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="385.15" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="381.93" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="378.71" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="375.49" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="372.27" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="369.05" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="365.82" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="362.6" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="359.38" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="356.16" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="352.94" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="349.72" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="346.5" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="410.91" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="343.28" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="478.54" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="475.32" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="472.1" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="468.88" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="465.66" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="462.44" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="459.22" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="456" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="452.78" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="449.56" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="446.34" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="443.12" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="439.9" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="436.68" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="433.46" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="430.24" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="427.01" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="423.79" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="420.57" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="417.35" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="481.76" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="414.13" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="484.72" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="484.72" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="484.72" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="484.72" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="484.72" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="484.72" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="484.72" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="484.72" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="484.72" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="484.72" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="484.72" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="484.72" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="484.72" cy="41.66" r="0.8" fill="#74bbe7" />
        </g>
        <g id="ButtonGroup">
          <foreignObject
            x="20"
            y="198.31"
            width="95"
            height="17"
            className="flex px-2"
          >
            <BaseButton
              label="Book Now"
              onClick={() => handleOpenBooking()}
              style={{
                background: "linear-gradient(180deg, #B3D05F 0%, #47B295 100%)",
              }}
              className="rounded-full w-full h-full text-xxs px-1 self-center text-[#fff] font-semibold"
            />
          </foreignObject>
          <foreignObject
            x="155"
            y="213.31"
            width="95"
            height="17"
            className="flex px-2"
          >
            <BaseButton
              label="Book Now"
              onClick={() => handleOpenBooking()}
              style={{
                background: "linear-gradient(180deg, #898FC3 0%, #84BAE4 100%)",
              }}
              className="rounded-full w-full h-full text-xxs px-1 self-center text-[#fff] font-semibold"
            />
          </foreignObject>
          <foreignObject
            x="290"
            y="235.31"
            width="95"
            height="17"
            className="flex px-2"
          >
            <BaseButton
              label="Book Now"
              onClick={() => handleOpenBooking()}
              style={{
                background: "linear-gradient(180deg, #575BA3 0%, #8561A4 100%)",
              }}
              className="rounded-full w-full h-full text-xxs px-1 self-center text-[#fff] font-semibold"
            />
          </foreignObject>
          <foreignObject
            x="424"
            y="258.31"
            width="95"
            height="17"
            className="flex px-2"
          >
            <BaseButton
              label="Book Now"
              onClick={() => handleOpenBooking()}
              style={{
                background: "linear-gradient(180deg, #FFA149 0%, #FF6258 100%)",
              }}
              className="rounded-full w-full h-full text-xxs px-1 self-center text-[#fff] font-semibold"
            />
          </foreignObject>
        </g>
        <g filter="url(#filter-1-9)">
          <path
            d="M225.21,11.54a13.55,13.55,0,0,1,7.59,1.86,5.57,5.57,0,0,1,2.81,4.78,7.16,7.16,0,0,1-1.67,4.42,12,12,0,0,1-4.28,3.45,11.64,11.64,0,0,1-5.26,1.3,6.08,6.08,0,0,1-2.89-.58q-2,4.92-2,7.45a4.52,4.52,0,0,1-3.24-.26,2.43,2.43,0,0,1-1.29-2.3c0-1.25.67-3.2,2-5.83a40.09,40.09,0,0,1,4.69-7.21q2.65-3.26,4.17-3.26a2.14,2.14,0,0,1,1.88.91,29.85,29.85,0,0,0-5.54,9,10.58,10.58,0,0,0,2.87-.72A15.35,15.35,0,0,0,228,23a8.29,8.29,0,0,0,2.36-2.43,5.68,5.68,0,0,0,.94-3.13,3.61,3.61,0,0,0-1.41-3.11,6.55,6.55,0,0,0-3.94-1,10.62,10.62,0,0,0-4.5,1,12.33,12.33,0,0,0-3.68,2.54,14.65,14.65,0,0,0-2.38,3.24,6.94,6.94,0,0,0-.91,3.17,3.12,3.12,0,0,0,.39,1.59,2.21,2.21,0,0,1-1.13.2,3.38,3.38,0,0,1-2.46-1,3.77,3.77,0,0,1-1-2.81,6.79,6.79,0,0,1,2.19-4.77,15.25,15.25,0,0,1,5.63-3.57A20,20,0,0,1,225.21,11.54Z"
            fill="#1b0f44"
          />
          <path
            d="M251.45,23.49a1.14,1.14,0,0,1,.86.22.64.64,0,0,1,.27.72q-5.43,7.68-8.29,7.68a2,2,0,0,1-1.75-.95,3.94,3.94,0,0,1-.65-2.25,2.21,2.21,0,0,1,0-.52q-3.8,4.53-6,4.53a2.27,2.27,0,0,1-1.63-.74,3.1,3.1,0,0,1-.76-2.3,11.32,11.32,0,0,1,1-4.31,14.16,14.16,0,0,1,2.83-4.33,5.2,5.2,0,0,1,3.7-1.89,3.29,3.29,0,0,1,1.94.61,3.16,3.16,0,0,1,1.2,1.75c1-1.53,1.86-2.3,2.65-2.3a2.07,2.07,0,0,1,1.35.47,2,2,0,0,1,.7.93,10,10,0,0,1-1,1.76,21.25,21.25,0,0,1-1.59,2.12,11,11,0,0,0-.55,2.92c0,.78.19,1.17.55,1.17Q247.53,28.78,251.45,23.49ZM238.2,29.42c.34,0,1-.46,1.89-1.39a26.55,26.55,0,0,0,2.77-3.34,17.83,17.83,0,0,1,.72-1.71c-.48-.91-1-1.36-1.56-1.36s-1.25.43-1.94,1.31a11.92,11.92,0,0,0-1.69,2.93,7.48,7.48,0,0,0-.64,2.62C237.75,29.11,237.9,29.42,238.2,29.42Z"
            fill="#1b0f44"
          />
          <path
            d="M264.15,24.43a25.75,25.75,0,0,1-5.64,6.22,9.25,9.25,0,0,1-5.5,2.18,3.93,3.93,0,0,1-2.9-1.12,4,4,0,0,1-1.12-3,9.68,9.68,0,0,1,1.12-4.21,12.68,12.68,0,0,1,3-4,5.62,5.62,0,0,1,3.71-1.67,4.87,4.87,0,0,1,2.56.75,2.21,2.21,0,0,1,1.23,1.94A3.77,3.77,0,0,1,259,24.4c-1.09.91-2,1.22-2.8.94a9.93,9.93,0,0,0,1.52-4.11c0-.48-.17-.72-.52-.72s-.87.4-1.52,1.19a13,13,0,0,0-1.78,3,7.85,7.85,0,0,0-.81,3.18c0,1.4.6,2.1,1.81,2.1a6.77,6.77,0,0,0,4.25-2A21.5,21.5,0,0,0,263,23.49a1.14,1.14,0,0,1,.86.22A.66.66,0,0,1,264.15,24.43Z"
            fill="#1b0f44"
          />
          <path
            d="M277.6,23.49a1.14,1.14,0,0,1,.86.22.66.66,0,0,1,.27.72q-5.14,7.68-8.42,7.68A2.53,2.53,0,0,1,268,30.59a5.59,5.59,0,0,1-.42-3.69,23.27,23.27,0,0,0-4,5.73,2.84,2.84,0,0,1-.68.07,2.42,2.42,0,0,1-1.79-.72,2.46,2.46,0,0,1-.71-1.81q0-2,2-6.4a47.8,47.8,0,0,1,4.67-8.1Q269.7,12,271.25,12a2.89,2.89,0,0,1,1.41.39,2,2,0,0,1,.89.81,29.38,29.38,0,0,0-5.7,7.5,41.43,41.43,0,0,0-3.8,8.21,47.78,47.78,0,0,1,5.27-6.4q2.93-3,4.52-3A1.55,1.55,0,0,1,275,20a1.51,1.51,0,0,1,.49,1.1,8.82,8.82,0,0,1-1.86,1.19,27.93,27.93,0,0,1-2.84,1.34,5.13,5.13,0,0,0-.29,2.45,4.68,4.68,0,0,0,.73,2c.38.56.77.85,1.18.85Q273.75,28.91,277.6,23.49Z"
            fill="#1b0f44"
          />
          <path
            d="M293,23.49a1.1,1.1,0,0,1,.85.22.66.66,0,0,1,.28.72q-5.44,7.68-8.3,7.68a2,2,0,0,1-1.75-.95,3.94,3.94,0,0,1-.64-2.25,3.19,3.19,0,0,1,0-.52c-2.53,3-4.51,4.53-6,4.53a2.29,2.29,0,0,1-1.64-.74,3.1,3.1,0,0,1-.76-2.3,11.32,11.32,0,0,1,1-4.31,14.19,14.19,0,0,1,2.84-4.33,5.2,5.2,0,0,1,3.69-1.89,3.29,3.29,0,0,1,1.94.61,3.06,3.06,0,0,1,1.2,1.75c1-1.53,1.86-2.3,2.66-2.3a2,2,0,0,1,1.34.47,2,2,0,0,1,.7.93,9.71,9.71,0,0,1-1,1.76,21.25,21.25,0,0,1-1.59,2.12,10.57,10.57,0,0,0-.55,2.92c0,.78.18,1.17.55,1.17C288.65,28.78,290.34,27,293,23.49ZM279.7,29.42c.35,0,1-.46,1.9-1.39a27.44,27.44,0,0,0,2.77-3.34,17.36,17.36,0,0,1,.71-1.71c-.47-.91-1-1.36-1.55-1.36a2.73,2.73,0,0,0-1.95,1.31,11.58,11.58,0,0,0-1.68,2.93,7.54,7.54,0,0,0-.65,2.62C279.25,29.11,279.4,29.42,279.7,29.42Z"
            fill="#1b0f44"
          />
          <path
            d="M308.31,23.49a1.13,1.13,0,0,1,.86.22.66.66,0,0,1,.28.72,24.91,24.91,0,0,1-3.83,4.51,27.8,27.8,0,0,1-4.27,3.17,25.73,25.73,0,0,1-3.92,6.66,6.31,6.31,0,0,1-4.8,2.58,3.84,3.84,0,0,1-2.49-.81,2.6,2.6,0,0,1-1-2.11,3,3,0,0,1,.12-.86,2.36,2.36,0,0,1,.4-.79,8.63,8.63,0,0,1,.58-.7,5.47,5.47,0,0,1,.86-.68c.38-.25.71-.46,1-.63s.69-.39,1.21-.66,1-.5,1.33-.67l1.52-.73,1.59-.76c.41-1.12.83-2.45,1.26-4a24,24,0,0,1-3.3,3.15,4.92,4.92,0,0,1-2.82,1.28,2.42,2.42,0,0,1-1.68-.72,2.64,2.64,0,0,1-.78-2,10.46,10.46,0,0,1,1.08-4.18,14.42,14.42,0,0,1,2.94-4.26,5.3,5.3,0,0,1,3.69-1.86,3.1,3.1,0,0,1,3,2.27c.89-1.47,1.79-2.21,2.69-2.21a2.07,2.07,0,0,1,1.35.5,2.32,2.32,0,0,1,.73.9,14.26,14.26,0,0,1-2,3.17,48.89,48.89,0,0,1-1.59,5.61A24.69,24.69,0,0,0,308.31,23.49ZM292.57,39.21a3,3,0,0,0,2.1-1.38,17,17,0,0,0,2.21-3.77q-5.25,2.58-5.25,4.31a.71.71,0,0,0,.29.61A1,1,0,0,0,292.57,39.21ZM295,29.42c.36,0,1-.45,1.94-1.37a29.41,29.41,0,0,0,2.82-3.29c.24-.65.47-1.22.71-1.72-.5-.95-1-1.42-1.59-1.42-.95,0-1.94,1-3,2.88a9.36,9.36,0,0,0-1.32,4.15C294.58,29.16,294.71,29.42,295,29.42Z"
            fill="#1b0f44"
          />
          <path
            d="M320.17,23.49a1.13,1.13,0,0,1,.86.22.64.64,0,0,1,.27.72q-6,8.4-11.27,8.4A4.21,4.21,0,0,1,307,31.74a3.91,3.91,0,0,1-1.12-3A9.72,9.72,0,0,1,307,24.56a12.66,12.66,0,0,1,3-4,5.75,5.75,0,0,1,3.78-1.69,4,4,0,0,1,2.54.85,2.46,2.46,0,0,1,1.08,2q0,1.59-2.13,3.6a15.65,15.65,0,0,1-5.42,3.21A1.72,1.72,0,0,0,311.71,30,7.24,7.24,0,0,0,316.12,28,21.38,21.38,0,0,0,320.17,23.49Zm-6.12-3c-.59,0-1.38.78-2.38,2.34a11.6,11.6,0,0,0-1.84,4.27,12.55,12.55,0,0,0,3.39-2.85,5,5,0,0,0,1.44-2.95C314.66,20.78,314.46,20.51,314.05,20.51Z"
            fill="#1b0f44"
          />
          <path
            d="M331.9,23.49a1.14,1.14,0,0,1,.86.22.64.64,0,0,1,.27.72,9.09,9.09,0,0,1-5.47,4,5.19,5.19,0,0,1-1.7,3.45,5,5,0,0,1-3.36,1.24,4.26,4.26,0,0,1-3.67-1.94,6.75,6.75,0,0,1-1.38-3.95c0-1.49.51-2.11,1.52-1.85a15.43,15.43,0,0,0,4.67,1.56c-.07-3.26-.09-5.12-.07-5.58l-.13-.13c-.11.2-.82,1.28-2.14,3.24a1.37,1.37,0,0,1-.79-.25.73.73,0,0,1-.34-.69,44.88,44.88,0,0,0,2.66-4A2.26,2.26,0,0,1,325.21,18c1.22,0,2,.32,2.28,1a4.53,4.53,0,0,0-.53,2,17.24,17.24,0,0,0,.26,3.18c.2,1.33.32,2.16.34,2.46A8.3,8.3,0,0,0,331.9,23.49ZM322,30.36a1.43,1.43,0,0,0,1.15-.55,2.29,2.29,0,0,0,.57-1.23A10.88,10.88,0,0,1,319.1,27a4.73,4.73,0,0,0,1.12,2.51A2.38,2.38,0,0,0,322,30.36Z"
            fill="#1b0f44"
          />
        </g>
        <g id="Prices">
          <path
            d="M47.23,115.52a2.16,2.16,0,0,1-1.46-.45,1.36,1.36,0,0,1-.39-1.3H46c-.11.81.33,1.22,1.3,1.22a1.77,1.77,0,0,0,.9-.2.87.87,0,0,0,.42-.59.67.67,0,0,0-.14-.65,1.82,1.82,0,0,0-.86-.38l-.42-.09a1.87,1.87,0,0,1-1-.52,1.12,1.12,0,0,1-.22-1,1.35,1.35,0,0,1,.62-.9,2.28,2.28,0,0,1,1.27-.32,1.84,1.84,0,0,1,1.41.43,1.34,1.34,0,0,1,.27,1.13H49a.85.85,0,0,0-.17-.78,1.37,1.37,0,0,0-1-.25c-.74,0-1.16.24-1.26.73a.69.69,0,0,0,.14.63,1.74,1.74,0,0,0,.83.37l.42.1c1,.22,1.39.75,1.22,1.59a1.42,1.42,0,0,1-.65.92A2.43,2.43,0,0,1,47.23,115.52Z"
            fill="#fff"
          />
          <path
            d="M51.77,112.29H51l-.48,2.25a.43.43,0,0,0,0,.31.38.38,0,0,0,.3.09h.32l-.09.47a2.66,2.66,0,0,1-.35,0,.84.84,0,0,1-.66-.23.79.79,0,0,1-.11-.68l.48-2.23h-.6l.11-.49h.59l.22-1h.56l-.22,1h.75Z"
            fill="#fff"
          />
          <path
            d="M55,113l-.36,1.68c0,.2,0,.3.16.3H55l-.1.45a1.47,1.47,0,0,1-.31,0,.52.52,0,0,1-.38-.13A.41.41,0,0,1,54,115a1.48,1.48,0,0,1-1.2.55,1.19,1.19,0,0,1-.92-.34.94.94,0,0,1-.18-.91c.11-.52.61-.83,1.49-.92l.57-.07c.39,0,.61-.18.67-.42a.47.47,0,0,0-.11-.47.93.93,0,0,0-.63-.17,1.18,1.18,0,0,0-.68.16,1,1,0,0,0-.33.55h-.55a1.57,1.57,0,0,1,.54-.91,1.73,1.73,0,0,1,1.07-.28C54.76,111.72,55.15,112.14,55,113Zm-2,2a1.36,1.36,0,0,0,.79-.24,1.18,1.18,0,0,0,.46-.68l.13-.57a1.24,1.24,0,0,1-.52.15l-.54.07c-.58.08-.9.28-1,.61a.51.51,0,0,0,.1.49A.74.74,0,0,0,52.94,115Z"
            fill="#fff"
          />
          <path
            d="M57.79,111.75H58l-.11.54h-.14a1.49,1.49,0,0,0-.93.28,1.22,1.22,0,0,0-.48.78l-.44,2.06h-.56l.77-3.61h.53l-.11.56A1.63,1.63,0,0,1,57.79,111.75Z"
            fill="#fff"
          />
          <path
            d="M60,112.29h-.74l-.48,2.25a.42.42,0,0,0,0,.31.36.36,0,0,0,.29.09h.33l-.1.47a2.58,2.58,0,0,1-.35,0,.85.85,0,0,1-.66-.23.79.79,0,0,1-.11-.68l.48-2.23h-.59l.1-.49h.6l.21-1h.56l-.21,1h.74Z"
            fill="#fff"
          />
          <path
            d="M60.49,115.41h-.56l.77-3.61h.56Zm.91-4.16h-.6l.14-.68h.6Z"
            fill="#fff"
          />
          <path
            d="M63.74,111.72a1,1,0,0,1,.9.38,1.3,1.3,0,0,1,.11,1.08l-.48,2.23h-.56l.47-2.27c.14-.61-.06-.92-.58-.92a1.19,1.19,0,0,0-.81.31,1.36,1.36,0,0,0-.47.84l-.43,2h-.56l.77-3.61h.53l-.11.48A1.63,1.63,0,0,1,63.74,111.72Z"
            fill="#fff"
          />
          <path
            d="M68.48,112.31l.11-.51h.53l-.74,3.47a2.08,2.08,0,0,1-.64,1.2,1.85,1.85,0,0,1-1.28.4,1.57,1.57,0,0,1-1-.32,1,1,0,0,1-.33-.84h.57a.61.61,0,0,0,.2.52,1.11,1.11,0,0,0,.66.16,1.23,1.23,0,0,0,1.31-1.12l.05-.24a1.63,1.63,0,0,1-1.15.47,1.32,1.32,0,0,1-1.19-.58,1.85,1.85,0,0,1-.19-1.46,2.24,2.24,0,0,1,.73-1.29,2,2,0,0,1,1.28-.45A1.22,1.22,0,0,1,68.48,112.31ZM66.79,115a1.27,1.27,0,0,0,.89-.34,1.73,1.73,0,0,0,.54-.92l0-.22a1.24,1.24,0,0,0-.15-1,.87.87,0,0,0-.77-.34,1.26,1.26,0,0,0-.91.36,1.78,1.78,0,0,0-.5,1A1.4,1.4,0,0,0,66,114.6.81.81,0,0,0,66.79,115Z"
            fill="#fff"
          />
          <path
            d="M73.83,113l-.36,1.68c0,.2,0,.3.16.3h.18l-.1.45a1.47,1.47,0,0,1-.31,0,.52.52,0,0,1-.38-.13.41.41,0,0,1-.13-.35,1.46,1.46,0,0,1-1.2.55,1.19,1.19,0,0,1-.92-.34.94.94,0,0,1-.18-.91c.11-.52.61-.83,1.49-.92l.57-.07c.39,0,.61-.18.67-.42a.47.47,0,0,0-.11-.47.93.93,0,0,0-.63-.17,1.18,1.18,0,0,0-.68.16,1,1,0,0,0-.33.55H71a1.57,1.57,0,0,1,.54-.91,1.73,1.73,0,0,1,1.07-.28C73.61,111.72,74,112.14,73.83,113Zm-2,2a1.36,1.36,0,0,0,.79-.24,1.18,1.18,0,0,0,.46-.68l.13-.57a1.24,1.24,0,0,1-.52.15l-.54.07c-.58.08-.9.28-1,.61a.51.51,0,0,0,.1.49A.74.74,0,0,0,71.79,115Z"
            fill="#fff"
          />
          <path
            d="M76.27,112.29h-.74l-.48,2.25a.43.43,0,0,0,0,.31.38.38,0,0,0,.3.09h.32l-.09.47a2.66,2.66,0,0,1-.35,0,.84.84,0,0,1-.66-.23.79.79,0,0,1-.11-.68l.48-2.23h-.6l.11-.49h.59l.22-1h.56l-.22,1h.75Z"
            fill="#fff"
          />
          <path
            d="M81.84,111.88h-.59a1,1,0,0,0-.11-.68.9.9,0,0,0-.62-.32l-.38,1.77.11,0c1,.22,1.39.75,1.22,1.59a1.39,1.39,0,0,1-.63.92,2.33,2.33,0,0,1-1.31.33l-.14.71H78.9l.15-.73a1.73,1.73,0,0,1-1.14-.56,1.42,1.42,0,0,1-.27-1.17h.61a1,1,0,0,0,.91,1.19l.4-1.86-.1,0a1.79,1.79,0,0,1-1-.52,1.1,1.1,0,0,1-.23-1,1.35,1.35,0,0,1,.62-.9,2.31,2.31,0,0,1,1.26-.32l.15-.7h.49l-.16.73a1.38,1.38,0,0,1,1,.5A1.34,1.34,0,0,1,81.84,111.88Zm-3-.3a.69.69,0,0,0,.1.6,1.49,1.49,0,0,0,.71.36l.36-1.69C79.35,110.87,79,111.11,78.87,111.58Zm2,2.62a.67.67,0,0,0-.11-.62,1.49,1.49,0,0,0-.74-.37L79.64,115a1.67,1.67,0,0,0,.84-.22A.88.88,0,0,0,80.87,114.2Z"
            fill="#fff"
          />
          <path
            d="M85,110.43h.64l-.69,3.21h.67l-.11.53h-.67l-.27,1.24H84l.27-1.24h-2.5l.12-.57Zm-.13.82-2.36,2.39h1.85Z"
            fill="#fff"
          />
          <path
            d="M89.51,110.88a2.93,2.93,0,0,1,.19,2.21,3.61,3.61,0,0,1-.82,1.76,1.86,1.86,0,0,1-1.48.67,1.4,1.4,0,0,1-1-.36,1.27,1.27,0,0,1-.38-1h.59a.79.79,0,0,0,.9.82c.79,0,1.34-.64,1.63-1.92a1.63,1.63,0,0,1-1.33.6,1.38,1.38,0,0,1-1.15-.52,1.52,1.52,0,0,1-.22-1.34,1.89,1.89,0,0,1,.64-1.09,1.83,1.83,0,0,1,1.21-.41A1.45,1.45,0,0,1,89.51,110.88Zm-1.58,2.26a1.28,1.28,0,0,0,.81-.29,1.22,1.22,0,0,0,.43-.71,1.08,1.08,0,0,0-.13-.94,1,1,0,0,0-.81-.36,1.16,1.16,0,0,0-.78.29,1.3,1.3,0,0,0-.43.76,1.1,1.1,0,0,0,.15.9A.88.88,0,0,0,87.93,113.14Z"
            fill="#fff"
          />
          <path
            d="M184.58,115.52a2.16,2.16,0,0,1-1.46-.45,1.36,1.36,0,0,1-.39-1.3h.61c-.11.81.33,1.22,1.3,1.22a1.77,1.77,0,0,0,.9-.2.87.87,0,0,0,.42-.59.67.67,0,0,0-.14-.65,1.82,1.82,0,0,0-.86-.38l-.42-.09a1.87,1.87,0,0,1-1-.52,1.12,1.12,0,0,1-.22-1,1.35,1.35,0,0,1,.62-.9,2.28,2.28,0,0,1,1.27-.32,1.84,1.84,0,0,1,1.41.43,1.34,1.34,0,0,1,.27,1.13h-.6a.85.85,0,0,0-.17-.78,1.37,1.37,0,0,0-.95-.25c-.74,0-1.16.24-1.26.73a.69.69,0,0,0,.14.63,1.74,1.74,0,0,0,.83.37l.42.1c1,.22,1.39.75,1.22,1.59a1.42,1.42,0,0,1-.65.92A2.43,2.43,0,0,1,184.58,115.52Z"
            fill="#fff"
          />
          <path
            d="M189.12,112.29h-.74l-.48,2.25a.38.38,0,0,0,0,.31.37.37,0,0,0,.29.09h.33l-.09.47a2.66,2.66,0,0,1-.35,0,.84.84,0,0,1-.66-.23.79.79,0,0,1-.11-.68l.48-2.23h-.6l.11-.49h.59l.22-1h.56l-.22,1h.75Z"
            fill="#fff"
          />
          <path
            d="M192.33,113l-.36,1.68c0,.2,0,.3.16.3h.18l-.1.45a1.47,1.47,0,0,1-.31,0,.52.52,0,0,1-.38-.13.41.41,0,0,1-.13-.35,1.48,1.48,0,0,1-1.2.55,1.19,1.19,0,0,1-.92-.34.94.94,0,0,1-.18-.91c.11-.52.61-.83,1.49-.92l.57-.07c.39,0,.61-.18.67-.42a.47.47,0,0,0-.11-.47.93.93,0,0,0-.63-.17,1.18,1.18,0,0,0-.68.16,1,1,0,0,0-.33.55h-.55a1.57,1.57,0,0,1,.54-.91,1.73,1.73,0,0,1,1.07-.28Q192.58,111.72,192.33,113Zm-2,2a1.36,1.36,0,0,0,.79-.24,1.18,1.18,0,0,0,.46-.68l.13-.57a1.24,1.24,0,0,1-.52.15l-.54.07c-.58.08-.9.28-1,.61a.51.51,0,0,0,.1.49A.74.74,0,0,0,190.29,115Z"
            fill="#fff"
          />
          <path
            d="M195.14,111.75h.18l-.11.54h-.14a1.49,1.49,0,0,0-.93.28,1.22,1.22,0,0,0-.48.78l-.44,2.06h-.56l.77-3.61H194l-.11.56A1.63,1.63,0,0,1,195.14,111.75Z"
            fill="#fff"
          />
          <path
            d="M197.35,112.29h-.74l-.48,2.25a.42.42,0,0,0,0,.31c0,.06.14.09.29.09h.33l-.1.47a2.58,2.58,0,0,1-.35,0,.85.85,0,0,1-.66-.23.79.79,0,0,1-.11-.68l.48-2.23h-.59l.1-.49h.6l.21-1h.56l-.21,1h.74Z"
            fill="#fff"
          />
          <path
            d="M197.84,115.41h-.56l.77-3.61h.56Zm.91-4.16h-.6l.14-.68h.6Z"
            fill="#fff"
          />
          <path
            d="M201.09,111.72a1,1,0,0,1,.9.38,1.3,1.3,0,0,1,.11,1.08l-.48,2.23h-.56l.47-2.27c.14-.61-.06-.92-.58-.92a1.24,1.24,0,0,0-.82.31,1.39,1.39,0,0,0-.46.84l-.43,2h-.56l.77-3.61H200l-.11.48A1.63,1.63,0,0,1,201.09,111.72Z"
            fill="#fff"
          />
          <path
            d="M205.83,112.31l.11-.51h.53l-.74,3.47a2.13,2.13,0,0,1-.64,1.2,1.85,1.85,0,0,1-1.28.4,1.57,1.57,0,0,1-1.05-.32,1,1,0,0,1-.33-.84H203a.64.64,0,0,0,.21.52,1.11,1.11,0,0,0,.66.16,1.23,1.23,0,0,0,1.31-1.12l.05-.24a1.63,1.63,0,0,1-1.15.47,1.32,1.32,0,0,1-1.19-.58,1.85,1.85,0,0,1-.19-1.46,2.24,2.24,0,0,1,.73-1.29,2,2,0,0,1,1.28-.45A1.22,1.22,0,0,1,205.83,112.31ZM204.14,115a1.29,1.29,0,0,0,.89-.34,1.73,1.73,0,0,0,.54-.92l.05-.22a1.24,1.24,0,0,0-.15-1,.87.87,0,0,0-.77-.34,1.26,1.26,0,0,0-.91.36,1.78,1.78,0,0,0-.5,1,1.4,1.4,0,0,0,.09,1.06A.81.81,0,0,0,204.14,115Z"
            fill="#fff"
          />
          <path
            d="M211.18,113l-.36,1.68c0,.2,0,.3.16.3h.18l-.1.45a1.47,1.47,0,0,1-.31,0,.52.52,0,0,1-.38-.13.41.41,0,0,1-.13-.35,1.48,1.48,0,0,1-1.2.55,1.19,1.19,0,0,1-.92-.34.94.94,0,0,1-.18-.91c.11-.52.61-.83,1.49-.92l.57-.07c.39,0,.61-.18.67-.42a.47.47,0,0,0-.11-.47.93.93,0,0,0-.63-.17,1.18,1.18,0,0,0-.68.16,1,1,0,0,0-.33.55h-.55a1.57,1.57,0,0,1,.54-.91,1.73,1.73,0,0,1,1.07-.28C211,111.72,211.35,112.14,211.18,113Zm-2,2a1.36,1.36,0,0,0,.79-.24,1.18,1.18,0,0,0,.46-.68l.13-.57a1.24,1.24,0,0,1-.52.15l-.54.07c-.58.08-.9.28-1,.61a.51.51,0,0,0,.1.49A.74.74,0,0,0,209.14,115Z"
            fill="#fff"
          />
          <path
            d="M213.62,112.29h-.74l-.48,2.25a.38.38,0,0,0,0,.31.37.37,0,0,0,.29.09h.33l-.09.47a2.66,2.66,0,0,1-.35,0,.84.84,0,0,1-.66-.23.79.79,0,0,1-.11-.68l.48-2.23h-.6l.11-.49h.59l.22-1h.56l-.22,1h.75Z"
            fill="#fff"
          />
          <path
            d="M219.19,111.88h-.59a1,1,0,0,0-.11-.68.9.9,0,0,0-.62-.32l-.38,1.77.11,0c1,.22,1.39.75,1.22,1.59a1.39,1.39,0,0,1-.63.92,2.36,2.36,0,0,1-1.31.33l-.14.71h-.49l.15-.73a1.73,1.73,0,0,1-1.14-.56,1.42,1.42,0,0,1-.27-1.17h.61a1,1,0,0,0,.91,1.19l.4-1.86-.1,0a1.79,1.79,0,0,1-1-.52,1.1,1.1,0,0,1-.23-1,1.35,1.35,0,0,1,.62-.9,2.31,2.31,0,0,1,1.26-.32l.15-.7h.49l-.16.73a1.38,1.38,0,0,1,1,.5A1.34,1.34,0,0,1,219.19,111.88Zm-3-.3a.69.69,0,0,0,.1.6,1.49,1.49,0,0,0,.71.36l.36-1.69C216.7,110.87,216.31,111.11,216.22,111.58Zm2,2.62a.67.67,0,0,0-.11-.62,1.49,1.49,0,0,0-.74-.37L217,115a1.67,1.67,0,0,0,.84-.22A.88.88,0,0,0,218.22,114.2Z"
            fill="#fff"
          />
          <path
            d="M221.58,112.16a1.4,1.4,0,0,1,1.15.52A1.53,1.53,0,0,1,223,114a1.92,1.92,0,0,1-.64,1.1,1.82,1.82,0,0,1-1.21.4,1.44,1.44,0,0,1-1.24-.56,2.93,2.93,0,0,1-.19-2.21,3.69,3.69,0,0,1,.82-1.77,1.89,1.89,0,0,1,1.48-.66,1.48,1.48,0,0,1,1,.35,1.3,1.3,0,0,1,.37,1h-.59a.78.78,0,0,0-.9-.81c-.79,0-1.34.64-1.63,1.92A1.64,1.64,0,0,1,221.58,112.16Zm.78,1.78a1.11,1.11,0,0,0-.16-.9.87.87,0,0,0-.75-.34,1.23,1.23,0,0,0-.81.28,1.3,1.3,0,0,0-.44.71,1.11,1.11,0,0,0,.13,1,.94.94,0,0,0,.81.35,1.15,1.15,0,0,0,.79-.29A1.34,1.34,0,0,0,222.36,113.94Z"
            fill="#fff"
          />
          <path
            d="M223.87,111l.11-.53h3.2l-.1.46a8.76,8.76,0,0,0-2.75,4.52h-.61a8.56,8.56,0,0,1,1-2.41,9,9,0,0,1,1.71-2Z"
            fill="#fff"
          />
          <path
            d="M319.91,115.52a2.16,2.16,0,0,1-1.46-.45,1.36,1.36,0,0,1-.39-1.3h.61c-.11.81.32,1.22,1.3,1.22a1.77,1.77,0,0,0,.9-.2.9.9,0,0,0,.42-.59.67.67,0,0,0-.14-.65,1.82,1.82,0,0,0-.86-.38l-.42-.09a1.87,1.87,0,0,1-1-.52,1.09,1.09,0,0,1-.22-1,1.35,1.35,0,0,1,.62-.9,2.28,2.28,0,0,1,1.27-.32,1.86,1.86,0,0,1,1.41.43,1.34,1.34,0,0,1,.27,1.13h-.6a.85.85,0,0,0-.17-.78,1.39,1.39,0,0,0-.95-.25c-.74,0-1.16.24-1.26.73a.69.69,0,0,0,.14.63,1.74,1.74,0,0,0,.83.37l.42.1c1,.22,1.39.75,1.22,1.59a1.42,1.42,0,0,1-.65.92A2.43,2.43,0,0,1,319.91,115.52Z"
            fill="#fff"
          />
          <path
            d="M324.45,112.29h-.74l-.48,2.25a.38.38,0,0,0,0,.31.37.37,0,0,0,.29.09h.33l-.09.47a2.71,2.71,0,0,1-.36,0,.83.83,0,0,1-.65-.23.79.79,0,0,1-.11-.68l.48-2.23h-.6l.11-.49h.59l.22-1H324l-.22,1h.74Z"
            fill="#fff"
          />
          <path
            d="M327.66,113l-.36,1.68c0,.2,0,.3.16.3h.17l-.09.45a1.47,1.47,0,0,1-.31,0,.52.52,0,0,1-.38-.13.41.41,0,0,1-.13-.35,1.49,1.49,0,0,1-1.2.55,1.19,1.19,0,0,1-.92-.34.94.94,0,0,1-.18-.91c.11-.52.61-.83,1.49-.92l.57-.07q.58-.06.66-.42a.45.45,0,0,0-.1-.47.93.93,0,0,0-.63-.17,1.16,1.16,0,0,0-.68.16,1,1,0,0,0-.34.55h-.54a1.57,1.57,0,0,1,.54-.91,1.71,1.71,0,0,1,1.07-.28Q327.92,111.72,327.66,113Zm-2,2a1.36,1.36,0,0,0,.79-.24,1.18,1.18,0,0,0,.46-.68l.13-.57a1.24,1.24,0,0,1-.52.15l-.54.07c-.58.08-.9.28-1,.61a.53.53,0,0,0,.11.49A.74.74,0,0,0,325.62,115Z"
            fill="#fff"
          />
          <path
            d="M330.47,111.75h.18l-.11.54h-.14a1.49,1.49,0,0,0-.93.28,1.22,1.22,0,0,0-.48.78l-.44,2.06H328l.77-3.61h.53l-.12.56A1.65,1.65,0,0,1,330.47,111.75Z"
            fill="#fff"
          />
          <path
            d="M332.68,112.29h-.74l-.48,2.25a.42.42,0,0,0,0,.31c0,.06.14.09.29.09h.32l-.09.47a2.58,2.58,0,0,1-.35,0,.85.85,0,0,1-.66-.23.79.79,0,0,1-.11-.68l.48-2.23h-.6l.11-.49h.59l.22-1h.56l-.22,1h.75Z"
            fill="#fff"
          />
          <path
            d="M333.17,115.41h-.56l.77-3.61h.56Zm.91-4.16h-.6l.14-.68h.59Z"
            fill="#fff"
          />
          <path
            d="M336.42,111.72a1,1,0,0,1,.9.38,1.3,1.3,0,0,1,.11,1.08l-.49,2.23h-.56l.48-2.27c.14-.61-.06-.92-.58-.92a1.22,1.22,0,0,0-.82.31,1.39,1.39,0,0,0-.46.84l-.44,2H334l.77-3.61h.54l-.11.48A1.63,1.63,0,0,1,336.42,111.72Z"
            fill="#fff"
          />
          <path
            d="M341.16,112.31l.11-.51h.53l-.74,3.47a2.13,2.13,0,0,1-.64,1.2,1.85,1.85,0,0,1-1.28.4,1.55,1.55,0,0,1-1.05-.32,1,1,0,0,1-.33-.84h.56a.64.64,0,0,0,.21.52,1.11,1.11,0,0,0,.66.16,1.23,1.23,0,0,0,1.31-1.12l.05-.24a1.63,1.63,0,0,1-1.16.47,1.31,1.31,0,0,1-1.18-.58,1.85,1.85,0,0,1-.19-1.46,2.24,2.24,0,0,1,.73-1.29,2,2,0,0,1,1.27-.45A1.22,1.22,0,0,1,341.16,112.31ZM339.47,115a1.29,1.29,0,0,0,.89-.34,1.73,1.73,0,0,0,.54-.92l.05-.22a1.29,1.29,0,0,0-.15-1,.88.88,0,0,0-.78-.34,1.25,1.25,0,0,0-.9.36,1.78,1.78,0,0,0-.5,1,1.4,1.4,0,0,0,.09,1.06A.81.81,0,0,0,339.47,115Z"
            fill="#fff"
          />
          <path
            d="M346.51,113l-.36,1.68c0,.2,0,.3.16.3h.18l-.1.45a1.47,1.47,0,0,1-.31,0,.52.52,0,0,1-.38-.13.41.41,0,0,1-.13-.35,1.48,1.48,0,0,1-1.2.55,1.19,1.19,0,0,1-.92-.34.94.94,0,0,1-.18-.91c.11-.52.61-.83,1.49-.92l.57-.07c.39,0,.61-.18.67-.42a.47.47,0,0,0-.11-.47.93.93,0,0,0-.63-.17,1.18,1.18,0,0,0-.68.16,1,1,0,0,0-.33.55h-.55a1.57,1.57,0,0,1,.54-.91,1.73,1.73,0,0,1,1.07-.28C346.29,111.72,346.68,112.14,346.51,113Zm-2,2a1.36,1.36,0,0,0,.79-.24,1.18,1.18,0,0,0,.46-.68l.13-.57a1.24,1.24,0,0,1-.52.15l-.54.07c-.58.08-.9.28-1,.61a.51.51,0,0,0,.1.49A.74.74,0,0,0,344.47,115Z"
            fill="#fff"
          />
          <path
            d="M349,112.29h-.74l-.48,2.25a.38.38,0,0,0,0,.31.37.37,0,0,0,.29.09h.33l-.09.47a2.71,2.71,0,0,1-.36,0,.83.83,0,0,1-.65-.23.79.79,0,0,1-.11-.68l.48-2.23h-.6l.11-.49h.59l.22-1h.56l-.22,1h.74Z"
            fill="#fff"
          />
          <path
            d="M354.52,111.88h-.59a1,1,0,0,0-.11-.68.9.9,0,0,0-.62-.32l-.38,1.77.11,0c1,.22,1.39.75,1.22,1.59a1.39,1.39,0,0,1-.63.92,2.36,2.36,0,0,1-1.31.33l-.15.71h-.49l.16-.73a1.73,1.73,0,0,1-1.14-.56,1.42,1.42,0,0,1-.27-1.17h.61a1,1,0,0,0,.91,1.19l.4-1.86-.11,0a1.8,1.8,0,0,1-.95-.52,1.1,1.1,0,0,1-.23-1,1.35,1.35,0,0,1,.62-.9,2.31,2.31,0,0,1,1.26-.32l.14-.7h.49l-.15.73a1.38,1.38,0,0,1,1,.5A1.34,1.34,0,0,1,354.52,111.88Zm-3-.3a.69.69,0,0,0,.1.6,1.49,1.49,0,0,0,.71.36l.36-1.69C352,110.87,351.64,111.11,351.55,111.58Zm2,2.62a.7.7,0,0,0-.11-.62,1.49,1.49,0,0,0-.74-.37l-.38,1.78a1.67,1.67,0,0,0,.84-.22A.88.88,0,0,0,353.55,114.2Z"
            fill="#fff"
          />
          <path
            d="M354.94,111l.11-.53h3.2l-.1.46a8.9,8.9,0,0,0-2.75,4.52h-.61a8.57,8.57,0,0,1,1-2.41,8.91,8.91,0,0,1,1.7-2Z"
            fill="#fff"
          />
          <path
            d="M361.35,110.43l-.12.53h-2.1l-.53,1.49a1.55,1.55,0,0,1,.92-.29,1.38,1.38,0,0,1,1.16.52,1.61,1.61,0,0,1,.2,1.34,1.9,1.9,0,0,1-.61,1.07,1.76,1.76,0,0,1-1.23.43,1.61,1.61,0,0,1-1.17-.4,1.31,1.31,0,0,1-.39-1.09h.6a.87.87,0,0,0,.25.72,1.25,1.25,0,0,0,1.54,0,1.42,1.42,0,0,0,.42-.77,1.07,1.07,0,0,0-.13-.9.9.9,0,0,0-.76-.33,1.16,1.16,0,0,0-1.08.6h-.58l1-2.87Z"
            fill="#fff"
          />
          <path
            d="M451.25,115.52a2.18,2.18,0,0,1-1.46-.45,1.36,1.36,0,0,1-.39-1.3H450c-.11.81.33,1.22,1.3,1.22a1.83,1.83,0,0,0,.91-.2.89.89,0,0,0,.41-.59.67.67,0,0,0-.13-.65,1.87,1.87,0,0,0-.86-.38l-.42-.09a1.79,1.79,0,0,1-1-.52,1.1,1.1,0,0,1-.23-1,1.33,1.33,0,0,1,.63-.9,2.23,2.23,0,0,1,1.26-.32,1.84,1.84,0,0,1,1.41.43,1.34,1.34,0,0,1,.27,1.13H453a.88.88,0,0,0-.18-.78,1.37,1.37,0,0,0-.95-.25c-.74,0-1.15.24-1.25.73a.69.69,0,0,0,.13.63,1.79,1.79,0,0,0,.83.37l.42.1c1,.22,1.39.75,1.22,1.59a1.41,1.41,0,0,1-.64.92A2.46,2.46,0,0,1,451.25,115.52Z"
            fill="#fff"
          />
          <path
            d="M455.79,112.29h-.74l-.47,2.25a.38.38,0,0,0,0,.31.36.36,0,0,0,.29.09h.33l-.1.47a2.58,2.58,0,0,1-.35,0,.87.87,0,0,1-.66-.23.79.79,0,0,1-.1-.68l.47-2.23h-.59l.1-.49h.6l.21-1h.56l-.21,1h.74Z"
            fill="#fff"
          />
          <path
            d="M459,113l-.36,1.68c0,.2,0,.3.16.3H459l-.1.45a1.47,1.47,0,0,1-.31,0,.52.52,0,0,1-.38-.13.45.45,0,0,1-.13-.35,1.46,1.46,0,0,1-1.19.55,1.22,1.22,0,0,1-.93-.34,1,1,0,0,1-.18-.91c.12-.52.61-.83,1.49-.92l.57-.07c.39,0,.61-.18.67-.42a.45.45,0,0,0-.11-.47.93.93,0,0,0-.63-.17,1.14,1.14,0,0,0-.67.16.92.92,0,0,0-.34.55h-.55a1.57,1.57,0,0,1,.54-.91,1.75,1.75,0,0,1,1.07-.28Q459.27,111.72,459,113Zm-2,2a1.34,1.34,0,0,0,.79-.24,1.19,1.19,0,0,0,.47-.68l.12-.57a1.19,1.19,0,0,1-.52.15l-.53.07c-.58.08-.91.28-1,.61a.53.53,0,0,0,.1.49A.74.74,0,0,0,457,115Z"
            fill="#fff"
          />
          <path
            d="M461.81,111.75H462l-.12.54h-.14a1.49,1.49,0,0,0-.93.28,1.25,1.25,0,0,0-.47.78l-.44,2.06h-.56l.77-3.61h.53l-.12.56A1.63,1.63,0,0,1,461.81,111.75Z"
            fill="#fff"
          />
          <path
            d="M464,112.29h-.75l-.47,2.25a.38.38,0,0,0,0,.31.37.37,0,0,0,.29.09h.33l-.1.47a2.51,2.51,0,0,1-.35,0,.83.83,0,0,1-.65-.23.76.76,0,0,1-.11-.68l.47-2.23h-.59l.1-.49h.6l.22-1h.56l-.22,1h.74Z"
            fill="#fff"
          />
          <path
            d="M464.52,115.41H464l.77-3.61h.56Zm.9-4.16h-.6l.14-.68h.6Z"
            fill="#fff"
          />
          <path
            d="M467.76,111.72a1,1,0,0,1,.9.38,1.3,1.3,0,0,1,.11,1.08l-.48,2.23h-.56l.48-2.27c.13-.61-.06-.92-.59-.92a1.19,1.19,0,0,0-.81.31,1.4,1.4,0,0,0-.47.84l-.43,2h-.56l.77-3.61h.53l-.1.48A1.61,1.61,0,0,1,467.76,111.72Z"
            fill="#fff"
          />
          <path
            d="M472.5,112.31l.12-.51h.53l-.74,3.47a2.13,2.13,0,0,1-.64,1.2,1.9,1.9,0,0,1-1.29.4,1.54,1.54,0,0,1-1-.32.93.93,0,0,1-.33-.84h.56a.59.59,0,0,0,.21.52,1.07,1.07,0,0,0,.66.16,1.23,1.23,0,0,0,1.3-1.12l.05-.24a1.61,1.61,0,0,1-1.15.47,1.3,1.3,0,0,1-1.18-.58,1.81,1.81,0,0,1-.19-1.46,2.23,2.23,0,0,1,.72-1.29,2,2,0,0,1,1.28-.45A1.21,1.21,0,0,1,472.5,112.31ZM470.82,115a1.31,1.31,0,0,0,.89-.34,1.79,1.79,0,0,0,.53-.92l.05-.22a1.24,1.24,0,0,0-.14-1,.9.9,0,0,0-.78-.34,1.26,1.26,0,0,0-.91.36,1.85,1.85,0,0,0-.5,1,1.4,1.4,0,0,0,.09,1.06A.83.83,0,0,0,470.82,115Z"
            fill="#fff"
          />
          <path
            d="M477.85,113l-.36,1.68c0,.2,0,.3.16.3h.18l-.1.45a1.47,1.47,0,0,1-.31,0,.52.52,0,0,1-.38-.13.45.45,0,0,1-.13-.35,1.46,1.46,0,0,1-1.19.55,1.22,1.22,0,0,1-.93-.34,1,1,0,0,1-.18-.91c.12-.52.61-.83,1.49-.92l.57-.07c.39,0,.61-.18.67-.42a.45.45,0,0,0-.11-.47.93.93,0,0,0-.63-.17,1.14,1.14,0,0,0-.67.16.92.92,0,0,0-.34.55H475a1.62,1.62,0,0,1,.54-.91,1.75,1.75,0,0,1,1.07-.28Q478.12,111.72,477.85,113Zm-2,2a1.34,1.34,0,0,0,.79-.24,1.19,1.19,0,0,0,.47-.68l.12-.57a1.19,1.19,0,0,1-.52.15l-.53.07c-.58.08-.9.28-1,.61a.53.53,0,0,0,.1.49A.74.74,0,0,0,475.81,115Z"
            fill="#fff"
          />
          <path
            d="M480.29,112.29h-.74l-.47,2.25a.38.38,0,0,0,0,.31.36.36,0,0,0,.29.09h.33l-.1.47a2.58,2.58,0,0,1-.35,0,.87.87,0,0,1-.66-.23.79.79,0,0,1-.1-.68l.47-2.23h-.59l.1-.49h.6l.21-1h.56l-.21,1h.74Z"
            fill="#fff"
          />
          <path
            d="M485.87,111.88h-.6a1,1,0,0,0-.1-.68.92.92,0,0,0-.63-.32l-.37,1.77.11,0c1,.22,1.39.75,1.22,1.59a1.46,1.46,0,0,1-.63.92,2.38,2.38,0,0,1-1.31.33l-.15.71h-.49l.15-.73a1.68,1.68,0,0,1-1.13-.56,1.42,1.42,0,0,1-.27-1.17h.6a1,1,0,0,0,.91,1.19l.4-1.86-.1,0a1.79,1.79,0,0,1-1-.52,1.09,1.09,0,0,1-.22-1,1.34,1.34,0,0,1,.61-.9,2.31,2.31,0,0,1,1.26-.32l.15-.7h.49l-.15.73a1.4,1.4,0,0,1,1,.5A1.38,1.38,0,0,1,485.87,111.88Zm-3-.3a.66.66,0,0,0,.11.6,1.45,1.45,0,0,0,.7.36l.36-1.69C483.37,110.87,483,111.11,482.89,111.58Zm2,2.62a.67.67,0,0,0-.11-.62,1.44,1.44,0,0,0-.73-.37l-.38,1.78a1.67,1.67,0,0,0,.84-.22A.83.83,0,0,0,484.89,114.2Z"
            fill="#fff"
          />
          <path
            d="M489.92,111.8a1.39,1.39,0,0,1-1,1,1.29,1.29,0,0,1,.61.56,1.31,1.31,0,0,1,.08.86,1.5,1.5,0,0,1-.63,1,2.14,2.14,0,0,1-1.27.36,1.78,1.78,0,0,1-1.36-.47,1.31,1.31,0,0,1-.27-1.27,1.42,1.42,0,0,1,1.16-1.09,1.09,1.09,0,0,1-.48-1.22,1.31,1.31,0,0,1,.57-.86,2.07,2.07,0,0,1,1.17-.29,1.55,1.55,0,0,1,1.16.41A1.08,1.08,0,0,1,489.92,111.8Zm-.9,2.29a.84.84,0,0,0-.17-.8A1.11,1.11,0,0,0,488,113a1.67,1.67,0,0,0-.9.23.93.93,0,0,0-.44.64.85.85,0,0,0,.18.82,1.16,1.16,0,0,0,.88.3,1.43,1.43,0,0,0,.86-.24A1,1,0,0,0,489,114.09Zm.31-2.29a.71.71,0,0,0-.14-.69,1,1,0,0,0-.73-.26c-.67,0-1,.24-1.13.71a.72.72,0,0,0,.17.7,1.05,1.05,0,0,0,.75.26,1.33,1.33,0,0,0,.72-.19A.85.85,0,0,0,489.33,111.8Z"
            fill="#fff"
          />
          <path
            d="M493.66,110.88a2.87,2.87,0,0,1,.2,2.21,3.7,3.7,0,0,1-.82,1.76,1.88,1.88,0,0,1-1.48.67,1.42,1.42,0,0,1-1-.36,1.3,1.3,0,0,1-.37-1h.58a.79.79,0,0,0,.91.82c.79,0,1.33-.64,1.63-1.92a1.64,1.64,0,0,1-1.33.6,1.38,1.38,0,0,1-1.15-.52,1.52,1.52,0,0,1-.22-1.34,1.88,1.88,0,0,1,.63-1.09,1.85,1.85,0,0,1,1.21-.41A1.44,1.44,0,0,1,493.66,110.88Zm-1.58,2.26a1.29,1.29,0,0,0,1.25-1,1.08,1.08,0,0,0-.13-.94,1,1,0,0,0-.81-.36,1.18,1.18,0,0,0-.79.29,1.41,1.41,0,0,0-.43.76,1.06,1.06,0,0,0,.16.9A.88.88,0,0,0,492.08,113.14Z"
            fill="#fff"
          />
        </g>
      </svg>
      <svg
        id="Layer_1"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        className="w-full desktop:hidden"
        style={{ transform: "translate(0, -5%)" }}
        viewBox="0 0 342.72 443.31"
      >
        <defs>
          <filter
            id="filter"
            x="178.36"
            y="68.31"
            width="136"
            height="171"
            filterUnits="userSpaceOnUse"
          >
            <feOffset input="SourceAlpha" />
            <feGaussianBlur stdDeviation="1" result="blur" />
            <feFlood flood-color="#605f60" />
            <feComposite operator="in" in2="blur" />
            <feComposite in="SourceGraphic" />
          </filter>
          <linearGradient
            id="linear-gradient"
            x1="-320.25"
            y1="118.01"
            x2="-191.55"
            y2="118.01"
            gradientTransform="matrix(-1, 0, 0, 1, -9.54, 0)"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stop-color="#74bbe7" />
            <stop offset="1" stop-color="#74bbe7" />
          </linearGradient>
          <linearGradient
            id="linear-gradient-2"
            x1="182.01"
            y1="106.15"
            x2="310.71"
            y2="106.15"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stop-color="#868ec6" />
            <stop offset="1" stop-color="#74bbe7" />
          </linearGradient>
          <filter id="filter-2">
            <feOffset input="SourceAlpha" />
            <feGaussianBlur stdDeviation="1" result="blur-2" />
            <feFlood flood-color="#7a797a" />
            <feComposite operator="in" in2="blur-2" />
            <feComposite in="SourceGraphic" />
          </filter>
          <filter
            id="filter-3"
            x="178.36"
            y="252.31"
            width="136"
            height="191"
            filterUnits="userSpaceOnUse"
          >
            <feOffset input="SourceAlpha" />
            <feGaussianBlur stdDeviation="1" result="blur-3" />
            <feFlood flood-color="#605f60" />
            <feComposite operator="in" in2="blur-3" />
            <feComposite in="SourceGraphic" />
          </filter>
          <linearGradient
            id="linear-gradient-3"
            x1="-320.25"
            y1="301.59"
            x2="-191.55"
            y2="301.59"
            gradientTransform="matrix(-1, 0, 0, 1, -9.54, 0)"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stop-color="#565ba7" />
            <stop offset="1" stop-color="#8b5fa7" />
          </linearGradient>
          <linearGradient
            id="linear-gradient-4"
            x1="182.01"
            y1="289.73"
            x2="310.71"
            y2="289.73"
            gradientTransform="matrix(1, 0, 0, 1, 0, 0)"
            href="#linear-gradient-3"
          />
          <filter id="filter-4">
            <feOffset input="SourceAlpha" />
            <feGaussianBlur stdDeviation="1" result="blur-4" />
            <feFlood flood-color="#7a797a" />
            <feComposite operator="in" in2="blur-4" />
            <feComposite in="SourceGraphic" />
          </filter>
          <filter
            id="filter-5"
            x="28.36"
            y="228.31"
            width="136"
            height="215"
            filterUnits="userSpaceOnUse"
          >
            <feOffset input="SourceAlpha" />
            <feGaussianBlur stdDeviation="1" result="blur-5" />
            <feFlood flood-color="#605f60" />
            <feComposite operator="in" in2="blur-5" />
            <feComposite in="SourceGraphic" />
          </filter>
          <linearGradient
            id="linear-gradient-5"
            x1="-170.25"
            y1="278.32"
            x2="-41.55"
            y2="278.32"
            gradientTransform="matrix(-1, 0, 0, 1, -9.54, 0)"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stop-color="#f6902f" />
            <stop offset="1" stop-color="#ef5456" />
          </linearGradient>
          <linearGradient
            id="linear-gradient-6"
            x1="32.01"
            y1="266.46"
            x2="160.71"
            y2="266.46"
            gradientTransform="matrix(1, 0, 0, 1, 0, 0)"
            href="#linear-gradient-5"
          />
          <filter id="filter-6">
            <feOffset input="SourceAlpha" />
            <feGaussianBlur stdDeviation="1" result="blur-6" />
            <feFlood flood-color="#7a797a" />
            <feComposite operator="in" in2="blur-6" />
            <feComposite in="SourceGraphic" />
          </filter>
          <filter
            id="filter-7"
            x="28.36"
            y="68.31"
            width="136"
            height="156"
            filterUnits="userSpaceOnUse"
          >
            <feOffset input="SourceAlpha" />
            <feGaussianBlur stdDeviation="1" result="blur-7" />
            <feFlood flood-color="#605f60" />
            <feComposite operator="in" in2="blur-7" />
            <feComposite in="SourceGraphic" />
          </filter>
          <linearGradient
            id="linear-gradient-7"
            x1="-170.25"
            y1="118.01"
            x2="-41.55"
            y2="118.01"
            gradientTransform="matrix(-1, 0, 0, 1, -9.54, 0)"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stop-color="#acd049" />
            <stop offset="1" stop-color="#00b392" />
          </linearGradient>
          <linearGradient
            id="linear-gradient-8"
            x1="32.01"
            y1="106.15"
            x2="160.71"
            y2="106.15"
            gradientTransform="matrix(1, 0, 0, 1, 0, 0)"
            href="#linear-gradient-7"
          />
          <filter id="filter-8">
            <feOffset input="SourceAlpha" />
            <feGaussianBlur stdDeviation="1" result="blur-8" />
            <feFlood flood-color="#7a797a" />
            <feComposite operator="in" in2="blur-8" />
            <feComposite in="SourceGraphic" />
          </filter>
          <filter id="filter-9">
            <feOffset dx="2" dy="2" input="SourceAlpha" />
            <feGaussianBlur result="blur-9" />
            <feFlood flood-color="#74bbe7" />
            <feComposite operator="in" in2="blur-9" />
            <feComposite in="SourceGraphic" />
          </filter>
        </defs>
        <rect
          x="182.01"
          y="71.92"
          width="128.7"
          height="163.97"
          rx="16.02"
          fill="#fff"
          filter="url(#filter)"
        />
        <path
          d="M310.71,114.3V96.71a16,16,0,0,0-16-16H198a16,16,0,0,0-16,16v58.62s15.34-23.53,33.4-29.9c11.22-4,23.4-1.63,52.32,5.85S310.71,114.3,310.71,114.3Z"
          fill="url(#linear-gradient)"
        />
        <path
          d="M182,140.38V87.93a16,16,0,0,1,16-16h96.66a16,16,0,0,1,16,16v24.23s-12.3,11.71-30.64,11.71S229,111,210.73,119A80.54,80.54,0,0,0,182,140.38Z"
          fill="url(#linear-gradient-2)"
        />
        <g filter="url(#filter-2)">
          <path
            d="M208.22,93.53h2.35c.56,0,.84.21.84.62s-.27.67-.8.67h-1.44a1.75,1.75,0,0,0-.82.11c-.09.07-.13.29-.13.65v3c0,.73.08,1.16.24,1.28a4.65,4.65,0,0,0,1.79.18,8,8,0,0,0,2-.16,2.1,2.1,0,0,0,.92-.83q.28-.42.57-.42c.33,0,.49.15.49.46a1.49,1.49,0,0,1-.15.53l-.55,1.27a.6.6,0,0,1-.53.42l-.36,0H205.2c-.59,0-.88-.18-.88-.53s.16-.45.48-.51l.3-.06a.46.46,0,0,0,.36-.22,2.23,2.23,0,0,0,.08-.75V90.74a2.23,2.23,0,0,0-.08-.75.46.46,0,0,0-.36-.22l-.32-.06a.49.49,0,0,1-.48-.51c0-.36.29-.53.88-.53h7.31a.64.64,0,0,1,.67.38l.55,1.37a1,1,0,0,1,.09.36c0,.26-.17.4-.51.4s-.43-.19-.68-.57a1.45,1.45,0,0,0-.84-.61,4.79,4.79,0,0,0-1.06-.08h-1.33a1.61,1.61,0,0,0-1,.18c-.14.12-.2.45-.2,1Z"
            fill="#fff"
          />
          <path
            d="M220.09,93.53l.34,1.48a2.34,2.34,0,0,1,.06.29c0,.24-.14.36-.44.36s-.31-.11-.47-.34a2.08,2.08,0,0,0-1.71-1c-.61,0-.91.26-.91.78a1.08,1.08,0,0,0,.57.85l1,.52a6.18,6.18,0,0,1,1.78,1.27,2.37,2.37,0,0,1-.3,3.19,3,3,0,0,1-2,.67,6,6,0,0,1-1.45-.21l-.26,0a.37.37,0,0,0-.25.07l-.25.14a.37.37,0,0,1-.21,0c-.25,0-.42-.17-.49-.51l-.36-1.55a1,1,0,0,1-.06-.32.42.42,0,0,1,.48-.47c.16,0,.37.17.62.53a2.45,2.45,0,0,0,2,1.2,1.21,1.21,0,0,0,.77-.25.8.8,0,0,0,.29-.63.89.89,0,0,0-.27-.65,5.21,5.21,0,0,0-1.12-.73,7.9,7.9,0,0,1-1.9-1.34,2.08,2.08,0,0,1-.51-1.42,2.17,2.17,0,0,1,.7-1.65,2.61,2.61,0,0,1,1.83-.65,3.68,3.68,0,0,1,.75.1,4.39,4.39,0,0,0,.73.09.39.39,0,0,0,.25-.08l.21-.13a.85.85,0,0,1,.23,0C219.89,93.09,220,93.24,220.09,93.53Z"
            fill="#fff"
          />
          <path
            d="M227.07,93.53l.34,1.48a1.6,1.6,0,0,1,.05.29c0,.24-.14.36-.43.36s-.31-.11-.48-.34a2.08,2.08,0,0,0-1.71-1c-.61,0-.91.26-.91.78a1.08,1.08,0,0,0,.57.85l1,.52a6.41,6.41,0,0,1,1.79,1.27,2.21,2.21,0,0,1,.47,1.44,2.17,2.17,0,0,1-.78,1.75,3,3,0,0,1-2,.67,6,6,0,0,1-1.45-.21l-.26,0a.39.39,0,0,0-.25.07l-.25.14a.31.31,0,0,1-.2,0c-.26,0-.42-.17-.5-.51l-.36-1.55a1,1,0,0,1-.06-.32.42.42,0,0,1,.48-.47c.16,0,.37.17.63.53a2.43,2.43,0,0,0,2,1.2,1.19,1.19,0,0,0,.77-.25.77.77,0,0,0,.29-.63.89.89,0,0,0-.27-.65,4.83,4.83,0,0,0-1.11-.73,7.5,7.5,0,0,1-1.9-1.34,2.09,2.09,0,0,1-.52-1.42,2.14,2.14,0,0,1,.71-1.65,2.58,2.58,0,0,1,1.82-.65,3.68,3.68,0,0,1,.75.1,4.39,4.39,0,0,0,.73.09.39.39,0,0,0,.25-.08l.21-.13a.93.93,0,0,1,.23,0C226.86,93.09,227,93.24,227.07,93.53Z"
            fill="#fff"
          />
          <path
            d="M235.33,96.65l-2.6,1.5a1.28,1.28,0,0,1-.55.21.54.54,0,0,1-.42-.18.59.59,0,0,1-.17-.43.49.49,0,0,1,.24-.44l1.2-.78c.48-.31.72-.64.72-1a1.21,1.21,0,0,0-.3-.83.94.94,0,0,0-.74-.34,1.37,1.37,0,0,0-1.21.81,4.34,4.34,0,0,0-.46,2.15,3.6,3.6,0,0,0,.54,2.11,1.71,1.71,0,0,0,1.48.76,4,4,0,0,0,1.93-.65,1.27,1.27,0,0,1,.53-.21c.33,0,.49.19.49.55s-.34.75-1,1.09a5,5,0,0,1-2.23.51,3.91,3.91,0,0,1-4.14-4.13,4.14,4.14,0,0,1,1.18-3,4,4,0,0,1,3-1.2,3.66,3.66,0,0,1,2.28.73,2.16,2.16,0,0,1,.95,1.76A1.15,1.15,0,0,1,235.33,96.65Z"
            fill="#fff"
          />
          <path
            d="M240.33,94.14a3.27,3.27,0,0,1,2.43-1,2.57,2.57,0,0,1,2,.75,3,3,0,0,1,.55,2v3.47a1.64,1.64,0,0,0,.1.69.75.75,0,0,0,.62.34c.32,0,.48.22.48.49s-.17.48-.51.48h-3.54c-.39,0-.58-.15-.58-.44a.43.43,0,0,1,.17-.41,1.78,1.78,0,0,1,.45-.16c.32-.07.48-.34.48-.8V96c0-1-.34-1.45-1-1.45a1.38,1.38,0,0,0-1.21.67,3.67,3.67,0,0,0-.36,1.9V99.4a2.33,2.33,0,0,0,.06.65.63.63,0,0,0,.5.32c.36.08.55.24.55.49a.42.42,0,0,1-.31.44,2.25,2.25,0,0,1-.59,0h-3.17c-.39,0-.59-.16-.59-.48s.22-.48.65-.53a.6.6,0,0,0,.53-.4,4.22,4.22,0,0,0,0-.71V96.07a2.19,2.19,0,0,0-.06-.65.57.57,0,0,0-.55-.28c-.41,0-.61-.2-.61-.52s.17-.41.51-.55l2.28-.9a1,1,0,0,1,.36-.08c.22,0,.33.17.33.5Z"
            fill="#fff"
          />
          <path
            d="M250.76,93.36h1.14q.57,0,.57.51t-.9.54a1.22,1.22,0,0,0-.68.11c-.09.07-.13.29-.13.65v3.89a3.09,3.09,0,0,0,.07.86.51.51,0,0,0,.52.3,1,1,0,0,0,.4-.08,2.81,2.81,0,0,1,.43-.11c.35,0,.52.2.52.59s-.22.48-.66.69a3.37,3.37,0,0,1-1.47.32,2,2,0,0,1-1.9-.92,3.35,3.35,0,0,1-.25-1.54V95c0-.26,0-.43-.16-.49a1.31,1.31,0,0,0-.58-.09c-.43,0-.65-.19-.65-.52s.26-.51.78-.51h.61V91.25a.81.81,0,0,1,.49-.74l1.24-.61a.74.74,0,0,1,.3-.11c.21,0,.31.18.31.55Z"
            fill="#fff"
          />
          <path
            d="M256.9,93.74v5A6.55,6.55,0,0,0,257,100a.43.43,0,0,0,.4.3l.23,0c.34.05.51.22.51.5s-.27.49-.8.49h-3.13c-.53,0-.8-.16-.8-.49s.18-.42.53-.5l.19,0a.49.49,0,0,0,.42-.32,6.06,6.06,0,0,0,.08-1.29V96.13a3.09,3.09,0,0,0,0-.62.58.58,0,0,0-.51-.34c-.46-.08-.69-.25-.69-.52s.2-.42.61-.59l2-.8a1.93,1.93,0,0,1,.48-.13C256.76,93.13,256.9,93.33,256.9,93.74Zm-2.68-3.32a1.39,1.39,0,0,1,.42-1,1.4,1.4,0,0,1,1-.43,1.37,1.37,0,0,1,1,.42,1.4,1.4,0,0,1,.42,1,1.41,1.41,0,0,1-.42,1,1.35,1.35,0,0,1-1,.43,1.42,1.42,0,0,1-1-.44A1.39,1.39,0,0,1,254.22,90.42Z"
            fill="#fff"
          />
          <path
            d="M263.74,100.66a2.6,2.6,0,0,1-2.11.91,2.79,2.79,0,0,1-1.94-.67,2.15,2.15,0,0,1-.76-1.71A2.57,2.57,0,0,1,260.24,97a3.75,3.75,0,0,1,2.09-.68c.42,0,.63.18.63.55a.65.65,0,0,1-.42.59l-.34.17a1.49,1.49,0,0,0-1,1.4,1.33,1.33,0,0,0,.28.88.91.91,0,0,0,.74.34,1,1,0,0,0,1-.72,6,6,0,0,0,.15-1.6v-1.1a4.36,4.36,0,0,0-.23-1.88,1.16,1.16,0,0,0-1-.4,3.06,3.06,0,0,0-1.16.27,5.28,5.28,0,0,0-.69.28.94.94,0,0,1-.38.12c-.38,0-.57-.21-.57-.61s.21-.53.61-.74a5.71,5.71,0,0,1,2.79-.69,2.94,2.94,0,0,1,2.67,1.09,3,3,0,0,1,.32,1.59v3.5a2,2,0,0,0,.09.81,1.15,1.15,0,0,0,.65.23.48.48,0,0,1,.46.5c0,.33-.26.49-.78.49h-2.13a.28.28,0,0,1-.32-.32Z"
            fill="#fff"
          />
          <path
            d="M271.28,89V98.7a6.55,6.55,0,0,0,.07,1.31.44.44,0,0,0,.4.3l.21,0c.36.07.53.23.53.5s-.26.49-.79.49h-3.18c-.53,0-.8-.16-.8-.49s.18-.43.54-.5l.21,0a.42.42,0,0,0,.39-.3,5.57,5.57,0,0,0,.08-1.31V91.18a.58.58,0,0,0-.6-.63c-.4,0-.6-.2-.6-.5a.63.63,0,0,1,.46-.57l2.26-1a1.1,1.1,0,0,1,.38-.12C271.13,88.38,271.28,88.6,271.28,89Z"
            fill="#fff"
          />
          <path
            d="M285.6,94.22h-2.66V96.4a.75.75,0,0,0,.11.33.54.54,0,0,1,.08.34.67.67,0,0,1-.29.47.9.9,0,0,1-.56.19.69.69,0,0,1-.25,0,1.36,1.36,0,0,1-.45-.35l-.56-.58a5.68,5.68,0,0,1-.81-.78.35.35,0,0,1,0-.29.75.75,0,0,0,.07-.28V94.29l-1.25.1a.4.4,0,0,0-.22.07.43.43,0,0,1-.2.08.28.28,0,0,1-.15,0A5.18,5.18,0,0,1,277,92.68a.73.73,0,0,1-.1-.61.62.62,0,0,1,.51-.38l.78-.08c1-.07,1.67-.11,2-.11V88c0-.1.1-.22.32-.37a1.39,1.39,0,0,1,.63-.25c.16,0,.48.24.95.79a4.7,4.7,0,0,1,.87,1.37,1.84,1.84,0,0,1,.08.48c0,.09,0,.27-.07.54a5.67,5.67,0,0,0-.07.82h1.33a4,4,0,0,0,.86-.11,2.54,2.54,0,0,1,.55-.06q.29.07.57.93a4.65,4.65,0,0,1,.28,1.47.55.55,0,0,1-.26.55A2,2,0,0,1,285.6,94.22Z"
            fill="#fff"
          />
        </g>
        <rect
          x="182.01"
          y="255.5"
          width="128.7"
          height="184.27"
          rx="16.02"
          fill="#fff"
          filter="url(#filter-3)"
        />
        <path
          d="M310.71,297.88V280.29a16,16,0,0,0-16-16H198a16,16,0,0,0-16,16v58.62s15.34-23.53,33.4-29.9c11.22-4,23.4-1.63,52.32,5.85S310.71,297.88,310.71,297.88Z"
          fill="url(#linear-gradient-3)"
        />
        <path
          d="M182,324V271.52a16,16,0,0,1,16-16h96.66a16,16,0,0,1,16,16v24.23s-12.3,11.7-30.64,11.7-51-12.87-69.34-4.84A80.45,80.45,0,0,0,182,324Z"
          fill="url(#linear-gradient-4)"
        />
        <g filter="url(#filter-4)">
          <path
            d="M200,279.05l-1.86,5.26c-.22.61-.57.92-1,.92s-.84-.32-1.08-1l-3.7-10a2.25,2.25,0,0,0-.38-.75,1.46,1.46,0,0,0-.63-.25c-.36-.08-.53-.25-.53-.52s.21-.49.64-.49h3.92c.43,0,.64.17.64.51s-.23.46-.68.5-.59.19-.59.45a3,3,0,0,0,.21.88l2.56,7.14,1.77-4.92-.87-2.62a2.25,2.25,0,0,0-.31-.66,1.24,1.24,0,0,0-.55-.24c-.37-.08-.55-.25-.55-.49s.22-.55.67-.55h3.74q.63,0,.63.51c0,.29-.21.47-.63.52s-.63.22-.63.51a2.92,2.92,0,0,0,.19.8l2.28,7,2.22-7.28a1.52,1.52,0,0,0,.08-.43c0-.28-.21-.45-.64-.51s-.63-.23-.63-.54.24-.53.72-.53h2.89c.4,0,.61.17.61.51s-.23.49-.67.56a.81.81,0,0,0-.51.22,2.42,2.42,0,0,0-.33.73l-3,9.76a2.47,2.47,0,0,1-.44,1,1,1,0,0,1-1.3,0,1.74,1.74,0,0,1-.42-.78Z"
            fill="#fff"
          />
          <path
            d="M212.65,277.51a3.4,3.4,0,0,1,2.34-.83,2.6,2.6,0,0,1,2.19.87,2.79,2.79,0,0,1,.39,1.64v3.09a7.34,7.34,0,0,0,.07,1.3.49.49,0,0,0,.41.32l.19,0c.35.08.53.25.53.5s-.26.49-.8.49h-3.13c-.46,0-.69-.16-.69-.47a.47.47,0,0,1,.42-.52l.29-.06c.25-.05.38-.38.38-1v-3.06a3,3,0,0,0-.17-1.24.92.92,0,0,0-.86-.42,1.45,1.45,0,0,0-1.33.84,5.12,5.12,0,0,0-.23,2v1.92a2.48,2.48,0,0,0,.08.8c.05.12.19.2.42.25a1.47,1.47,0,0,1,.43.14.43.43,0,0,1,.16.4c0,.3-.25.45-.74.45h-3.1c-.53,0-.8-.16-.8-.49s.18-.43.53-.5l.21,0a.45.45,0,0,0,.4-.31,6.23,6.23,0,0,0,.08-1.31v-7.09a1.93,1.93,0,0,0-.11-.83c-.07-.12-.23-.18-.5-.21s-.59-.21-.59-.51.16-.41.48-.55l2.27-1a1,1,0,0,1,.35-.1c.29,0,.43.26.43.78Z"
            fill="#fff"
          />
          <path
            d="M219.55,281a4.15,4.15,0,0,1,1.17-3.09,4.83,4.83,0,0,1,6.27,0,4.53,4.53,0,0,1,0,6.13,4.35,4.35,0,0,1-3.17,1.17,4.21,4.21,0,0,1-3.09-1.16A4.11,4.11,0,0,1,219.55,281Zm2.49,0a4.19,4.19,0,0,0,.48,2.19,1.45,1.45,0,0,0,1.33.77q1.8,0,1.8-2.94c0-2-.61-3-1.82-3S222,279,222,281Z"
            fill="#fff"
          />
          <path
            d="M232.6,272.61v9.67a6.23,6.23,0,0,0,.08,1.31.45.45,0,0,0,.4.31l.21,0c.35.07.53.23.53.5s-.27.49-.8.49h-3.17c-.53,0-.8-.16-.8-.49s.18-.43.53-.5l.21,0a.45.45,0,0,0,.4-.31,6.23,6.23,0,0,0,.08-1.31v-7.52a.58.58,0,0,0-.6-.63c-.4,0-.6-.2-.6-.49a.6.6,0,0,1,.46-.57l2.26-1a1,1,0,0,1,.38-.12C232.46,272,232.6,272.18,232.6,272.61Z"
            fill="#fff"
          />
          <path
            d="M241.36,280.23l-2.6,1.5a1.28,1.28,0,0,1-.55.21.54.54,0,0,1-.42-.18.61.61,0,0,1-.17-.43.49.49,0,0,1,.25-.44l1.19-.77c.48-.32.73-.64.73-1a1.24,1.24,0,0,0-.31-.84.94.94,0,0,0-.74-.34,1.38,1.38,0,0,0-1.21.82,4.3,4.3,0,0,0-.46,2.14,3.6,3.6,0,0,0,.54,2.11,1.71,1.71,0,0,0,1.48.76,4,4,0,0,0,1.93-.64,1.27,1.27,0,0,1,.53-.21c.33,0,.5.18.5.54s-.35.76-1.05,1.1a5.13,5.13,0,0,1-2.23.5,3.89,3.89,0,0,1-4.13-4.13,4.15,4.15,0,0,1,1.17-3,4,4,0,0,1,3-1.2,3.65,3.65,0,0,1,2.28.74,2.15,2.15,0,0,1,1,1.75A1.17,1.17,0,0,1,241.36,280.23Z"
            fill="#fff"
          />
          <path
            d="M256.81,272.42l.49,1.82a2,2,0,0,1,.08.48c0,.33-.18.49-.54.49a.87.87,0,0,1-.63-.38,3.88,3.88,0,0,0-3-1.57,3.06,3.06,0,0,0-2.66,1.42,6.82,6.82,0,0,0-1,3.9q0,3.23,1.69,4.54a3.34,3.34,0,0,0,2.09.74c.81,0,1.31-.22,1.5-.65a2.18,2.18,0,0,0,.12-.89v-1.18a3.12,3.12,0,0,0,0-.61.45.45,0,0,0-.4-.36l-.46-.05a.48.48,0,0,1-.49-.52c0-.35.3-.53.89-.53h3.21c.58,0,.88.18.88.53s-.16.45-.48.52l-.3.05c-.19,0-.31.11-.35.21a2.32,2.32,0,0,0-.07.76v.72a3.45,3.45,0,0,0,0,.71.29.29,0,0,0,.23.21l.24.07a.37.37,0,0,1,.27.4c0,.47-.56.91-1.66,1.32a10,10,0,0,1-3.49.62,6,6,0,0,1-4.53-1.8,6.53,6.53,0,0,1-1.72-4.74,6.83,6.83,0,0,1,1.7-4.84,5.76,5.76,0,0,1,4.46-1.85,8.44,8.44,0,0,1,2.32.33,1.29,1.29,0,0,0,.25,0,.46.46,0,0,0,.34-.22.52.52,0,0,1,.42-.22C256.52,271.89,256.71,272.07,256.81,272.42Z"
            fill="#fff"
          />
          <path
            d="M262.85,278.23a3.38,3.38,0,0,1,.87-1.2,1.81,1.81,0,0,1,1.17-.35,2,2,0,0,1,1,.23.69.69,0,0,1,.41.56,2.75,2.75,0,0,1-.53,1.09c-.17.24-.32.36-.46.36a.76.76,0,0,1-.41-.19,1.26,1.26,0,0,0-.69-.27c-.53,0-.9.34-1.12,1a6.85,6.85,0,0,0-.21,2v.82a5.76,5.76,0,0,0,.09,1.36c.05.17.22.26.48.28h.29c.39,0,.59.19.59.5s-.27.49-.8.49h-3.31c-.53,0-.8-.16-.8-.49s.18-.44.54-.5l.22,0a.45.45,0,0,0,.4-.3,6.43,6.43,0,0,0,.08-1.32v-2.62a1.76,1.76,0,0,0-.08-.66c-.07-.18-.26-.28-.55-.29s-.57-.19-.57-.5.16-.42.48-.55l2.33-.9a.87.87,0,0,1,.27-.07c.23,0,.34.13.34.41Z"
            fill="#fff"
          />
          <path
            d="M266.59,281a4.15,4.15,0,0,1,1.17-3.09,4.33,4.33,0,0,1,3.15-1.15,4.28,4.28,0,0,1,3.12,1.16,4.53,4.53,0,0,1,0,6.13,4.32,4.32,0,0,1-3.17,1.17,4.21,4.21,0,0,1-3.09-1.16A4.11,4.11,0,0,1,266.59,281Zm2.49,0a4.19,4.19,0,0,0,.48,2.19,1.47,1.47,0,0,0,1.33.77q1.8,0,1.8-2.94c0-2-.61-3-1.82-3S269.08,279,269.08,281Z"
            fill="#fff"
          />
          <path
            d="M276.11,281a4.15,4.15,0,0,1,1.17-3.09,4.33,4.33,0,0,1,3.15-1.15,4.28,4.28,0,0,1,3.12,1.16,4.53,4.53,0,0,1,0,6.13,4.34,4.34,0,0,1-3.17,1.17,4.21,4.21,0,0,1-3.09-1.16A4.11,4.11,0,0,1,276.11,281Zm2.49,0a4.19,4.19,0,0,0,.48,2.19,1.47,1.47,0,0,0,1.33.77q1.8,0,1.8-2.94c0-2-.61-3-1.82-3S278.6,279,278.6,281Z"
            fill="#fff"
          />
          <path
            d="M289.07,277.7a3.32,3.32,0,0,1,2.4-1,2.52,2.52,0,0,1,2.23,1.12,3.29,3.29,0,0,1,2.49-1.12,2.5,2.5,0,0,1,2.06.83,2.61,2.61,0,0,1,.43,1.64v3.64a2.12,2.12,0,0,0,.11.89c.07.11.27.19.62.25s.47.22.47.5-.25.49-.74.49l-3.21,0c-.39,0-.59-.19-.59-.49s.09-.38.27-.43l.34-.1a.44.44,0,0,0,.33-.26,2.4,2.4,0,0,0,.07-.76v-3.05a3.38,3.38,0,0,0-.12-1.12.81.81,0,0,0-.84-.51,1.31,1.31,0,0,0-1.21.81,4.5,4.5,0,0,0-.25,1.8v2.09a2.21,2.21,0,0,0,.09.8q.08.18.42.24c.35.06.52.23.52.51a.43.43,0,0,1-.49.49h-3a1.65,1.65,0,0,1-.75-.11.44.44,0,0,1-.16-.36c0-.29.17-.47.5-.53a.56.56,0,0,0,.44-.27,2.5,2.5,0,0,0,.09-.86v-3.11c0-1-.32-1.5-1-1.5a1.35,1.35,0,0,0-1.39,1.18,8.66,8.66,0,0,0-.11,1.78v2.11a.59.59,0,0,0,.5.65c.34.07.5.24.5.51s-.27.51-.81.51h-2.85c-.59,0-.88-.16-.88-.47s.17-.47.52-.52.54-.14.61-.27a2.89,2.89,0,0,0,.11-1v-3.22c0-.43-.21-.66-.56-.69s-.66-.19-.66-.51.15-.42.46-.53l2.37-.94a.65.65,0,0,1,.29-.07c.21,0,.32.16.32.48Z"
            fill="#fff"
          />
        </g>
        <rect
          x="32.01"
          y="232.23"
          width="128.7"
          height="207.54"
          rx="16.02"
          fill="#fff"
          filter="url(#filter-5)"
        />
        <path
          d="M160.71,274.62V257a16,16,0,0,0-16-16H48a16,16,0,0,0-16,16v58.62s15.34-23.53,33.4-29.9c11.22-4,23.4-1.62,52.32,5.85S160.71,274.62,160.71,274.62Z"
          fill="url(#linear-gradient-5)"
        />
        <path
          d="M32,300.69V248.25a16,16,0,0,1,16-16h96.66a16,16,0,0,1,16,16v24.23s-12.3,11.7-30.64,11.7-51-12.87-69.34-4.84A80.58,80.58,0,0,0,32,300.69Z"
          fill="url(#linear-gradient-6)"
        />
        <g filter="url(#filter-6)">
          <path
            d="M66.16,249.25l.46,2.05a1.57,1.57,0,0,1,0,.27q0,.45-.57.45a.62.62,0,0,1-.49-.34,3.17,3.17,0,0,0-2.7-1.69,1.55,1.55,0,0,0-1.06.34,1.16,1.16,0,0,0-.4.93,1.86,1.86,0,0,0,.7,1.37c.09.09.53.41,1.33,1a18.24,18.24,0,0,1,2.6,2.09,3.59,3.59,0,0,1,1,2.6A3.42,3.42,0,0,1,66,261a4.33,4.33,0,0,1-3,1,6.34,6.34,0,0,1-2-.29,2.11,2.11,0,0,0-.42-.11.63.63,0,0,0-.41.2.66.66,0,0,1-.46.2c-.26,0-.46-.23-.57-.68l-.47-1.89a1.54,1.54,0,0,1-.06-.38c0-.39.21-.59.63-.59a.53.53,0,0,1,.45.25l.27.38a3.28,3.28,0,0,0,2.81,1.5,2.25,2.25,0,0,0,1.49-.46,1.56,1.56,0,0,0,.56-1.25,2,2,0,0,0-.54-1.33,11.63,11.63,0,0,0-2.06-1.7c-.93-.64-1.53-1.09-1.81-1.33a3.59,3.59,0,0,1-1.46-2.73,2.82,2.82,0,0,1,1-2.24,3.83,3.83,0,0,1,2.58-.83,8.18,8.18,0,0,1,2.05.3l.24,0a.74.74,0,0,0,.44-.2.77.77,0,0,1,.44-.2C65.89,248.64,66.07,248.84,66.16,249.25Z"
            fill="#fff"
          />
          <path
            d="M71.61,254.06V259a6.43,6.43,0,0,0,.08,1.32.45.45,0,0,0,.4.3l.23,0c.34.05.51.21.51.49s-.27.5-.8.5H68.9c-.54,0-.8-.17-.8-.5s.18-.42.53-.49l.19,0a.5.5,0,0,0,.42-.32,6.56,6.56,0,0,0,.07-1.3v-2.56a3.72,3.72,0,0,0,0-.63.6.6,0,0,0-.52-.34c-.45-.08-.68-.25-.68-.51s.2-.43.61-.59l2-.8a1.56,1.56,0,0,1,.48-.13C71.47,253.45,71.61,253.65,71.61,254.06Zm-2.68-3.33a1.47,1.47,0,0,1,2.49-1,1.41,1.41,0,0,1,.42,1,1.43,1.43,0,0,1-.42,1,1.45,1.45,0,0,1-2,0A1.44,1.44,0,0,1,68.93,250.73Z"
            fill="#fff"
          />
          <path
            d="M79.92,254.3A2.07,2.07,0,0,1,81,256.18a2.34,2.34,0,0,1-2.3,2.3c-.65,0-1-.19-1-.59,0-.14.1-.26.29-.38a1.52,1.52,0,0,0,.85-1.38,1.37,1.37,0,0,0-.43-1,1.57,1.57,0,0,0-1.11-.4,1.48,1.48,0,0,0-1.56,1.54,1.83,1.83,0,0,0,.47,1.25,11.8,11.8,0,0,0,2,1.51,13,13,0,0,1,2,1.48,2.91,2.91,0,0,1,.9,2.15A2.8,2.8,0,0,1,80,264.86a4.24,4.24,0,0,1-2.74.86,4.45,4.45,0,0,1-2.63-.7,2.15,2.15,0,0,1-1-1.84,1.75,1.75,0,0,1,1.81-1.81c.67,0,1,.2,1,.59a.42.42,0,0,1-.32.39c-.46.17-.69.44-.69.8a1.13,1.13,0,0,0,.49.92,2.13,2.13,0,0,0,1.28.36,1.92,1.92,0,0,0,1.25-.41A1.32,1.32,0,0,0,79,263a1.8,1.8,0,0,0-.71-1.33,14.8,14.8,0,0,0-1.54-1.12,13.27,13.27,0,0,1-2.11-1.66,3.29,3.29,0,0,1-1-2.26,2.82,2.82,0,0,1,1-2.26,4,4,0,0,1,2.65-.86,5.55,5.55,0,0,1,1.26.15.73.73,0,0,0,.23,0c.2,0,.32-.16.38-.46.08-.49.32-.74.72-.74a.71.71,0,0,1,.81.8A1,1,0,0,1,79.92,254.3Z"
            fill="#fff"
          />
          <path
            d="M85.44,254.45a3.34,3.34,0,0,1,2.44-1,2.63,2.63,0,0,1,2,.74,3,3,0,0,1,.55,2v3.48a1.47,1.47,0,0,0,.1.68.75.75,0,0,0,.61.35c.33.05.49.21.49.49s-.17.48-.51.48H87.59c-.39,0-.59-.15-.59-.44a.49.49,0,0,1,.17-.42,1.66,1.66,0,0,1,.46-.15c.32-.08.47-.34.47-.8v-3.5c0-1-.34-1.44-1-1.44a1.36,1.36,0,0,0-1.21.67,3.64,3.64,0,0,0-.36,1.9v2.24a2.24,2.24,0,0,0,.06.64.62.62,0,0,0,.5.33c.36.07.54.24.54.49a.42.42,0,0,1-.3.44,2.94,2.94,0,0,1-.59,0H82.56c-.4,0-.59-.16-.59-.48s.21-.49.64-.54a.59.59,0,0,0,.54-.4,4.58,4.58,0,0,0,0-.7v-3.15a2.79,2.79,0,0,0-.05-.65.59.59,0,0,0-.55-.29c-.41,0-.61-.2-.61-.51s.17-.42.51-.56l2.28-.89a.84.84,0,0,1,.36-.08c.22,0,.32.16.32.49Z"
            fill="#fff"
          />
          <path
            d="M97.19,261a2.57,2.57,0,0,1-2.11.91,2.85,2.85,0,0,1-1.94-.66,2.16,2.16,0,0,1-.76-1.71,2.58,2.58,0,0,1,1.31-2.24,3.77,3.77,0,0,1,2.09-.69c.42,0,.63.19.63.55a.64.64,0,0,1-.42.59l-.34.17a1.51,1.51,0,0,0-1,1.41,1.3,1.3,0,0,0,.28.87.92.92,0,0,0,.74.35,1,1,0,0,0,1-.73,5.87,5.87,0,0,0,.15-1.59v-1.1a4.29,4.29,0,0,0-.23-1.89,1.18,1.18,0,0,0-1-.39,3,3,0,0,0-1.16.26,5.44,5.44,0,0,0-.68.29,1.19,1.19,0,0,1-.38.11c-.38,0-.57-.2-.57-.61s.2-.52.6-.74a5.83,5.83,0,0,1,2.8-.68,3,3,0,0,1,2.66,1.08,3.07,3.07,0,0,1,.32,1.6v3.49a2,2,0,0,0,.09.82c.07.09.28.16.65.23a.47.47,0,0,1,.46.49c0,.33-.26.5-.78.5H97.51c-.22,0-.32-.11-.32-.33Z"
            fill="#fff"
          />
          <path
            d="M104.67,253.68h1.14q.57,0,.57.51c0,.35-.3.53-.89.53a1.35,1.35,0,0,0-.69.11,1.05,1.05,0,0,0-.13.65v3.9a2.88,2.88,0,0,0,.08.85.51.51,0,0,0,.51.3,1,1,0,0,0,.4-.07,2.27,2.27,0,0,1,.44-.12c.34,0,.51.2.51.59s-.22.49-.66.7a3.31,3.31,0,0,1-1.47.31,2,2,0,0,1-1.9-.91,3.58,3.58,0,0,1-.24-1.54v-4.2c0-.27-.06-.43-.17-.49a1.13,1.13,0,0,0-.58-.1c-.43,0-.64-.18-.64-.51s.26-.51.78-.51h.61v-2.11a.78.78,0,0,1,.49-.74l1.23-.61a.64.64,0,0,1,.31-.12c.2,0,.3.19.3.55Z"
            fill="#fff"
          />
          <path
            d="M113.34,260.82a3.4,3.4,0,0,1-2.45,1.08c-1.71,0-2.57-.88-2.57-2.64v-3.17a.53.53,0,0,0-.5-.57c-.4-.05-.6-.23-.6-.53s.21-.49.62-.63l2-.68a1,1,0,0,1,.38-.08c.26,0,.4.23.4.7v4.49a2.44,2.44,0,0,0,.23,1.24.92.92,0,0,0,.83.35,1.38,1.38,0,0,0,1.09-.51,2.58,2.58,0,0,0,.45-1.71v-1.81a2.44,2.44,0,0,0-.06-.68.53.53,0,0,0-.51-.34q-.63-.06-.63-.48c0-.26.22-.47.65-.6l2.22-.75.29-.05c.25,0,.38.22.38.66V259a8,8,0,0,0,.06,1.31.5.5,0,0,0,.41.31l.19,0c.36.07.53.24.53.49s-.26.5-.79.5h-2.34c-.22,0-.32-.11-.32-.33Z"
            fill="#fff"
          />
          <path
            d="M121.07,255a3.53,3.53,0,0,1,.87-1.21,2.09,2.09,0,0,1,2.13-.11c.28.16.42.34.42.56a2.73,2.73,0,0,1-.53,1.08c-.17.24-.32.36-.46.36a.81.81,0,0,1-.42-.19,1.19,1.19,0,0,0-.68-.26c-.53,0-.91.34-1.12,1a6.88,6.88,0,0,0-.21,2V259a6.72,6.72,0,0,0,.08,1.36c.06.17.22.27.49.28l.28,0c.4,0,.59.19.59.49s-.26.5-.79.5h-3.31c-.53,0-.8-.17-.8-.5s.18-.43.53-.5l.23,0a.44.44,0,0,0,.4-.3,6.43,6.43,0,0,0,.08-1.32v-2.61a1.79,1.79,0,0,0-.08-.67c-.08-.18-.26-.27-.55-.29s-.57-.19-.57-.49.16-.43.47-.56l2.34-.89a.72.72,0,0,1,.27-.08c.23,0,.34.14.34.41Z"
            fill="#fff"
          />
          <path
            d="M131.52,257l-2.6,1.5a1.26,1.26,0,0,1-.56.21.54.54,0,0,1-.41-.18.57.57,0,0,1-.17-.43.47.47,0,0,1,.24-.43l1.2-.78c.48-.32.72-.64.72-1a1.24,1.24,0,0,0-.3-.84,1,1,0,0,0-.74-.34,1.37,1.37,0,0,0-1.21.82,4.3,4.3,0,0,0-.47,2.14,3.58,3.58,0,0,0,.54,2.11,1.72,1.72,0,0,0,1.49.76,4,4,0,0,0,1.93-.64,1.27,1.27,0,0,1,.53-.21c.33,0,.49.18.49.55s-.34.75-1,1.09a5,5,0,0,1-2.24.51,3.91,3.91,0,0,1-4.13-4.14,4.17,4.17,0,0,1,1.18-3,4,4,0,0,1,3-1.19,3.6,3.6,0,0,1,2.28.73,2.15,2.15,0,0,1,.95,1.76A1.16,1.16,0,0,1,131.52,257Z"
            fill="#fff"
          />
        </g>
        <rect
          x="32.01"
          y="71.92"
          width="128.7"
          height="148.47"
          rx="16.02"
          fill="#fff"
          filter="url(#filter-7)"
        />
        <path
          d="M160.71,114.3V96.71a16,16,0,0,0-16-16H48a16,16,0,0,0-16,16v58.62s15.34-23.53,33.4-29.9c11.22-4,23.4-1.63,52.32,5.85S160.71,114.3,160.71,114.3Z"
          fill="url(#linear-gradient-7)"
        />
        <path
          d="M32,140.38V87.93a16,16,0,0,1,16-16h96.66a16,16,0,0,1,16,16v24.23s-12.3,11.71-30.64,11.71S79,111,60.73,119A80.54,80.54,0,0,0,32,140.38Z"
          fill="url(#linear-gradient-8)"
        />
        <g filter="url(#filter-8)">
          <path
            d="M65.25,93.53H67.6c.56,0,.84.21.84.62s-.27.67-.8.67H66.2a1.75,1.75,0,0,0-.82.11c-.09.07-.13.29-.13.65v3c0,.73.08,1.16.24,1.28a4.65,4.65,0,0,0,1.79.18,8,8,0,0,0,2-.16,2.1,2.1,0,0,0,.92-.83q.28-.42.57-.42c.33,0,.49.15.49.46a1.44,1.44,0,0,1-.15.53l-.55,1.27a.6.6,0,0,1-.53.42l-.36,0H62.23c-.59,0-.88-.18-.88-.53s.16-.45.48-.51l.3-.06a.46.46,0,0,0,.36-.22,2.23,2.23,0,0,0,.08-.75V90.74a2.23,2.23,0,0,0-.08-.75.46.46,0,0,0-.36-.22l-.32-.06a.49.49,0,0,1-.48-.51c0-.36.29-.53.88-.53h7.31a.64.64,0,0,1,.67.38l.55,1.37a1,1,0,0,1,.09.36c0,.26-.17.4-.51.4s-.43-.19-.68-.57A1.45,1.45,0,0,0,68.8,90a4.79,4.79,0,0,0-1.06-.08H66.41a1.61,1.61,0,0,0-1,.18c-.14.12-.2.45-.2,1Z"
            fill="#fff"
          />
          <path
            d="M77.12,93.53,77.46,95a2.34,2.34,0,0,1,.06.29c0,.24-.14.36-.44.36-.15,0-.31-.11-.47-.34a2.08,2.08,0,0,0-1.71-1c-.61,0-.91.26-.91.78a1.08,1.08,0,0,0,.57.85l1,.52a6.18,6.18,0,0,1,1.78,1.27,2.37,2.37,0,0,1-.3,3.19,3,3,0,0,1-2,.67,6,6,0,0,1-1.45-.21l-.26,0a.37.37,0,0,0-.25.07l-.25.14a.37.37,0,0,1-.21,0c-.25,0-.41-.17-.49-.51l-.36-1.55a1,1,0,0,1-.06-.32.42.42,0,0,1,.48-.47c.16,0,.37.18.62.53a2.45,2.45,0,0,0,2,1.2,1.21,1.21,0,0,0,.77-.25.8.8,0,0,0,.29-.63.89.89,0,0,0-.27-.65,5.21,5.21,0,0,0-1.12-.73,7.9,7.9,0,0,1-1.9-1.34A2.08,2.08,0,0,1,72,95.45a2.17,2.17,0,0,1,.7-1.65,2.61,2.61,0,0,1,1.83-.65,3.68,3.68,0,0,1,.75.1,4.39,4.39,0,0,0,.73.09.45.45,0,0,0,.25-.07l.21-.14a.85.85,0,0,1,.23,0C76.92,93.09,77.06,93.24,77.12,93.53Z"
            fill="#fff"
          />
          <path
            d="M84.1,93.53,84.44,95a1.6,1.6,0,0,1,0,.29c0,.24-.14.36-.43.36s-.31-.11-.48-.34a2.08,2.08,0,0,0-1.71-1c-.61,0-.91.26-.91.78a1.08,1.08,0,0,0,.57.85l1,.52a6.41,6.41,0,0,1,1.79,1.27,2.21,2.21,0,0,1,.47,1.44,2.17,2.17,0,0,1-.78,1.75,3,3,0,0,1-2,.67,6,6,0,0,1-1.45-.21l-.26,0a.39.39,0,0,0-.25.07l-.25.14a.31.31,0,0,1-.2,0c-.26,0-.42-.17-.5-.51l-.36-1.55a1,1,0,0,1-.06-.32.42.42,0,0,1,.48-.47c.16,0,.37.18.63.53a2.43,2.43,0,0,0,2,1.2,1.19,1.19,0,0,0,.77-.25.77.77,0,0,0,.29-.63.89.89,0,0,0-.27-.65,4.83,4.83,0,0,0-1.11-.73,7.5,7.5,0,0,1-1.9-1.34A2.09,2.09,0,0,1,79,95.45a2.14,2.14,0,0,1,.71-1.65,2.58,2.58,0,0,1,1.82-.65,3.68,3.68,0,0,1,.75.1,4.39,4.39,0,0,0,.73.09.45.45,0,0,0,.25-.07l.21-.14a.93.93,0,0,1,.23,0C83.89,93.09,84,93.24,84.1,93.53Z"
            fill="#fff"
          />
          <path
            d="M92.36,96.65l-2.6,1.5a1.28,1.28,0,0,1-.55.21.54.54,0,0,1-.42-.18.59.59,0,0,1-.17-.43.49.49,0,0,1,.24-.44l1.2-.78c.48-.31.72-.64.72-1a1.21,1.21,0,0,0-.3-.83.94.94,0,0,0-.74-.34,1.37,1.37,0,0,0-1.21.81,4.34,4.34,0,0,0-.46,2.15,3.6,3.6,0,0,0,.54,2.11,1.71,1.71,0,0,0,1.48.76A4,4,0,0,0,92,99.57a1.27,1.27,0,0,1,.53-.21c.33,0,.49.19.49.55s-.34.75-1,1.09a5,5,0,0,1-2.23.51,3.91,3.91,0,0,1-4.14-4.13,4.14,4.14,0,0,1,1.18-3,4,4,0,0,1,3-1.2,3.66,3.66,0,0,1,2.28.73,2.16,2.16,0,0,1,1,1.76A1.15,1.15,0,0,1,92.36,96.65Z"
            fill="#fff"
          />
          <path
            d="M97.36,94.14a3.27,3.27,0,0,1,2.43-1,2.57,2.57,0,0,1,2,.75,3,3,0,0,1,.55,2v3.48a1.62,1.62,0,0,0,.1.68.75.75,0,0,0,.62.34c.32,0,.48.22.48.5s-.17.47-.51.47H99.5c-.39,0-.58-.15-.58-.44a.43.43,0,0,1,.17-.41,1.78,1.78,0,0,1,.45-.16c.32-.07.48-.34.48-.79V96q0-1.44-1-1.44a1.37,1.37,0,0,0-1.21.66,3.67,3.67,0,0,0-.36,1.9V99.4a2.33,2.33,0,0,0,.06.65.63.63,0,0,0,.5.32c.36.08.55.24.55.5a.41.41,0,0,1-.31.43,2.25,2.25,0,0,1-.59,0H94.47c-.39,0-.59-.16-.59-.48s.22-.48.65-.53a.6.6,0,0,0,.53-.4,4.22,4.22,0,0,0,0-.71V96.07a2.19,2.19,0,0,0-.06-.65.59.59,0,0,0-.55-.28c-.41,0-.61-.2-.61-.52s.17-.41.51-.55l2.28-.9a1,1,0,0,1,.36-.08c.22,0,.33.17.33.5Z"
            fill="#fff"
          />
          <path
            d="M107.79,93.36h1.14q.57,0,.57.51t-.9.54a1.22,1.22,0,0,0-.68.11c-.09.08-.13.29-.13.65v3.89a3.09,3.09,0,0,0,.07.86.51.51,0,0,0,.52.3,1,1,0,0,0,.4-.08,2.81,2.81,0,0,1,.43-.11c.35,0,.52.2.52.59s-.22.48-.66.69a3.37,3.37,0,0,1-1.47.32,2,2,0,0,1-1.9-.92,3.35,3.35,0,0,1-.25-1.54V95c0-.27,0-.44-.16-.5a1.31,1.31,0,0,0-.58-.09c-.43,0-.65-.19-.65-.52s.26-.51.78-.51h.61V91.25a.81.81,0,0,1,.49-.74l1.24-.61a.74.74,0,0,1,.3-.11c.21,0,.31.18.31.55Z"
            fill="#fff"
          />
          <path
            d="M113.93,93.74v5A6.55,6.55,0,0,0,114,100a.43.43,0,0,0,.4.3l.23,0c.34.05.51.22.51.5s-.27.49-.8.49h-3.13c-.53,0-.8-.16-.8-.49s.18-.42.53-.5l.19,0a.49.49,0,0,0,.42-.32,6.06,6.06,0,0,0,.08-1.29V96.13a3.09,3.09,0,0,0,0-.62.58.58,0,0,0-.51-.34c-.46-.08-.69-.25-.69-.52s.2-.42.61-.59l2-.79a1.67,1.67,0,0,1,.48-.14C113.79,93.13,113.93,93.33,113.93,93.74Zm-2.68-3.32a1.39,1.39,0,0,1,.42-1,1.4,1.4,0,0,1,1-.43,1.37,1.37,0,0,1,1,.42,1.4,1.4,0,0,1,.42,1,1.41,1.41,0,0,1-.42,1,1.35,1.35,0,0,1-1,.43,1.42,1.42,0,0,1-1-.44A1.39,1.39,0,0,1,111.25,90.42Z"
            fill="#fff"
          />
          <path
            d="M120.77,100.66a2.6,2.6,0,0,1-2.11.91,2.79,2.79,0,0,1-1.94-.67,2.15,2.15,0,0,1-.76-1.71A2.59,2.59,0,0,1,117.27,97a3.83,3.83,0,0,1,2.09-.68c.42,0,.63.18.63.55a.65.65,0,0,1-.42.59l-.34.17a1.5,1.5,0,0,0-1,1.4,1.33,1.33,0,0,0,.28.88.91.91,0,0,0,.74.34,1,1,0,0,0,1-.72,6,6,0,0,0,.15-1.6v-1.1a4.36,4.36,0,0,0-.23-1.88,1.16,1.16,0,0,0-1-.4,3.06,3.06,0,0,0-1.16.27,5.28,5.28,0,0,0-.69.28.94.94,0,0,1-.38.12c-.38,0-.57-.21-.57-.61s.21-.53.61-.74a5.71,5.71,0,0,1,2.79-.69,2.94,2.94,0,0,1,2.67,1.09,3,3,0,0,1,.32,1.59v3.5a2,2,0,0,0,.09.81,1.15,1.15,0,0,0,.65.23.48.48,0,0,1,.46.5c0,.33-.26.49-.78.49h-2.13a.28.28,0,0,1-.32-.32Z"
            fill="#fff"
          />
          <path
            d="M128.31,89V98.7a6.55,6.55,0,0,0,.07,1.31.44.44,0,0,0,.4.3l.21,0c.36.07.53.23.53.5s-.26.49-.79.49h-3.18c-.53,0-.8-.16-.8-.49s.18-.43.54-.5l.21,0a.45.45,0,0,0,.4-.3A6.55,6.55,0,0,0,126,98.7V91.18a.58.58,0,0,0-.6-.63c-.4,0-.6-.2-.6-.5a.63.63,0,0,1,.46-.57l2.26-1a1.1,1.1,0,0,1,.38-.12C128.16,88.38,128.31,88.6,128.31,89Z"
            fill="#fff"
          />
        </g>
        <path
          d="M52.72,147.6h0a3.86,3.86,0,0,1,3.86,3.86h0a3.86,3.86,0,0,1-3.86,3.87h0a3.87,3.87,0,0,1-3.87-3.87h0A3.86,3.86,0,0,1,52.72,147.6Z"
          fill="#dfdfdf"
        />
        <path
          d="M54.38,150.28a.19.19,0,0,1,0,.27l-2.2,2.1a.19.19,0,0,1-.14,0,.2.2,0,0,1-.14-.06l-.84-1a.19.19,0,1,1,.28-.25l.72.82,2.06-2A.19.19,0,0,1,54.38,150.28Z"
          fill="#fff"
          fill-rule="evenodd"
        />
        <path
          d="M52.72,159.82h0a3.86,3.86,0,0,1,3.86,3.86h0a3.86,3.86,0,0,1-3.86,3.87h0a3.87,3.87,0,0,1-3.87-3.87h0A3.86,3.86,0,0,1,52.72,159.82Z"
          fill="#dfdfdf"
        />
        <path
          d="M54.38,162.5a.19.19,0,0,1,0,.27l-2.2,2.1a.16.16,0,0,1-.14,0,.2.2,0,0,1-.14-.06l-.84-1a.19.19,0,1,1,.28-.25l.72.82,2.06-2A.19.19,0,0,1,54.38,162.5Z"
          fill="#fff"
          fill-rule="evenodd"
        />
        <path
          d="M52.72,172h0a3.86,3.86,0,0,1,3.86,3.86h0a3.86,3.86,0,0,1-3.86,3.87h0a3.87,3.87,0,0,1-3.87-3.87h0A3.86,3.86,0,0,1,52.72,172Z"
          fill="#dfdfdf"
        />
        <path
          d="M54.38,174.72a.19.19,0,0,1,0,.27l-2.2,2.1a.16.16,0,0,1-.14,0,.2.2,0,0,1-.14-.06l-.84-1a.19.19,0,1,1,.28-.25l.72.82,2.06-2A.19.19,0,0,1,54.38,174.72Z"
          fill="#fff"
          fill-rule="evenodd"
        />
        <path
          d="M52.72,184.26h0a3.86,3.86,0,0,1,3.86,3.86h0A3.86,3.86,0,0,1,52.72,192h0a3.87,3.87,0,0,1-3.87-3.87h0A3.86,3.86,0,0,1,52.72,184.26Z"
          fill="#dfdfdf"
        />
        <path
          d="M54.38,186.94a.19.19,0,0,1,0,.27l-2.2,2.1a.16.16,0,0,1-.14.05.2.2,0,0,1-.14-.06l-.84-1a.19.19,0,1,1,.28-.25l.72.82,2.06-2A.19.19,0,0,1,54.38,186.94Z"
          fill="#fff"
          fill-rule="evenodd"
        />
        <text
          transform="translate(59.21 154.76)"
          font-size="7"
          fill="#231f20"
          font-family="NotoSans-Italic, Noto Sans"
          font-style="italic"
        >
          <tspan letter-spacing="-0.02em">W</tspan>
          <tspan x="5.85" y="0">
            arm Hygienic Bath
          </tspan>
        </text>
        <text
          transform="translate(59.21 166.99)"
          font-size="7"
          fill="#231f20"
          font-family="NotoSans-Italic, Noto Sans"
          font-style="italic"
        >
          Bl
          <tspan x="6.01" y="0" letter-spacing="-0.02em">
            o
          </tspan>
          <tspan x="9.81" y="0" letter-spacing="0em">
            w Dry
          </tspan>
        </text>
        <text
          transform="translate(59.21 179.21)"
          font-size="7"
          fill="#231f20"
          font-family="NotoSans-Italic, Noto Sans"
          font-style="italic"
        >
          Gentle Brush &amp; De-Shedding
        </text>
        <text
          transform="translate(59.21 191.43)"
          font-size="7"
          fill="#231f20"
          font-family="NotoSans-Italic, Noto Sans"
          font-style="italic"
        >
          Pawdicu
          <tspan x="26.09" y="0" letter-spacing="-0.02em">
            r
          </tspan>
          <tspan x="28.73" y="0" letter-spacing="0em">
            e (Buff &amp;{" "}
          </tspan>
          <tspan x="57.15" y="0" letter-spacing="-0.05em">
            T
          </tspan>
          <tspan x="60.3" y="0">
            rim)
          </tspan>
        </text>
        <path
          d="M203,147h0a3.86,3.86,0,0,1,3.86,3.87h0a3.86,3.86,0,0,1-3.86,3.86h0a3.86,3.86,0,0,1-3.87-3.86h0A3.87,3.87,0,0,1,203,147Z"
          fill="#dfdfdf"
        />
        <path
          d="M204.65,149.7a.19.19,0,0,1,0,.26l-2.21,2.1a.2.2,0,0,1-.14.06.21.21,0,0,1-.13-.07l-.85-1a.19.19,0,1,1,.29-.25l.71.82,2.06-2A.19.19,0,0,1,204.65,149.7Z"
          fill="#fff"
          fill-rule="evenodd"
        />
        <path
          d="M203,159.23h0a3.86,3.86,0,0,1,3.86,3.87h0A3.86,3.86,0,0,1,203,167h0a3.86,3.86,0,0,1-3.87-3.86h0A3.87,3.87,0,0,1,203,159.23Z"
          fill="#dfdfdf"
        />
        <path
          d="M204.65,161.92a.2.2,0,0,1,0,.27l-2.21,2.09a.2.2,0,0,1-.14.06.21.21,0,0,1-.13-.07l-.85-1a.19.19,0,1,1,.29-.25l.71.82,2.06-2A.19.19,0,0,1,204.65,161.92Z"
          fill="#fff"
          fill-rule="evenodd"
        />
        <path
          d="M203,171.45h0a3.86,3.86,0,0,1,3.86,3.87h0a3.86,3.86,0,0,1-3.86,3.86h0a3.86,3.86,0,0,1-3.87-3.86h0A3.87,3.87,0,0,1,203,171.45Z"
          fill="#dfdfdf"
        />
        <path
          d="M204.65,174.14a.2.2,0,0,1,0,.27l-2.21,2.09a.2.2,0,0,1-.14.06.21.21,0,0,1-.13-.07l-.85-1a.19.19,0,0,1,0-.27.2.2,0,0,1,.27,0l.71.82,2.06-2A.19.19,0,0,1,204.65,174.14Z"
          fill="#fff"
          fill-rule="evenodd"
        />
        <path
          d="M203,183.67h0a3.86,3.86,0,0,1,3.86,3.87h0A3.86,3.86,0,0,1,203,191.4h0a3.86,3.86,0,0,1-3.87-3.86h0A3.87,3.87,0,0,1,203,183.67Z"
          fill="#dfdfdf"
        />
        <path
          d="M204.65,186.36a.2.2,0,0,1,0,.27l-2.21,2.1a.24.24,0,0,1-.14.05.17.17,0,0,1-.13-.07l-.85-1a.18.18,0,0,1,0-.26.19.19,0,0,1,.27,0l.71.82,2.06-2A.19.19,0,0,1,204.65,186.36Z"
          fill="#fff"
          fill-rule="evenodd"
        />
        <path
          d="M203,195.9h0a3.86,3.86,0,0,1,3.86,3.86h0a3.86,3.86,0,0,1-3.86,3.86h0a3.86,3.86,0,0,1-3.87-3.86h0A3.86,3.86,0,0,1,203,195.9Z"
          fill="#dfdfdf"
        />
        <path
          d="M204.65,198.58a.2.2,0,0,1,0,.27l-2.21,2.1a.24.24,0,0,1-.14.05.17.17,0,0,1-.13-.07l-.85-1a.18.18,0,0,1,0-.26.19.19,0,0,1,.27,0l.71.82,2.06-2A.19.19,0,0,1,204.65,198.58Z"
          fill="#fff"
          fill-rule="evenodd"
        />
        <text
          transform="translate(209.48 154.18)"
          font-size="7"
          fill="#231f20"
          font-family="NotoSans-Italic, Noto Sans"
          font-style="italic"
        >
          <tspan letter-spacing="-0.02em">W</tspan>
          <tspan x="5.85" y="0">
            arm Hygienic Bath
          </tspan>
        </text>
        <text
          transform="translate(209.48 166.4)"
          font-size="7"
          fill="#231f20"
          font-family="NotoSans-Italic, Noto Sans"
          font-style="italic"
        >
          Bl
          <tspan x="6.01" y="0" letter-spacing="-0.02em">
            o
          </tspan>
          <tspan x="9.81" y="0" letter-spacing="0em">
            w Dry
          </tspan>
        </text>
        <text
          transform="translate(209.48 178.62)"
          font-size="7"
          fill="#231f20"
          font-family="NotoSans-Italic, Noto Sans"
          font-style="italic"
        >
          Signatu
          <tspan x="23.8" y="0" letter-spacing="-0.02em">
            r
          </tspan>
          <tspan x="26.45" y="0" letter-spacing="0em">
            e B
          </tspan>
          <tspan x="35.96" y="0" letter-spacing="-0.02em">
            r
          </tspan>
          <tspan x="38.6" y="0">
            eed Hai
          </tspan>
          <tspan x="62.01" y="0" letter-spacing="-0.02em">
            r
          </tspan>
          <tspan x="64.65" y="0">
            cut
          </tspan>
        </text>
        <text
          transform="translate(209.48 190.84)"
          font-size="7"
          fill="#231f20"
          font-family="NotoSans-Italic, Noto Sans"
          font-style="italic"
        >
          Gentle Brush &amp; De-Shedding
        </text>
        <text
          transform="translate(209.48 203.06)"
          font-size="7"
          fill="#231f20"
          font-family="NotoSans-Italic, Noto Sans"
          font-style="italic"
        >
          Pawdicu
          <tspan x="26.09" y="0" letter-spacing="-0.02em">
            r
          </tspan>
          <tspan x="28.73" y="0" letter-spacing="0em">
            e (Buff &amp;{" "}
          </tspan>
          <tspan x="57.15" y="0" letter-spacing="-0.05em">
            T
          </tspan>
          <tspan x="60.3" y="0">
            rim)
          </tspan>
        </text>
        <path
          d="M201.05,329.87h0a3.86,3.86,0,0,1,3.86,3.86h0a3.86,3.86,0,0,1-3.86,3.86h0a3.86,3.86,0,0,1-3.86-3.86h0A3.86,3.86,0,0,1,201.05,329.87Z"
          fill="#dfdfdf"
        />
        <path
          d="M202.71,332.55a.19.19,0,0,1,0,.27l-2.21,2.1a.24.24,0,0,1-.14.05.17.17,0,0,1-.13-.07l-.85-1a.19.19,0,0,1,.29-.25l.71.81,2.06-2A.19.19,0,0,1,202.71,332.55Z"
          fill="#fff"
          fill-rule="evenodd"
        />
        <path
          d="M201.05,342.09h0a3.86,3.86,0,0,1,3.86,3.86h0a3.86,3.86,0,0,1-3.86,3.86h0a3.86,3.86,0,0,1-3.86-3.86h0A3.86,3.86,0,0,1,201.05,342.09Z"
          fill="#dfdfdf"
        />
        <path
          d="M202.71,344.77a.19.19,0,0,1,0,.27l-2.21,2.1a.24.24,0,0,1-.14.05.2.2,0,0,1-.13-.06l-.85-1a.19.19,0,1,1,.29-.25l.71.81,2.06-2A.19.19,0,0,1,202.71,344.77Z"
          fill="#fff"
          fill-rule="evenodd"
        />
        <path
          d="M201.05,354.31h0a3.86,3.86,0,0,1,3.86,3.86h0a3.86,3.86,0,0,1-3.86,3.86h0a3.86,3.86,0,0,1-3.86-3.86h0A3.86,3.86,0,0,1,201.05,354.31Z"
          fill="#dfdfdf"
        />
        <path
          d="M202.71,357a.19.19,0,0,1,0,.27l-2.21,2.1a.24.24,0,0,1-.14.05.16.16,0,0,1-.13-.06l-.85-1a.19.19,0,0,1,.29-.25l.71.82,2.06-2A.19.19,0,0,1,202.71,357Z"
          fill="#fff"
          fill-rule="evenodd"
        />
        <path
          d="M201.05,366.53h0a3.86,3.86,0,0,1,3.86,3.86h0a3.86,3.86,0,0,1-3.86,3.87h0a3.86,3.86,0,0,1-3.86-3.87h0A3.86,3.86,0,0,1,201.05,366.53Z"
          fill="#dfdfdf"
        />
        <path
          d="M202.71,369.21a.19.19,0,0,1,0,.27l-2.21,2.1a.19.19,0,0,1-.14.05.16.16,0,0,1-.13-.06l-.85-1a.19.19,0,1,1,.29-.25l.71.82,2.06-2A.19.19,0,0,1,202.71,369.21Z"
          fill="#fff"
          fill-rule="evenodd"
        />
        <path
          d="M201.05,378.75h0a3.86,3.86,0,0,1,3.86,3.86h0a3.86,3.86,0,0,1-3.86,3.87h0a3.86,3.86,0,0,1-3.86-3.87h0A3.86,3.86,0,0,1,201.05,378.75Z"
          fill="#dfdfdf"
        />
        <path
          d="M202.71,381.43a.19.19,0,0,1,0,.27l-2.21,2.1a.19.19,0,0,1-.14.05.16.16,0,0,1-.13-.06l-.85-1a.19.19,0,0,1,.29-.25l.71.82,2.06-2A.19.19,0,0,1,202.71,381.43Z"
          fill="#fff"
          fill-rule="evenodd"
        />
        <path
          d="M201.05,390.44h0a3.86,3.86,0,0,1,3.86,3.86h0a3.86,3.86,0,0,1-3.86,3.86h0a3.86,3.86,0,0,1-3.86-3.86h0A3.86,3.86,0,0,1,201.05,390.44Z"
          fill="#dfdfdf"
        />
        <path
          d="M202.71,393.12a.19.19,0,0,1,0,.27l-2.21,2.1a.24.24,0,0,1-.14.05.2.2,0,0,1-.13-.06l-.85-1a.19.19,0,0,1,.29-.25l.71.81,2.06-2A.19.19,0,0,1,202.71,393.12Z"
          fill="#fff"
          fill-rule="evenodd"
        />
        <path
          d="M201.05,402.66h0a3.86,3.86,0,0,1,3.86,3.86h0a3.86,3.86,0,0,1-3.86,3.86h0a3.86,3.86,0,0,1-3.86-3.86h0A3.86,3.86,0,0,1,201.05,402.66Z"
          fill="#dfdfdf"
        />
        <path
          d="M202.71,405.34a.19.19,0,0,1,0,.27l-2.21,2.1a.24.24,0,0,1-.14.05.16.16,0,0,1-.13-.06l-.85-1a.19.19,0,1,1,.29-.25l.71.82,2.06-2A.19.19,0,0,1,202.71,405.34Z"
          fill="#fff"
          fill-rule="evenodd"
        />
        <text
          transform="translate(207.54 337.03)"
          font-size="7"
          fill="#231f20"
          font-family="NotoSans-Italic, Noto Sans"
          font-style="italic"
        >
          <tspan letter-spacing="-0.02em">W</tspan>
          <tspan x="5.85" y="0">
            arm Hygienic Bath
          </tspan>
        </text>
        <text
          transform="translate(207.54 349.25)"
          font-size="7"
          fill="#231f20"
          font-family="NotoSans-Italic, Noto Sans"
          font-style="italic"
        >
          Bl
          <tspan x="6.01" y="0" letter-spacing="-0.02em">
            o
          </tspan>
          <tspan x="9.81" y="0" letter-spacing="0em">
            w Dry
          </tspan>
        </text>
        <text
          transform="translate(207.54 361.47)"
          font-size="7"
          fill="#231f20"
          font-family="NotoSans-Italic, Noto Sans"
          font-style="italic"
        >
          Signatu
          <tspan x="23.8" y="0" letter-spacing="-0.02em">
            r
          </tspan>
          <tspan x="26.45" y="0" letter-spacing="0em">
            e B
          </tspan>
          <tspan x="35.96" y="0" letter-spacing="-0.02em">
            r
          </tspan>
          <tspan x="38.6" y="0">
            eed Hai
          </tspan>
          <tspan x="62.01" y="0" letter-spacing="-0.02em">
            r
          </tspan>
          <tspan x="64.65" y="0">
            cut
          </tspan>
        </text>
        <text
          transform="translate(207.54 373.69)"
          font-size="7"
          fill="#231f20"
          font-family="NotoSans-Italic, Noto Sans"
          font-style="italic"
        >
          Gentle Brush &amp; De-Shedding
        </text>
        <text
          transform="translate(207.54 385.91)"
          font-size="7"
          fill="#231f20"
          font-family="NotoSans-Italic, Noto Sans"
          font-style="italic"
        >
          Pawdicu
          <tspan x="26.09" y="0" letter-spacing="-0.02em">
            r
          </tspan>
          <tspan x="28.73" y="0" letter-spacing="0em">
            e (Buff &amp;{" "}
          </tspan>
          <tspan x="57.15" y="0" letter-spacing="-0.05em">
            T
          </tspan>
          <tspan x="60.3" y="0">
            rim)
          </tspan>
        </text>
        <text
          transform="translate(207.54 397.6)"
          font-size="7"
          fill="#231f20"
          font-family="NotoSans-Italic, Noto Sans"
          font-style="italic"
        >
          7 Point{" "}
          <tspan x="23.59" y="0" letter-spacing="-0.02em">
            W
          </tspan>
          <tspan x="29.44" y="0">
            ellness Check
          </tspan>
        </text>
        <text
          transform="translate(207.54 409.82)"
          font-size="7"
          fill="#231f20"
          font-family="NotoSans-Italic, Noto Sans"
          font-style="italic"
        >
          Ear Cleaning
        </text>
        <path
          d="M53,306.6h0a3.86,3.86,0,0,1,3.86,3.86h0A3.86,3.86,0,0,1,53,314.33h0a3.86,3.86,0,0,1-3.86-3.87h0A3.86,3.86,0,0,1,53,306.6Z"
          fill="#dfdfdf"
        />
        <path
          d="M54.62,309.28a.19.19,0,0,1,0,.27l-2.21,2.1a.17.17,0,0,1-.14.05.16.16,0,0,1-.13-.06l-.85-1a.19.19,0,1,1,.29-.25l.71.82,2.06-2A.19.19,0,0,1,54.62,309.28Z"
          fill="#fff"
          fill-rule="evenodd"
        />
        <path
          d="M53,318.82h0a3.86,3.86,0,0,1,3.86,3.86h0A3.86,3.86,0,0,1,53,326.55h0a3.86,3.86,0,0,1-3.86-3.87h0A3.86,3.86,0,0,1,53,318.82Z"
          fill="#dfdfdf"
        />
        <path
          d="M54.62,321.5a.19.19,0,0,1,0,.27l-2.21,2.1a.15.15,0,0,1-.14.05.16.16,0,0,1-.13-.06l-.85-1a.19.19,0,1,1,.29-.25l.71.82,2.06-2A.19.19,0,0,1,54.62,321.5Z"
          fill="#fff"
          fill-rule="evenodd"
        />
        <path
          d="M53,331h0a3.86,3.86,0,0,1,3.86,3.86h0A3.86,3.86,0,0,1,53,338.77h0a3.86,3.86,0,0,1-3.86-3.87h0A3.86,3.86,0,0,1,53,331Z"
          fill="#dfdfdf"
        />
        <path
          d="M54.62,333.72a.19.19,0,0,1,0,.27l-2.21,2.1a.15.15,0,0,1-.14.05.16.16,0,0,1-.13-.06l-.85-1a.19.19,0,1,1,.29-.25l.71.82,2.06-2A.19.19,0,0,1,54.62,333.72Z"
          fill="#fff"
          fill-rule="evenodd"
        />
        <path
          d="M53,343.26h0a3.86,3.86,0,0,1,3.86,3.86h0A3.86,3.86,0,0,1,53,351h0a3.86,3.86,0,0,1-3.86-3.87h0A3.86,3.86,0,0,1,53,343.26Z"
          fill="#dfdfdf"
        />
        <path
          d="M54.62,345.94a.19.19,0,0,1,0,.27l-2.21,2.1a.15.15,0,0,1-.14.05.16.16,0,0,1-.13-.06l-.85-1a.19.19,0,1,1,.29-.25l.71.82,2.06-2A.19.19,0,0,1,54.62,345.94Z"
          fill="#fff"
          fill-rule="evenodd"
        />
        <path
          d="M53,355.48h0a3.86,3.86,0,0,1,3.86,3.87h0A3.86,3.86,0,0,1,53,363.21h0a3.86,3.86,0,0,1-3.86-3.86h0A3.86,3.86,0,0,1,53,355.48Z"
          fill="#dfdfdf"
        />
        <path
          d="M54.62,358.16a.19.19,0,0,1,0,.27l-2.21,2.1a.18.18,0,0,1-.14.06.21.21,0,0,1-.13-.07l-.85-1a.19.19,0,0,1,.29-.25l.71.82,2.06-2A.2.2,0,0,1,54.62,358.16Z"
          fill="#fff"
          fill-rule="evenodd"
        />
        <path
          d="M53,367.17h0A3.86,3.86,0,0,1,56.82,371h0A3.86,3.86,0,0,1,53,374.9h0A3.86,3.86,0,0,1,49.1,371h0A3.86,3.86,0,0,1,53,367.17Z"
          fill="#dfdfdf"
        />
        <path
          d="M54.62,369.85a.19.19,0,0,1,0,.27l-2.21,2.1a.15.15,0,0,1-.14,0,.16.16,0,0,1-.13-.06l-.85-1a.19.19,0,1,1,.29-.25l.71.82,2.06-2A.19.19,0,0,1,54.62,369.85Z"
          fill="#fff"
          fill-rule="evenodd"
        />
        <path
          d="M53,379.39h0a3.86,3.86,0,0,1,3.86,3.86h0A3.86,3.86,0,0,1,53,387.12h0a3.86,3.86,0,0,1-3.86-3.87h0A3.86,3.86,0,0,1,53,379.39Z"
          fill="#dfdfdf"
        />
        <path
          d="M54.62,382.07a.19.19,0,0,1,0,.27l-2.21,2.1a.15.15,0,0,1-.14.05.16.16,0,0,1-.13-.06l-.85-1a.19.19,0,1,1,.29-.25l.71.82,2.06-2A.19.19,0,0,1,54.62,382.07Z"
          fill="#fff"
          fill-rule="evenodd"
        />
        <path
          d="M53,391.61h0a3.86,3.86,0,0,1,3.86,3.86h0A3.86,3.86,0,0,1,53,399.34h0a3.86,3.86,0,0,1-3.86-3.87h0A3.86,3.86,0,0,1,53,391.61Z"
          fill="#dfdfdf"
        />
        <path
          d="M54.62,394.29a.19.19,0,0,1,0,.27l-2.21,2.1a.15.15,0,0,1-.14,0,.16.16,0,0,1-.13-.06l-.85-1a.19.19,0,1,1,.29-.25l.71.82,2.06-2A.19.19,0,0,1,54.62,394.29Z"
          fill="#fff"
          fill-rule="evenodd"
        />
        <path
          d="M53,403.83h0a3.86,3.86,0,0,1,3.86,3.87h0A3.86,3.86,0,0,1,53,411.56h0a3.86,3.86,0,0,1-3.86-3.86h0A3.86,3.86,0,0,1,53,403.83Z"
          fill="#dfdfdf"
        />
        <path
          d="M54.62,406.51a.19.19,0,0,1,0,.27l-2.21,2.1a.18.18,0,0,1-.14.06.21.21,0,0,1-.13-.07l-.85-1a.19.19,0,1,1,.29-.25l.71.82,2.06-2A.2.2,0,0,1,54.62,406.51Z"
          fill="#fff"
          fill-rule="evenodd"
        />
        <text
          transform="translate(59.45 313.76)"
          font-size="7"
          fill="#231f20"
          font-family="NotoSans-Italic, Noto Sans"
          font-style="italic"
        >
          <tspan letter-spacing="-0.02em">W</tspan>
          <tspan x="5.85" y="0">
            arm Hygienic Bath
          </tspan>
        </text>
        <text
          transform="translate(59.45 325.99)"
          font-size="7"
          fill="#231f20"
          font-family="NotoSans-Italic, Noto Sans"
          font-style="italic"
        >
          Bl
          <tspan x="6.01" y="0" letter-spacing="-0.02em">
            o
          </tspan>
          <tspan x="9.81" y="0" letter-spacing="0em">
            w Dry
          </tspan>
        </text>
        <text
          transform="translate(59.45 338.21)"
          font-size="7"
          fill="#231f20"
          font-family="NotoSans-Italic, Noto Sans"
          font-style="italic"
        >
          Signatu
          <tspan x="23.8" y="0" letter-spacing="-0.02em">
            r
          </tspan>
          <tspan x="26.45" y="0" letter-spacing="0em">
            e B
          </tspan>
          <tspan x="35.96" y="0" letter-spacing="-0.02em">
            r
          </tspan>
          <tspan x="38.6" y="0">
            eed Hai
          </tspan>
          <tspan x="62.01" y="0" letter-spacing="-0.02em">
            r
          </tspan>
          <tspan x="64.65" y="0">
            cut
          </tspan>
        </text>
        <text
          transform="translate(59.45 411)"
          font-size="7"
          fill="#231f20"
          font-family="NotoSans-Italic, Noto Sans"
          font-style="italic"
        >
          One À la carte Item
        </text>
        <text
          transform="translate(59.45 350.43)"
          font-size="7"
          fill="#231f20"
          font-family="NotoSans-Italic, Noto Sans"
          font-style="italic"
        >
          Gentle Brush &amp; De-Shedding
        </text>
        <text
          transform="translate(59.45 362.65)"
          font-size="7"
          fill="#231f20"
          font-family="NotoSans-Italic, Noto Sans"
          font-style="italic"
        >
          Pawdicu
          <tspan x="26.09" y="0" letter-spacing="-0.02em">
            r
          </tspan>
          <tspan x="28.73" y="0" letter-spacing="0em">
            e (Buff &amp;{" "}
          </tspan>
          <tspan x="57.15" y="0" letter-spacing="-0.05em">
            T
          </tspan>
          <tspan x="60.3" y="0">
            rim)
          </tspan>
        </text>
        <text
          transform="translate(59.45 374.34)"
          font-size="7"
          fill="#231f20"
          font-family="NotoSans-Italic, Noto Sans"
          font-style="italic"
        >
          7 Point{" "}
          <tspan x="23.59" y="0" letter-spacing="-0.02em">
            W
          </tspan>
          <tspan x="29.44" y="0">
            ellness Check
          </tspan>
        </text>
        <text
          transform="translate(59.45 386.56)"
          font-size="7"
          fill="#231f20"
          font-family="NotoSans-Italic, Noto Sans"
          font-style="italic"
        >
          Ear Cleaning
        </text>
        <text
          transform="translate(59.45 398.78)"
          font-size="7"
          fill="#231f20"
          font-family="NotoSans-Italic, Noto Sans"
          font-style="italic"
        >
          Brush{" "}
          <tspan x="19.94" y="0" letter-spacing="-0.07em">
            T
          </tspan>
          <tspan x="22.95" y="0">
            eeth
          </tspan>
        </text>
        <rect
          x="0.5"
          y="0.5"
          width="341.72"
          height="49.26"
          rx="12"
          fill="#fff"
          stroke="#1b0f44"
          stroke-miterlimit="10"
        />
        <g id="Dots">
          <circle cx="25.26" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="22.03" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="18.81" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="15.59" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="12.37" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="9.15" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="28.48" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="96.11" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="92.89" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="89.67" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="86.45" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="83.23" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="80" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="76.78" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="73.56" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="70.34" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="67.12" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="63.9" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="60.68" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="57.46" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="54.24" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="51.02" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="47.8" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="44.58" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="41.36" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="38.14" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="34.92" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="99.33" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="31.7" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="166.94" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="163.72" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="160.5" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="157.28" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="154.06" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="150.84" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="147.62" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="144.4" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="141.18" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="137.95" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="134.73" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="131.51" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="128.29" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="125.07" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="121.85" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="118.63" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="115.41" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="112.19" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="108.97" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="105.75" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="170.16" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="102.53" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="237.79" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="234.57" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="231.35" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="228.13" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="224.91" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="221.69" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="218.47" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="215.25" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="212.03" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="208.81" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="205.59" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="202.37" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="199.15" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="195.92" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="192.7" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="189.48" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="186.26" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="183.04" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="179.82" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="176.6" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="241.01" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="173.38" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="309.05" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="305.83" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="302.61" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="299.39" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="296.17" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="292.95" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="289.73" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="286.51" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="283.29" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="280.07" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="276.85" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="273.63" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="270.41" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="267.19" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="263.97" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="260.75" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="257.53" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="254.3" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="251.08" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="247.86" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="312.27" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="244.64" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="334.82" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="331.6" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="328.38" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="325.16" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="321.94" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="318.72" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="315.49" cy="1.75" r="0.8" fill="#74bbe7" />
          <circle cx="25.26" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="22.03" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="18.81" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="15.59" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="12.37" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="9.15" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="5.93" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="28.48" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="96.11" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="92.89" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="89.67" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="86.45" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="83.23" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="80" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="76.78" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="73.56" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="70.34" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="67.12" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="63.9" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="60.68" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="57.46" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="54.24" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="51.02" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="47.8" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="44.58" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="41.36" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="38.14" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="34.92" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="99.33" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="31.7" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="166.94" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="163.72" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="160.5" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="157.28" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="154.06" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="150.84" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="147.62" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="144.4" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="141.18" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="137.95" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="134.73" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="131.51" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="128.29" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="125.07" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="121.85" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="118.63" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="115.41" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="112.19" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="108.97" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="105.75" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="170.16" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="102.53" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="237.79" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="234.57" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="231.35" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="228.13" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="224.91" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="221.69" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="218.47" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="215.25" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="212.03" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="208.81" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="205.59" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="202.37" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="199.15" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="195.92" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="192.7" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="189.48" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="186.26" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="183.04" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="179.82" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="176.6" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="241.01" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="173.38" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="309.05" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="305.83" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="302.61" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="299.39" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="296.17" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="292.95" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="289.73" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="286.51" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="283.29" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="280.07" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="276.85" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="273.63" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="270.41" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="267.19" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="263.97" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="260.75" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="257.53" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="254.3" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="251.08" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="247.86" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="312.27" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="244.64" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="338.04" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="334.82" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="331.6" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="328.38" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="325.16" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="321.94" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="318.72" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="315.49" cy="4.64" r="0.8" fill="#74bbe7" />
          <circle cx="25.26" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="22.03" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="18.81" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="15.59" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="12.37" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="9.15" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="5.93" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="2.71" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="28.48" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="96.11" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="92.89" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="89.67" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="86.45" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="83.23" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="80" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="76.78" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="73.56" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="70.34" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="67.12" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="63.9" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="60.68" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="57.46" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="54.24" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="51.02" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="47.8" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="44.58" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="41.36" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="38.14" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="34.92" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="99.33" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="31.7" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="166.94" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="163.72" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="160.5" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="157.28" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="154.06" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="150.84" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="147.62" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="144.4" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="141.18" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="137.95" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="134.73" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="131.51" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="128.29" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="125.07" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="121.85" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="118.63" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="115.41" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="112.19" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="108.97" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="105.75" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="170.16" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="102.53" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="237.79" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="234.57" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="231.35" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="228.13" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="224.91" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="221.69" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="218.47" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="215.25" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="212.03" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="208.81" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="205.59" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="202.37" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="199.15" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="195.92" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="192.7" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="189.48" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="186.26" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="183.04" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="179.82" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="176.6" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="241.01" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="173.38" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="309.05" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="305.83" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="302.61" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="299.39" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="296.17" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="292.95" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="289.73" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="286.51" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="283.29" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="280.07" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="276.85" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="273.63" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="270.41" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="267.19" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="263.97" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="260.75" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="257.53" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="254.3" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="251.08" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="247.86" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="312.27" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="244.64" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="341.26" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="338.04" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="334.82" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="331.6" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="328.38" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="325.16" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="321.94" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="318.72" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="315.49" cy="7.5" r="0.8" fill="#74bbe7" />
          <circle cx="25.26" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="22.03" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="18.81" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="15.59" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="12.37" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="9.15" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="5.93" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="2.71" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="28.48" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="96.11" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="92.89" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="89.67" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="86.45" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="83.23" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="80" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="76.78" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="73.56" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="70.34" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="67.12" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="63.9" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="60.68" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="57.46" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="54.24" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="51.02" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="47.8" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="44.58" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="41.36" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="38.14" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="34.92" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="99.33" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="31.7" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="166.94" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="163.72" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="160.5" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="157.28" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="154.06" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="150.84" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="147.62" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="144.4" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="141.18" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="137.95" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="134.73" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="131.51" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="128.29" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="125.07" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="121.85" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="118.63" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="115.41" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="112.19" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="108.97" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="105.75" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="170.16" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="102.53" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="237.79" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="234.57" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="231.35" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="228.13" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="224.91" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="221.69" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="218.47" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="215.25" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="212.03" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="208.81" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="205.59" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="202.37" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="199.15" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="195.92" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="192.7" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="189.48" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="186.26" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="183.04" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="179.82" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="176.6" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="241.01" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="173.38" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="309.05" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="305.83" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="302.61" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="299.39" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="296.17" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="292.95" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="289.73" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="286.51" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="283.29" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="280.07" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="276.85" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="273.63" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="270.41" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="267.19" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="263.97" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="260.75" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="257.53" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="254.3" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="251.08" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="247.86" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="312.27" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="244.64" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="341.26" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="338.04" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="334.82" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="331.6" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="328.38" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="325.16" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="321.94" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="318.72" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="315.49" cy="10.39" r="0.8" fill="#74bbe7" />
          <circle cx="25.26" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="22.03" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="18.81" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="15.59" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="12.37" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="9.15" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="5.93" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="2.71" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="28.48" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="96.11" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="92.89" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="89.67" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="86.45" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="83.23" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="80" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="76.78" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="73.56" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="70.34" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="67.12" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="63.9" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="60.68" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="57.46" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="54.24" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="51.02" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="47.8" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="44.58" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="41.36" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="38.14" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="34.92" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="99.33" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="31.7" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="166.94" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="163.72" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="160.5" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="157.28" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="154.06" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="150.84" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="147.62" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="144.4" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="141.18" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="137.95" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="134.73" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="131.51" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="128.29" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="125.07" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="121.85" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="118.63" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="115.41" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="112.19" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="108.97" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="105.75" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="170.16" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="102.53" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="237.79" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="234.57" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="231.35" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="228.13" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="224.91" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="221.69" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="218.47" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="215.25" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="212.03" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="208.81" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="205.59" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="202.37" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="199.15" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="195.92" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="192.7" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="189.48" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="186.26" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="183.04" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="179.82" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="176.6" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="241.01" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="173.38" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="309.05" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="305.83" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="302.61" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="299.39" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="296.17" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="292.95" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="289.73" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="286.51" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="283.29" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="280.07" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="276.85" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="273.63" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="270.41" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="267.19" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="263.97" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="260.75" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="257.53" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="254.3" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="251.08" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="247.86" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="312.27" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="244.64" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="341.26" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="338.04" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="334.82" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="331.6" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="328.38" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="325.16" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="321.94" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="318.72" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="315.49" cy="13.07" r="0.8" fill="#74bbe7" />
          <circle cx="25.26" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="22.03" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="18.81" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="15.59" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="12.37" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="9.15" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="5.93" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="2.71" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="28.48" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="96.11" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="92.89" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="89.67" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="86.45" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="83.23" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="80" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="76.78" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="73.56" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="70.34" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="67.12" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="63.9" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="60.68" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="57.46" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="54.24" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="51.02" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="47.8" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="44.58" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="41.36" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="38.14" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="34.92" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="99.33" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="31.7" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="166.94" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="163.72" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="160.5" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="157.28" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="154.06" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="150.84" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="147.62" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="144.4" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="141.18" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="137.95" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="134.73" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="131.51" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="128.29" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="125.07" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="121.85" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="118.63" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="115.41" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="112.19" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="108.97" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="105.75" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="170.16" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="102.53" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="237.79" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="234.57" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="231.35" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="228.13" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="224.91" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="221.69" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="218.47" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="215.25" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="212.03" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="208.81" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="205.59" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="202.37" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="199.15" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="195.92" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="192.7" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="189.48" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="186.26" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="183.04" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="179.82" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="176.6" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="241.01" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="173.38" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="309.05" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="305.83" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="302.61" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="299.39" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="296.17" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="292.95" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="289.73" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="286.51" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="283.29" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="280.07" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="276.85" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="273.63" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="270.41" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="267.19" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="263.97" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="260.75" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="257.53" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="254.3" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="251.08" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="247.86" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="312.27" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="244.64" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="341.26" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="338.04" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="334.82" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="331.6" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="328.38" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="325.16" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="321.94" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="318.72" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="315.49" cy="15.96" r="0.8" fill="#74bbe7" />
          <circle cx="25.26" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="22.03" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="18.81" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="15.59" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="12.37" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="9.15" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="5.93" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="2.71" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="28.48" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="96.11" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="92.89" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="89.67" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="86.45" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="83.23" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="80" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="76.78" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="73.56" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="70.34" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="67.12" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="63.9" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="60.68" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="57.46" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="54.24" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="51.02" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="47.8" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="44.58" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="41.36" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="38.14" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="34.92" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="99.33" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="31.7" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="166.94" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="163.72" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="160.5" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="157.28" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="154.06" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="150.84" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="147.62" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="144.4" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="141.18" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="137.95" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="134.73" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="131.51" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="128.29" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="125.07" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="121.85" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="118.63" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="115.41" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="112.19" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="108.97" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="105.75" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="170.16" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="102.53" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="237.79" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="234.57" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="231.35" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="228.13" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="224.91" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="221.69" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="218.47" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="215.25" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="212.03" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="208.81" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="205.59" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="202.37" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="199.15" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="195.92" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="192.7" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="189.48" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="186.26" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="183.04" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="179.82" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="176.6" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="241.01" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="173.38" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="309.05" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="305.83" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="302.61" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="299.39" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="296.17" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="292.95" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="289.73" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="286.51" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="283.29" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="280.07" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="276.85" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="273.63" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="270.41" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="267.19" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="263.97" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="260.75" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="257.53" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="254.3" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="251.08" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="247.86" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="312.27" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="244.64" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="341.26" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="338.04" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="334.82" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="331.6" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="328.38" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="325.16" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="321.94" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="318.72" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="315.49" cy="18.83" r="0.8" fill="#74bbe7" />
          <circle cx="25.26" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="22.03" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="18.81" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="15.59" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="12.37" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="9.15" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="5.93" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="2.71" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="28.48" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="96.11" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="92.89" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="89.67" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="86.45" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="83.23" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="80" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="76.78" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="73.56" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="70.34" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="67.12" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="63.9" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="60.68" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="57.46" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="54.24" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="51.02" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="47.8" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="44.58" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="41.36" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="38.14" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="34.92" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="99.33" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="31.7" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="166.94" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="163.72" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="160.5" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="157.28" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="154.06" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="150.84" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="147.62" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="144.4" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="141.18" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="137.95" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="134.73" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="131.51" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="128.29" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="125.07" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="121.85" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="118.63" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="115.41" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="112.19" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="108.97" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="105.75" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="170.16" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="102.53" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="237.79" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="234.57" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="231.35" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="228.13" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="224.91" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="221.69" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="218.47" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="215.25" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="212.03" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="208.81" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="205.59" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="202.37" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="199.15" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="195.92" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="192.7" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="189.48" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="186.26" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="183.04" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="179.82" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="176.6" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="241.01" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="173.38" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="309.05" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="305.83" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="302.61" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="299.39" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="296.17" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="292.95" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="289.73" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="286.51" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="283.29" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="280.07" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="276.85" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="273.63" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="270.41" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="267.19" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="263.97" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="260.75" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="257.53" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="254.3" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="251.08" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="247.86" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="312.27" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="244.64" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="341.26" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="338.04" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="334.82" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="331.6" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="328.38" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="325.16" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="321.94" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="318.72" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="315.49" cy="21.71" r="0.8" fill="#74bbe7" />
          <circle cx="25.26" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="22.03" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="18.81" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="15.59" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="12.37" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="9.15" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="5.93" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="2.71" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="28.48" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="96.11" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="92.89" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="89.67" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="86.45" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="83.23" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="80" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="76.78" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="73.56" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="70.34" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="67.12" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="63.9" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="60.68" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="57.46" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="54.24" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="51.02" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="47.8" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="44.58" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="41.36" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="38.14" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="34.92" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="99.33" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="31.7" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="166.94" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="163.72" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="160.5" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="157.28" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="154.06" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="150.84" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="147.62" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="144.4" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="141.18" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="137.95" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="134.73" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="131.51" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="128.29" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="125.07" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="121.85" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="118.63" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="115.41" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="112.19" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="108.97" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="105.75" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="170.16" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="102.53" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="237.79" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="234.57" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="231.35" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="228.13" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="224.91" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="221.69" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="218.47" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="215.25" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="212.03" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="208.81" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="205.59" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="202.37" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="199.15" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="195.92" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="192.7" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="189.48" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="186.26" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="183.04" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="179.82" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="176.6" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="241.01" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="173.38" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="309.05" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="305.83" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="302.61" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="299.39" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="296.17" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="292.95" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="289.73" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="286.51" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="283.29" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="280.07" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="276.85" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="273.63" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="270.41" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="267.19" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="263.97" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="260.75" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="257.53" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="254.3" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="251.08" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="247.86" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="312.27" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="244.64" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="341.26" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="338.04" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="334.82" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="331.6" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="328.38" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="325.16" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="321.94" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="318.72" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="315.49" cy="24.59" r="0.8" fill="#74bbe7" />
          <circle cx="25.26" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="22.03" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="18.81" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="15.59" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="12.37" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="9.15" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="5.93" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="2.71" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="28.48" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="96.11" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="92.89" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="89.67" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="86.45" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="83.23" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="80" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="76.78" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="73.56" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="70.34" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="67.12" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="63.9" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="60.68" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="57.46" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="54.24" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="51.02" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="47.8" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="44.58" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="41.36" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="38.14" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="34.92" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="99.33" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="31.7" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="166.94" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="163.72" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="160.5" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="157.28" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="154.06" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="150.84" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="147.62" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="144.4" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="141.18" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="137.95" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="134.73" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="131.51" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="128.29" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="125.07" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="121.85" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="118.63" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="115.41" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="112.19" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="108.97" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="105.75" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="170.16" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="102.53" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="237.79" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="234.57" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="231.35" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="228.13" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="224.91" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="221.69" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="218.47" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="215.25" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="212.03" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="208.81" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="205.59" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="202.37" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="199.15" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="195.92" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="192.7" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="189.48" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="186.26" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="183.04" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="179.82" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="176.6" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="241.01" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="173.38" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="309.05" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="305.83" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="302.61" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="299.39" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="296.17" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="292.95" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="289.73" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="286.51" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="283.29" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="280.07" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="276.85" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="273.63" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="270.41" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="267.19" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="263.97" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="260.75" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="257.53" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="254.3" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="251.08" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="247.86" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="312.27" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="244.64" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="341.26" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="338.04" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="334.82" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="331.6" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="328.38" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="325.16" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="321.94" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="318.72" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="315.49" cy="27.47" r="0.8" fill="#74bbe7" />
          <circle cx="25.26" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="22.03" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="18.81" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="15.59" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="12.37" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="9.15" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="5.93" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="2.71" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="28.48" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="96.11" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="92.89" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="89.67" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="86.45" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="83.23" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="80" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="76.78" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="73.56" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="70.34" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="67.12" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="63.9" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="60.68" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="57.46" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="54.24" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="51.02" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="47.8" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="44.58" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="41.36" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="38.14" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="34.92" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="99.33" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="31.7" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="166.94" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="163.72" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="160.5" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="157.28" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="154.06" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="150.84" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="147.62" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="144.4" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="141.18" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="137.95" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="134.73" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="131.51" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="128.29" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="125.07" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="121.85" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="118.63" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="115.41" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="112.19" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="108.97" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="105.75" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="170.16" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="102.53" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="237.79" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="234.57" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="231.35" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="228.13" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="224.91" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="221.69" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="218.47" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="215.25" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="212.03" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="208.81" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="205.59" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="202.37" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="199.15" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="195.92" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="192.7" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="189.48" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="186.26" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="183.04" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="179.82" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="176.6" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="241.01" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="173.38" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="309.05" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="305.83" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="302.61" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="299.39" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="296.17" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="292.95" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="289.73" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="286.51" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="283.29" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="280.07" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="276.85" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="273.63" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="270.41" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="267.19" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="263.97" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="260.75" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="257.53" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="254.3" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="251.08" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="247.86" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="312.27" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="244.64" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="341.26" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="338.04" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="334.82" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="331.6" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="328.38" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="325.16" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="321.94" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="318.72" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="315.49" cy="30.34" r="0.8" fill="#74bbe7" />
          <circle cx="25.26" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="22.03" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="18.81" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="15.59" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="12.37" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="9.15" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="5.93" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="2.71" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="28.48" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="96.11" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="92.89" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="89.67" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="86.45" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="83.23" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="80" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="76.78" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="73.56" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="70.34" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="67.12" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="63.9" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="60.68" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="57.46" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="54.24" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="51.02" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="47.8" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="44.58" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="41.36" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="38.14" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="34.92" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="99.33" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="31.7" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="166.94" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="163.72" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="160.5" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="157.28" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="154.06" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="150.84" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="147.62" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="144.4" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="141.18" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="137.95" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="134.73" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="131.51" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="128.29" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="125.07" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="121.85" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="118.63" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="115.41" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="112.19" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="108.97" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="105.75" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="170.16" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="102.53" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="237.79" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="234.57" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="231.35" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="228.13" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="224.91" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="221.69" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="218.47" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="215.25" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="212.03" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="208.81" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="205.59" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="202.37" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="199.15" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="195.92" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="192.7" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="189.48" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="186.26" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="183.04" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="179.82" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="176.6" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="241.01" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="173.38" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="309.05" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="305.83" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="302.61" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="299.39" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="296.17" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="292.95" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="289.73" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="286.51" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="283.29" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="280.07" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="276.85" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="273.63" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="270.41" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="267.19" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="263.97" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="260.75" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="257.53" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="254.3" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="251.08" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="247.86" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="312.27" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="244.64" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="341.26" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="338.04" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="334.82" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="331.6" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="328.38" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="325.16" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="321.94" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="318.72" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="315.49" cy="33.23" r="0.8" fill="#74bbe7" />
          <circle cx="25.26" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="22.03" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="18.81" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="15.59" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="12.37" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="9.15" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="5.93" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="2.71" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="28.48" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="96.11" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="92.89" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="89.67" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="86.45" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="83.23" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="80" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="76.78" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="73.56" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="70.34" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="67.12" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="63.9" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="60.68" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="57.46" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="54.24" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="51.02" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="47.8" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="44.58" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="41.36" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="38.14" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="34.92" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="99.33" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="31.7" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="166.94" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="163.72" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="160.5" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="157.28" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="154.06" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="150.84" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="147.62" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="144.4" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="141.18" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="137.95" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="134.73" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="131.51" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="128.29" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="125.07" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="121.85" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="118.63" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="115.41" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="112.19" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="108.97" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="105.75" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="170.16" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="102.53" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="237.79" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="234.57" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="231.35" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="228.13" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="224.91" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="221.69" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="218.47" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="215.25" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="212.03" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="208.81" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="205.59" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="202.37" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="199.15" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="195.92" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="192.7" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="189.48" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="186.26" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="183.04" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="179.82" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="176.6" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="241.01" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="173.38" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="309.05" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="305.83" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="302.61" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="299.39" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="296.17" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="292.95" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="289.73" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="286.51" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="283.29" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="280.07" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="276.85" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="273.63" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="270.41" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="267.19" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="263.97" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="260.75" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="257.53" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="254.3" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="251.08" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="247.86" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="312.27" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="244.64" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="341.26" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="338.04" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="334.82" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="331.6" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="328.38" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="325.16" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="321.94" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="318.72" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="315.49" cy="35.91" r="0.8" fill="#74bbe7" />
          <circle cx="25.26" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="22.03" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="18.81" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="15.59" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="12.37" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="9.15" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="5.93" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="2.71" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="28.48" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="96.11" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="92.89" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="89.67" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="86.45" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="83.23" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="80" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="76.78" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="73.56" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="70.34" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="67.12" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="63.9" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="60.68" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="57.46" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="54.24" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="51.02" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="47.8" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="44.58" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="41.36" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="38.14" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="34.92" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="99.33" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="31.7" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="166.94" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="163.72" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="160.5" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="157.28" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="154.06" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="150.84" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="147.62" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="144.4" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="141.18" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="137.95" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="134.73" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="131.51" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="128.29" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="125.07" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="121.85" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="118.63" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="115.41" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="112.19" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="108.97" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="105.75" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="170.16" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="102.53" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="237.79" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="234.57" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="231.35" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="228.13" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="224.91" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="221.69" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="218.47" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="215.25" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="212.03" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="208.81" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="205.59" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="202.37" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="199.15" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="195.92" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="192.7" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="189.48" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="186.26" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="183.04" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="179.82" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="176.6" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="241.01" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="173.38" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="309.05" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="305.83" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="302.61" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="299.39" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="296.17" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="292.95" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="289.73" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="286.51" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="283.29" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="280.07" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="276.85" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="273.63" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="270.41" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="267.19" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="263.97" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="260.75" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="257.53" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="254.3" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="251.08" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="247.86" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="312.27" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="244.64" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="341.26" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="338.04" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="334.82" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="331.6" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="328.38" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="325.16" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="321.94" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="318.72" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="315.49" cy="38.8" r="0.8" fill="#74bbe7" />
          <circle cx="25.26" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="22.03" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="18.81" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="15.59" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="12.37" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="9.15" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="5.93" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="2.71" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="28.48" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="96.11" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="92.89" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="89.67" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="86.45" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="83.23" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="80" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="76.78" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="73.56" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="70.34" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="67.12" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="63.9" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="60.68" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="57.46" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="54.24" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="51.02" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="47.8" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="44.58" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="41.36" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="38.14" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="34.92" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="99.33" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="31.7" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="166.94" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="163.72" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="160.5" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="157.28" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="154.06" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="150.84" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="147.62" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="144.4" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="141.18" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="137.95" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="134.73" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="131.51" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="128.29" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="125.07" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="121.85" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="118.63" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="115.41" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="112.19" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="108.97" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="105.75" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="170.16" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="102.53" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="237.79" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="234.57" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="231.35" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="228.13" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="224.91" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="221.69" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="218.47" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="215.25" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="212.03" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="208.81" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="205.59" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="202.37" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="199.15" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="195.92" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="192.7" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="189.48" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="186.26" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="183.04" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="179.82" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="176.6" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="241.01" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="173.38" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="309.05" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="305.83" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="302.61" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="299.39" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="296.17" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="292.95" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="289.73" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="286.51" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="283.29" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="280.07" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="276.85" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="273.63" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="270.41" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="267.19" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="263.97" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="260.75" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="257.53" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="254.3" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="251.08" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="247.86" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="312.27" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="244.64" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="341.26" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="338.04" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="334.82" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="331.6" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="328.38" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="325.16" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="321.94" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="318.72" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="315.49" cy="41.66" r="0.8" fill="#74bbe7" />
          <circle cx="25.26" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="22.03" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="18.81" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="15.59" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="12.37" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="9.15" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="5.93" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="2.71" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="28.48" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="96.11" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="92.89" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="89.67" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="86.45" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="83.23" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="80" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="76.78" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="73.56" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="70.34" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="67.12" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="63.9" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="60.68" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="57.46" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="54.24" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="51.02" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="47.8" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="44.58" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="41.36" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="38.14" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="34.92" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="99.33" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="31.7" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="166.94" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="163.72" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="160.5" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="157.28" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="154.06" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="150.84" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="147.62" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="144.4" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="141.18" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="137.95" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="134.73" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="131.51" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="128.29" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="125.07" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="121.85" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="118.63" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="115.41" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="112.19" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="108.97" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="105.75" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="170.16" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="102.53" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="237.79" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="234.57" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="231.35" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="228.13" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="224.91" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="221.69" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="218.47" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="215.25" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="212.03" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="208.81" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="205.59" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="202.37" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="199.15" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="195.92" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="192.7" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="189.48" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="186.26" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="183.04" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="179.82" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="176.6" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="241.01" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="173.38" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="309.05" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="305.83" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="302.61" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="299.39" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="296.17" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="292.95" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="289.73" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="286.51" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="283.29" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="280.07" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="276.85" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="273.63" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="270.41" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="267.19" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="263.97" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="260.75" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="257.53" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="254.3" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="251.08" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="247.86" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="312.27" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="244.64" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="338.04" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="334.82" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="331.6" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="328.38" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="325.16" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="321.94" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="318.72" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="315.49" cy="44.55" r="0.8" fill="#74bbe7" />
          <circle cx="25.26" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="22.03" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="18.81" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="15.59" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="12.37" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="9.15" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="5.93" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="28.48" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="96.11" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="92.89" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="89.67" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="86.45" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="83.23" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="80" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="76.78" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="73.56" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="70.34" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="67.12" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="63.9" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="60.68" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="57.46" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="54.24" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="51.02" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="47.8" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="44.58" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="41.36" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="38.14" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="34.92" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="99.33" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="31.7" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="166.94" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="163.72" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="160.5" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="157.28" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="154.06" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="150.84" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="147.62" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="144.4" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="141.18" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="137.95" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="134.73" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="131.51" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="128.29" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="125.07" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="121.85" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="118.63" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="115.41" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="112.19" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="108.97" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="105.75" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="170.16" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="102.53" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="237.79" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="234.57" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="231.35" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="228.13" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="224.91" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="221.69" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="218.47" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="215.25" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="212.03" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="208.81" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="205.59" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="202.37" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="199.15" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="195.92" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="192.7" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="189.48" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="186.26" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="183.04" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="179.82" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="176.6" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="241.01" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="173.38" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="309.05" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="305.83" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="302.61" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="299.39" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="296.17" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="292.95" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="289.73" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="286.51" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="283.29" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="280.07" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="276.85" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="273.63" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="270.41" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="267.19" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="263.97" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="260.75" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="257.53" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="254.3" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="251.08" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="247.86" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="312.27" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="244.64" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="334.82" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="331.6" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="328.38" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="325.16" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="321.94" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="318.72" cy="47.39" r="0.8" fill="#74bbe7" />
          <circle cx="315.49" cy="47.39" r="0.8" fill="#74bbe7" />
        </g>
        <g id="ButtonGroup">
          <foreignObject
            x="48.42"
            y="198.31"
            width="95"
            height="17"
            className="flex px-2"
          >
            <BaseButton
              label="Book Now"
              onClick={() => handleOpenBooking()}
              style={{
                background: "linear-gradient(180deg, #B3D05F 0%, #47B295 100%)",
              }}
              className="rounded-full w-full h-full text-xxs self-center text-[#fff] font-semibold"
            />
          </foreignObject>
          <foreignObject
            x="198.81"
            y="212.72"
            width="95"
            height="17"
            className="flex px-2"
          >
            <BaseButton
              label="Book Now"
              onClick={() => handleOpenBooking()}
              style={{
                background: "linear-gradient(180deg, #898FC3 0%, #84BAE4 100%)",
              }}
              className="rounded-full w-full h-full text-xxs self-center text-[#fff] font-semibold"
            />
          </foreignObject>
          <foreignObject
            x="199.19"
            y="417.58"
            width="95"
            height="17"
            className="flex px-2"
          >
            <BaseButton
              label="Book Now"
              onClick={() => handleOpenBooking()}
              style={{
                background: "linear-gradient(180deg, #575BA3 0%, #8561A4 100%)",
              }}
              className="rounded-full w-full h-full text-xxs self-center text-[#fff] font-semibold"
            />
          </foreignObject>
          <foreignObject
            x="48.58"
            y="417.31"
            width="95"
            height="17"
            className="flex px-2"
          >
            <BaseButton
              label="Book Now"
              onClick={() => handleOpenBooking()}
              style={{
                background: "linear-gradient(180deg, #FFA149 0%, #FF6258 100%)",
              }}
              className="rounded-full w-full h-full text-xxs self-center text-[#fff] font-semibold"
            />
          </foreignObject>
        </g>
        <g filter="url(#filter-9)">
          <path
            d="M126.57,11.54a13.59,13.59,0,0,1,7.6,1.86,5.56,5.56,0,0,1,2.8,4.78,7.16,7.16,0,0,1-1.67,4.42A12,12,0,0,1,131,26.05a11.61,11.61,0,0,1-5.26,1.3,6,6,0,0,1-2.88-.58q-2,4.92-2,7.45a4.51,4.51,0,0,1-3.24-.26,2.43,2.43,0,0,1-1.3-2.3c0-1.25.68-3.2,2-5.83A40.6,40.6,0,0,1,123,18.62q2.65-3.26,4.18-3.26a2.17,2.17,0,0,1,1.88.91,29.65,29.65,0,0,0-5.54,9,10.52,10.52,0,0,0,2.86-.72,15.07,15.07,0,0,0,3-1.56,8.16,8.16,0,0,0,2.36-2.43,5.68,5.68,0,0,0,.94-3.13,3.61,3.61,0,0,0-1.41-3.11,6.53,6.53,0,0,0-3.93-1,10.63,10.63,0,0,0-4.51,1,12.16,12.16,0,0,0-3.67,2.54,14.39,14.39,0,0,0-2.39,3.24,6.91,6.91,0,0,0-.9,3.17,3.12,3.12,0,0,0,.39,1.59,2.28,2.28,0,0,1-1.14.2,3.38,3.38,0,0,1-2.46-1,3.77,3.77,0,0,1-1-2.81,6.79,6.79,0,0,1,2.19-4.77,15.29,15.29,0,0,1,5.64-3.57A19.94,19.94,0,0,1,126.57,11.54Z"
            fill="#1b0f44"
          />
          <path
            d="M152.81,23.49a1.13,1.13,0,0,1,.86.22.66.66,0,0,1,.28.72q-5.44,7.68-8.3,7.68a2,2,0,0,1-1.75-.95,3.94,3.94,0,0,1-.65-2.25,2.93,2.93,0,0,1,0-.52q-3.79,4.53-6,4.53a2.29,2.29,0,0,1-1.64-.74,3.1,3.1,0,0,1-.76-2.3,11.32,11.32,0,0,1,1-4.31,14.16,14.16,0,0,1,2.83-4.33,5.2,5.2,0,0,1,3.7-1.89,3.29,3.29,0,0,1,1.94.61,3.16,3.16,0,0,1,1.2,1.75c1-1.53,1.86-2.3,2.66-2.3a2,2,0,0,1,1.34.47,2,2,0,0,1,.7.93,10,10,0,0,1-1,1.76,22.93,22.93,0,0,1-1.58,2.12,10.57,10.57,0,0,0-.55,2.92c0,.78.18,1.17.55,1.17C148.5,28.78,150.2,27,152.81,23.49Zm-13.25,5.93c.35,0,1-.46,1.9-1.39a28.39,28.39,0,0,0,2.77-3.34,17.36,17.36,0,0,1,.71-1.71c-.48-.91-1-1.36-1.56-1.36s-1.25.43-1.94,1.31a11.58,11.58,0,0,0-1.68,2.93,7.32,7.32,0,0,0-.65,2.62C139.11,29.11,139.26,29.42,139.56,29.42Z"
            fill="#1b0f44"
          />
          <path
            d="M165.51,24.43a25.73,25.73,0,0,1-5.63,6.22,9.3,9.3,0,0,1-5.51,2.18,3.93,3.93,0,0,1-2.9-1.12,4,4,0,0,1-1.12-3,9.68,9.68,0,0,1,1.12-4.21,12.84,12.84,0,0,1,3-4,5.62,5.62,0,0,1,3.71-1.67,4.87,4.87,0,0,1,2.56.75A2.21,2.21,0,0,1,162,21.58a3.77,3.77,0,0,1-1.64,2.82c-1.09.91-2,1.22-2.8.94A9.78,9.78,0,0,0,159,21.23q0-.72-.51-.72c-.37,0-.88.4-1.53,1.19a13,13,0,0,0-1.78,3,7.85,7.85,0,0,0-.81,3.18c0,1.4.6,2.1,1.81,2.1a6.74,6.74,0,0,0,4.25-2,21.5,21.5,0,0,0,3.92-4.46,1.14,1.14,0,0,1,.86.22A.64.64,0,0,1,165.51,24.43Z"
            fill="#1b0f44"
          />
          <path
            d="M179,23.49a1.14,1.14,0,0,1,.86.22.64.64,0,0,1,.27.72q-5.15,7.68-8.42,7.68a2.52,2.52,0,0,1-2.33-1.52,5.59,5.59,0,0,1-.42-3.69,23.67,23.67,0,0,0-4,5.73,2.79,2.79,0,0,1-.68.07,2.38,2.38,0,0,1-1.78-.72,2.43,2.43,0,0,1-.72-1.81q0-2,2-6.4a48.48,48.48,0,0,1,4.67-8.1q2.65-3.7,4.21-3.71a2.89,2.89,0,0,1,1.41.39,2,2,0,0,1,.89.81,29.84,29.84,0,0,0-5.7,7.5,40.73,40.73,0,0,0-3.79,8.21,47.7,47.7,0,0,1,5.26-6.4c2-2,3.46-3,4.52-3a1.55,1.55,0,0,1,1.13.48,1.51,1.51,0,0,1,.49,1.1A8.53,8.53,0,0,1,175,22.25a27,27,0,0,1-2.84,1.34,5.13,5.13,0,0,0-.29,2.45,4.68,4.68,0,0,0,.73,2c.38.56.77.85,1.18.85Q175.11,28.91,179,23.49Z"
            fill="#1b0f44"
          />
          <path
            d="M194.32,23.49a1.14,1.14,0,0,1,.86.22.66.66,0,0,1,.27.72q-5.44,7.68-8.29,7.68a2,2,0,0,1-1.75-.95,3.87,3.87,0,0,1-.65-2.25,3.19,3.19,0,0,1,0-.52c-2.53,3-4.51,4.53-6,4.53a2.31,2.31,0,0,1-1.64-.74,3.14,3.14,0,0,1-.76-2.3,11.32,11.32,0,0,1,1-4.31,14.19,14.19,0,0,1,2.84-4.33A5.2,5.2,0,0,1,184,19.35a3.34,3.34,0,0,1,2,.61,3.09,3.09,0,0,1,1.19,1.75c1-1.53,1.86-2.3,2.66-2.3a2.07,2.07,0,0,1,1.35.47,1.94,1.94,0,0,1,.69.93,9.71,9.71,0,0,1-1,1.76,21.25,21.25,0,0,1-1.59,2.12,10.57,10.57,0,0,0-.55,2.92c0,.78.18,1.17.55,1.17C190,28.78,191.7,27,194.32,23.49Zm-13.25,5.93c.34,0,1-.46,1.89-1.39a27.44,27.44,0,0,0,2.77-3.34,17.36,17.36,0,0,1,.71-1.71c-.47-.91-1-1.36-1.55-1.36s-1.25.43-1.94,1.31a11.32,11.32,0,0,0-1.69,2.93,7.54,7.54,0,0,0-.65,2.62C180.61,29.11,180.76,29.42,181.07,29.42Z"
            fill="#1b0f44"
          />
          <path
            d="M209.67,23.49a1.12,1.12,0,0,1,.86.22.66.66,0,0,1,.28.72A24.88,24.88,0,0,1,207,28.94a28.38,28.38,0,0,1-4.28,3.17,25.45,25.45,0,0,1-3.92,6.66,6.31,6.31,0,0,1-4.8,2.58,3.84,3.84,0,0,1-2.49-.81,2.6,2.6,0,0,1-1-2.11,3,3,0,0,1,.12-.86,2.36,2.36,0,0,1,.4-.79,8.67,8.67,0,0,1,.59-.7,5.32,5.32,0,0,1,.85-.68c.38-.25.72-.46,1-.63s.7-.39,1.21-.66,1-.5,1.33-.67.88-.41,1.52-.73l1.59-.76c.41-1.12.83-2.45,1.27-4a24.6,24.6,0,0,1-3.31,3.15,4.92,4.92,0,0,1-2.82,1.28,2.44,2.44,0,0,1-1.68-.72,2.64,2.64,0,0,1-.78-2,10.46,10.46,0,0,1,1.08-4.18,14.61,14.61,0,0,1,2.94-4.26,5.3,5.3,0,0,1,3.69-1.86,3.13,3.13,0,0,1,1.86.58,3.21,3.21,0,0,1,1.19,1.69c.88-1.47,1.78-2.21,2.69-2.21a2.07,2.07,0,0,1,1.34.5,2.32,2.32,0,0,1,.73.9,14.26,14.26,0,0,1-2,3.17,48.89,48.89,0,0,1-1.59,5.61A24.69,24.69,0,0,0,209.67,23.49ZM193.93,39.21a3,3,0,0,0,2.1-1.38,17,17,0,0,0,2.21-3.77Q193,36.64,193,38.37a.74.74,0,0,0,.29.61A1,1,0,0,0,193.93,39.21Zm2.43-9.79c.37,0,1-.45,1.94-1.37a29.41,29.41,0,0,0,2.82-3.29c.24-.65.48-1.22.71-1.72-.49-.95-1-1.42-1.58-1.42-.95,0-1.95,1-3,2.88a9.27,9.27,0,0,0-1.33,4.15C195.94,29.16,196.08,29.42,196.36,29.42Z"
            fill="#1b0f44"
          />
          <path
            d="M221.53,23.49a1.13,1.13,0,0,1,.86.22.66.66,0,0,1,.28.72q-6,8.4-11.28,8.4a4.24,4.24,0,0,1-3.06-1.09,3.91,3.91,0,0,1-1.12-3,9.72,9.72,0,0,1,1.12-4.22,12.81,12.81,0,0,1,3-4,5.79,5.79,0,0,1,3.78-1.69,4,4,0,0,1,2.54.85,2.47,2.47,0,0,1,1.09,2c0,1.06-.72,2.26-2.14,3.6a15.7,15.7,0,0,1-5.41,3.21A1.71,1.71,0,0,0,213.08,30a7.24,7.24,0,0,0,4.4-2.07A21.38,21.38,0,0,0,221.53,23.49Zm-6.12-3c-.58,0-1.38.78-2.38,2.34a11.4,11.4,0,0,0-1.83,4.27,12.66,12.66,0,0,0,3.38-2.85A5,5,0,0,0,216,21.32C216,20.78,215.82,20.51,215.41,20.51Z"
            fill="#1b0f44"
          />
          <path
            d="M233.26,23.49a1.13,1.13,0,0,1,.86.22.66.66,0,0,1,.28.72,9.14,9.14,0,0,1-5.48,4,5.15,5.15,0,0,1-1.7,3.45,5,5,0,0,1-3.35,1.24,4.28,4.28,0,0,1-3.68-1.94,6.75,6.75,0,0,1-1.38-3.95c0-1.49.51-2.11,1.52-1.85A15.43,15.43,0,0,0,225,26.9c-.07-3.26-.09-5.12-.07-5.58l-.12-.13c-.11.2-.83,1.28-2.14,3.24a1.4,1.4,0,0,1-.8-.25.73.73,0,0,1-.34-.69,44.88,44.88,0,0,0,2.66-4A2.27,2.27,0,0,1,226.57,18c1.22,0,2,.32,2.29,1a4.41,4.41,0,0,0-.54,2,17.24,17.24,0,0,0,.26,3.18c.21,1.33.32,2.16.34,2.46A8.26,8.26,0,0,0,233.26,23.49Zm-9.94,6.87a1.43,1.43,0,0,0,1.14-.55,2.29,2.29,0,0,0,.57-1.23A10.93,10.93,0,0,1,220.46,27a4.73,4.73,0,0,0,1.12,2.51A2.39,2.39,0,0,0,223.32,30.36Z"
            fill="#1b0f44"
          />
        </g>
        <rect
          x="0.5"
          y="0.5"
          width="341.72"
          height="49.26"
          rx="12"
          fill="none"
          stroke="#1b0f44"
          stroke-miterlimit="10"
        />
        <g id="Prices">
          <path
            d="M76,113.84a2.11,2.11,0,0,1-1.46-.46,1.36,1.36,0,0,1-.39-1.3h.61c-.11.82.32,1.23,1.3,1.23a1.66,1.66,0,0,0,.9-.21.88.88,0,0,0,.42-.59.68.68,0,0,0-.14-.65,1.73,1.73,0,0,0-.86-.37l-.42-.1a1.8,1.8,0,0,1-1-.52,1.09,1.09,0,0,1-.22-1,1.4,1.4,0,0,1,.62-.9,2.37,2.37,0,0,1,1.27-.32,1.86,1.86,0,0,1,1.41.43,1.35,1.35,0,0,1,.27,1.13h-.6a.81.81,0,0,0-.18-.77,1.34,1.34,0,0,0-.94-.26c-.74,0-1.16.25-1.26.74a.66.66,0,0,0,.14.62,1.74,1.74,0,0,0,.83.37l.42.1c1,.23,1.39.76,1.22,1.59a1.42,1.42,0,0,1-.65.92A2.35,2.35,0,0,1,76,113.84Z"
            fill="#fff"
          />
          <path
            d="M80.55,110.6h-.74l-.48,2.25a.38.38,0,0,0,0,.31.37.37,0,0,0,.29.1H80l-.1.47-.35,0a.69.69,0,0,1-.76-.92l.48-2.23h-.6l.11-.49h.59l.22-1h.56l-.22,1h.74Z"
            fill="#fff"
          />
          <path
            d="M83.76,111.29,83.4,113c0,.2,0,.3.16.3h.17l-.09.46-.31,0a.54.54,0,0,1-.38-.13.45.45,0,0,1-.13-.36,1.46,1.46,0,0,1-1.2.55,1.16,1.16,0,0,1-.92-.34,1,1,0,0,1-.19-.91c.12-.52.62-.83,1.5-.92l.57-.06q.58-.08.66-.42a.48.48,0,0,0-.1-.48.93.93,0,0,0-.63-.17,1.24,1.24,0,0,0-.68.16,1,1,0,0,0-.34.56H81a1.6,1.6,0,0,1,.54-.92,1.71,1.71,0,0,1,1.07-.28Q84,110,83.76,111.29Zm-2,2a1.39,1.39,0,0,0,.79-.24,1.18,1.18,0,0,0,.46-.68l.13-.57a1.23,1.23,0,0,1-.52.16l-.54.07c-.58.07-.9.27-1,.6a.53.53,0,0,0,.11.49A.7.7,0,0,0,81.72,113.33Z"
            fill="#fff"
          />
          <path
            d="M86.57,110.06h.18l-.11.54H86.5a1.55,1.55,0,0,0-.93.28,1.25,1.25,0,0,0-.48.79l-.44,2.06h-.56l.77-3.62h.53l-.12.56A1.65,1.65,0,0,1,86.57,110.06Z"
            fill="#fff"
          />
          <path
            d="M88.78,110.6H88l-.48,2.25a.49.49,0,0,0,0,.31.38.38,0,0,0,.3.1h.32l-.09.47-.35,0a.81.81,0,0,1-.66-.24.79.79,0,0,1-.11-.68l.48-2.23h-.6l.11-.49h.59l.22-1h.56l-.22,1h.75Z"
            fill="#fff"
          />
          <path
            d="M89.27,113.73h-.56l.77-3.62H90Zm.9-4.16h-.59l.14-.69h.59Z"
            fill="#fff"
          />
          <path
            d="M92.52,110a1.06,1.06,0,0,1,.9.38,1.34,1.34,0,0,1,.11,1.08L93,113.73h-.56l.48-2.27c.14-.62-.06-.93-.58-.93a1.27,1.27,0,0,0-.82.31,1.39,1.39,0,0,0-.46.84l-.44,2H90.1l.77-3.62h.54l-.11.49A1.6,1.6,0,0,1,92.52,110Z"
            fill="#fff"
          />
          <path
            d="M97.26,110.62l.11-.51h.53l-.74,3.48a2.1,2.1,0,0,1-.64,1.19,1.85,1.85,0,0,1-1.28.4,1.55,1.55,0,0,1-1-.31,1,1,0,0,1-.33-.85h.56a.63.63,0,0,0,.21.52,1.11,1.11,0,0,0,.66.17,1.25,1.25,0,0,0,1.31-1.12l.05-.25a1.63,1.63,0,0,1-1.16.47,1.33,1.33,0,0,1-1.18-.57,1.84,1.84,0,0,1-.19-1.47,2.31,2.31,0,0,1,.73-1.29,2,2,0,0,1,1.27-.45A1.22,1.22,0,0,1,97.26,110.62Zm-1.69,2.69a1.34,1.34,0,0,0,.89-.34A1.8,1.8,0,0,0,97,112l0-.22a1.3,1.3,0,0,0-.15-.95.9.9,0,0,0-.78-.34,1.25,1.25,0,0,0-.9.36,1.77,1.77,0,0,0-.5,1,1.44,1.44,0,0,0,.08,1A.85.85,0,0,0,95.57,113.31Z"
            fill="#fff"
          />
          <path
            d="M102.61,111.29l-.36,1.68c0,.2,0,.3.16.3h.17l-.09.46-.31,0a.56.56,0,0,1-.38-.13.45.45,0,0,1-.13-.36,1.46,1.46,0,0,1-1.2.55,1.16,1.16,0,0,1-.92-.34,1,1,0,0,1-.19-.91c.12-.52.62-.83,1.5-.92l.57-.06q.58-.08.66-.42a.46.46,0,0,0-.1-.48.93.93,0,0,0-.63-.17,1.24,1.24,0,0,0-.68.16,1,1,0,0,0-.34.56H99.8a1.6,1.6,0,0,1,.54-.92,1.71,1.71,0,0,1,1.07-.28Q102.87,110,102.61,111.29Zm-2,2a1.36,1.36,0,0,0,.79-.24,1.18,1.18,0,0,0,.46-.68l.13-.57a1.23,1.23,0,0,1-.52.16l-.54.07c-.58.07-.9.27-1,.6a.53.53,0,0,0,.11.49A.7.7,0,0,0,100.57,113.33Z"
            fill="#fff"
          />
          <path
            d="M105.05,110.6h-.74l-.48,2.25a.38.38,0,0,0,0,.31.37.37,0,0,0,.29.1h.33l-.1.47-.35,0a.69.69,0,0,1-.76-.92l.48-2.23h-.6l.11-.49h.59l.22-1h.56l-.22,1h.74Z"
            fill="#fff"
          />
          <path
            d="M110.62,110.19H110a1,1,0,0,0-.11-.68.9.9,0,0,0-.62-.32l-.38,1.77.11,0c1,.23,1.39.76,1.22,1.59a1.39,1.39,0,0,1-.63.92,2.44,2.44,0,0,1-1.31.34l-.15.7h-.49l.16-.73a1.72,1.72,0,0,1-1.14-.55,1.44,1.44,0,0,1-.27-1.18H107a1,1,0,0,0,.91,1.2l.4-1.87-.11,0a1.8,1.8,0,0,1-1-.52,1.12,1.12,0,0,1-.22-1,1.4,1.4,0,0,1,.62-.9,2.31,2.31,0,0,1,1.26-.32l.14-.7h.49l-.15.73a1.38,1.38,0,0,1,1,.5A1.35,1.35,0,0,1,110.62,110.19Zm-3-.29a.64.64,0,0,0,.1.59,1.33,1.33,0,0,0,.71.36l.35-1.69C108.13,109.18,107.74,109.43,107.65,109.9Zm2,2.61a.7.7,0,0,0-.11-.62,1.49,1.49,0,0,0-.74-.37l-.38,1.79a1.67,1.67,0,0,0,.84-.23A.88.88,0,0,0,109.65,112.51Z"
            fill="#fff"
          />
          <path
            d="M113.76,108.74h.63l-.68,3.21h.67l-.11.53h-.67l-.27,1.25h-.59l.27-1.25h-2.5l.12-.57Zm-.14.83L111.27,112h1.85Z"
            fill="#fff"
          />
          <path
            d="M118.29,109.19a2.93,2.93,0,0,1,.19,2.21,3.74,3.74,0,0,1-.82,1.77,1.89,1.89,0,0,1-1.48.66,1.42,1.42,0,0,1-1-.36,1.27,1.27,0,0,1-.37-1h.59c0,.54.33.81.9.81.79,0,1.34-.64,1.63-1.92a1.63,1.63,0,0,1-1.33.6,1.38,1.38,0,0,1-1.15-.52,1.51,1.51,0,0,1-.22-1.33,1.92,1.92,0,0,1,.64-1.1,1.83,1.83,0,0,1,1.21-.41A1.45,1.45,0,0,1,118.29,109.19Zm-1.59,2.26a1.28,1.28,0,0,0,.82-.29,1.25,1.25,0,0,0,.43-.7,1.1,1.1,0,0,0-.13-.95,1,1,0,0,0-.81-.36,1.16,1.16,0,0,0-.79.3,1.35,1.35,0,0,0-.43.75,1.09,1.09,0,0,0,.16.91A.87.87,0,0,0,116.7,111.45Z"
            fill="#fff"
          />
          <path
            d="M226,113.84a2.11,2.11,0,0,1-1.46-.46,1.36,1.36,0,0,1-.39-1.3h.61c-.11.82.32,1.23,1.3,1.23a1.66,1.66,0,0,0,.9-.21.88.88,0,0,0,.42-.59.68.68,0,0,0-.14-.65,1.73,1.73,0,0,0-.86-.37l-.42-.1a1.8,1.8,0,0,1-1-.52,1.09,1.09,0,0,1-.22-1,1.4,1.4,0,0,1,.62-.9,2.37,2.37,0,0,1,1.27-.32,1.86,1.86,0,0,1,1.41.43,1.35,1.35,0,0,1,.27,1.13h-.6a.83.83,0,0,0-.17-.77,1.38,1.38,0,0,0-1-.26c-.74,0-1.16.25-1.26.74a.66.66,0,0,0,.14.62,1.74,1.74,0,0,0,.83.37l.42.1c1,.23,1.39.76,1.22,1.59a1.42,1.42,0,0,1-.65.92A2.35,2.35,0,0,1,226,113.84Z"
            fill="#fff"
          />
          <path
            d="M230.54,110.6h-.74l-.48,2.25a.38.38,0,0,0,0,.31.37.37,0,0,0,.29.1H230l-.1.47-.35,0a.69.69,0,0,1-.76-.92l.48-2.23h-.6l.11-.49h.59l.22-1h.56l-.22,1h.74Z"
            fill="#fff"
          />
          <path
            d="M233.75,111.29l-.36,1.68c0,.2,0,.3.16.3h.17l-.09.46-.31,0a.54.54,0,0,1-.38-.13.45.45,0,0,1-.13-.36,1.46,1.46,0,0,1-1.2.55,1.16,1.16,0,0,1-.92-.34,1,1,0,0,1-.19-.91c.12-.52.62-.83,1.5-.92l.57-.06q.59-.08.66-.42a.46.46,0,0,0-.1-.48.93.93,0,0,0-.63-.17,1.24,1.24,0,0,0-.68.16,1,1,0,0,0-.34.56h-.54a1.6,1.6,0,0,1,.54-.92,1.71,1.71,0,0,1,1.07-.28Q234,110,233.75,111.29Zm-2,2a1.36,1.36,0,0,0,.79-.24,1.18,1.18,0,0,0,.46-.68l.13-.57a1.23,1.23,0,0,1-.52.16l-.54.07c-.58.07-.9.27-1,.6a.53.53,0,0,0,.11.49A.7.7,0,0,0,231.71,113.33Z"
            fill="#fff"
          />
          <path
            d="M236.56,110.06h.18l-.11.54h-.14a1.55,1.55,0,0,0-.93.28,1.25,1.25,0,0,0-.48.79l-.44,2.06h-.56l.77-3.62h.53l-.12.56A1.65,1.65,0,0,1,236.56,110.06Z"
            fill="#fff"
          />
          <path
            d="M238.77,110.6H238l-.48,2.25a.42.42,0,0,0,0,.31.34.34,0,0,0,.29.1h.32l-.09.47-.35,0a.82.82,0,0,1-.66-.24.79.79,0,0,1-.11-.68l.48-2.23h-.6l.11-.49h.59l.22-1h.56l-.22,1h.75Z"
            fill="#fff"
          />
          <path
            d="M239.26,113.73h-.56l.77-3.62H240Zm.9-4.16h-.59l.14-.69h.59Z"
            fill="#fff"
          />
          <path
            d="M242.51,110a1.06,1.06,0,0,1,.9.38,1.34,1.34,0,0,1,.11,1.08l-.49,2.24h-.56l.48-2.27c.14-.62-.06-.93-.58-.93a1.27,1.27,0,0,0-.82.31,1.39,1.39,0,0,0-.46.84l-.44,2h-.56l.77-3.62h.54l-.11.49A1.6,1.6,0,0,1,242.51,110Z"
            fill="#fff"
          />
          <path
            d="M247.25,110.62l.11-.51h.53l-.74,3.48a2.1,2.1,0,0,1-.64,1.19,1.85,1.85,0,0,1-1.28.4,1.55,1.55,0,0,1-1-.31,1,1,0,0,1-.33-.85h.56a.63.63,0,0,0,.21.52,1.11,1.11,0,0,0,.66.17,1.24,1.24,0,0,0,1.31-1.12l0-.25a1.63,1.63,0,0,1-1.16.47,1.33,1.33,0,0,1-1.18-.57,1.84,1.84,0,0,1-.19-1.47,2.31,2.31,0,0,1,.73-1.29,2,2,0,0,1,1.27-.45A1.22,1.22,0,0,1,247.25,110.62Zm-1.69,2.69a1.34,1.34,0,0,0,.89-.34A1.8,1.8,0,0,0,247,112l0-.22a1.3,1.3,0,0,0-.15-.95.9.9,0,0,0-.78-.34,1.25,1.25,0,0,0-.9.36,1.77,1.77,0,0,0-.5,1,1.38,1.38,0,0,0,.09,1A.82.82,0,0,0,245.56,113.31Z"
            fill="#fff"
          />
          <path
            d="M252.6,111.29l-.36,1.68c0,.2,0,.3.16.3h.18l-.1.46-.31,0a.56.56,0,0,1-.38-.13.45.45,0,0,1-.13-.36,1.45,1.45,0,0,1-1.2.55,1.16,1.16,0,0,1-.92-.34.94.94,0,0,1-.18-.91c.11-.52.61-.83,1.49-.92l.57-.06c.39,0,.61-.19.67-.42a.48.48,0,0,0-.11-.48.93.93,0,0,0-.63-.17,1.26,1.26,0,0,0-.68.16.94.94,0,0,0-.33.56h-.55a1.6,1.6,0,0,1,.54-.92,1.73,1.73,0,0,1,1.07-.28Q252.86,110,252.6,111.29Zm-2,2a1.36,1.36,0,0,0,.79-.24,1.18,1.18,0,0,0,.46-.68l.13-.57a1.23,1.23,0,0,1-.52.16l-.54.07q-.87.11-1,.6a.51.51,0,0,0,.1.49A.7.7,0,0,0,250.56,113.33Z"
            fill="#fff"
          />
          <path
            d="M255,110.6h-.74l-.48,2.25a.38.38,0,0,0,0,.31.37.37,0,0,0,.29.1h.33l-.1.47-.35,0a.69.69,0,0,1-.76-.92l.48-2.23h-.6l.11-.49h.59l.22-1h.56l-.22,1h.74Z"
            fill="#fff"
          />
          <path
            d="M260.61,110.19H260a1,1,0,0,0-.11-.68.9.9,0,0,0-.62-.32l-.38,1.77.11,0c1,.23,1.39.76,1.22,1.59a1.39,1.39,0,0,1-.63.92,2.44,2.44,0,0,1-1.31.34l-.15.7h-.49l.16-.73a1.72,1.72,0,0,1-1.14-.55,1.44,1.44,0,0,1-.27-1.18H257a1,1,0,0,0,.91,1.2l.4-1.87-.11,0a1.73,1.73,0,0,1-1-.52,1.1,1.1,0,0,1-.23-1,1.4,1.4,0,0,1,.62-.9,2.31,2.31,0,0,1,1.26-.32l.14-.7h.49l-.15.73a1.38,1.38,0,0,1,1,.5A1.35,1.35,0,0,1,260.61,110.19Zm-3-.29a.67.67,0,0,0,.1.59,1.33,1.33,0,0,0,.71.36l.36-1.69C258.12,109.18,257.73,109.43,257.64,109.9Zm2,2.61a.7.7,0,0,0-.11-.62,1.49,1.49,0,0,0-.74-.37l-.38,1.79a1.67,1.67,0,0,0,.84-.23A.88.88,0,0,0,259.64,112.51Z"
            fill="#fff"
          />
          <path
            d="M263,110.48a1.39,1.39,0,0,1,1.15.51,1.54,1.54,0,0,1,.22,1.34,1.89,1.89,0,0,1-.64,1.1,1.83,1.83,0,0,1-1.21.41,1.45,1.45,0,0,1-1.24-.57,2.93,2.93,0,0,1-.19-2.21,3.73,3.73,0,0,1,.82-1.77,1.89,1.89,0,0,1,1.48-.66,1.44,1.44,0,0,1,1,.36,1.27,1.27,0,0,1,.37,1h-.59a.78.78,0,0,0-.9-.81c-.79,0-1.34.64-1.63,1.92A1.63,1.63,0,0,1,263,110.48Zm.78,1.78a1.17,1.17,0,0,0-.16-.91.88.88,0,0,0-.76-.34,1.23,1.23,0,0,0-.8.29,1.19,1.19,0,0,0-.44.7,1.1,1.1,0,0,0,.13,1,1,1,0,0,0,.81.36,1.16,1.16,0,0,0,.78-.29A1.36,1.36,0,0,0,263.78,112.26Z"
            fill="#fff"
          />
          <path
            d="M265.29,109.27l.11-.53h3.2l-.1.46a8.79,8.79,0,0,0-2.75,4.53h-.61a8.5,8.5,0,0,1,1-2.42,8.92,8.92,0,0,1,1.71-2Z"
            fill="#fff"
          />
          <path
            d="M226.58,297.84a2.11,2.11,0,0,1-1.46-.46,1.36,1.36,0,0,1-.39-1.3h.61c-.11.82.32,1.23,1.3,1.23a1.66,1.66,0,0,0,.9-.21.85.85,0,0,0,.42-.59.68.68,0,0,0-.14-.65,1.73,1.73,0,0,0-.86-.37l-.42-.1a1.8,1.8,0,0,1-1-.52,1.12,1.12,0,0,1-.22-1,1.4,1.4,0,0,1,.62-.9,2.37,2.37,0,0,1,1.27-.32,1.84,1.84,0,0,1,1.41.43,1.35,1.35,0,0,1,.27,1.13h-.6a.83.83,0,0,0-.17-.77,1.38,1.38,0,0,0-.95-.26c-.74,0-1.16.25-1.26.74a.66.66,0,0,0,.14.62,1.74,1.74,0,0,0,.83.37l.42.1c1,.23,1.39.76,1.22,1.59a1.42,1.42,0,0,1-.65.92A2.35,2.35,0,0,1,226.58,297.84Z"
            fill="#fff"
          />
          <path
            d="M231.12,294.6h-.74l-.48,2.25a.38.38,0,0,0,0,.31.37.37,0,0,0,.29.1h.33l-.09.47-.35,0a.81.81,0,0,1-.66-.24.79.79,0,0,1-.11-.68l.48-2.23h-.6l.11-.49h.59l.22-1h.56l-.22,1h.75Z"
            fill="#fff"
          />
          <path
            d="M234.33,295.29,234,297c0,.2,0,.3.16.3h.18l-.1.46-.31,0a.56.56,0,0,1-.38-.13.45.45,0,0,1-.13-.36,1.45,1.45,0,0,1-1.2.55,1.16,1.16,0,0,1-.92-.34.94.94,0,0,1-.18-.91c.11-.52.61-.83,1.49-.92l.57-.06c.39-.05.61-.19.67-.42a.48.48,0,0,0-.11-.48.93.93,0,0,0-.63-.17,1.26,1.26,0,0,0-.68.16,1,1,0,0,0-.33.56h-.55a1.6,1.6,0,0,1,.54-.92,1.73,1.73,0,0,1,1.07-.28C234.11,294,234.5,294.45,234.33,295.29Zm-2,2a1.36,1.36,0,0,0,.79-.24,1.18,1.18,0,0,0,.46-.68l.13-.57a1.23,1.23,0,0,1-.52.16l-.54.07q-.87.1-1,.6a.51.51,0,0,0,.1.49A.7.7,0,0,0,232.29,297.33Z"
            fill="#fff"
          />
          <path
            d="M237.14,294.06h.18l-.11.54h-.14a1.55,1.55,0,0,0-.93.28,1.25,1.25,0,0,0-.48.79l-.44,2.06h-.56l.77-3.62H236l-.11.56A1.63,1.63,0,0,1,237.14,294.06Z"
            fill="#fff"
          />
          <path
            d="M239.35,294.6h-.74l-.48,2.25a.42.42,0,0,0,0,.31.34.34,0,0,0,.29.1h.32l-.09.47-.35,0a.82.82,0,0,1-.66-.24.79.79,0,0,1-.11-.68l.48-2.23h-.6l.11-.49h.6l.21-1h.56l-.21,1h.74Z"
            fill="#fff"
          />
          <path
            d="M239.84,297.73h-.56l.77-3.62h.56Zm.91-4.16h-.6l.14-.69h.6Z"
            fill="#fff"
          />
          <path
            d="M243.09,294a1,1,0,0,1,.9.38,1.3,1.3,0,0,1,.11,1.08l-.48,2.24h-.56l.47-2.27c.14-.62-.06-.93-.58-.93a1.27,1.27,0,0,0-.82.31,1.39,1.39,0,0,0-.46.84l-.43,2.05h-.56l.77-3.62H242l-.11.49A1.6,1.6,0,0,1,243.09,294Z"
            fill="#fff"
          />
          <path
            d="M247.83,294.62l.11-.51h.53l-.74,3.48a2,2,0,0,1-.64,1.19,1.85,1.85,0,0,1-1.28.4,1.57,1.57,0,0,1-1.05-.31,1,1,0,0,1-.33-.85H245a.63.63,0,0,0,.21.52,1.11,1.11,0,0,0,.66.17,1.24,1.24,0,0,0,1.31-1.12l.05-.25a1.63,1.63,0,0,1-1.15.47,1.34,1.34,0,0,1-1.19-.57,1.88,1.88,0,0,1-.19-1.47,2.31,2.31,0,0,1,.73-1.29,2,2,0,0,1,1.28-.45A1.22,1.22,0,0,1,247.83,294.62Zm-1.69,2.69A1.32,1.32,0,0,0,247,297a1.8,1.8,0,0,0,.54-.93l.05-.22a1.25,1.25,0,0,0-.15-.95.9.9,0,0,0-.77-.34,1.26,1.26,0,0,0-.91.36,1.77,1.77,0,0,0-.5,1,1.38,1.38,0,0,0,.09,1.05A.82.82,0,0,0,246.14,297.31Z"
            fill="#fff"
          />
          <path
            d="M253.18,295.29l-.36,1.68c0,.2,0,.3.16.3h.18l-.1.46-.31,0a.56.56,0,0,1-.38-.13.45.45,0,0,1-.13-.36,1.44,1.44,0,0,1-1.2.55,1.16,1.16,0,0,1-.92-.34.94.94,0,0,1-.18-.91c.11-.52.61-.83,1.49-.92l.57-.06c.39-.05.61-.19.67-.42a.48.48,0,0,0-.11-.48.93.93,0,0,0-.63-.17,1.26,1.26,0,0,0-.68.16,1,1,0,0,0-.33.56h-.55a1.6,1.6,0,0,1,.54-.92A1.73,1.73,0,0,1,252,294C253,294,253.35,294.45,253.18,295.29Zm-2,2a1.36,1.36,0,0,0,.79-.24,1.18,1.18,0,0,0,.46-.68l.13-.57a1.23,1.23,0,0,1-.52.16l-.54.07q-.87.1-1,.6a.51.51,0,0,0,.1.49A.7.7,0,0,0,251.14,297.33Z"
            fill="#fff"
          />
          <path
            d="M255.62,294.6h-.74l-.48,2.25a.38.38,0,0,0,0,.31.37.37,0,0,0,.29.1h.33l-.09.47-.35,0a.81.81,0,0,1-.66-.24.79.79,0,0,1-.11-.68l.48-2.23h-.6l.11-.49h.59l.22-1h.56l-.22,1h.75Z"
            fill="#fff"
          />
          <path
            d="M261.19,294.19h-.59a1,1,0,0,0-.11-.68.9.9,0,0,0-.62-.32l-.38,1.77.11,0c1,.23,1.39.76,1.22,1.59a1.39,1.39,0,0,1-.63.92,2.42,2.42,0,0,1-1.31.34l-.14.7h-.5l.16-.73a1.72,1.72,0,0,1-1.14-.55,1.44,1.44,0,0,1-.27-1.18h.61a1,1,0,0,0,.91,1.2l.4-1.87-.1,0a1.73,1.73,0,0,1-1-.52,1.1,1.1,0,0,1-.23-1,1.4,1.4,0,0,1,.62-.9,2.31,2.31,0,0,1,1.26-.32l.15-.7h.49l-.16.73a1.38,1.38,0,0,1,1,.5A1.35,1.35,0,0,1,261.19,294.19Zm-3-.29a.67.67,0,0,0,.1.59,1.33,1.33,0,0,0,.71.36l.36-1.69C258.7,293.18,258.31,293.43,258.22,293.9Zm2,2.61a.67.67,0,0,0-.11-.62,1.49,1.49,0,0,0-.74-.37l-.38,1.79a1.67,1.67,0,0,0,.84-.23A.88.88,0,0,0,260.22,296.51Z"
            fill="#fff"
          />
          <path
            d="M261.61,293.27l.11-.53h3.2l-.09.46a8.87,8.87,0,0,0-2.76,4.53h-.6a8.5,8.5,0,0,1,1-2.42,9.13,9.13,0,0,1,1.7-2Z"
            fill="#fff"
          />
          <path
            d="M268,292.74l-.11.53h-2.1l-.54,1.49a1.54,1.54,0,0,1,.92-.28,1.35,1.35,0,0,1,1.16.52,1.63,1.63,0,0,1,.21,1.33,2,2,0,0,1-.62,1.07,1.76,1.76,0,0,1-1.23.44,1.62,1.62,0,0,1-1.17-.41,1.3,1.3,0,0,1-.39-1.09h.6a.84.84,0,0,0,.25.72,1.1,1.1,0,0,0,.76.25,1.12,1.12,0,0,0,.78-.29,1.48,1.48,0,0,0,.42-.78,1,1,0,0,0-.13-.89.88.88,0,0,0-.76-.34,1.16,1.16,0,0,0-1.08.6h-.58l1-2.87Z"
            fill="#fff"
          />
          <path
            d="M76.94,274.84a2.11,2.11,0,0,1-1.46-.46,1.36,1.36,0,0,1-.39-1.3h.61c-.11.82.33,1.23,1.3,1.23a1.73,1.73,0,0,0,.91-.21.87.87,0,0,0,.41-.59.68.68,0,0,0-.13-.65,1.78,1.78,0,0,0-.87-.37l-.42-.1a1.73,1.73,0,0,1-1-.52,1.1,1.1,0,0,1-.23-1,1.38,1.38,0,0,1,.63-.9,2.31,2.31,0,0,1,1.26-.32,1.84,1.84,0,0,1,1.41.43,1.35,1.35,0,0,1,.27,1.13H78.7a.86.86,0,0,0-.18-.77,1.37,1.37,0,0,0-1-.26c-.74,0-1.15.25-1.25.74a.66.66,0,0,0,.13.62,1.79,1.79,0,0,0,.83.37l.42.1c1,.23,1.39.76,1.22,1.59a1.41,1.41,0,0,1-.64.92A2.38,2.38,0,0,1,76.94,274.84Z"
            fill="#fff"
          />
          <path
            d="M81.48,271.6h-.74l-.48,2.25a.42.42,0,0,0,0,.31.36.36,0,0,0,.29.1h.33l-.1.47-.35,0a.82.82,0,0,1-.66-.24.79.79,0,0,1-.11-.68l.48-2.23h-.59l.1-.49h.6l.21-1h.56l-.21,1h.74Z"
            fill="#fff"
          />
          <path
            d="M84.69,272.29,84.33,274c0,.2,0,.3.16.3h.18l-.1.46-.31,0a.56.56,0,0,1-.38-.13.48.48,0,0,1-.13-.36,1.44,1.44,0,0,1-1.2.55,1.17,1.17,0,0,1-.92-.34.94.94,0,0,1-.18-.91c.11-.52.61-.83,1.49-.92l.57-.06c.39-.05.61-.19.67-.42a.48.48,0,0,0-.11-.48.93.93,0,0,0-.63-.17,1.26,1.26,0,0,0-.68.16,1,1,0,0,0-.33.56h-.55a1.6,1.6,0,0,1,.54-.92,1.73,1.73,0,0,1,1.07-.28Q85,271,84.69,272.29Zm-2,2a1.34,1.34,0,0,0,.79-.24,1.13,1.13,0,0,0,.46-.68l.13-.57a1.23,1.23,0,0,1-.52.16l-.54.07q-.87.1-1,.6a.53.53,0,0,0,.1.49A.7.7,0,0,0,82.65,274.33Z"
            fill="#fff"
          />
          <path
            d="M87.5,271.06h.18l-.11.54h-.14a1.55,1.55,0,0,0-.93.28,1.23,1.23,0,0,0-.47.79l-.44,2.06H85l.78-3.62h.53l-.12.56A1.63,1.63,0,0,1,87.5,271.06Z"
            fill="#fff"
          />
          <path
            d="M89.72,271.6H89l-.47,2.25a.38.38,0,0,0,0,.31.36.36,0,0,0,.29.1h.33l-.1.47-.35,0a.8.8,0,0,1-.65-.24.76.76,0,0,1-.11-.68l.47-2.23h-.59l.1-.49h.6l.21-1h.56l-.21,1h.74Z"
            fill="#fff"
          />
          <path
            d="M90.21,274.73h-.57l.78-3.62H91Zm.9-4.16h-.6l.14-.69h.6Z"
            fill="#fff"
          />
          <path
            d="M93.45,271a1,1,0,0,1,.9.38,1.3,1.3,0,0,1,.11,1.08L94,274.73h-.56l.47-2.27c.14-.62,0-.93-.58-.93a1.24,1.24,0,0,0-.81.31,1.4,1.4,0,0,0-.47.84l-.43,2.05H91l.77-3.62h.53l-.11.49A1.62,1.62,0,0,1,93.45,271Z"
            fill="#fff"
          />
          <path
            d="M98.19,271.62l.11-.51h.54l-.75,3.48a2.1,2.1,0,0,1-.63,1.19,1.9,1.9,0,0,1-1.29.4,1.54,1.54,0,0,1-1-.31,1,1,0,0,1-.33-.85h.56a.58.58,0,0,0,.21.52,1.05,1.05,0,0,0,.65.17,1.24,1.24,0,0,0,1.31-1.12l0-.25a1.61,1.61,0,0,1-1.15.47,1.35,1.35,0,0,1-1.19-.57,1.88,1.88,0,0,1-.18-1.47,2.29,2.29,0,0,1,.72-1.29,2,2,0,0,1,1.28-.45A1.21,1.21,0,0,1,98.19,271.62Zm-1.68,2.69a1.31,1.31,0,0,0,.88-.34,1.8,1.8,0,0,0,.54-.93l0-.22a1.21,1.21,0,0,0-.15-.95.9.9,0,0,0-.77-.34,1.26,1.26,0,0,0-.91.36,1.77,1.77,0,0,0-.5,1,1.38,1.38,0,0,0,.09,1.05A.83.83,0,0,0,96.51,274.31Z"
            fill="#fff"
          />
          <path
            d="M103.54,272.29l-.36,1.68c0,.2,0,.3.16.3h.18l-.1.46-.31,0a.56.56,0,0,1-.38-.13.48.48,0,0,1-.13-.36,1.44,1.44,0,0,1-1.2.55,1.17,1.17,0,0,1-.92-.34.94.94,0,0,1-.18-.91c.12-.52.61-.83,1.49-.92l.57-.06c.39-.05.61-.19.67-.42a.46.46,0,0,0-.11-.48.93.93,0,0,0-.63-.17,1.26,1.26,0,0,0-.68.16,1,1,0,0,0-.33.56h-.55a1.6,1.6,0,0,1,.54-.92,1.75,1.75,0,0,1,1.07-.28Q103.81,271,103.54,272.29Zm-2,2a1.34,1.34,0,0,0,.79-.24,1.19,1.19,0,0,0,.47-.68l.12-.57a1.23,1.23,0,0,1-.52.16l-.54.07c-.57.07-.9.27-1,.6a.53.53,0,0,0,.1.49A.7.7,0,0,0,101.5,274.33Z"
            fill="#fff"
          />
          <path
            d="M106,271.6h-.74l-.48,2.25a.42.42,0,0,0,0,.31.36.36,0,0,0,.29.1h.33l-.1.47-.35,0a.82.82,0,0,1-.66-.24.79.79,0,0,1-.11-.68l.48-2.23h-.59l.1-.49h.6l.21-1h.56l-.21,1h.74Z"
            fill="#fff"
          />
          <path
            d="M111.55,271.19H111a.91.91,0,0,0-.11-.68.9.9,0,0,0-.62-.32l-.38,1.77.12,0c1,.23,1.39.76,1.21,1.59a1.39,1.39,0,0,1-.63.92,2.42,2.42,0,0,1-1.31.34l-.14.7h-.49l.15-.73a1.72,1.72,0,0,1-1.14-.55,1.44,1.44,0,0,1-.27-1.18H108a1,1,0,0,0,.91,1.2l.4-1.87-.1,0a1.73,1.73,0,0,1-1-.52,1.1,1.1,0,0,1-.23-1,1.4,1.4,0,0,1,.62-.9,2.31,2.31,0,0,1,1.26-.32l.15-.7h.49l-.16.73a1.38,1.38,0,0,1,1,.5A1.31,1.31,0,0,1,111.55,271.19Zm-3-.29a.67.67,0,0,0,.1.59,1.38,1.38,0,0,0,.71.36l.36-1.69C109.06,270.18,108.67,270.43,108.58,270.9Zm2,2.61a.67.67,0,0,0-.11-.62,1.44,1.44,0,0,0-.74-.37l-.37,1.79a1.67,1.67,0,0,0,.84-.23A.86.86,0,0,0,110.58,273.51Z"
            fill="#fff"
          />
          <path
            d="M115.61,271.11a1.38,1.38,0,0,1-1,1,1.19,1.19,0,0,1,.69,1.42,1.5,1.5,0,0,1-.63,1,2.08,2.08,0,0,1-1.27.37,1.79,1.79,0,0,1-1.36-.48,1.33,1.33,0,0,1-.28-1.27,1.45,1.45,0,0,1,1.17-1.09,1.08,1.08,0,0,1-.48-1.22,1.26,1.26,0,0,1,.57-.86,2.16,2.16,0,0,1,1.17-.29,1.59,1.59,0,0,1,1.16.41A1.08,1.08,0,0,1,115.61,271.11Zm-.91,2.29a.84.84,0,0,0-.16-.8,1.11,1.11,0,0,0-.84-.28,1.67,1.67,0,0,0-.9.22.92.92,0,0,0-.44.65.83.83,0,0,0,.17.81,1.2,1.2,0,0,0,.89.31,1.39,1.39,0,0,0,.86-.25A1,1,0,0,0,114.7,273.4Zm.32-2.29a.73.73,0,0,0-.14-.69,1,1,0,0,0-.73-.26c-.67,0-1,.24-1.13.72a.73.73,0,0,0,.17.7,1.06,1.06,0,0,0,.75.25,1.35,1.35,0,0,0,.72-.19A.83.83,0,0,0,115,271.11Z"
            fill="#fff"
          />
          <path
            d="M119.35,270.19a2.92,2.92,0,0,1,.2,2.21,3.83,3.83,0,0,1-.82,1.77,1.9,1.9,0,0,1-1.48.66,1.42,1.42,0,0,1-1-.36,1.27,1.27,0,0,1-.37-1h.58a.79.79,0,0,0,.91.81c.79,0,1.33-.64,1.63-1.92a1.64,1.64,0,0,1-1.33.6,1.37,1.37,0,0,1-1.15-.52,1.51,1.51,0,0,1-.23-1.33,2,2,0,0,1,.64-1.1,1.85,1.85,0,0,1,1.21-.41A1.44,1.44,0,0,1,119.35,270.19Zm-1.58,2.26a1.24,1.24,0,0,0,.81-.29,1.21,1.21,0,0,0,.44-.7,1.1,1.1,0,0,0-.13-.95,1,1,0,0,0-.81-.36,1.13,1.13,0,0,0-.79.3,1.35,1.35,0,0,0-.43.75,1.13,1.13,0,0,0,.15.91A.9.9,0,0,0,117.77,272.45Z"
            fill="#fff"
          />
        </g>
      </svg>
    </>
  );
};

export default PricesSection;
